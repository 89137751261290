import React, { useState, useMemo } from "react";
import { Row, Button, Menu, Modal, Tooltip, Dropdown,Col, Breadcrumb } from "antd";
import {
  LoadingOutlined,
  ShareAltOutlined,
  PlusOutlined,
  DownOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";


import axios from "axios";

import ENVIRONMENT from "../../../../environments";

import EntityAccessDenied from "../EntityAccessDenied/EntityAccessDenied";

import DottedLoader from "../../DottedLoader";

import FileConstants from "../../../../FileConstants";

import SpaceColorVariationModal from "../ProductComponents/SpaceColorVariationModal";
import OtherVariationsAction from "./OtherVariationsAction";
import AddEntityToProject from "../AddEntityToProject/AddEntityToProject";
import { downloadNameMapping } from "./SpacePageConstants";

const QUERY_PARAMS_TO_RETAIN = ["category", "search"];

const Header = ({
  isLoading,
  userAccess,
  roomDetails,
  storePage,
  folderParentID,
  params,
  setSharingModal,
  downloadSceneImagesArchive,
  platform,
  download_links,
  customer_username,
  company_id,
  is_store_item,
  savedID,
  spaceDetails,
  userProjects,
  performStoreAction,
  inProgress,
  downloadImagesMapping,
  downloadImagesByType
}) => {
  const companyId = localStorage.getItem("company_id");
  const IS_MSPROVIDER = localStorage.getItem('is_msprovider');
  const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
  const [dummySceneSetup, setDummySceneSetup] = useState(false);
  const [dummySceneError, setDummySceneError] = useState(false);
  const [variationModal, setVariationModal] = useState(false);
  const [spaceColorVariationModal, setSpaceColorVariationModal] =
    useState(false);
  const [projectModalVisible, setProjectModalVisible] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState(null);

  const tabNameMapping={
    'active': 'Active Projects',
    'requested': 'Requested Projects',
    'approved': 'Approved Projects',
    'mspActive': 'Active Projects',
    'mspApproved': 'Approved Projects'
    }

  const urlParams = new URLSearchParams(window.location.search);
  const projectId = urlParams.get("project_id")
  const projectName = urlParams.get("project_name")
  const projectTab = urlParams.get("projectTab")
  const selectedUser = urlParams.get("selectedUser")

  const getLibraryUrl = () => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    let queryString = "";
    for (const [key, value] of searchParams.entries()) {
      if (key != "entity_id" && QUERY_PARAMS_TO_RETAIN.includes(key)) {
        queryString = `${queryString}&${key}=${encodeURIComponent(value)}`;
      }
    }

    if (folderParentID) {
      return "/rooms?folder_id=" + folderParentID + queryString;
    }
    return "/rooms?entity_id=" + params.id + queryString;
  };


  const handleCustomerDummyScene = () => {
    if (roomDetails.customer_dummy_scene_id == "") {
      setDummySceneSetup(true);
      createCollection(
        "Dummy-collection-" + roomDetails.room_id,
        roomDetails.room_id
      );
    } else {
      window.location.href =
        "/scene_builder/" +
        roomDetails.customer_dummy_scene_id +
        "?customer_dummy_scene=true";
    }
  };

  const createCollection = (collectionName, roomId) => {
    const payload = {
      collection_name: collectionName,
      username: localStorage.getItem("username"),
    };

    if (companyId != undefined) {
      payload.company_id = companyId;
    }

    axios
      .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
      .then((res) => {
        addRoomToCollection(res.data.collection_id, roomId);
      })
      .catch(() => {
        setDummySceneError(true);
      });
  };

  const addRoomToCollection = (collectionId, roomId) => {
    const payload = {
      collection_id: collectionId,
      room_id: roomId,
      username: localStorage.getItem("username"),
    };
    axios
      .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
      .then((res) => {
        createScene(collectionId, roomId);
      })
      .catch(() => {
        console.log("Room could not be added to collection ", collectionId);
        setDummySceneError(true);
      });
  };

  const createScene = (collectionId, roomId) => {
    {
      const payload = {
        scene_name: "Dummy-scene-" + roomId,
        collection_id: collectionId,
        room_id: roomId,
        is_hidden: true,
        username: localStorage.getItem("username"),
      };

      if (companyId != undefined) {
        payload.company_id = companyId;
      }

      axios
        .post(ENVIRONMENT.UPDATE_SCENE, payload)
        .then((res) => {
          const sceneId = res.data["scene_id"];

          let update_payload = {
            room_id: roomId,
            customer_dummy_scene_id: sceneId,
          };
          axios
            .post(ENVIRONMENT.UPDATE_ROOM, update_payload)
            .then((response) => {
              console.log(response);
              setDummySceneSetup(false);
              window.location.href =
                "/scene_builder/" + sceneId + "?customer_dummy_scene=true";
            });
        })
        .catch(() => {
          setDummySceneError(true);
        });
    }
  };

  const addMenu = useMemo(
    () => (
      <Menu
        className="manrope f-14"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <Menu.Item
          key={1}
        >
          <a
            className="manrope f-14"
            target="_blank"
            onClick={() => {
              setProjectModalVisible(true);
              setSelectedSpace(params.id);
            }}
            style={{ margin: "0px", padding: "0px" }}
          >
            Add to Project
          </a>
        </Menu.Item>
        <Menu.Item key={2}>
          <a
            className="manrope f-14"
            target="_blank"
            onClick={() => performStoreAction()}
            style={{ margin: "0px", padding: "0px" }}
          >
            {is_store_item ? "Remove from Store" : "Add to Store"}
          </a>
        </Menu.Item>
      </Menu>
    ),
    [is_store_item]
  );

  const downloadMenu = (
    <Menu style={{ width: "200px" }}>
        <Menu.ItemGroup key={"assets"} title={<span className='manrope f-12 grey-8c'>Images</span>} >
            <Menu.Item key={0}>
                <div className='manrope f-14 black-55' onClick={() => downloadSceneImagesArchive()}>360 Image(s)</div>
            </Menu.Item>
            {Object.entries(downloadImagesMapping)?.map(([key,val],ind)=><Menu.Item key={ind}>
                <div className='manrope f-14 black-55' onClick={() => downloadImagesByType(key,`${downloadNameMapping[key]}.zip`)}>{downloadNameMapping[key]}</div>
            </Menu.Item>)}
        </Menu.ItemGroup>

       {download_links?.length>0? <Menu.ItemGroup key={"model"} title={<span className='manrope f-12 grey-8c'>Model</span>} >
        {(download_links || []).map((ele, index) => {
            return (
              <Menu.Item key={index}>
                <a
                  className="manrope f-14"
                  target="_blank"
                  href={ele.model_url.replace(
                    ENVIRONMENT.DEFAULT_BASE_URL,
                    ENVIRONMENT.getBaseURL(platform)
                  )}
                >
                  {ele.display_name}
                </a>
              </Menu.Item>
            );
          })}
        </Menu.ItemGroup>:<></>}
    </Menu>
);

  return (
    <>
      {" "}
      {isLoading ? (
        <DottedLoader />
      ) : userAccess == "restricted" ? (
        <EntityAccessDenied entityType={"space"} />
      ) : (
        <Row
          type="flex"
          justify="space-between"
          style={{ alignItems: "center" }}
        >
           <Col span={12}>
                <Breadcrumb className='display-flex j-s a-c'>
                    { projectName && projectId ? 
                      <><Breadcrumb.Item className="manrope f-14 lh-28 black-73 white-space-text">
                          <a
                              className="manrope f-14 lh-28 black-73 white-space-text"
                              href={projectTab === "approved" || projectTab === "mspApproved" ? `/library-projects?tab=${projectTab}` : `/projects?tab=${projectTab}`}
                          >
                              <ArrowLeftOutlined className='mr-8'/>
                              {IS_MSPROVIDER && MANAGED_CUSTOMER_USERNAME && (projectTab == 'mspActive' || projectTab == 'mspApproved') ? `${MANAGED_CUSTOMER_USERNAME}'s ${tabNameMapping[projectTab]}` : `Your ${tabNameMapping[projectTab]}`}
                          </a>
                      </Breadcrumb.Item><Breadcrumb.Item className="manrope f-14 lh-28 black-73 white-space-text">
                              <a
                                  className="manrope f-14 lh-28 black-73 white-space-text"
                                  href={`/projects/${projectId}?tab=${projectTab}&selectedUser=${selectedUser}`}
                              >
                                  {projectName.substring(0, 15) + (projectName.length > 15 ? '...' : '')}
                              </a>
                          </Breadcrumb.Item></>  :
                      <>
                        <Breadcrumb.Item>
                          <a className='manrope f-14 lh-28 black-73 white-space-text'
                              href={storePage ? '/store' : getLibraryUrl()}>
                              <ArrowLeftOutlined className='mr-8'/>
                              {storePage ? "Store":"Space Library"}
                          </a>
                        </Breadcrumb.Item>
                        {roomDetails.category? <Breadcrumb.Item>
                            <a className='manrope f-14 lh-28 black-73 white-space-text'
                                href={storePage ? '/store' : getLibraryUrl()}>
                              {roomDetails.category}
                            </a>
                        </Breadcrumb.Item>:<></>}
                      </>
                      }
                    <Breadcrumb.Item className='manrope f-14 lh-28 black-d9 limit-lines limit-1'>{roomDetails.room_name}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
          {!storePage && !inProgress ? (
            <div className="flex-row">
              <Tooltip
                title={<span className="manrope f-12 white">Share Space</span>}
              >
                <Button 
                  ghost
                  onClick={() => setSharingModal(true)}
                  className="nav-btns light-blue f-14 ml-16"
                  icon={<ShareAltOutlined />}
                  style={{ marginRight: "16px" }} />

              </Tooltip>

              <div
                id="components-dropdown-demo-dropdown-button mr-8"
                style={{ marginRight: "16px" }}
              >
                <Dropdown
                  overlay={addMenu}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <Button className="modal-okay square font-14">
                    <PlusOutlined /> Add
                  </Button>
                </Dropdown>
              </div>

              <Tooltip
                title={<span className="manrope f-12 white">Download</span>}
              >
                {customer_username == FileConstants.CUSTOMER_USERNAME ||
                company_id == FileConstants.COMPANY_ID ||
                FileConstants.isMSProvider ? (
                  <Dropdown overlay={downloadMenu} trigger={["click"]}>
                      <Button className='product-btn light-blue f-14 br-2' ghost>Download <DownOutlined /></Button>
                  </Dropdown>
                ) : (
                  ""
                )}
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </Row>
      )}
      <Modal visible={dummySceneSetup} closable={false} footer={null}>
        <div>
          <span>
            <LoadingOutlined className="loading-center mb-20" />
          </span>
          <h2
            style={{ textAlign: "center" }}
            className="manrope f-18 black-00 w-600 capitalize"
          >
            Please Wait!
          </h2>
          <h2
            style={{ textAlign: "center" }}
            className="manrope f-16 black-00 w-500"
          >
            We are setting up the space for you.
          </h2>
        </div>
      </Modal>
      <Modal visible={dummySceneError} closable={true} footer={null}>
        <div>
          <span>
            <LoadingOutlined className="loading-center mb-20" />
          </span>
          <h2
            style={{ textAlign: "center" }}
            className="manrope f-18 black-00 w-600 capitalize"
          >
            Error Occurred
          </h2>
          <h2
            style={{ textAlign: "center" }}
            className="manrope f-16 black-00 w-500"
          >
            An error occurred while setting up the space. Please try again
            later.
          </h2>
        </div>
      </Modal>
      <SpaceColorVariationModal
        openVariationTool={() =>
          handleCustomerDummyScene()
        }
        spaceColorVariationModal={spaceColorVariationModal}
        setSpaceColorVariationModal={setSpaceColorVariationModal}
      />
      <OtherVariationsAction
        params={params}
        savedID={savedID}
        roomDetails={roomDetails}
        spaceDetails={spaceDetails}
        setVariationModal={setVariationModal}
        variationModal={variationModal}
      />
      {userProjects ? (
        <AddEntityToProject
          entityId={selectedSpace}
          entityType="Room"
          modalVisible={projectModalVisible}
          setModalVisible={setProjectModalVisible}
          projects={userProjects}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
