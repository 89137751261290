import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import 'antd/dist/antd.css';
import '@ant-design/compatible/assets/index.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import CustomerMainLayout from '../CustomerMainLayout/CustomerMainLayout';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants';
import InAppPurchaseLimitModal from '../InAppPurchaseLimitModal';
import PaymentConfirmationModal from '../../PaymentConfirmationModalSingle/PaymentConfirmationModalSingle';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal';
import SelectScene from '../AddProductLifestyleFlow/SelectScene';
import SelectSpace from '../AddProductLifestyleFlow/SelectSpace';
import SceneName from '../AddProductLifestyleFlow/SceneName';
import SubscriptionPackageUpdate from '../../SubscriptionPackageUpdate';
import SelectProduct from './SelectProduct';

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem('username');
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
const SHARED_ENTITIES = localStorage.getItem('shared_entities');
const AddNewSceneFlow = (props) => {
    const [step, setStep] = useState(2);
    const [isStore, setIsStore] = useState(false);
    const [roomType, setRoomType] = useState('');
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [productsAddedToScene, setProductsAddedToScene] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false);
    const [relatedScenes, setRelatedScenes] = useState([]);
    const [inAppPurchaseLimitModal, setInAppPurchaseLimitModal] = useState(false);
    const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [itemType, setItemType] = useState('space');
    const [requestStatusProduct, setRequestStatusProduct] = useState(null);
    const [cardStatusProduct, setCardStatusProduct] = useState(null);
    const [requestStatusRoom, setRequestStatusRoom] = useState(null);
    const [requestStatusScene, setRequestStatusScene] = useState(null);
    const [requestType, setRequestType] = useState('');
    const [entityName, setEntityName] = useState('');
    const [entityRoomPrice, setEntityRoomPrice] = useState(-1);
    const [entityProductPrice, setEntityProductPrice] = useState(-1);
    const [entityScenePrice, setEntityScenePrice] = useState(-1);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [disablePopup, setDisablePopup] = useState(false);
    const [currentPackage, setCurrentPackage] = useState('');
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState('');
    const [purchaseTypeProduct, setPurchaseTypeProduct] = useState('');
    const [purchaseTypeRoom, setPurchaseTypeRoom] = useState('');
    const [usedCountProducts, setUsedCountProducts] = useState(0);
    const [availableCountProducts, setAvailableCountProducts] = useState(0);
    const [usedCountRooms, setUsedCountRooms] = useState(0);
    const [availableCountRooms, setAvailableCountRooms] = useState(0);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [isALL3DEntity, setIsALL3DEntity] = useState(true);
    const [purchaseTypeTemplate, setPurchaseTypeTemplate] = useState(null);
    const [usedCountTemplates, setUsedCountTemplates] = useState(null);
    const [availableCountTemplates, setAvailableCountTemplates] = useState(null);
    const [cardStatusTemplate, setCardStatusTemplate] = useState(null);
    const [paymentRequestID, setPaymentRequestID] = useState(-1);
    const [libraryPayload, setLibraryPayload] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [confirmRequest, setConfirmRequest] = useState(false);
    const [isSubscriptionUpdateRequest, setIsSubscriptionUpdateRequest] = useState(false);
    const [subsriptionTypeRoom, setSubscriptionTypeRoom] = useState('');
    const [subscriptionTypeTemplates, setSubscriptionTypeTemplates] = useState(null);
    const [cardStatusRoom, setCardStatusRoom] = useState(null);
    const [gatewayPayload, setGatewayPayload] = useState(null);
    const [sceneCreationType, setSceneCreationType] = useState('');
    const [currentCollectionId, setCurrentCollectionId] = useState(-1);
    const [currentSceneId, setCurrentSceneId] = useState(-1);
    const [currentProductId, setCurrentProductId] = useState(-1);
    const [selectedScenes, setSelectedScenes] = useState([]);
    const [isStoreProduct, setIsStoreProduct] = useState(false);
    const [productsAlreadyAdded, setProductsAlreadyAdded] = useState(true);
    const [sceneAlreadyCreated, setSceneAlreadyCreated] = useState(true);
    const [createdSceneId, setCreatedSceneId] = useState(-1);
    const [productIsStore, setProductIsStore] = useState(false);
    const [redirectionPath, setRedirectionPath] = useState(null);
    const [projectID, setProjectID] = useState(null);
    const [projectTab, setProjectTab] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    
    const params =  props?.match?.params;
    const productId = params?.id ?? '';

    useEffect(() => {
        let url_path = props?.match?.path;
        if (props && props.props) {
            url_path = props.props?.match?.path;
        }
        console.log(url_path);
        console.log(props);
        if (props && url_path) {
            if (url_path.includes("create-empty-scene")) {
                setSceneCreationType("new-empty-scene");
                setRoomType("space");
                setStep(2);
                setIsStore(false);
                const project_id = new URLSearchParams(window.location.search).get("project_id");
                setProjectID(project_id);
                const tab = new URLSearchParams(window.location.search).get("tab");
                setProjectTab(tab);
                const curUser = new URLSearchParams(window.location.search).get("selectedUser");
                setSelectedUser(curUser);
            }
            else if (url_path.includes("create-scene-from-template")) {
                setSceneCreationType("template-scene");
                setRoomType("scene");
                setIsStore(false);
                setStep(2);
            }
            else if (url_path.includes("create-new-scene-from-existing-products")) {
                setSceneCreationType("new-scene-from-existing-products");
                setRoomType("space");
                setIsStore(false);
                setStep(2);
                let col_id = new URLSearchParams(window.location.search).get("collection_id")
                let scene_id = new URLSearchParams(window.location.search).get("scene_id")
                const project_id = new URLSearchParams(window.location.search).get("project_id");
                setProjectID(project_id);
                setCurrentSceneId(scene_id)
                setCurrentCollectionId(col_id)
            }
            else if (url_path.includes("add-product-to-existing-scene")) {
                setSceneCreationType("add-product-to-existing-scene");
                setRoomType("scene");
                setItemType("product")
                setIsStore(false);
                setStep(2);
                let prod_id = new URLSearchParams(window.location.search).get("product_id")
                let storeProduct = new URLSearchParams(window.location.search).get("storeItem")
                if (storeProduct){
                    setIsStoreProduct(true)
                    getStoreProductData(prod_id)
                }
                setCurrentProductId(prod_id)
            } else if (url_path.includes('scene_renders')) {
                const project_id = new URLSearchParams(window.location.search).get("project_id");
                setProjectID(project_id);
                setSceneCreationType("scene_renders");
                setRoomType("collection");
                setCurrentCollectionId(props.collection_id);
                setCurrentSceneId(props.scene_id);
                setStep(4);
                setIsStore(false);
                setSelectedRoom({room_id: props.room_id, space_areas: props.space_areas})
            }
        }
    }, [props, props.match?.path]);

    useEffect(() => {
        let redirection_path = new URLSearchParams(window.location.search).get("origin");
        setRedirectionPath(redirection_path);
    }, []);

    const handleCancel = () =>{
        if(redirectionPath){
            window.location.href = redirectionPath + (projectTab ? `?tab=${projectTab}` : '') + (selectedUser ? `&selectedUser=${selectedUser}` : '')  
        } else {
            props.history.push({
                pathname: '/home'
            }); 
        }
    }

    const getStoreProductData = (product_id) => {
        let payload = {"product_id": product_id}
        axios
            .post(ENVIRONMENT.FETCH_PRODUCT, payload)
            .then((res) => {
                setEntityProductPrice(res.data.price ? res.data.price : 0)
                setEntityName(res.data.product_name)
            })
            .catch(() => {
            });
    }

    const handleSubmit = (sceneName) => {
        setLoading(true);
        if (roomType === 'space') {// when adding empty space
            const roomId = selectedRoom.room_id;
            addRoomToCollection(sceneName, roomId);
        } else if (!isStore && roomType === 'scene' && sceneCreationType != 'template-scene') {
            if (sceneCreationType == "add-product-to-existing-scene") {
                if (isStoreProduct && !(requestStatusScene == 'always_allowed' || requestStatusScene == 'allowed')) {
                    setPaymentConfirmationModal(true)
                }
                else {
                    addProductToExistingScenes()
                }
            } else {
                const { collection_id, id } = selectedRoom;
                addProductsAndCreateSceneLogic(collection_id, sceneName, null, null, id);
            }
        } else if (roomType == 'collection') {
            addProductToCollection(currentCollectionId, selectedRoom.space_areas, props.scene_name)
        } else {
            getSceneRelatedDetail(sceneName)
        };
    };

    const addProductToExistingScenes = () => {
        let collection_ids = selectedScenes.map(ele => {
            let newObj = { collection_id: ele.collection_id };
            if (ele.area_name) {
                newObj.area_name = ele.area_name;
            }
            return newObj;
        });
        let payload = {
            collection_ids: collection_ids,
            lambda_type: "collection-update"
        }
        let collection_payload = { product_id: currentProductId }
        payload['add_to_library'] = {
            "product_id": currentProductId,
            "username": localStorage.getItem('username')
        }
        collection_payload['username'] = localStorage.getItem('username');
        payload.collection_payload = collection_payload
        axios
            .post(ENVIRONMENT.ADD_PRODUCT_TO_MULTIPLE_SCENES, payload)
            .then((res) => {
                if (collection_ids.length == 1)
                    window.location.href = `/list-products?scenes_added=1&scene_id=${selectedScenes[0].id}`
                else
                    window.location.href = `/list-products?scenes_added=${selectedScenes.length}`
            })
            .catch(() => {
                setLoading(false);
            });
    }


    const getCurrentCollectionProducts = (areas) => {
        return new Promise((resolve, reject) => {
            const payload = {
                "required_fields": ["id", "name", "product_id"],
                "order_by": "id desc",
                "filter_string": `(id__exact=${currentCollectionId})`
            };

            axios
                .post(ENVIRONMENT.LIST_COLLECTION, payload)
                .then((res) => {
                    let data = res.data;
                    let product_ids = [];
                    for (let i = 0; i < data.length; i++) {
                        let product_obj = { "product_id": data[i].product_id };
                        if (areas?.length) {
                            product_obj.area_name = areas[0];
                        }
                        product_ids.push(product_obj);
                    }
                    resolve(product_ids);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    const addProductToCollection = (collectionId, areas, sceneId) => {
        if (currentCollectionId == -1) {
            if (productId == '' && productsAddedToScene.length == 0) {
                setProductsAlreadyAdded(false);
            } else {
                let product_list = []
                if (productId) {
                    let product_obj = { "product_id": productId }
                    if (selectedRoom?.space_areas.length) {
                        product_obj.area_name = areas[0];
                    }
                    product_list.push(product_obj)
                }
                product_list = product_list.concat(productsAddedToScene)
                let payload = {
                    'username': CUSTOMER_USERNAME,
                    collection_id: collectionId,
                    'products_list': product_list,
                    'update_collection_metadata': true
                }
                if (selectedRoom?.space_areas.length > 0) {
                    payload.space_allocation = true;
                }

                axios
                    .post(ENVIRONMENT.ADD_PRODUCTS_BATCH_TO_COLLECTION, payload)
                    .then((response) => {
                        setProductsAlreadyAdded(false);
                        if (roomType != 'space' && roomType != 'collection') {
                            setSceneAlreadyCreated(false);
                            setCreatedSceneId(sceneId);
                        }
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            }
        } else if (roomType != 'collection') {
            getCurrentCollectionProducts(selectedRoom?.space_areas).then((product_ids) => {
                let payload = {
                    'username': CUSTOMER_USERNAME,
                    'products_list': product_ids,
                    collection_id: collectionId,
                    'update_collection_metadata': true
                }
                if (selectedRoom?.space_areas.length > 0) {
                    payload.space_allocation = true;
                }

                axios
                    .post(ENVIRONMENT.ADD_PRODUCTS_BATCH_TO_COLLECTION, payload)
                    .then((response) => {
                        setProductsAlreadyAdded(false);
                        if (roomType != 'space' && roomType != 'collection') {
                            setSceneAlreadyCreated(false);
                            setCreatedSceneId(sceneId);
                        }
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            })
            .catch((error) => {
                console.error("Error fetching collection products:", error);
            });
        } else if (roomType == 'collection' && productsAddedToScene?.length > 0) {
            let product_list = productsAddedToScene;
            let payload = {
                'username': CUSTOMER_USERNAME,
                'products_list': product_list,
                collection_id: collectionId,
            }
            if (selectedRoom?.space_areas.length > 0) {
                payload.space_allocation = true;
            }

            axios.post(ENVIRONMENT.ADD_PRODUCTS_BATCH_TO_COLLECTION, payload)
                .then((response) => {
                    handleCollectionAndSceneUpdate(currentSceneId, collectionId);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    const handleCollectionAndSceneUpdate = (scene_id, collection_id) => {
        let payload = {}
        payload = {
            "scene_id": scene_id,
            "render_generation": true
        };
        console.log(payload)
        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then((res) => {
                let collection_payload = {
                    "collection_id": collection_id.toString(),
                    "render_generation": true
                }
                console.log(payload)
                axios.post(ENVIRONMENT.UPDATE_COLLECTION, collection_payload)
                    .then((res) => {
                        message.success('Product added to collection and scene.');
                        if(projectID != null){
                            window.location.href = '/scene_builder/' +  scene_id + '?origin=/home&&project_id=' + projectID
                        } else {
                            window.location.href = '/scene_builder/' + scene_id + '?origin=/home';
                        }
                    })
            });
    }

    const addProductsAndCreateSceneLogic = (collectionId, sceneName, roomId, areas, sceneId) => {
        setSceneAlreadyCreated(true);
        setProductsAlreadyAdded(true);
        if (currentCollectionId == -1 && productId == '' && productsAddedToScene.length == 0) {
            setProductsAlreadyAdded(false);
            createScene(collectionId, sceneName, roomId);
        } else {
            addProductToCollection(collectionId, areas, sceneId);
            if (roomType == 'space') {
                createScene(collectionId, sceneName, roomId);
            } else {
                setProductsAlreadyAdded(false);

            }
        }
    }

    useEffect(() => {
        if (!sceneAlreadyCreated && !productsAlreadyAdded && createdSceneId != -1) {
            if(projectID != null){
                window.location.href = '/scene_builder/' +  createdSceneId + '?origin=/home&&project_id=' + projectID
            } else {
                window.location.href = '/scene_builder/' + createdSceneId + '?origin=/home';
            }
            setSceneAlreadyCreated(false);
            setProductsAlreadyAdded(false);
            setCreatedSceneId(-1);
        }
    }, [sceneAlreadyCreated, productsAlreadyAdded, createdSceneId]);

    const createScene = (collectionId, sceneName, roomId) => {
        // setCreateSceneLoader(true);
        {
            const payload = {
                scene_name: sceneName,
                collection_id: collectionId,
                room_id: roomId,
                username: localStorage.getItem("username"),
            };

            if (
                FileConstants.isMSProvider &&
                (localStorage.getItem("managed_customer_username") != null ||
                    localStorage.getItem('managed_company_id') != null)
            ) {
                payload.designed_for = localStorage.getItem("managed_customer_username");
            }

            if (COMPANY_ID != undefined) {
                payload.company_id = COMPANY_ID;
            }

            if (sceneCreationType in FileConstants.SCENE_CREATION_FLOW) {
                payload.scene_created_from = FileConstants.SCENE_CREATION_FLOW[sceneCreationType];
            }

            if (sceneCreationType == "new-scene-from-existing-products" && currentSceneId != -1) {
                payload.copied_product_from = currentSceneId
            }
            if (projectID) {
                payload.project_id = projectID;
            }

            axios
                .post(ENVIRONMENT.UPDATE_SCENE, payload)
                .then((res) => {
                    const sceneId = res.data['scene_id'];
                    setSceneAlreadyCreated(false);
                    setCreatedSceneId(sceneId);
                })
                .catch(() => {
                    setLoading(false);

                });
        }
    };

    const processSubmission = (payload = libraryPayload) => {
        setButtonLoader(true);
        axios
            .post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then((response) => {
                setButtonLoader(false);
                if (response.data.status != undefined && response.data.status == 1) {
                    const { collection_id, scene_id } = response.data.action_response;
                    addProductsAndCreateSceneLogic(collection_id, libraryPayload.request_payload.name, null, null, scene_id);
                } else {
                    if ('request_allowed' in response.data && response.data['request_allowed'] == 'not_allowed') {
                        setPaymentConfirmationModal(false);
                        setInAppLimitExceededModal(true);
                        setSelectedRoom(null);
                    } else {
                        setPaymentFailureMessage(response.data.error_message);
                        setPaymentFailureModal(true);
                    }
                }
            })
            .catch((error) => {
                setButtonLoader(false);
            });
    };

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener(
            'card_saved',
            (e) => {
                const action = e.detail.action;
                setCardSaveFlowTriggered(false);
                if (action == 'save_card') {
                    message.success('Payment Method Updated Successfully.');
                }
            },
            false,
        );

        setPaymentFailureModal(false);
        const child_window = window.open(
            '/save-card?action=save_card',
            '',
            'width=600,height=600',
        );
        const refreshInterval = setInterval(() => {
            if (child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
            }
        }, 500);
        setCardSaveFlowTriggered(true);
    };

    const loadUserRequestStatusRooms = (confirm_request = null) => {
        const check_payload = {
            username: localStorage.getItem("username"),
            action: 'check',
            request_type: 'room_prop',
        };
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload).then((res) => {
            setPurchaseTypeRoom(res.data['purchase_type']);
            setUsedCountRooms(res.data['usage_count']);
            setAvailableCountRooms(res.data['available_limit']);
            setSubscriptionTypeRoom(res.data['subscription_plan']);
            setRequestStatusRoom(res.data['request_allowed']);
            setCardStatusRoom(res.data['card_status']);
            setEntityRoomPrice(res.data['price']);
            if (requestType == 'room_prop' && confirm_request != null) {
                setConfirmRequest(true);
            }
        });
    };

    const loadUserRequestStatusScenes = (confirm_request = null) => {
        const check_payload = {
            username: localStorage.getItem("username"),
            action: 'check',
            request_type: 'scene_template_prop',
        };
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload).then((res) => {
            setPurchaseTypeTemplate(res.data['purchase_type']);
            setUsedCountTemplates(res.data['usage_count']);
            setAvailableCountTemplates(res.data['available_limit']);
            setSubscriptionTypeTemplates(res.data['subscription_plan']);
            setRequestStatusScene(res.data['request_allowed']);
            setCardStatusTemplate(res.data['card_status']);
            setEntityScenePrice(res.data['price']);
            if (requestType == 'scene_template_prop' && confirm_request != null) {
                setConfirmRequest(true);
            }
        });
    };

    const countLimitFull = () => {
        if (itemType == 'products') {
            return usedCountProducts == usedCountProducts + availableCountProducts;
        } else if (itemType == 'spaces') {
            return usedCountRooms == usedCountRooms + availableCountRooms;
        } else {
            return usedCountTemplates == usedCountTemplates + availableCountTemplates;
        }
    };

    const upgradeSubscriptionPlan = () => {
        const payload = {
            username: localStorage.getItem("username"),
            subscription_type: subscriptionPlan,
        };
        setButtonLoader(true);
        axios
            .post(ENVIRONMENT.UPGRADE_SUBSCRIPTION, payload)
            .then((response) => {
                setSubscriptionModal(false);
                setButtonLoader(false);
                setPaymentConfirmationModal(false);
                setConfirmationModal(true);
                setTimeout(() => {
                    loadUserRequestStatusRooms();
                    loadUserRequestStatusScenes();
                }, 3000);
                message.success(
                    'Your subscription plan has been successfully upgraded.',
                );
                processSubmission();
            })
            .catch((error) => { });
    };

    const initiateConfirmation = () => {
        window.addEventListener(
            'card_saved',
            (e) => {
                const action = e.detail.action;
                setCardSaveFlowTriggered(false);
                if (
                    action == 'product_prop' ||
                    action == 'room_prop' ||
                    action == 'scene_template_prop'
                ) {
                    processSubmission();
                }
                else {
                    addProductToExistingScenes();
                }
            },
            false,
        );

        if (requestType == 'room_prop') {
            if (
                requestStatusRoom == 'allowed' ||
                requestStatusRoom == 'always_allowed'
            ) {
                processSubmission();
            }
            if (requestStatusRoom == 'payment_required') {
                if (cardStatusProduct == 0) {
                    const child_window = window.open(
                        '/save-card?action=' + requestType,
                        '',
                        'width=600,height=600',
                    );
                    const refreshInterval = setInterval(() => {
                        if (child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
                if (cardStatusProduct == 1) {
                    processSubmission();
                }
            }
        }
        if (requestType == 'scene_template_prop') {
            if (
                requestStatusScene == 'allowed' ||
                requestStatusScene == 'always_allowed'
            ) {
                processSubmission();
            }
            if (requestStatusScene == 'payment_required') {
                if (cardStatusTemplate == 0) {
                    const child_window = window.open(
                        '/save-card?action=' + requestType,
                        '',
                        'width=600,height=600',
                    );
                    const refreshInterval = setInterval(() => {
                        if (child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
                if (cardStatusTemplate == 1) {
                    processSubmission();
                }
            }
        }
    };

    const initiateSubscriptionConfirmation = () => {
        window.addEventListener(
            'card_saved',
            (e) => {
                const action = e.detail.action;
                setCardSaveFlowTriggered(false);
                if (action == 'subscription_update') {
                    upgradeSubscriptionPlan();
                }
            },
            false,
        );

        if (
            requestType == 'product_prop' ||
            requestType == 'room_prop' ||
            requestType == 'scene_template_prop'
        ) {
            if (cardStatusProduct == 1) {
                upgradeSubscriptionPlan();
            }
            if (cardStatusProduct == 0) {
                setIsSubscriptionUpdateRequest(true);
                const child_window = window.open(
                    '/save-card?action=subscription_update',
                    '',
                    'width=600,height=600',
                );
                const refreshInterval = setInterval(() => {
                    if (child_window.closed) {
                        setCardSaveFlowTriggered(false);
                        clearInterval(refreshInterval);
                    }
                }, 500);
                setCardSaveFlowTriggered(true);
            }
        }
    };

    const addRoomToCollection = (sceneName, roomId) => {
        let payload = {
            room_id: roomId,
            collection_name: sceneName,
            username: localStorage.getItem("username"),
        };
        let company_id = COMPANY_ID
        if (FileConstants.isMSProvider && localStorage.getItem("managed_customer_username") != null
        ) {
            payload.designed_for = localStorage.getItem("managed_customer_username");
            company_id = localStorage.getItem('managed_company_id');
        }

        if (company_id != undefined) {
            payload.company_id = company_id;
        }
        const gateway_payload = {
            username: CUSTOMER_USERNAME,
            request_type: 'room_prop',
            action: 'perform',
            update: '',
            request_payload: payload,
            price_category: '',
            request_id: Date.now().toString(),
        };
        setGatewayPayload(gatewayPayload);
        if (!isStore || selectedRoom.price == 0 || selectedRoom.price == '' || (requestStatusRoom == 'always_allowed' ||requestStatusRoom == 'allowed')) {
            processRequestSubmission(gateway_payload);
        } else {
            if (
                requestStatusRoom == 'always_allowed' ||
                requestStatusRoom == 'allowed'
            ) {
                processRequestSubmission(gateway_payload);
            } else if (requestStatusRoom == 'payment_required') {
                if (cardStatusRoom == 1) {
                    processRequestSubmission(gateway_payload);
                }
                if (cardStatusRoom == 0) {
                    setGatewayPayload(gateway_payload);
                    setLoading(false);
                    window.addEventListener(
                        'card_saved',
                        (e) => {
                            const action = e.detail.action;
                            setCardSaveFlowTriggered(false);
                            if (action == 'room_prop') {
                                processRequestSubmission(gateway_payload);
                            }
                        },
                        false,
                    );

                    const child_window = window.open(
                        '/save-card?action=room_prop',
                        '',
                        'width=600,height=600',
                    );
                    const refreshInterval = setInterval(() => {
                        if (child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
            }
        }
    };

    const getCollectionPayload = (collectionID) => {
        const payload = {
            required_fields: ['id', 'space_areas'],
            order_by: 'id desc',
        };
        let filter_string = '';
        const customer_username =
            'customer_username__exact=\'' + localStorage.getItem("username") + '\'';
        let company_id = '';
        const is_hidden = 'is_hidden__not=true';
        const has_access_to =
            'has_access_to__like=\'%"' + localStorage.getItem("username") + '"%\'';

        if (
            localStorage.getItem('shared_entities') != undefined &&
            localStorage.getItem("company_id") &&
            localStorage.getItem('shared_entities').split('_').includes('collection')
        ) {
            company_id = 'company_id__exact=' + localStorage.getItem("company_id");
            filter_string =
                '(' +
                company_id +
                '||' +
                customer_username +
                ')&&(' +
                is_hidden +
                ')&&(template_collection_id__is=null)';
        } else {
            filter_string =
                '(' +
                customer_username +
                '&&' +
                is_hidden +
                ')&&(template_collection_id__is=null)';
        }
        filter_string =
            filter_string +
            '||(' +
            has_access_to +
            ')||(template_collection_id__not=NULL&&' +
            customer_username +
            '&&' +
            is_hidden +
            ')';
        filter_string = filter_string + '&&(id__exact=' + collectionID + ')';
        payload['filter_string'] = filter_string;

        return payload;
    };

    const processRequestSubmission = (gateway_payload = gatewayPayload) => {
        if (!isStore || selectedRoom.price == 0 || selectedRoom.price == '0' || (requestStatusRoom == 'always_allowed' ||requestStatusRoom == 'allowed')) {
            axios
                .post(ENVIRONMENT.UPDATE_COLLECTION, gateway_payload.request_payload)
                .then((res) => {
                    const areas = res.data.space_areas;
                    const collection_id = res.data.collection_id;
                    addProductsAndCreateSceneLogic(collection_id, gateway_payload.request_payload.collection_name, gateway_payload.request_payload.room_id, areas, null);

                });
        } else {
            axios
                .post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                .then((response) => {
                    if (
                        'request_allowed' in response.data &&
                        response.data['request_allowed'] == 'not_allowed'
                    ) {
                        setInAppLimitExceededModal(true);
                        setLoading(false);
                    } else if (
                        response.data.status != undefined &&
                        response.data.status == 1
                    ) {
                        loadUserRequestStatusRooms();
                        collectionRoomUpdateCallback(
                            response.data.action_response.collection_id,
                            gateway_payload.request_payload.collection_name,
                            gateway_payload.request_payload.room_id,
                        );
                    } else {
                        setPaymentFailureMessage(response.data.error_message);
                        setPaymentFailureModal(true);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    message.error('An error occured while adding space to collection.');
                });
        }
    };

    const collectionRoomUpdateCallback = (
        collectionId,
        collectionName,
        roomId,
    ) => {
        const payload = getCollectionPayload(collectionId);
        axios.post(ENVIRONMENT.LIST_COLLECTION, payload).then((res) => {
            let areas = [];
            res.data.map((col) => {
                if (col.id == collectionId) {
                    areas = col.space_areas;
                }
            });
            addProductsAndCreateSceneLogic(collectionId, collectionName, roomId, areas, null);
        });
    };

    const getSceneRelatedDetail = (new_scene_name) => {
        const payload = {
            action: 'check',
            scene_template_id: selectedRoom.scene_id,
            customer_username: localStorage.getItem("username"),
        };
        if (localStorage.getItem("company_id")) {
            payload.company_id = localStorage.getItem("company_id");
        }

        axios
            .post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
            .then((res) => {
                setRelatedScenes(res.data);
                addToLibraryScene(new_scene_name);
            })
            .catch((error) => { });
    };

    const addToLibraryScene = (new_scene_name) => {
        const { id, price, brand } = selectedRoom;
        setEntityName(new_scene_name);
        setIsALL3DEntity(true);
        setEntityScenePrice(price);
        setRequestType('scene_template_prop');
        const payload = {
            action: 'add_to_library',
            id: id,
            name: new_scene_name,
            requested_by: localStorage.getItem("username"),
        };
        if (localStorage.getItem("company_id")) {
            payload.company_id = localStorage.getItem("company_id");
        }

        if (sceneCreationType in FileConstants.SCENE_CREATION_FLOW) {
            payload.scene_created_from = FileConstants.SCENE_CREATION_FLOW[sceneCreationType];
        }


        const gateway_payload = {
            username: localStorage.getItem("username"),
            request_type: 'scene_template_prop',
            action: 'perform',
            request_payload: payload,
            category: '',
            request_id: Date.now().toString(),
        };
        if (price == 0) {
            addFreeScene(gateway_payload);
        } else {
            setEntityScenePrice(price);
            if (brand == FileConstants.ALL3D_COMPANY) {
                if (requestStatusScene == 'update_subscription') {
                    // update subscription plan modal here
                    setSubscriptionModal(true);
                    setLibraryPayload(gateway_payload);
                    setLoading(false);
                }
                if (
                    requestStatusScene == 'allowed' ||
                    requestStatusScene == 'always_allowed' ||
                    (relatedScenes && relatedScenes.length > 0)
                ) {
                    setLibraryPayload(gateway_payload);
                    setLoading(false);
                }
                if (requestStatusScene == 'payment_required') {
                    setPaymentConfirmationModal(true);
                    setLibraryPayload(gateway_payload);
                    setLoading(false);
                }
            } else {
                setIsALL3DEntity(false);
                setRequestStatusScene('payment_required');
                setPaymentConfirmationModal(true);
                setLibraryPayload(gateway_payload);
                setLoading(false);
            }
        }
    };

    const addFreeScene = (gatewayPayload) => {
        const payload = gatewayPayload.request_payload;
        const id = payload.id;
        payload.action = 'add_to_library_free';

        axios.post(ENVIRONMENT.ADD_FREE_ITEMS_TO_LIBRARY, payload).then((res) => {
            setPaymentConfirmationModal(false);
            window.location.href = '/scene_builder/' + res.data.scene_id + '?origin=/home';
        });
    };

    useEffect(() => {
        if (confirmRequest) {
            initiateConfirmation();
        }
    }, [confirmRequest]);

    useEffect(() => {
        if (roomType === 'space') {
            loadUserRequestStatusRooms();
        } else loadUserRequestStatusScenes();
    }, [roomType]);

    return (
        <CustomerMainLayout selected="6">
            <div>
                {step == 2 && (
                    <div>
                        {roomType == 'space' ? (
                            <SelectSpace
                                project_id={projectID}
                                redirectionPath={redirectionPath}
                                handleCancel={handleCancel}
                                empty_scene={sceneCreationType == "new-scene-from-existing-products" ? false : true}
                                selectedRoom={selectedRoom}
                                setSelectedRoom={setSelectedRoom}
                                setStep={setStep}
                                setLoading={setLoading}
                                loading={loading}
                                roomType={roomType}
                                setIsStore={setIsStore}
                                isStore={isStore}
                                creationType={sceneCreationType}
                                requestStatusRoom={requestStatusRoom}
                            />
                        ) : roomType == 'scene' ? (
                            <SelectScene
                                project_id={projectID}
                                redirectionPath={redirectionPath}
                                empty_scene={sceneCreationType == "add-product-to-existing-scene" ? false : true}
                                selectedRoom={selectedRoom}
                                setSelectedRoom={setSelectedRoom}
                                setStep={setStep}
                                handleSubmit={handleSubmit}
                                setLoading={setLoading}
                                loading={loading}
                                roomType={roomType}
                                setIsStore={setIsStore}
                                multiple_select={sceneCreationType == "add-product-to-existing-scene" ? true : false}
                                selectedScenes={selectedScenes}
                                setSelectedScenes={setSelectedScenes}
                                creationType={sceneCreationType}
                            />
                        ): ''}
                    </div>
                )}
                {step == 4 ? <SelectProduct
                    handleSubmit={handleSubmit}
                    scene_id={currentSceneId}
                    roomType={roomType}
                    project_id={projectID}
                    redirectionPath={redirectionPath}
                    productsAddedToScene={productsAddedToScene}
                    setProductsAddedToScene={setProductsAddedToScene}
                    setStep={setStep}
                    setLoading={setLoading}
                    selectedRoom={selectedRoom}
                    loading={loading}
                    isStore={productIsStore}
                    setIsStore={setProductIsStore} 
                    requestStatusRoom={requestStatusRoom}
                    sceneFlow/>
                    : ''}
                {step == 3 && (
                    <div>
                        <SceneName
                        project_id={projectID}
                        handleSubmit={handleSubmit}
                        setStep={setStep}
                        loading={loading} />
                    </div>
                )}
                <InAppPurchaseLimitModal
                    visible={inAppLimitExceededModal}
                    setVisible={setInAppLimitExceededModal}
                />
                <SubscriptionPackageUpdate
                    subscriptionPlan={subscriptionPlan}
                    setSubscriptionPlan={setSubscriptionPlan}
                    currentPackage={currentPackage}
                    currentSubscriptionPlan={currentPackage}
                    isSubscriptionActive={isSubscriptionActive}
                    visible={subscriptionModal}
                    requestStatus={
                        requestType == 'product_prop' ?
                            requestStatusProduct :
                            requestStatusRoom == 'room_prop' ?
                                requestStatusRoom :
                                requestStatusScene
                    }
                    onCancel={() => setSubscriptionModal(false)}
                    heading={
                        <span>
                            You have succeeded the quota for adding{' '}
                            {requestType == 'product_prop' ? 'product props' : 'space props'}{' '}
                            to your library. Do you want to upgrade your current plan?
                        </span>
                    }
                    text={
                        <span>
                            Your current package is selected by default. Select another
                            package to upgrade.{' '}
                        </span>
                    }
                    footer={[
                        <div key="footer">
                            {requestType == 'product_prop' &&
                                requestStatusProduct == 'update_subscription' ? (
                                <span
                                    style={{ color: '#276DD7' }}
                                    className="justify-in-start manrope f-14"
                                >
                                    Please upgrade your package to keep using this service.
                                </span>
                            ) : requestType == 'room_prop' &&
                                requestStatusRoom == 'update_subscription' ? (
                                <span
                                    style={{ color: '#276DD7' }}
                                    className="justify-in-start manrope f-14"
                                >
                                    Please upgrade your package to keep using this service.
                                </span>
                            ) : requestType == 'scene_template_prop' &&
                                requestStatusScene == 'update_subscription' ? (
                                <span
                                    style={{ color: '#276DD7' }}
                                    className="justify-in-start manrope f-14"
                                >
                                    Please upgrade your package to keep using this service.
                                </span>
                            ) : (
                                ''
                            )}
                            <span className="justify-in-end">
                                <Button
                                    key="back"
                                    className="modal-okay-gray square font-14"
                                    onClick={() => setSubscriptionModal(false)}
                                >
                                    Cancel
                                </Button>
                                {cardStatusProduct == 1 ? (
                                    <Button
                                        disabled={
                                            subscriptionPlan == '' ||
                                            cardSaveFlowTriggered ||
                                            buttonLoader
                                        }
                                        key="back"
                                        className="modal-okay square font-14"
                                        onClick={upgradeSubscriptionPlan}
                                    >
                                        {buttonLoader ? (
                                            <span>
                                                Upgrading Plan <LoadingOutlined spin />
                                            </span>
                                        ) : (
                                            <span>Confirm & Process Payment </span>
                                        )}
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={
                                            subscriptionPlan == '' ||
                                            cardSaveFlowTriggered ||
                                            buttonLoader
                                        }
                                        key="back"
                                        style={{ textAlign: 'center' }}
                                        className="modal-okay square font-14"
                                        onClick={initiateSubscriptionConfirmation}
                                    >
                                        {buttonLoader ? (
                                            <span>
                                                Upgrading Plan <LoadingOutlined spin />
                                            </span>
                                        ) : (
                                            <span>Confirm & Enter Payment Details </span>
                                        )}
                                    </Button>
                                )}
                            </span>
                        </div>,
                    ]}
                />
                <PaymentFailureModal
                    paymentFailureMessage={paymentFailureMessage}
                    setPaymentFailureModal={setPaymentFailureModal}
                    updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
                    paymentFailureModal={paymentFailureModal}
                />
                <PaymentConfirmationModal
                    storeProp={true}
                    itemType={itemType}
                    purchaseType={
                        itemType == 'products' ?
                            purchaseTypeProduct :
                            itemType == 'space' ?
                                purchaseTypeRoom :
                                purchaseTypeTemplate
                    }
                    usedCount={
                        itemType == 'products' ?
                            usedCountProducts :
                            itemType == 'space' ?
                                usedCountRooms :
                                usedCountTemplates
                    }
                    availableCount={
                        itemType == 'products' ?
                            availableCountProducts :
                            itemType == 'space' ?
                                availableCountRooms :
                                availableCountTemplates
                    }
                    name={entityName}
                    disable={disablePopup}
                    visible={paymentConfirmationModal}
                    setSubscriptionModal={
                        isALL3DEntity ? setSubscriptionModal : undefined
                    }
                    amount={
                        <span>
                            $
                            {itemType == 'products' ?
                                entityProductPrice :
                                itemType == 'space' ?
                                    entityRoomPrice :
                                    entityScenePrice}
                        </span>
                    }
                    onCancel={() => {
                        setPaymentConfirmationModal(false);
                        loadUserRequestStatusRooms();
                        loadUserRequestStatusScenes();
                    }}
                    text={
                        countLimitFull() ?
                            'In order to proceed with the request for adding this entity to your library, please pay the required amount.' :
                            'Are you sure you want to add this item to your library?'
                    }
                    footer={[
                        <span
                            key="footer"
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <Button
                                disabled={buttonLoader}
                                className="modal-okay-gray font-14 square"
                                onClick={() => {
                                    setPaymentConfirmationModal(false);
                                    loadUserRequestStatusRooms();
                                    loadUserRequestStatusScenes();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={buttonLoader || disablePopup || cardSaveFlowTriggered}
                                className="modal-okay font-14 square"
                                onClick={initiateConfirmation}
                            >
                                {itemType == 'products' ?
                                    cardStatusProduct == 1 &&
                                        requestStatusProduct == 'payment_required' ?
                                        'Confirm & Process Payment' :
                                        cardStatusProduct == 0 &&
                                            requestStatusProduct == 'payment_required' ?
                                            'Confirm & Enter Payment Details' :
                                            'Confirm' :
                                    itemType == 'spaces' ?
                                        cardStatusProduct == 1 &&
                                            requestStatusRoom == 'payment_required' ?
                                            'Confirm & Process Payment' :
                                            cardStatusProduct == 0 &&
                                                requestStatusRoom == 'payment_required' ?
                                                'Confirm & Enter Payment Details' :
                                                'Confirm' :
                                        cardStatusTemplate == 1 &&
                                            requestStatusScene == 'payment_required' ?
                                            'Confirm & Process Payment' :
                                            cardStatusTemplate == 0 &&
                                                requestStatusScene == 'payment_required' ?
                                                'Confirm & Enter Payment Details' :
                                                'Confirm'}
                                {buttonLoader == true ? (
                                    <LoadingOutlined type="sync" spin />
                                ) : (
                                    ''
                                )}
                            </Button>
                        </span>,
                    ]}
                />
            </div>
        </CustomerMainLayout>
    );
};

export default AddNewSceneFlow;
