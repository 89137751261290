import React, { useContext, useState } from 'react';
import _ from "lodash";
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import { Avatar, Breadcrumb, Tooltip, Row, Col, Button, Dropdown, Menu, Divider, Space, Popover } from 'antd';
import { ArrowLeftOutlined, CommentOutlined, DownOutlined, DownloadOutlined, FlagOutlined, LinkOutlined, LoginOutlined, MailOutlined, ShareAltOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';
import './ProductQA.scss'
import ENVIRONMENT from '../../../../environments';
import { getBaseURL } from '../../../../environments/env';
import * as Utilities from '../../Utilities';
import CollaborateConstants from '../CollaborateTool/Constants';

const CUSTOMER_USERNAME = localStorage.getItem('username');
const IS_MSPROVIDER = localStorage.getItem('is_msprovider');
const COMPANY_ID = localStorage.getItem('company_id');
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');

const ProductQANavigation = () => {
    const { access_level, productAssetData, convertProductFeedbackToPDF, collaborateMode, downloadImagesArchive, productData,
        setCollaborateAccessModal, allCommentsLoaded, disablePDF, displayLoader, canvasLoader, autoSaveLoader, annotateLoader,
        initialLoader, refreshLoader, downloadCryptoImagesArchive, openCommentHistory, openShareModal, activeCollaboratorList,
        openVariantModal, projectID } = useContext(CollaborateQAContext);

    const [showDropdown, setShowDropdown] = useState(false);

    const tabNameMapping={
        'active': 'Active Projects',
        'requested': 'Requested Projects',
        'approved': 'Approved Projects',
        'mspActive': 'Active Projects',
        'mspApproved': 'Approved Projects'
        }
    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get("project_id")
    const projectName = urlParams.get("project_name")
    const projectTab = urlParams.get("projectTab")
    const selectedUser = urlParams.get("selectedUser")

    // Function to toggle the dropdown visibility
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    // Function to generate the dropdown menu
    const dropdownMenu = (
        <Menu>
            {activeCollaboratorList.map((collaborator, index) => (
                <Menu.Item key={index} className="display-flex j-s a-c">
                    <Avatar
                        className="cursor-pointer mr-12 manrope f-16 white lh-32 circle-bg light-blue">
                        {Utilities.getInitials(collaborator.name ? collaborator.name : collaborator.customer_username)}
                    </Avatar>
                    <div className="collaborator-email-username">
                        <span className="manrope f-14 w-700" style={{ display: 'block' }}>{collaborator.customer_username}</span>
                        <span className="manrope f-12 w-400" style={{ display: 'block' }}>{collaborator.email}</span>
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    );


    const menu = (
        <Menu style={{ width: "200px" }}>
            <Menu.ItemGroup key={"assets"} title={<span className='manrope f-12 grey-8c'>Assets</span>} >
                <Menu.Item className='manrope f-14' key={0} onClick={() => downloadImagesArchive()}>
                    <span>All Images</span>
                </Menu.Item>
                <Menu.Item key={1} className='manrope f-14' onClick={() => downloadCryptoImagesArchive()}>
                    <span>Cryptomatte</span>
                </Menu.Item>
                <Menu.Item key={2} onClick={() => convertProductFeedbackToPDF()}
                    disabled={allCommentsLoaded || disablePDF || displayLoader || annotateLoader
                        || (canvasLoader && collaborateMode) || initialLoader || refreshLoader || autoSaveLoader}>
                    <Tooltip className='manrope f-14'
                        title={<span className='manrope f-12 white'>{disablePDF ? "No feedback exists yet" :
                            (allCommentsLoaded || displayLoader || (canvasLoader && collaborateMode) || initialLoader || refreshLoader || autoSaveLoader || annotateLoader) ?
                                "Please wait while collaboration data is being fetched." : "Download changes made in Collaboration Mode"}</span>}>
                        <span className='cursor-pointer'>Feedback (PDF)</span>
                    </Tooltip>
                </Menu.Item>
            </Menu.ItemGroup>
            {productAssetData && productAssetData.download_links && productAssetData.download_links.length > 0 &&
                <Menu.ItemGroup key={"model"} title={<span className='manrope f-12 grey-8c'>Model</span>} >
                    {(productAssetData.download_links || []).map((ele, index) => {
                        return (
                            <Menu.Item key={index + 4}>
                                <a className='manrope f-14' target="_blank"
                                    download
                                    href={ele.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(productAssetData['platform']))} >{ele.display_name}</a>
                            </Menu.Item>
                        );
                    })}
                </Menu.ItemGroup>}
        </Menu>
    );

    return (<Header className='product-nav-header white qa'>
        <Row className='display-flex j-s-b a-c'>
            <Col span={12}>
                <Breadcrumb className='display-flex j-s a-c breadcrumb-setting-product-qa'>
                { projectName && projectId ? 
                      <>
                      <Breadcrumb.Item className="manrope f-14 lh-28 black-73 white-space-text">
                          <a
                              className="manrope f-14 lh-28 black-73 white-space-text display-flex j-s a-c"
                              href={projectTab === "approved" || projectTab === "mspApproved" ? `/library-projects?tab=${projectTab}` : `/projects?tab=${projectTab}`}
                          >
                              <ArrowLeftOutlined className='mr-8'/>
                              {IS_MSPROVIDER && MANAGED_CUSTOMER_USERNAME && (projectTab == 'mspActive' || projectTab == 'mspApproved') ? `${MANAGED_CUSTOMER_USERNAME}'s ${tabNameMapping[projectTab]}` : `Your ${tabNameMapping[projectTab]}`}
                          </a>
                      </Breadcrumb.Item><Breadcrumb.Item className="manrope f-14 lh-28 black-73 white-space-text">
                              <a
                                  className="manrope f-14 lh-28 black-73 white-space-text"
                                  href={`/projects/${projectId}?tab=${projectTab}&selectedUser=${selectedUser}`}
                              >
                                  {projectName.substring(0, 15) + (projectName.length > 15 ? '...' : '')}
                              </a>
                          </Breadcrumb.Item></> : 
                    <Breadcrumb.Item>
                        <a className='manrope f-14 lh-28 black-73 white-space-text display-flex j-s a-c'
                            href={'/home'}>
                            <ArrowLeftOutlined className='mr-8' />
                            {"Review"}
                        </a>
                    </Breadcrumb.Item>}
                    <Breadcrumb.Item className='manrope f-14 lh-28 black-d9 clamp-text w-100'>
                        <Tooltip title={<span className='manrope f-12 white'>{productAssetData['product_name']}</span>}>
                            {productAssetData['product_name']}
                        </Tooltip>
                    </Breadcrumb.Item>
                    {collaborateMode &&
                        <Breadcrumb.Item className='manrope f-14 lh-28 black-d9'>
                            <Tooltip title={<span className='manrope f-12 white'>Collaboration Mode</span>}>
                                Collaboration Mode
                            </Tooltip>
                        </Breadcrumb.Item>}
                </Breadcrumb>
            </Col>
            <Col span={12} className='display-flex j-e a-c'>
                {collaborateMode && (CollaborateConstants.edit_access_levels.includes(access_level)) && <>
                    {activeCollaboratorList.slice(0, 2).map((collaborator, index) => (
                        <Popover
                            content={<span className='manrope f-12 black-33'>{collaborator.customer_username} ({collaborator.email}) </span>}>
                            <div className='ml-6'>
                                <Avatar
                                    key={index}
                                    className={`cursor-pointer manrope f-16 white lh-32 circle-bg light-blue`}>
                                    {Utilities.getInitials(collaborator.name ? collaborator.name : collaborator.customer_username)}
                                </Avatar>
                            </div>
                        </Popover>
                    ))}
                    {activeCollaboratorList.length > 2 && (
                        <Dropdown overlay={dropdownMenu} trigger={['click']}>
                            <div className='ml-6'>
                                <Avatar
                                    className="cursor-pointer manrope f-16 lh-32"
                                    onClick={toggleDropdown}>
                                    +{activeCollaboratorList.length - 2}
                                </Avatar>
                            </div>
                        </Dropdown>
                    )}
                    {activeCollaboratorList.length > 0 && <Divider type="vertical" className='collab-nav-divider mr-12' />}
                </>}

                {(["co-owner", "owner"].includes(access_level)) && productData && productData.model_status == 4 &&
                    <Tooltip title={<span className='manrope f-12 white'>Public Links</span>}>
                        <Button className='product-btn light-blue f-16 br-2 small-icon mr-8' onClick={openShareModal} ghost><LinkOutlined /></Button>
                    </Tooltip>}



                {(CollaborateConstants.edit_access_levels.includes(access_level)) && productData && productData.model_status == 4 &&
                    <Tooltip title={<span className='manrope f-12 white'>Download Options</span>}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Button
                                className='product-btn light-blue f-16 br-2 small-icon mr-8'
                                ghost>
                                <DownloadOutlined />
                            </Button>
                        </Dropdown>
                    </Tooltip>}
                {collaborateMode && <Tooltip title={<span className='manrope f-12 white'>Comment History</span>}>
                    <Button className='display-flex product-btn light-blue f-16 br-2 small-icon mr-8' ghost onClick={openCommentHistory}>
                        <img
                            className='product-qa-comment-icon'
                            src="/img/collaborate_qa/message-noti.svg" />
                    </Button>
                </Tooltip>}
                {projectID != null && (CollaborateConstants.edit_access_levels.includes(access_level)) &&
                    <Tooltip title={<span className='manrope f-12 white'>Generate Product Variant</span>}>
                        <Button
                            className='product-btn dark-blue f-14 br-4 small  mr-8'
                            onClick={openVariantModal}>Generate Product Variant</Button>
                    </Tooltip>}
                {collaborateMode &&
                    <Tooltip title={<span className='manrope f-12 white'>Invite Collaborators</span>}>
                        <Button
                            icon={<UserOutlined />}
                            className='product-btn dark-blue f-14 br-4 small'
                            onClick={() => setCollaborateAccessModal(true)}>Invite</Button>
                    </Tooltip>}
            </Col>
        </Row>
    </Header>)
}

export default ProductQANavigation;