import { multiplyVectorsElementWise } from "../HelperFunctions";

/**
 * Manages the stacking behavior and validation of objects in the scene
 */
export default class StackingManager {
    /**
     * Creates a new StackingManager instance
     * @param {SceneCreator} sceneCreator - The main scene creator instance
     * @param {SelectionManager} selectionManager - Manager handling object selection state
     * @param {RaycastManager} raycastManager - Manager handling raycasting operations
     */
    constructor(sceneCreator, selectionManager, raycastManager) {
        this.sceneCreator = sceneCreator;
        this.selectionManager = selectionManager;
        this.raycastManager = raycastManager;
    }

    /**
     * Checks if an item can be used as a base for stacking other objects
     * Validates against predefined categories in the scene creator
     * @param {THREE.Object3D} item - The item to check for stacking validity
     * @returns {boolean} True if the item can be used as a base for stacking, false otherwise
     */
    isValidBaseItemForStacking(item) {
        if (this.sceneCreator.itemCategoryList[item.name] != undefined) {
            for (let index = 0; index < this.sceneCreator.validBaseItemCategories.length; index++) {
                const category = this.sceneCreator.validBaseItemCategories[index];
                if (this.sceneCreator.itemCategoryList[item.name].toLowerCase().includes(category)) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Compares two items to determine if one can be stacked on the other
     * Handles special cases for rugs and compares object dimensions for regular items
     * @param {THREE.Object3D} itemToBeStacked - The item that will be stacked
     * @param {THREE.Object3D} baseItem - The item that will serve as the base
     * @returns {Object} Object containing:
     *                   - isStackable: boolean indicating if stacking is possible
     *                   - linkToParent: boolean indicating if the items should be linked in hierarchy
     */
    compareForStacking(itemToBeStacked, baseItem) {
        let itemAisRug = itemToBeStacked.userData.isRug;
        let itemBisRug = baseItem.userData.isRug;

        if (itemBisRug) {
            return { isStackable: true, linkToParent: false };
        } else if (itemAisRug && !itemBisRug) {
            return { isStackable: false, linkToParent: false };
        } else if (!itemAisRug && !itemBisRug) {
            let dimensionsA = multiplyVectorsElementWise(itemToBeStacked.userData.size, itemToBeStacked.userData.scale);
            let dimensionsB = multiplyVectorsElementWise(baseItem.userData.size, baseItem.userData.scale);
            let coveredAreaA = (dimensionsA.x * dimensionsA.z);
            let coveredAreaB = (dimensionsB.x * dimensionsB.z);

            return { isStackable: (coveredAreaA < coveredAreaB * 4.5), linkToParent: true };
        }
    }
} 