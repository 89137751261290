import React, { useContext, useState, useEffect } from 'react';
import _, { isMap } from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Row, Col, Modal, Tabs, Tooltip } from 'antd';
import {  HeartFilled, HeartOutlined } from '@ant-design/icons';
import { FAVOURITES_CONTROLLER } from '../../../../environments/env';
import ProductImageCarousel from './ProductImageCarousel';
import FileConstants from '../../../../FileConstants';
import ReactPlayer from 'react-player';
import axios from 'axios';

const { TabPane } = Tabs;
const CUSTOMER_USERNAME = localStorage.getItem("username");

const ProductAssetViewer = () => {
    const { productAssetData, product_id, selectedAsset, statusOf360Spin, statusOfMp4Video,
        productAssetViewer, closeProductAssetViewer, setSelectedAsset, getDownloadUrl, favImages, modalRef } = useContext(CustomerProductContext);
    
        const [isSpin360Fav, setIsSpin360Fav] = useState(false)
        const [isMp4Fav, setIsMp4Fav] = useState(false)
        const [spin360FavId, setSpin360FavId] = useState(-1)
        const [mp4FavId, setMp4FavId] = useState(-1)
    
        const changeAssetTab = (key) => {
        setSelectedAsset(key);
    }

    useEffect(() => {
        if(favImages){
            favImages.filter(item => item.entity_id == product_id && item.entity_type == "product").map(obj => {
                if(obj.image_name == `${product_id}.mp4`){
                    setIsMp4Fav(true)
                    setMp4FavId(obj.id)
                }
                else if(obj.image_name == `${product_id}_Frame1.jpg`){
                    setIsSpin360Fav(true)
                    setSpin360FavId(obj.id)
                }
            })    
        }
    }, [])

    const markFavorite = (assetType, imageName) => {
        let payload = {
            action: 'add_favorite',
            entity_id: product_id,
            entity_type: 'product',
            image_type: assetType,
            image_name: imageName,
            favorited_by: CUSTOMER_USERNAME
        }

        axios.post(FAVOURITES_CONTROLLER, payload).then(res => {
            if(res && res['data']?.favorite_id){
                if(assetType == 'mp4'){
                    setMp4FavId(res['data']?.favorite_id)
                }
                else if(assetType == 'spin_360'){
                    setSpin360FavId(res['data']?.favorite_id)
                }
            }
        })
    }

    const markUnfavorite = (assetType, favId) => {
        let payload = {
            action: 'remove_favorite',
            favorite_id: favId,
            favorited_by: CUSTOMER_USERNAME
        }

        axios.post(FAVOURITES_CONTROLLER, payload)
        if(assetType == 'mp4'){
            setMp4FavId(-1)
        }
        else{
            setSpin360FavId(-1)
        }
    }
    const handleFavorite = (assetType) => {
        console.log('asset = ', assetType)
        if(assetType == 'mp4'){
            if(isMp4Fav){
                setIsMp4Fav(false)
                markUnfavorite(assetType, mp4FavId)
            }
            else{
                setIsMp4Fav(true)
                markFavorite(assetType, `${product_id}.mp4`)
            }
        }
        else if (assetType == 'spin_360'){
            if(isSpin360Fav){
                setIsSpin360Fav(false)
                markUnfavorite(assetType, spin360FavId)
            }
            else{
                setIsSpin360Fav(true)
                markFavorite(assetType, `${product_id}_Frame1.jpg`)
            }
        }


    }

    const renderFavIcon = (assetType) => {
            let favState = false
            if(assetType == 'mp4')
                favState = isMp4Fav
            else
                favState = isSpin360Fav
            return favState ? (
                <Tooltip title={<span className="manrope f-12 white">Unfavorite</span>}>
                <div
                    className="pointer-event-auto img-fav-icon-modal white"
                    onClick={(e) => handleFavorite(assetType)}
                >
                    <HeartFilled style={{ fontSize: "24px" }} />
                </div>
                </Tooltip>
            ) : (
                <Tooltip title={<span className="manrope f-12 white">Favorite</span>}>
                <div
                    className="pointer-event-auto img-fav-icon-modal white"
                    onClick={(e) => handleFavorite(assetType)}
                >
                    <HeartOutlined style={{ fontSize: "24px" }} />
                </div>
                </Tooltip>
            );
    }

    return (
        <Modal
            className='product-asset-viewer-modal'
            visible={productAssetViewer}
            footer={null}
            closable={true}
            destroyOnClose={true}
            onCancel={closeProductAssetViewer}>
            <Row ref={modalRef}>
                <Col span={24}>
                    <Tabs
                        className='items-table'
                        onChange={changeAssetTab}
                        tabBarGutter={32}
                        activeKey={selectedAsset}
                        size="large">
                        <TabPane
                            tab={<span className={`manrope f-14 asset-tab-heading ${selectedAsset === "images" ? `selected` : ``}`}>Images</span>}
                            key={"images"}
                            className="pd-10">
                            <ProductImageCarousel />
                        </TabPane>
                        <TabPane
                            tab={<span className={`manrope f-14 asset-tab-heading ${selectedAsset === "3d_preview" ? `selected` : ``}`}>3D Preview</span>}
                            key={"3d_preview"}
                            className="pd-10">
                            <div className='glb-height'>
                                <Row id='model-inspector-frame' className='model-inspector-viewer-customer'>
                                    <iframe id='model-viewer-3d' className='model-inspector-frame-customer' src={FileConstants.ModelInspector + product_id} />
                                </Row>
                            </div>
                        </TabPane>
                        {statusOf360Spin && (
                            <TabPane
                                tab={<span className={`manrope f-14 asset-tab-heading ${selectedAsset === "360_view" ? `selected` : ``}`}>360 Spin</span>}
                                key={"360_view"}
                                className="pd-10">
                                <div className='overlay-container mt-50'>
                                    {renderFavIcon('spin_360')}
                                </div>
                                <div id="container-360" className="product-viewer-360-container">
                                    {statusOf360Spin === "pending" ?
                                        (<div className="render-in-progress">
                                            360 Spin in Progress
                                        </div>) : (<div
                                            className="cloudimage-360"
                                            id="image-360"
                                            data-folder={productAssetData['360']?.["data-folder"]}
                                            data-filename={productAssetData['360']?.["data-filename"]}
                                            data-amount={productAssetData['360']?.["data-amount"]} />
                                        )}
                                </div>
                            </TabPane>
                        )}
                        {statusOfMp4Video && (
                            <TabPane
                                tab={<span className={`manrope f-14 asset-tab-heading ${selectedAsset === "mp4_video" ? `selected` : ``}`}>MP4 Video</span>}
                                key={"mp4_video"}
                                className="pd-10">
                                <Row className={`${statusOfMp4Video === "pending" ? `product-viewer-mp4-viewer` : ``} `}>
                                    {statusOfMp4Video === "pending" ?
                                        (<div className="render-in-progress">
                                            MP4 Video in Progress
                                        </div>) :
                                        <>
                                            <div className='overlay-container mt-50'>
                                                {renderFavIcon('mp4')}
                                            </div>
                                        <ReactPlayer
                                            width={"100%"}
                                            height={"calc(100vh - 140px)"}
                                            url={getDownloadUrl("VIDEO")}
                                            controls={true}
                                            loop={true} />
                                        </>}
                                </Row>
                            </TabPane>
                        )}
                    </Tabs>
                </Col>
            </Row>
        </Modal>
    );
}

export default ProductAssetViewer;
