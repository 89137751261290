
import * as THREE from "three";

// The class is empty because is only has static variables
export default class Constants {

}

/**
 * List of Unit vectors for all 3 dimensions.
 */
Constants._unit = {
    X: new THREE.Vector3( 1, 0, 0 ),
    Y: new THREE.Vector3( 0, 1, 0 ),
    Z: new THREE.Vector3( 0, 0, 1 ),
    aX: new THREE.Vector3( -1, 0, 0 ),
    aY: new THREE.Vector3( 0, -1, 0 ),
    aZ: new THREE.Vector3( 0, 0, -1 ),
};

/**
 * Color for selected state of asset (Light blue color).
 */
Constants.defaultHighLightColor = new THREE.Color().setRGB( 0, 0.5, 1 );


/**
 * Color for focused state of asset (Yellow color).
 */
Constants.focusedHighlightColor = new THREE.Color().setRGB( 1, 1, 0 );

/**
 * Color for invalid position state of asset (Red color).
 */
Constants.invalidHighLightColor = new THREE.Color().setRGB( 1, 0, 0 );

/**
 * Minimun covered area for an object to be considered a rug.
 */
Constants.rugMinCoveredAreaThreshold = 2;

/**
 * Maximum height(size in Y) for an object to be considered a rug.
 */
Constants.rugMaxHeightThreshold = 0.075;

/**
 * Dictionary for holding name strings of all available managers.
 */
Constants.Manager = {
    RotationControls : "RotationControls",
    NavigationControl : "NavigationControl",
    SunControls : "SunControls",
    CameraControls : "CameraControls",
    AssetLoadingManager : "AssetLoadingManager",
    SpaceManager : "SpaceManager",
    ObjectPlacementManager : "ObjectPlacementManager",
    RaycastManager : "RaycastManager",
    PillowPlacementManager : "PillowPlacementManager",
    ObjectSnappingManager : "ObjectSnappingManager",
    TransformSnappingManager: "TransformSnappingManager",
    LostAndFoundAreaManager : "LostAndFoundAreaManager",
    DebugEngine : "DebugEngine",
    GridViewManager: "GridViewManager",
    SceneLoader : "SceneLoader",
    AutoPlacementManager : "AutoPlacementManager",
    SpaceConfigurator: "SpaceConfigurator",
    TransformControls : "TransformControls",
    MeasurementTool: "MeasurementTool",
    SelectionManager: "SelectionManager"
}

/**
 * Enum for projection type of camera.
 */
Constants.CameraProjectionType = {
    PERSPECTIVE 	: "perspective",
    ORTHOGRAPHIC 	: "orthographic"
}

/**
 * Enum for asset type.
 * This should be removed as only Item type is being used in the system now.
 */
Constants.AssetType = {
    ROOM 	: "room",
    ITEM 	: "item",
    PROP 	: "prop"
}

/**
 * Enum for transformation type.
 */
Constants.TransformationType = {
    TRANSLATE 	: "translate",
    ROTATE 		: "rotate"
}

/**
 * Enum for placement type of item.
 */
Constants.PlacementType = {
    FLOOR 	: "floor",
    CEILING : "ceiling",
    WALL 	: "wall"
}

/**
 * Enum for placemenet state of item.
 */
Constants.AssetState = {
    PLACING : "placing",
    INVALID : "invalid",
    PLACED  : "placed",
    ROTATING : "rotating",
    INVALID_ROTATION: "invalid_rotation"
}

/**
 * Enum for supported aspect types.
 */
Constants.AspectType = {
    HORIZONTAL : "horizontal",
    VERTICAL : "vertical",
    SQUARE : "square",
    CUSTOM : "custom"
}

/**
 * Enum for supported space configuration types
 */
Constants.SpaceConfigurationType = {
    COLOR : "color",
    TEXTURE: "texture",
    MATERIAL: "material"
}

/**
 * Enum for space structure types
 */
Constants.SpaceStructureType = {
    WALL : "Wall",
    FLOOR: "Floor",
    CEILING: "Ceiling",
    DEFAULT: ''
}

/**
 * Enum for space structure types
 */
Constants.FreeModeStates = {
    ROTATION : "rotate",
    OFF: "off",
    POSITION: "translate",
}

/**
 * Axis helpers
 */
Constants.HelperAxis = {
    X: "XHelper",
    Y: "YHelper",
    Z: "ZHelper"
}

/**
 * Axis helpers Colors
 */
Constants.HelperAxisColor = {
    X: 0xff0000,
    Y: 0x00ff00,
    Z: 0x0000ff
}

/**
 * Selection Modes
 * 1. Single : You can only select one object at a time 
 * 2. Multiple : You can select multiple objects at a time
 */
Constants.SelectionMode = {
    SINGLE: "single",
    MULTIPLE: "multiple"
}
