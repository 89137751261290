import Constants from "../Constants";
import * as THREE from 'three';

/**
 * Manages the correction of object placement positions based on their placement type
 */
export default class PlacementCorrectionManager {
    /**
     * Creates a new PlacementCorrectionManager instance
     * @param {SelectionManager} selectionManager - Manager handling object selection state
     * @param {Function} getIsPlacementCorrectionRequired - Function that returns whether placement correction is needed
     */
    constructor(selectionManager, getIsPlacementCorrectionRequired) {
        this.selectionManager = selectionManager;
        this.getIsPlacementCorrectionRequired = getIsPlacementCorrectionRequired;
    }

    /**
     * Applies placement correction to an object based on its current placement type
     * @param {THREE.Vector3} position - The position vector to be corrected
     */
    applyPlacementCorrection(position) {
        const object = this.selectionManager.selection.objects[0];

        if (!this.getIsPlacementCorrectionRequired()) {
            return;
        }

        switch (object.userData.currentPlacement) {
            case Constants.PlacementType.WALL:
                this.wallPlacementCorrection(position);
                break;
            case Constants.PlacementType.CEILING:
                this.ceilingPlacementCorrection(position);
                break;
            case Constants.PlacementType.FLOOR:
                this.floorPlacementCorrection(position);
                break;
        }
    }

    /**
     * Corrects the position of wall-placed objects by adjusting for object depth
     * @param {THREE.Vector3} position - The position vector to be corrected
     */
    wallPlacementCorrection(position) {
        const object = this.selectionManager.selection.objects[0];

        if (!this.getIsPlacementCorrectionRequired()) {
            return;
        }

        const objectSize = object.userData.pvb.getDataForSnapTest();
        const objectDepth = objectSize.frontOrigin.distanceTo(objectSize.backOrigin) / 2.0;
        const direction = new THREE.Vector3();
        object.getWorldDirection(direction);
        direction.normalize();
        const scaledDirection = direction.multiplyScalar(objectDepth);
        position.add(scaledDirection);
    }

    /**
     * Corrects the position of ceiling-placed objects by adjusting height
     * @param {THREE.Vector3} position - The position vector to be corrected
     */
    ceilingPlacementCorrection(position) {
        const object = this.selectionManager.selection.objects[0];
        const boundingBox = new THREE.Box3().setFromObject(object);
        const height = boundingBox.max.y - boundingBox.min.y;
        if (object.userData.originalPlacement == 'wall') {
            position.y -= height / 2;
        } else {
            position.y -= height;
        }
    }

    /**
     * Corrects the position of floor-placed objects by adjusting height
     * @param {THREE.Vector3} position - The position vector to be corrected
     */
    floorPlacementCorrection(position) {
        const object = this.selectionManager.selection.objects[0];

        if (!this.getIsPlacementCorrectionRequired()) {
            return;
        }

        const boundingBox = new THREE.Box3().setFromObject(object);
        const height = boundingBox.max.y - boundingBox.min.y;
        if (object.userData.originalPlacement == 'wall') {
            position.y += height / 2;
        } else {
            position.y += height;
        }
    }

    /**
     * Calculates the corrected height for an object based on its placement type
     * @returns {number} The corrected height value, or 0 if no correction needed
     */
    getPlacementCorrectedHeight() {
        const object = this.selectionManager.selection.objects[0];
        console.log("getPlacementCorrectedHeight", object.userData.currentPlacement);
        if (object.userData.currentPlacement) {
            const boundingBox = new THREE.Box3().setFromObject(object);
            let height = boundingBox.max.y - boundingBox.min.y;
            if (object.userData.originalPlacement == Constants.PlacementType.WALL) {
                height = height/2;
            }
            return height;
        } 
        return 0;
    }

} 