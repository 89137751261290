import { getRootNodeFromObject, setHighlightedState, getObjectFromRootByName, rounded, roundedEqual } from "../HelperFunctions";
import Constants from "../Constants";
import * as THREE from "three";

/**
 * Processes translation movement for the selected object
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 */
function processTranslation (manager) {
    manager.applyPlacementCorrection(manager.pointEnd);
    let offset = manager.pointEnd.clone().sub( manager.pointStart );
    let inverseParentQuaternion = new THREE.Quaternion();
    inverseParentQuaternion.copy( manager.selectionManager.selection.parentQuaternion ).inverse();
    offset.applyQuaternion( inverseParentQuaternion );
    if ( manager.selectionManager.selection.placementType === Constants.PlacementType.WALL ) {
        manager.selectionManager.selection.objects[0].position.add( offset );
    }
    else {

        if( manager.selectionManager.selection.objects[0].userData.snapped ) {
            manager.pullVector.copy( manager.pointEnd ).sub ( manager.pullOrigin );

            let pullMag = manager.pullVector.length();
            let normalizedPullVector = manager.pullVector.clone().normalize();
            let dotProduct = normalizedPullVector.clone().dot( manager.selectionManager.selection.worldDirection );

            // Positive Value == Clockwise and Negative Value == Anti-Clockwise
            let pullDir = normalizedPullVector.clone().cross( manager.selectionManager.selection.worldDirection ).y;
            let validatePlacementCheck = true;
            
            switch( manager.selectionManager.selection.objects[0].userData.snapDir ) {

                case "back":
                if ( Math.abs( dotProduct ) > 0.65 ) {
                    if( pullMag > 0.1 ) {

                        manager.selectionManager.selection.objects[0].userData.snapped = false;
                        manager.pullVector.y = 0;
                        manager.moveAsset( manager.selectionManager.selection.objects[0], manager.selectionManager.selection.objects[0].position.clone().add( manager.pullVector ));
                        manager.pointStart.copy( manager.pointEnd );
                        validatePlacementCheck = false;

                    }

                }

                if( dotProduct <= 0.7 ) {

                    if( pullDir > 0 ) {
                        manager.selectionManager.selection.objects[0].translateOnAxis( Constants._unit.aX, pullMag );
                        manager.pullOrigin.copy ( manager.pointEnd );
                    }
                    else {
                        manager.selectionManager.selection.objects[0].translateOnAxis( Constants._unit.X, pullMag );
                        manager.pullOrigin.copy ( manager.pointEnd );
                    }
                }

                break;

                case "front":
                if ( Math.abs( dotProduct ) > 0.65 ) {

                    if( pullMag > 0.1 ) {

                        manager.selectionManager.selection.objects[0].userData.snapped = false;
                        manager.pullVector.y = 0;
                        manager.moveAsset( manager.selectionManager.selection.objects[0], manager.selectionManager.selection.objects[0].position.clone().add( manager.pullVector ));
                        manager.pointStart.copy( manager.pointEnd );
                        validatePlacementCheck = false;

                    }
                    
                }

                else if( dotProduct >= -0.7 ) {

                    if( pullDir > 0 ) {
                        manager.selectionManager.selection.objects[0].translateOnAxis( Constants._unit.aX, pullMag );
                        manager.pullOrigin.copy ( manager.pointEnd );
                    }
                    else {
                        manager.selectionManager.selection.objects[0].translateOnAxis( Constants._unit.X, pullMag );
                        manager.pullOrigin.copy ( manager.pointEnd );
                    }
                    
                }
               
                break;

                case "left" :
                if ( Math.abs( dotProduct ) < 0.50 ) {

                    if( pullMag > 0.1 ) {

                        manager.selectionManager.selection.objects[0].userData.snapped = false;
                        manager.pullVector.y = 0;
                        manager.moveAsset( manager.selectionManager.selection.objects[0], manager.selectionManager.selection.objects[0].position.clone().add( manager.pullVector ));
                        manager.pointStart.copy( manager.pointEnd );
                        validatePlacementCheck = false;

                    }

                }

                else {

                    if( dotProduct > 0 ) {
                        manager.selectionManager.selection.objects[0].translateOnAxis( Constants._unit.Z, pullMag );
                        manager.pullOrigin.copy ( manager.pointEnd );
                    }
                    else {
                        manager.selectionManager.selection.objects[0].translateOnAxis( Constants._unit.aZ, pullMag );
                        manager.pullOrigin.copy ( manager.pointEnd );
                    }
                }

                break;

                case "right" :
                if ( Math.abs( dotProduct ) < 0.50 ) {

                    if( pullMag > 0.1 ) {

                        manager.selectionManager.selection.objects[0].userData.snapped = false;
                        manager.pullVector.y = 0;
                        manager.moveAsset( manager.selectionManager.selection.objects[0], manager.selectionManager.selection.objects[0].position.clone().add( manager.pullVector ));
                        manager.pointStart.copy( manager.pointEnd );
                        validatePlacementCheck = false;

                    }
                    
                }

                else {

                    if( dotProduct > 0 ) {
                        manager.selectionManager.selection.objects[0].translateOnAxis( Constants._unit.Z, pullMag );
                        manager.pullOrigin.copy ( manager.pointEnd );
                    }
                    else {
                        manager.selectionManager.selection.objects[0].translateOnAxis( Constants._unit.aZ, pullMag );
                        manager.pullOrigin.copy ( manager.pointEnd );
                    }
                    
                }

                break;

                default:
                break;

            }
            if (validatePlacementCheck === true) {
                manager.validatePlacement();
            }
        }
        else {
            manager.moveAsset( manager.selectionManager.selection.objects[0], manager.selectionManager.selection.objects[0].position.clone().add( offset ));        
        }
    }

    manager.pointStart.copy( manager.pointEnd );
}

/**
 * Handles placement of objects on the floor
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} placementInfo - Information about the placement position and intersections
 */
export function handleFloorPlacement(manager, placementInfo) {
    if (manager.isJumpingToMisc(placementInfo)) {
        handleMiscJump(manager, placementInfo);
        return;
    }

    if (manager.isJumpingToItem(placementInfo)) {
        handleItemJump(manager, placementInfo);
        return;
    }

    if (manager.isMovingOnItem(placementInfo)) {
        handleItemMovement(manager, placementInfo);
        return;
    }

    if (manager.isJumpingToFloor(placementInfo)) {
        handleFloorJump(manager, placementInfo);
        return;
    }

    if (manager.isMovingOnFloor(placementInfo)) {
        handleFloorMovement(manager, placementInfo);
        return;
    }

    handleDefaultFloorPosition(manager);
}

/**
 * Handles jumping to miscellaneous objects (non-standard placement surfaces)
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} placementInfo - Information about the placement position and intersections
 */
export function handleMiscJump(manager, placementInfo) {
    if (!manager.selectionManager.selection.objects[0].userData.isRug) {
        manager.updateParent(manager.selectionManager.selection.objects[0], placementInfo.intersectedObj);
        manager.selectionManager.selection.objects[0].userData.isStacked = true;
        manager.moveAsset(manager.selectionManager.selection.objects[0], placementInfo.intersectionPoint);
        manager.pointStart.copy(placementInfo.intersectionPoint);
    } else if (placementInfo?.helperPoint) {
        manager.pointStart.y = placementInfo.helperPoint.y;
        manager.pointEnd.copy(placementInfo.helperPoint);
        processTranslation(manager);
    }
}

/**
 * Handles jumping to stackable items
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} placementInfo - Information about the placement position and intersections
 */
export function handleItemJump(manager, placementInfo) {
    let stackingInfo = manager.compareForStacking(
        manager.selectionManager.selection.objects[0], 
        placementInfo.intersectedObj
    );

    if (stackingInfo.isStackable) {
        processJump(manager, placementInfo.intersectionPoint, placementInfo.intersectedObj, stackingInfo.linkToParent);
        manager.pointStart.copy(placementInfo.intersectionPoint);
    } else {
        placementInfo.intersectionPoint = null;
        placementInfo.intersectedObj = null;
    }
}

/**
 * Handles movement while on top of another item
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} placementInfo - Information about the placement position and intersections
 */
export function handleItemMovement(manager, placementInfo) {
    if (manager.isChangingHeightOnItem(placementInfo)) {
        processJump(manager, placementInfo.intersectionPoint, placementInfo.intersectedObj);
        manager.pointStart.copy(placementInfo.intersectionPoint);
    } else {
        manager.pointEnd.copy(placementInfo.intersectionPoint);
        processTranslation(manager);
    }
}

/**
 * Handles jumping from a stacked position to the floor
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} placementInfo - Information about the placement position and intersections
 */
export function handleFloorJump(manager, placementInfo) {
    if (placementInfo?.helperPoint) {
        processJump(manager, placementInfo.helperPoint, manager.scene);
        manager.pointStart.copy(placementInfo.helperPoint);
    }
}

/**
 * Handles movement along the floor surface
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} placementInfo - Information about the placement position and intersections
 */
export function handleFloorMovement(manager, placementInfo) {
    if (placementInfo?.helperPoint) {
        manager.pointStart.y = placementInfo.helperPoint.y;
        manager.pointEnd.copy(placementInfo.helperPoint);
        processTranslation(manager);
        manager.selectionManager.selection.objects[0].position.y = placementInfo.helperPoint.y;
        manager.positionSelectionVertically();
    }
}

/**
 * Handles default floor positioning when no specific placement is needed
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 */
export function handleDefaultFloorPosition(manager) {
    const onScene = manager.selectionManager.selection.objects[0].parent == manager.scene;
    const heightMismatch = rounded(manager.selectionManager.selection.worldPosition.y) != 
        rounded(manager.helperPlane.position.y);

    if (onScene && heightMismatch) {
        manager.selectionManager.selection.objects[0].position.y = manager.helperPlane.position.y;
    }
}

/**
 * Handles placement of objects on walls
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} placementInfo - Information about the placement position and intersections
 */
export function handleWallPlacement(manager, placementInfo) {
    if (placementInfo?.intersectionPoint) {
        handleWallIntersectionPoint(manager, placementInfo);
    } else if (placementInfo?.helperPoint) {
        handleWallHelperPoint(manager, placementInfo);
    }
}

/**
 * Handles wall placement when there is a valid intersection point
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} placementInfo - Information about the placement position and intersections
 */
export function handleWallIntersectionPoint(manager, placementInfo) {
    if (isNaN(manager.pointStart.x)) {
        manager.pointStart.copy(placementInfo.intersectionPoint);
    }

    if (manager.selectionManager.selection.state == Constants.AssetState.INVALID) {
        manager.selectionManager.selection.state = Constants.AssetState.PLACING;
        let selectionObj = getObjectFromRootByName(manager.selectionManager.selection.objects[0], 
            manager.selectionManager.selection.objects[0].name) || manager.selectionManager.selection.objects[0];
        setHighlightedState(selectionObj, true, Constants.defaultHighLightColor);
    } else {
        manager.preserveAssetPreviousState();
    }

    if (manager.selectionManager.selection.worldDirection != null && placementInfo.direction != null && !roundedEqual(manager.selectionManager.selection.worldDirection, placementInfo.direction)) {
        handleWallDirectionChange(manager, placementInfo);
        return;
    }

    manager.pointEnd.copy(placementInfo.intersectionPoint);
    processTranslation(manager);
}

/**
 * Handles changes in wall direction for wall-mounted objects
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} placementInfo - Information about the placement position and intersections
 */
export function handleWallDirectionChange(manager, placementInfo) {
    manager.selectionManager.selection.worldDirection.copy(placementInfo.direction);
    manager.selectionManager.selection.objects[0].position.copy(placementInfo.intersectionPoint);

    let lookAtPoint = new THREE.Vector3();
    lookAtPoint.copy(placementInfo.intersectionPoint).add(placementInfo.direction);
    manager.selectionManager.selection.objects[0].lookAt(lookAtPoint);
    manager.pointStart.copy(manager.selectionManager.selection.objects[0].position);
    manager.isPlacementCorrectionRequired = true;
}

/**
 * Handles wall placement using helper points when no intersection is found
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} placementInfo - Information about the placement position and intersections
 */
export function handleWallHelperPoint(manager, placementInfo) {
    if (manager.selectionManager.selection.state == Constants.AssetState.PLACING) {
        manager.selectionManager.selection.state = Constants.AssetState.INVALID;
        let selectionObj = getObjectFromRootByName(manager.selectionManager.selection.objects[0], 
            manager.selectionManager.selection.objects[0].name) || manager.selectionManager.selection.objects[0];
        setHighlightedState(selectionObj, true, Constants.invalidHighLightColor);
    }

    let lookAtPoint = new THREE.Vector3();
    lookAtPoint.copy(manager.selectionManager.selection.objects[0].position).add(Constants._unit.Y);
    manager.selectionManager.selection.objects[0].lookAt(lookAtPoint);
    manager.pointStart.copy(manager.selectionManager.selection.objects[0].position);
    manager.pointEnd.copy(placementInfo.helperPoint);
    processTranslation(manager);
}

/**
 * Handles placement of objects on the ceiling
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} placementInfo - Information about the placement position and intersections
 */
export function handleCeilingPlacement(manager, placementInfo) {
    if (placementInfo?.helperPoint) {
        manager.pointStart.y = placementInfo.helperPoint.y;
        manager.pointEnd.copy(placementInfo.helperPoint);
        processTranslation(manager);
        const onScene = manager.selectionManager.selection.objects[0].parent == manager.scene;
        const heightMismatch = rounded(manager.selectionManager.selection.worldPosition.y) != 
            rounded(manager.helperPlane.position.y);

        if (onScene && heightMismatch) {
            manager.selectionManager.selection.objects[0].position.y = 
                manager.helperPlane.position.y - manager.getPlacementCorrectedHeight();
        }
    }
}

/**
 * Updates the currently focused asset and its highlighting
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} intersect - The intersection data from raycasting
 */
export function updateFocusedAsset(manager, intersect) {

    let focusedAsset = getRootNodeFromObject(manager.scene, intersect.object);
    if (focusedAsset !== manager.focusedAsset) {
        clearFocusedAsset(manager);
    }
    manager.focusedAsset = focusedAsset;
    if (manager.focusedAsset != null && !manager.isMultipleItemsMoving && !manager.selectionManager.selection.objects.includes(manager.focusedAsset)) {
        let selectionObj = getObjectFromRootByName(manager.focusedAsset, 
            manager.focusedAsset.name) || manager.focusedAsset;
        setHighlightedState(selectionObj, true, Constants.focusedHighlightColor);
    }

}

/**
 * Clears the focused asset and its highlighting
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 */
export function clearFocusedAsset(manager) {
    if (manager.focusedAsset != null) {
        if (!manager.selectionManager.selection.objects.includes(manager.focusedAsset)) {
            let selectionObj = getObjectFromRootByName(manager.focusedAsset, 
                manager.focusedAsset.name) || manager.focusedAsset;
                setHighlightedState(selectionObj, false);
        } else {
            let selectionObj = getObjectFromRootByName(manager.focusedAsset, 
                manager.focusedAsset.name) || manager.focusedAsset;
            if (manager.selectionManager.selection.objects[0].userData.isFrozen) {
                setHighlightedState(selectionObj, true, Constants.invalidHighLightColor);
            } else {
                setHighlightedState(selectionObj, true, Constants.defaultHighLightColor);
            }
        }
        manager.focusedAsset = null;
    }
}

/**
 * Updates the hover image display for the focused object
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {Object} intersect - The intersection data from raycasting
 */
export function updateHoverImage(manager, intersect) {
    let item = manager.scene.getObjectById(intersect.object.userData.rootNode);

    if (!manager.selectionManager.selection.objects[0]) {
        manager.sceneCreator.showHoverImage(item.name);
        manager.sceneCreator.updateHoverImagePosition(item);
    } else if (item.name != manager.selectionManager.selection.objects[0].name && 
        manager.selectionManager.selection.state == Constants.AssetState.PLACING) {
        manager.sceneCreator.showHoverImage(item.name);
        manager.sceneCreator.updateHoverImagePosition(item);
    } else {
        manager.sceneCreator.hideHoverImage();
    }
}

/**
 * Processes jumping movement between different surfaces or parents
 * @param {ObjectPlacementManager} manager - The manager handling object placement
 * @param {THREE.Vector3} newPosition - The new position to move to
 * @param {THREE.Object3D} newParent - The new parent object to attach to
 * @param {boolean} updateParent - Whether to update the parent relationship (default: true)
 */
export function processJump(manager, newPosition, newParent, updateParent = true) {
    if (newParent != manager.scene) {
        if (updateParent) {
            manager.updateParent(manager.selectionManager.selection.objects[0], newParent);
            manager.moveAsset(
                manager.selectionManager.selection.objects[0], 
                newParent.worldToLocal(newPosition.clone())
            );
        } else {
            if (manager.selectionManager.selection.objects[0].userData.isStacked) {
                manager.updateParent(manager.selectionManager.selection.objects[0], manager.scene);
            }
            manager.moveAsset(manager.selectionManager.selection.objects[0], newPosition);
        }

        manager.selectionManager.selection.objects[0].userData.isStacked = true;

        if (manager.selectionManager.selection.objects[0].userData.isPillow && 
            manager.sceneCreator.activeCamera.name != 'topDown' && 
            manager.sceneCreator.activeCamera.name != 'topDownOrtho' && 
            manager.selectionManager.selection.objects[0].userData.isStacked) {
            manager.rotationControls.showAxis('X', true);
        }
    } else {
        manager.updateParent(manager.selectionManager.selection.objects[0], newParent);
        manager.selectionManager.selection.objects[0].userData.isStacked = false;
        manager.moveAsset(manager.selectionManager.selection.objects[0], newPosition);
        if (manager.selectionManager.selection.objects[0].userData.isPillow && 
            !manager.selectionManager.selection.objects[0].userData.isStacked) {
            manager.rotationControls.showAxis('X', false);
        }
    }
}