import * as THREE from 'three';
import Constants from "../Constants";
import { isConvexHull, getObjectFromRootByName, setHighlightedState, getRootNodeFromObject } from "../HelperFunctions";

/**
 * Manages collision detection between objects in the scene
 */
export default class CollisionManager {
    /**
     * Creates a new CollisionManager instance
     * @param {THREE.Scene} scene - The Three.js scene
     * @param {SelectionManager} selectionManager - Manager handling object selection state
     * @param {RaycastManager} raycastManager - Manager handling raycasting operations
     */
    constructor(scene, selectionManager, raycastManager) {
        this.scene = scene;
        this.selectionManager = selectionManager;
        this.raycastManager = raycastManager;
    }

    /**
     * Detects if there are any objects below the currently selected object
     * Uses raycasting in the downward direction to find intersections
     * @param {Array<THREE.Object3D>} intersectObjects - Array of objects to check for intersections
     * @returns {Object|null} Returns null if no objects found below or if object needs to be unstacked
     */
    detectObjectBelow(intersectObjects) {
        const rayOrigin = this.selectionManager.selection.worldPosition;
        const downwardDirection = new THREE.Vector3(0, -1, 0);
        const raycastLength = 10;
    
        this.raycastManager.updateRaycasterProperties(rayOrigin, downwardDirection, raycastLength);
        let results = this.raycastManager.setAndIntersectAll(rayOrigin, downwardDirection, intersectObjects);
        if (results != null) {
            results = results.filter( item => {
                return ( getRootNodeFromObject(item.object) != this.selectionManager.selection.objects[0] && !isConvexHull(item.object));
            })
        }
        if (!results || results.length == 0) {
            this.updateParent(this.selectionManager.selection.objects[0], this.scene);
            this.selectionManager.selection.objects[0].userData.isStacked = false;
        }
        return null;
    }

    /**
     * Detects collisions between the selected object and other objects in the scene
     * Uses raycasting between corners of the object's bounding box
     * @param {Array<THREE.Object3D>} intersectObjects - Array of objects to check for collisions
     * @returns {boolean} Returns true if collision detected, false otherwise
     */
    detectCollision(intersectObjects) {
        let isColliding = false;
        const cornersList = this.selectionManager.selection.objects[0].userData.pvb.getCorners();
        const corners = [cornersList.c1, cornersList.c2, cornersList.c3, cornersList.c4, cornersList.c5, cornersList.c6, cornersList.c7, cornersList.c8];
        const selectionParent = this.selectionManager.selection.objects[0].parent;
        
        const cornerPairs = [
            [0, 1], [0, 2], [0, 4], [0, 5],
            [1, 3], [1, 6], [1, 7], [2, 3],
            [2, 5], [2, 7], [3, 6], [3, 4],
            [4, 5], [4, 6], [5, 7], [6, 7]
        ];

        let threshold = this.selectionManager.selection.objects[0].userData.isPillow ? 0.20 : 0.10;

        for (let [j, i] of cornerPairs) {
            if (isColliding) break;
            
            const directionVector = new THREE.Vector3().subVectors(corners[j], corners[i]).normalize().negate();
            const lineOrigin = corners[j].clone();
            const raycastLength = corners[j].distanceTo(corners[i]) * (1 - threshold);
            
            this.raycastManager.updateRaycasterProperties(lineOrigin, directionVector, raycastLength);
            this.updateParent(this.selectionManager.selection.objects[0], this.scene);

            const results = this.raycastManager.setAndIntersect(lineOrigin, directionVector, intersectObjects);
            this.updateParent(this.selectionManager.selection.objects[0], selectionParent);

            if (results!=false && !isConvexHull(results.object)) {
                isColliding = true;
            }
        }
        this.raycastManager.resetFarValue();
        return isColliding;
    }

    /**
     * Detects collisions when object is in free movement mode
     * Updates object highlighting based on collision state
     * @param {SpaceManager} spaceManager - Manager containing references to scene objects
     */
    detectCollisionInFreeMode(spaceManager) {
        let intersectObjects = [];
        const selectionParent = this.selectionManager.selection.objects[0].parent;
        if (selectionParent != this.scene) {
            intersectObjects.push(selectionParent);
        }
        intersectObjects = intersectObjects.concat(
            spaceManager.walls, 
            spaceManager.floors, 
            spaceManager.doors, 
            spaceManager.ceilings, 
            spaceManager.miscNodes
        );
        
        let isColliding = this.detectCollision(intersectObjects);
        
        const selectionObj = getObjectFromRootByName(this.selectionManager.selection.objects[0], this.selectionManager.selection.objects[0].name) || this.selectionManager.selection.objects[0];
        const highlightColor = isColliding ? Constants.invalidHighLightColor : Constants.defaultHighLightColor;
        setHighlightedState(selectionObj, true, highlightColor);
    }

    /**
     * Updates the parent of an object, handling attachment and detachment from scene
     * @param {THREE.Object3D} object - The object to update
     * @param {THREE.Object3D} newParent - The new parent object
     */
    updateParent(object, newParent) {
        this.scene.attach(object);
        if (newParent != this.scene) {
            newParent.attach(object);
        }
    }
} 