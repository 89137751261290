import { getRootNodeFromObject, setHighlightedState, getObjectFromRootByName, isConvexHull } from "../HelperFunctions";
import { 
    handleFloorPlacement,
    handleMiscJump,
    handleItemJump,
    handleItemMovement,
    handleFloorJump,
    handleFloorMovement,
    handleDefaultFloorPosition,
    handleWallPlacement,
    handleWallIntersectionPoint,
    handleWallDirectionChange,
    handleWallHelperPoint,
    handleCeilingPlacement,
    updateFocusedAsset,
    updateHoverImage,
    clearFocusedAsset
} from "./MouseEventsHelpers.js";
import Constants from "../Constants.js";

/**
 * Manages mouse events and their interactions with objects in the scene
 */
export default class MouseEventsManager {
    /**
     * Creates a new MouseEventsManager instance
     * @param {ObjectPlacementManager} objectPlacementManager - The manager handling object placement
     */
    constructor(objectPlacementManager) {
        this.manager = objectPlacementManager;
    }

    /**
     * Handles selection state and stores previous positions of selected objects
     */
    handleSelectionState() {
        if (this.manager.selectionManager.selection.objects.length > 0) {
            this.manager.objectSnappingManager.snapGuide.setTarget(this.manager.selectionManager.selection.objects[0]);
            this.manager.selectedObjectPositions = this.manager.selectionManager.selection.objects.map(obj => ({
                object: obj,
                position: obj.position.clone(),
                rotation: obj.rotation.clone(),
                parent: obj.parent
            }));
            this.manager.selectedObjectRotation = this.manager.selectionManager.selection.objects[0].rotation.clone();
            this.manager.selectedObjectPreviousParent = this.manager.selectionManager.selection.objects[0].parent;
        } else {
            this.manager.selectedCameraRotation = this.manager.sceneCreator.activeCamera.rotation.clone();
        }
    }

    /**
     * Handles ceiling transparency when in top-down view
     */
    handleCeilingTransparency() {
        const isCeilingPlacement = this.manager.selectionManager.selection.placementType == Constants.PlacementType.CEILING;
        const isTopDownView = this.manager.sceneCreator.activeCamera.name == "topDown" || this.manager.sceneCreator.activeCamera.name == "topDownOrtho";
        
        if (isCeilingPlacement && isTopDownView) {
            this.manager.spaceManager.enableTransparentCeiling();
        }
    }

    /**
     * Handles object intersection and sets movement origin
     */
    handleObjectIntersection() {
        let intersect = this.manager.raycastManager.cameraIntersectSceneAssets(this.manager.clipping);
        if (intersect && getRootNodeFromObject(this.manager.scene, intersect.object) == this.manager.selectionManager.selection.objects[0]) {
            this.manager.setMovementOrigin();
            this.manager.selectionManager.selection.state = Constants.AssetState.PLACING;
        }
    }

    /**
     * Handles pillow-specific selection behavior and state
     */
    handlePillowSelection() {
        this.manager.selectionManager.selection.refreshSelectionTransform();
        
        const isPillowNotSelected = this.manager.pillowPlacementManager.currentPillowObject == null || 
                                  this.manager.pillowPlacementManager.currentPillowObject != this.manager.selectionManager.selection.objects[0];

        if (isPillowNotSelected) {
            let placementInfo = this.manager.getPlacementInfo(this.manager.selectionManager.selection.placementType);
            if (placementInfo) {
                this.manager.pillowPlacementManager.setBaseObject(placementInfo.intersectedObj);
                this.manager.preserveAssetPreviousState();
            }
        }

        if (this.manager.rotationControls.active) {
            this.manager.selectionManager.selection.state = Constants.AssetState.ROTATING;
        }
    }

    /**
     * Handles visibility of pillow rotation gizmo based on stacking state
     */
    handlePillowGizmo() {
        const isPillowUnstacked = this.manager.selectionManager.selection.objects.length > 0 && 
            this.manager.selectionManager.selection.objects[0].userData.isPillow && 
            !this.manager.selectionManager.selection.objects[0].userData.isStacked;
            
        if (isPillowUnstacked) {
            this.manager.rotationControls.showAxis('X', false);
        }
    }

    /**
     * Handles rotation state updates and transformations
     */
    handleRotationState() {
        const isRotating = this.manager.selectionManager.selection.state == Constants.AssetState.ROTATING || 
            this.manager.selectionManager.selection.state == Constants.AssetState.INVALID_ROTATION;
        
        if (!this.manager.sceneCreator.isPanning && 
            this.manager.selectionManager.selection.objects.length > 0 && 
            isRotating) {
            this.manager.selectionManager.selection.refreshSelectionTransform();
        }
    }

    /**
     * Handles placement state and updates cursor style
     * @param {Object} placementInfo - Information about the placement position and intersections
     */
    handlePlacementState(placementInfo) {
        if (this.manager.selectionManager.selection.objects[0].userData.isPillow) {
            let checkPillowBaseObject = this.manager.pillowPlacementManager.currentBaseObject == placementInfo.intersectedObj;
            this.manager.pillowPlacementManager.setBaseObject(placementInfo.intersectedObj);
            if (!checkPillowBaseObject) {
                this.manager.pillowPlacementManager.resetPillowRotation(this.manager.selectionManager.selection.objects[0]);
            }
        }

        this.manager.sceneContainer.style.cursor = "grabbing";
        
        if (this.manager.selectionManager.selection.objects[0].userData.isRug) {
            this.manager.setDisclaimer("After moving the rug, move the items that were on the rug to the floor.");
        }
    }

    /**
     * Routes placement movement handling based on placement type
     * @param {Object} placementInfo - Information about the placement position and intersections
     */
    handlePlacementMovement(placementInfo) {
        const placementType = this.manager.selectionManager.selection.placementType;

        if (placementType === Constants.PlacementType.FLOOR) {
            handleFloorPlacement(this.manager, placementInfo);
        } else if (placementType === Constants.PlacementType.WALL) {
            handleWallPlacement(this.manager, placementInfo);
        } else if (placementType === Constants.PlacementType.CEILING) {
            handleCeilingPlacement(this.manager, placementInfo);
        }
    }

    handleMiscJump(placementInfo) {
        handleMiscJump(this.manager, placementInfo);
    }

    handleItemJump(placementInfo) {
        handleItemJump(this.manager, placementInfo);
    }

    handleItemMovement(placementInfo) {
        handleItemMovement(this.manager, placementInfo);
    }

    handleFloorJump(placementInfo) {
        handleFloorJump(this.manager, placementInfo);
    }

    handleFloorMovement(placementInfo) {
        handleFloorMovement(this.manager, placementInfo);
    }

    handleDefaultFloorPosition() {
        handleDefaultFloorPosition(this.manager);
    }

    handleWallIntersectionPoint(placementInfo) {
        handleWallIntersectionPoint(this.manager, placementInfo);
    }

    handleWallDirectionChange(placementInfo) {
        handleWallDirectionChange(this.manager, placementInfo);
    }

    handleWallHelperPoint(placementInfo) {
        handleWallHelperPoint(this.manager, placementInfo);
    }

    /**
     * Handles intersection with UI elements and updates hover states
     * @param {Object} intersect - The intersection data from raycasting
     */
    handleIntersectUI(intersect) {
        if (this.manager.sceneCreator.editModeOn) {
            updateFocusedAsset(this.manager, intersect);
            updateHoverImage(this.manager, intersect);
            this.manager.sceneContainer.style.cursor = "grab";
        }
        this.manager.navControl.hide = true;
    }

    /**
     * Handles panning state and cursor updates
     */
    handlePanningState() {

        clearFocusedAsset(this.manager);

        this.manager.sceneCreator.hideHoverImage();

        if (this.manager.sceneCreator.isPanning) {
            this.manager.sceneContainer.style.cursor = "move";
            this.manager.navControl.hide = true;
        } else {
            this.handleNonPanningState();
        }

        this.updateCameraControls();
    }

    /**
     * Handles non-panning state and navigation control visibility
     */
    handleNonPanningState() {
        this.manager.sceneContainer.style.cursor = "auto";
        
        if (this.manager.selectionManager.selection.objects[0] || 
            !this.manager.raycastManager.cameraIntersectFloor()) {
            this.manager.navControl.hide = true;
        } else {
            this.manager.navControl.hide = false;
        }
    }

    /**
     * Updates camera controls and slider values
     */
    updateCameraControls() {
        if (this.manager.sceneCreator.cameraControls.isConnected && 
            this.manager.setSliderValue != null) {
            this.manager.setSliderValue(this.manager.getCameraPolarAngle());
        }
    }

    /**
     * Handles selection state changes on mouse up event
     */
    handleSelectionStateOnMouseUp() {
        if (this.manager.selectionManager.selection.objects.length === 1) {
            this.manager.sceneCreator.panningEnabled = true;

            if (this.manager.selectionManager.selection.state == Constants.AssetState.INVALID || 
                this.manager.selectionManager.selection.state == Constants.AssetState.INVALID_ROTATION) {
                this.manager.restoreAssetPreviousState();
                let selectionObj = getObjectFromRootByName(this.manager.selectionManager.selection.objects[0], 
                    this.manager.selectionManager.selection.objects[0].name) || this.manager.selectionManager.selection.objects[0];
                if (this.manager.selectionManager.selection.objects[0].userData.isFrozen) {
            setHighlightedState(selectionObj, true, Constants.invalidHighLightColor);
                } else {
            setHighlightedState(selectionObj, true, Constants.defaultHighLightColor);
                }
                this.manager.selectionManager.selection.objects[0].userData.snapped = false;
            }
        } else if (this.manager.selectionManager.selection.objects.length > 1) {
            if (this.manager.isMultipleItemsMoving && this.manager.selectionManager.selection.placementType == Constants.PlacementType.FLOOR) {
                this.manager.setParentAfterVerticalCollision();
            }
            this.manager.sceneCreator.panningEnabled = true;
            for (let i = 0; i < this.manager.selectionManager.selection.objects.length; i++) {
                if (this.manager.selectionManager.selection.placementType == Constants.PlacementType.FLOOR && this.manager.selectionManager.selection.objects[i].userData.isCollidingWithWall) {
                    if (this.manager.selectionManager.selection.objects[i].userData.lastValidParent) {
                        this.manager.updateParent(this.manager.selectionManager.selection.objects[i], this.manager.selectionManager.selection.objects[i].userData.lastValidParent);
                    } else {
                        this.manager.selectionManager.selection.objects[i].userData.lastValidParent = this.manager.scene;
                    }
                    if(this.manager.selectionManager.selection.objects[i].userData.lastValidPosition) {
                        this.manager.selectionManager.selection.objects[i].position.copy(this.manager.selectionManager.selection.objects[i].userData.lastValidPosition);
                    }
                }
                if (this.manager.selectionManager.selection.placementType == Constants.PlacementType.WALL) {
                    if(this.manager.selectionManager.selection.objects[i].userData.lastValidPosition) {
                        this.manager.selectionManager.selection.objects[i].position.copy(this.manager.selectionManager.selection.objects[i].userData.lastValidPosition);
                    }
                }
                let selectionObj = getObjectFromRootByName(this.manager.selectionManager.selection.objects[i], 
                    this.manager.selectionManager.selection.objects[i].name) || this.manager.selectionManager.selection.objects[i];    
                setHighlightedState(selectionObj, true, Constants.defaultHighLightColor);    
                this.manager.selectionManager.selection.objects[i].userData.snapped = false;
            }
            this.manager.selectionManager.selection.refreshSelectionTransformArray();
        }
    }

    /**
     * Handles position changes and adds to action history
     */
    handlePositionChange() {
        if(this.manager.transformControls.enabled) {
            return;
        }
        const actions = this.manager.selectedObjectPositions.map(initial => {
            const obj = initial.object;
            if (!obj?.userData?.isFrozen && !initial.position.equals(obj.position)) {
                return {
                    target: obj,
                    transformation: "position",
                    previousState: {
                        parent: initial.parent,
                        position: initial.position,
                        rotation: initial.rotation
                    },
                    newState: {
                        position: obj.position.clone()
                    },
                    resetTransform: () => this.manager.selectionManager.selection.refreshSelectionTransform()
                };
            }
            return null;
        }).filter(action => action !== null); // Remove null actions

        // Only add to action stack if there were actual movements
        if (actions.length > 0) {
            this.manager.actionManager.addAction(actions);
            console.log("action manager", this.manager.actionManager.actionStack);
        }
        this.manager.selectedObjectPositions = this.manager.selectionManager.selection.objects.map(obj => ({
            object: obj,
            position: obj.position.clone(),
            rotation: obj.rotation.clone(),
            parent: obj.parent
        }));
    }

    /**
     * Handles selection reset when clicking empty space
     */
    handleSelectionReset() {
        let intersect = this.manager.raycastManager.cameraIntersectSceneAssets(this.manager.clipping);
        const shouldResetSelection = this.manager.sceneCreator.mouseDown && 
            !this.manager.rotationControls.active && 
            !this.manager.transformControls.enabled && 
            !this.manager.isMultipleItemsMoving &&
            !this.manager.sceneCreator.isPanning &&
            !intersect;

        if (shouldResetSelection) {
            this.manager.resetSelection();
            if (this.manager.sceneCreator.activeCamera.name == "topDown" || 
                this.manager.sceneCreator.activeCamera.name == "topDownOrtho") {
                this.manager.spaceManager.disableCeiling();
            }
        }
    }

    /**
     * Handles pillow height adjustment
     */
    handlePillowAdjustment() {
        const isPillowSelected = this.manager.selectionManager.selection.objects.length > 0 && 
            this.manager.selectionManager.selection.objects[0].userData.isPillow;
        const isNotRotating = this.manager.selectionManager.selection.state != Constants.AssetState.ROTATING && 
            this.manager.selectionManager.selection.state != Constants.AssetState.INVALID_ROTATION;

        if (!this.manager.transformControls.enabled && isPillowSelected && isNotRotating) {
            this.manager.pillowPlacementManager.adjustPillowHeight(this.manager.selectionManager.selection.objects[0]);
        }
    }

    /**
     * Handles final cleanup after mouse interactions
     */
    postMouseEventActions() {
        this.manager.selectionManager.selection.state = Constants.AssetState.PLACED;
        this.manager.isPlacementCorrectionRequired = false;
        this.manager.pointStart.set(0, 0, 0);
        this.manager.pointEnd.set(0, 0, 0);
        this.manager.isMultipleItemsMoving = false;

        if (!this.manager.transformControls.enabled) {
            this.manager.adjustWallItemIntersectingMoulding();
        }

        this.manager.objectSnappingManager.snapGuide.setTarget(null);
        if (this.manager.selectionManager.selectedObjectInMultipleSelection !== null) {
            this.manager.setParentMultipleItemsAfterMovement();
            this.manager.selectionManager.selectedObjectInMultipleSelection = null;
        }

    }

    /**
     * Handles transform controls state and object detection
     */
    handleTransformControls() {
        if (this.manager.transformControls.enabled && this.manager.selectionManager.selection.objects[0]) {
            let intersectObjects = [];
            const selectionParent = this.manager.selectionManager.selection.objects[0].parent;
            if (selectionParent != this.manager.scene) {
                intersectObjects.push(selectionParent);
                this.manager.detectObjectBelow(intersectObjects);
            }
        }
    }

    /**
     * Sets selection to currently focused asset if edit mode is enabled
     */
    selectFocusedAsset(){
        if( this.manager.focusedAsset != null && this.manager.sceneCreator.editModeOn && !this.manager.isMultipleItemsMoving ){

            this.manager.setSelection ( this.manager.focusedAsset );
            this.manager.selectionManager.selection.state = Constants.AssetState.PLACING;

            this.manager.placementInfoTargets = this.manager.scene.children.filter( ( item ) => { return ( item != this.manager.selectionManager.selection.objects[0] && item.type == "Scene" && !(item.userData && item.userData.isGrid) ) } );
            this.manager.placementInfoTargets.push ( this.manager.helperPlane );

            if( this.manager.selectionManager.selection.placementType === Constants.PlacementType.WALL) {
                this.manager.spaceManager.floors[0].getWorldPosition( this.manager.helperPlane.position );
            }
            else {
                this.manager.helperPlane.position.copy ( this.manager.selectionManager.selection.worldPosition );    
            }

            this.manager.helperPlane.updateMatrixWorld();

            this.manager.setMovementOrigin();
            
        }
    }

    /**
     * Sets wall intersect
     */
    setWallIntersect() {
        if (this.manager.selectionManager.selection.objects.length > 0 && this.manager.selectionManager.selection.placementType === Constants.PlacementType.WALL) {
            let wall = this.manager.getWallIntersect(this.manager.selectionManager.selection.objects[0]);
            if (wall) {
                this.manager.selectionManager.wallIntersect = wall.object;
            }
        }
    }

    
    /**
     * Checks if the single selected object is frozen
     * @returns {boolean} - True if the object is frozen, false otherwise
     */
    checkSingleObjectFrozen() {
        if (this.manager.selectionManager.selection.objects.length == 1) {
            return this.manager.selectionManager.selection.objects[0]?.userData?.isFrozen;
        }
    }
    
    /**
     * Handles single object movement
     */
    handleSingleObjectMovement() {
        // Handle single object movement
        const isPlacing = !this.manager.transformControls.enabled && 
            !this.manager.sceneCreator.isPanning && 
            this.manager.selectionManager.selection.objects.length === 1 &&
            !this.checkSingleObjectFrozen() &&         
            (this.manager.selectionManager.selection.state == Constants.AssetState.PLACING ||
             this.manager.selectionManager.selection.state == Constants.AssetState.INVALID);
        if (isPlacing) {
            
            this.manager.sceneCreator.panningEnabled = false;
            this.manager.sceneCreator.isPanned = true;
            this.manager.selectionManager.selection.refreshSelectionTransform();
            let placementInfo = this.manager.getPlacementInfo(this.manager.selectionManager.selection.placementType);

            if (placementInfo) {
                this.handlePlacementState(placementInfo);
                this.handlePlacementMovement(placementInfo);
            }
        }
    }

    /**
     * Handles multiple objects movement
     */
    handleMultipleObjectsMovement() {
        // Handle multiple objects movement
        if (this.manager.sceneCreator.mouseDown && this.manager.selectionManager.selectedObjectInMultipleSelection !== null) {
            this.manager.isMultipleItemsMoving = true;
            this.manager.setParentBeforeVerticalCollision();
            this.manager.getMultipleObjectsPlacementInfo();
        }
    }

    /**
     * Handles mouse up event
     */
    onMouseUp = () => {

        if(this.manager.transformSnappingManager.enabled) {
            this.manager.transformSnappingManager.events.onMouseUp();
            return;
        }

        // Handle selection state changes
        this.handleSelectionStateOnMouseUp();

        // Handle position changes and action management
        this.handlePositionChange();

        // Handle selection reset
        this.handleSelectionReset();

        // Handle pillow-specific adjustments
        this.handlePillowAdjustment();

        // Handle focused asset selection
        if (!this.manager.rotationControls.active && !this.manager.transformControls.enabled) {
            this.selectFocusedAsset();
        }

        // Handle final cleanup post mouse event 
        this.postMouseEventActions();

        // Handle transform controls
        this.handleTransformControls();

    }

    /**
     * Handles mouse move event
     */
    onMouseMove = () => {

        if(this.manager.transformSnappingManager.enabled) {
            this.manager.transformSnappingManager.events.onMouseMove();
            if (this.manager.sceneCreator.isPanning) {
                this.manager.transformSnappingManager.resetTransformSnappingManager();
            }
            else {
                this.manager.transformSnappingManager.events.onMouseMove();
            }
            return;
        }

        // Set wall intersect
        this.setWallIntersect();

        // Handle pillow gizmo visibility
        this.handlePillowGizmo();

        // Handle rotation state
        this.handleRotationState();

        // Handle intersections and UI
        let intersect = this.manager.raycastManager.cameraIntersectSceneAssets(this.manager.clipping);
        const canHandleIntersect = intersect && 
            !this.manager.transformControls.enabled && 
            !this.manager.sceneCreator.isPanning && 
            this.manager.sceneCreator.panningEnabled && 
            !this.manager.rotationControls.active;

        if (canHandleIntersect) {
            this.handleIntersectUI(intersect);
        } else {
            if (this.manager.sceneCreator.panningEnabled) {
                this.handlePanningState();
            }
            if (this.manager.transformControls.enabled && this.manager.selectionManager.selection.objects[0]) {
                this.manager.detectCollisionInFreeMode();
            }
        }
        if (this.manager.selectionManager.selection.objects.length == 1) {
            this.handleSingleObjectMovement();
        } else if (!this.manager.rotationControls.active && this.manager.selectionManager.selection.objects.length > 1) {
            this.handleMultipleObjectsMovement();
        }
        
    }

    setDisclaimerFalse = () => {
        this.manager.setDisclaimer(null);
    }
    
    /**
     * Handles mouse down event
     */
    onMouseDown = () => {

        if(this.manager.transformSnappingManager.enabled) {
            this.manager.transformSnappingManager.events.onMouseDown();
            return;
        }

        if (this.manager.transformControls.enabled) return;

        if (this.manager.isMultipleSelectionKeyPressed) {
            this.manager.selectionManager.selectionMode = Constants.SelectionMode.MULTIPLE;
        }
        else {
            this.manager.selectionManager.selectionMode = Constants.SelectionMode.SINGLE;
        }
    
        // Handle selection state and store previous positions
        this.handleSelectionState();

        const hasSelection = this.manager.selectionManager.selection.objects.length > 0;
        
        if (hasSelection && !this.manager.rotationControls.active) {
            // Handle ceiling transparency for top-down view
            this.handleCeilingTransparency();
            
            // Handle object intersection and movement
            this.handleObjectIntersection();
        }
        else if (hasSelection && this.manager.selectionManager.selection.objects[0].userData.isPillow) {
            // Handle pillow-specific selection behavior
            this.handlePillowSelection();
        }

        if (this.manager.selectionManager.selection.objects.length > 1) {
            this.manager.setLastValidParentForSelectedObjects();
            this.manager.setSelectedObjectInMultipleSelection();
        }

        this.setDisclaimerFalse();
        
    }
} 