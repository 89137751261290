import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Tooltip, Row, Col, Button, Card } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import ProductPageConstants from './ProductPageConstants.js';

const FeatureProductImages = () => {
    const { heroShot, statusOf360Spin, statusOfMp4Video, secondaryShots, productImageCount, openProductAssetViewer,
            setSelectedAsset, setSelectedProductImage, allProductImages, setImgAccessedFromCarousel, setSelectedImgFilename,
            setSelectedImgOriginalFormat } = useContext(CustomerProductContext);

    const selectAssetAndOpenViewer = (asset, image = heroShot) => {
        setSelectedAsset(asset);
        setSelectedProductImage(image);
        let image_index = allProductImages.findIndex(img => img.url == image);
        setSelectedImgOriginalFormat(allProductImages[image_index].format);
        setSelectedImgFilename(allProductImages[image_index].filename);
        setImgAccessedFromCarousel(false);
        openProductAssetViewer();
    }


    const getFormat = (displayed_image = heroShot) => {
        let image = allProductImages.filter(img => img.url === displayed_image);
        let type = "";
        if (image.length > 0) {
            type = image[0].type;
        }

        return type;
    }

    return (
        <Col className="frame" span={12}>
            <Card className="image-viewer">
                <Tooltip title={<span className='manrope f-12 white'>Expand image</span>} placement="left">
                    <img src={"/img/fullScreenIcon.jpg"}
                        alt="expand image"
                        className="expand-icon-pos" onClick={() => { selectAssetAndOpenViewer("images", heroShot) }} />
                </Tooltip>
                <div className='hero-shot-label'>
                    <div className='manrope f-14 black-26 lh-28'>{ProductPageConstants.IMAGE_TYPES[getFormat()]}</div>
                </div>
                <img className="hero-shot" alt="Hero Shot" src={heroShot} onClick={() => { selectAssetAndOpenViewer("images", heroShot) }} />
                <div className='product-action-bar'>
                    {ProductPageConstants.PRODUCT_ACTIONS.map((action, index) => (
                        ((statusOf360Spin == "" && action.type == "360_view") || (statusOfMp4Video == "" && action.type == "mp4_video")) ? "" :
                            <Tooltip title={action.tooltip} placement="top" >
                                <Card className='product-action-menu-card' key={index} onClick={() => { selectAssetAndOpenViewer(action.type) }}>
                                    <img src={action.icon} className='product-action-img' />
                                </Card>
                            </Tooltip>
                    ))}
                </div>
            </Card>

            <Row className="w-100">
                <Col span={24} className='display-flex j-s-b a-c gg-16 w-100'>
                    {secondaryShots.map((image, index) => (
                        <Card className="image-card" key={index}>
                            <div className='hero-shot-label'>
                                <div className='manrope f-14 black-26 lh-28'>{ProductPageConstants.IMAGE_TYPES[getFormat(image)]}</div>
                            </div>
                            <img className="secondary-shot" alt={"Shot_" + index}
                                onClick={() => { selectAssetAndOpenViewer("images", image) }}
                                src={image} />
                        </Card>
                    ))}
                </Col>
            </Row>
            <Button className="button" onClick={() => selectAssetAndOpenViewer("images")}>
                <div className="text">See All {productImageCount} Images</div>
                <ArrowRightOutlined className="arrow-right" />
            </Button>
        </Col>
    )
}

export default FeatureProductImages;