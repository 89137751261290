import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import axios from "axios";
import { Card, Checkbox, Dropdown, Menu, message, Modal, Button, Tooltip, Popover, Avatar, Badge } from "antd";
import "@ant-design/compatible/assets/index.css";
import {
  ClockCircleOutlined,
  LoadingOutlined,
  PictureOutlined,
  CheckCircleFilled,
  EditOutlined
} from "@ant-design/icons";
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg'
import "../../../../styles/helper.scss";
import * as Utilities from "../../Utilities";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LibraryGrid from "../LibraryGrid/LibraryGrid";
import ENVIRONMENT from "../../../../environments";
import ProjectConstants from "./Constants";
import FileContants from "../../../../FileConstants";
import "./CustomerProject.scss";

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem("username");
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
const SEQUENCE_IDS = localStorage.getItem('sequence_ids');

const EntitiesList = (props) => {
  const { data, selectable, selected, setSelected, projectStatus, isFilter, accessActions, 
    projectName, selectedUser, activeTab } =
    props;
  const [columnCount, setColumnCount] = useState(4);
  const [rowCount, setRowCount] = useState(1);
  const maxColumnWidth = 280;
  const rowSize =
    selectable ||
    projectStatus == "approved"
      ? 470
      : 515;

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const itemIndex = rowIndex * columnCount + columnIndex;
    const ele = data[itemIndex] && data[itemIndex];

    if (!ele) {
      return null;
    }

    return (
      <div key={key} style={style}>
        <EntityItem
          selectAssetAndSetBasicInfo={props.selectAssetAndSetBasicInfo}
          selectable={selectable}
          selected={selected}
          setSelected={setSelected}
          projectStatus={projectStatus}
          accessActions={accessActions}
          key={key}
          ele={ele}
          setCurrentScene={props.setCurrentScene}
          setCurrentSceneName={props.setCurrentSceneName}
          setDuplicateSceneModal={props.setDuplicateSceneModal}
          projectName={projectName}
          projectTab={activeTab}
          selectedUser={selectedUser}
        />
      </div>
    );
  };

  useEffect(() => {
    setRowCount(Math.ceil(data.length / columnCount));
  }, [data]);

  return (
    <>
      {data.length == 0 ? (
        <div
          className="empty-section"
          style={{
            marginTop: "10%",
            marginBottom: 40,
            position: "relative",
            textAlign: "-webkit-center",
          }}
        >
          <img
            id="empty-section-image"
            style={{ width: "10%" }}
            src="../../../../img/Collections.svg"
          />
          <p className="empty-section-text manrope f-14 black-55">
            Nothing to show
          </p>
        </div>
      ) : (
        <LibraryGrid
          setColumnCount={setColumnCount}
          setRowCount={setRowCount}
          maxColumnWidth={maxColumnWidth}
          data={data}
          cellRenderer={cellRenderer}
          columnCount={columnCount}
          getColumnWidth={() => {}}
          rowCount={rowCount}
          getEstimatedRowSize={rowSize}
          handleColumnWidthChange={() => {}}
          contentHeight={isFilter || selectable ? " grid-content-320" : ""}
        />
      )}
    </>
  );
};

const EntityItem = (props) => {
  const { ele, selectable, selected, setSelected, projectStatus, accessActions,
    projectName, selectedUser, projectTab
   } = props;

  const [removeItemModal, setRemoveItemModal] = useState(false)
  const [deleteItemModal, setDeleteItemModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [removingItemLoader, setRemovingItemLoader] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [deleteModalHeading, setDeleteModalHeading] = useState('')
  const [deleteModalDescription, setDeleteModalDescription] = useState('')
  const isCopyInProgress = ele.scene_created_from == 'duplication_inprogress';

  let updateTime = Utilities.timestampToTimeSince(
    selectable ? ele.last_modified_stamp : ele.last_modified
  );
  if (updateTime.includes("h")) {
    updateTime = "Today";
  }

  const getSequenceId = (sequence_ids = SEQUENCE_IDS, entity_type) => {
    if (sequence_ids) {
      sequence_ids = JSON.parse(sequence_ids);
      const sequence_data = sequence_ids.find((sequence_data) => sequence_data['entity_type'] === entity_type);
      if (sequence_data) {
        return sequence_data['sequence_id'];
      }
    }
    return -1;
  };

  const handleSelectClick = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((ele) => ele !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const statusData = ProjectConstants.status_data;
  const sceneStatusData = ProjectConstants.scene_status_data;

  const entityData = ProjectConstants.entity_data;

  const select_id = ele[entityData[ele.entity_type].select_id];
  const getThumbnail = () => {
    if (ele.entity_type === "room") {
      return (
        ENVIRONMENT.getBaseURL(ele.platform) +
        ENVIRONMENT.SPACE_THUMBNAIL_URI +
        (selectable ? ele.room_id : ele.id) +
        "/" +
        ele.thumbnail.split("/")[ele.thumbnail.split("/").length - 1]
      );
    } else if (ele.entity_type === "product") {
      return (
        ENVIRONMENT.getBaseURL(ele.platform) +
        ENVIRONMENT.THUMBNAIL_URI +
        ele.thumbnail.split("/")[ele.thumbnail.split("/").length - 1]
      );
    } else if (ele.entity_type === "scene") {
      return (
        ENVIRONMENT.getBaseURL(ele.platform) +
        ENVIRONMENT.SCENE_THUMBNAIL_URI +
        ele.id +
        "/" +
        ele.thumbnail
      );
    }
  };

  const redirectToEntity = () => {
    let urlParams = `&project_name=${projectName}&selectedUser=${selectedUser}&projectTab=${projectTab}`
    if (ele.entity_type === "room") {
      if (
        ele.status == "in_review" &&
        accessActions.includes("approve_entity")
      ) {
        window.location.href = `/room-qa/${ele.id}?entity_id=${ele.id}&project_id=${ele.project_id}` + urlParams;
      } else if (ele.status !== "in_review") {
        window.location.href = `/rooms/${ele.id}?entity_id=${ele.id}&project_id=${ele.project_id}` + urlParams;
      }
    } else if ((ele.entity_type === "product" && ele.status === "approved") || (ele.entity_type === "product" && ele.status === "in_review" && !accessActions.includes('approve_entity'))) {
      window.location.href = `/products/${ele.id}?entity_id=${ele.id}&project_id=${ele.project_id}` + urlParams;
    } else if(ele.entity_type === "product" && (ele.status === "in_progress" || ele.status === "rejected")) {
      window.location.href = `/product_page/${ele.id}`
    } else if (ele.entity_type === "product" && ele.status === "in_review" && accessActions.includes('approve_entity')) {
      window.location.href = `/product-qa/${ele.id}?entity_id=${ele.id}&project_id=${ele.project_id}` + urlParams;
    } else if (ele.entity_type === "scene" && (ele.status == "in_progress" || ele.status == "approved")) {
      window.location.href = `/scene_renders/${ele.id}?entity_id=${ele.id}&project_id=${ele.project_id}`+ urlParams;
    }
    else if (ele.entity_type === "scene") {
        window.location.href = `/scene_builder/${ele.id}?entity_id=${ele.id}&project_id=${ele.project_id}`;
      }
  };

  const handleCardClick = () => {
    if (selectable) {
      handleSelectClick(select_id);
    } else {
      redirectToEntity();
    }
  };

  const removeItemFromProject = (remove_from_project = true) => {
      let ele = selectedItem;
      if (ele) {
        setRemovingItemLoader(true);
        let payload = {
          project_id: ele.project_id,
          generate_thumbnail: true,
        };
        if (ele.entity_type === "product") {
          payload['products_list'] = [ele.id];
        } else if (ele.entity_type === "room") {
          payload['rooms_list'] = [ele.id];
        } else if (ele.entity_type === "scene") {
          payload['scenes_list'] = [ele.id];
        }
  
        axios.post(ENVIRONMENT.DELETE_ENTITY_BATCH_TO_PROJECT, payload).then(
          (res) => {
            setRemovingItemLoader(false);
              if (res && res.data.status == 201) {
                if(remove_from_project)
                {
                  message.success('Item Removed from the project');
                }
                else 
                {
                  setDeleteLoader(false);
                  setDeleteModal(false);
                  message.success((selectedItem != null ? FileContants.ENTITY_MAPPING[selectedItem.entity_type] : '') + " Deleted");
                }
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              } else {
                message.error('Something went wrong');
              }
          }
        ).catch((error) => {
          setRemovingItemLoader(false);
          message.error('Something went wrong.');
        });
      } else {
        message.error('Something went wrong.');
      }
  };
  
  const deleteIncompleteItem = (ele) => {
    setDeleteLoader(true);
    let entityTypeMapping = {
      product: 'Product',
      room: 'Space',
      scene: 'Scene',
      material: 'Material',
    };

    let api_end_point = ENVIRONMENT.DELETE_INCOMPLETE_REQUEST;
    let payload = {
      entity_id: ele.id,
      entity_type: entityTypeMapping[ele.entity_type],
    };

    axios.post(api_end_point, payload)
      .then((response) => {
        message.success(entityTypeMapping[ele.entity_type] + ' Deleted Successfully.');
        setTimeout(() => {
          window.location.reload();
        }, 600);
      })
      .catch((error) => {
        message.error('Error in deleting');
        setDeleteLoader(false);
        setDeleteItemModal(false);
      });
   
  };
  

  const getAssetImageCount = (product) => {
    if (ele.company_render_count && ele.company_lifestyle_render_count) {
      return ele.company_render_count + ele.company_lifestyle_render_count;
    } else if (ele.company_render_count) {
        return ele.company_render_count;
    } else if (ele.company_lifestyle_render_count) {
        return ele.company_lifestyle_render_count;
    } else {
        return 0;
    }
  }

  const duplicateScene = (id, scene_name) => {
    let duplicate_scene_name = 'Copy of ' + scene_name
    props.setCurrentScene(id);
    props.setCurrentSceneName(duplicate_scene_name);
    props.setDuplicateSceneModal(true)
  }

  const copyProductToNewSpace = (id, collection_id, project_id) => {
    window.location.href = `/create-new-scene-from-existing-products?collection_id=${collection_id}&scene_id=${id}&project_id=${project_id}`
  }

  const deleteItem = (ele) => {
    if(ele.entity_type == "product"){
      setDeleteModalHeading("You are about to delete this product")
      if(ele.company_id == COMPANY_ID){
        setDeleteModalDescription("This product and its assets will be deleted for all the users in your company. Are you sure you want to proceed?")
      }
      else{
        setDeleteModalDescription("Deleting this product would delete all assets generated with it. Are you sure you want to delete this product?")
      }
    }
    else if(ele.entity_type == 'room'){
      setDeleteModalHeading("You're about to delete a space")
      if(ele.company_id == COMPANY_ID){
        setDeleteModalDescription("This space and its assets will be deleted for the users of your company. Are you sure you want to delete?")
      }
      else{
        setDeleteModalDescription("Deleting this space would delete all assets generated with it. Are you sure you want to delete?")
      }
    }
    else if(ele.entity_type == 'scene'){
      setDeleteModalHeading("You're about to delete a Scene")
      if(ele.company_id == COMPANY_ID){
        setDeleteModalDescription("This scene and all of it's assets like lifestyle images, 360s and 3D tours will be deleted for all the users of your company.")
      }
      else{
        setDeleteModalDescription("Deleting this scene would delete all assets generated with it like lifestyle images, 360s and 3D tours.")
      }
    }

    setSelectedItem(ele);
    setDeleteModal(true);
  }

  const onDelete = (ele) => {
    console.log(ele);
    setDeleteLoader(true);
    let api_end_point = '';
    let payload = {};

    if(ele.entity_type == "product"){
      api_end_point = ENVIRONMENT.UPDATE_PRODUCT;
  
      payload = {
        "product_id": ele.id,
        "is_hidden": true,
        "username": localStorage.getItem("username"),
      };
      let seq_id = getSequenceId(SEQUENCE_IDS,  (ele.company_id == COMPANY_ID || ele.customer_username == localStorage.getItem("username")) ? 'owned_product' : 'other_product')
      payload['sequence_id'] = seq_id 
  
      if ((localStorage.getItem("username") != ele.customer_username) && (COMPANY_ID != ele.company_id)) {
        //case of customer deleting the product added from store or shared by some other customer
        api_end_point = ENVIRONMENT.DELETE_SHARED_ENTITY;
        payload = {
          "product_id": ele.id,
          "username": localStorage.getItem("username"),
        };
      }
    }
    else if(ele.entity_type == 'room'){
      api_end_point = ENVIRONMENT.UPDATE_ROOM;
      payload = {
        "room_id": ele.id,
        "is_hidden": true,
        "username": localStorage.getItem("username"),
      };
      let seq_id = getSequenceId(SEQUENCE_IDS,  (ele.company_id == COMPANY_ID || ele.customer_username == localStorage.getItem("username")) ? 'owned_space' : 'other_space')
      payload['sequence_id'] = seq_id 
      
      if ((localStorage.getItem("username") != ele.customer_username) && (COMPANY_ID != ele.company_id)) {
        //case of customer deleting the room added from store or shared by some other customer
        api_end_point = ENVIRONMENT.DELETE_SHARED_ENTITY;
        payload = {
          "room_id": ele.id,
          "username": localStorage.getItem("username"),
        };
      }
    }
    else if(ele.entity_type == 'scene'){
      api_end_point = ENVIRONMENT.UPDATE_SCENE;
      payload = {
          "scene_id": ele.id,
          "is_hidden": true,
          "username": localStorage.getItem('username'),
          "project_id": ele.project_id
      };
      let seq_id = getSequenceId(SEQUENCE_IDS,  (ele.company_id == COMPANY_ID || ele.customer_username == localStorage.getItem("username")) ? 'owned_scene' : 'other_scene')
      payload['sequence_id'] = seq_id 
      
      if ((localStorage.getItem("username") != ele.customer_username) && (COMPANY_ID != ele.company_id)) {
          //case of customer deleting the scene added from store or shared by some other customer
          api_end_point = ENVIRONMENT.DELETE_SHARED_ENTITY;
          payload = {
              "scene_id": ele.id,
              "username": localStorage.getItem("username"),
          };
      }
    }

    console.log(payload);
    axios.post(api_end_point, payload)
      .then((response) => {
        removeItemFromProject(false);
      })
      .catch((error) => {
        console.log(error);
        message.error(`Error in deleting ${FileContants.ENTITY_MAPPING[ele.entity_type]}`);
        setDeleteLoader(false);
        setDeleteModal(false);
      });
  }

  let menu = (id, entity, selectAssetAndSetBasicInfo) => {
    return <Menu style={{ width: "148px", marginLeft: "10px", backgroundColor: '#E6F7FF' }}>
      <Menu.Item className='manrope f-14' key={0} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'silo')}>
        <Tooltip>
          <span className='cursor-pointer' style={{ color: '#276DD7' }}>Silo Images</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item className='manrope f-14' key={1} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'mp4')}>
        <Tooltip>
          <span className='cursor-pointer' style={{ color: '#276DD7' }}>MP4 Video</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key={2} className='manrope f-14' onClick={() => selectAssetAndSetBasicInfo(id, entity, 'dim')}>
        <span className='cursor-pointer' style={{ color: '#276DD7' }}>Dimensional Image</span>
      </Menu.Item>
      <Menu.Item className='manrope f-14' key={3} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'lifestyle')}>
        <Tooltip>
          <span className='cursor-pointer' style={{ color: '#276DD7' }}>Lifestyle Images</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item className='manrope f-14' key={4} onClick={() => selectAssetAndSetBasicInfo(id, entity, '360')}>
        <Tooltip>
          <span className='cursor-pointer' style={{ color: '#276DD7' }}>Product 360 Spin</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item className='manrope f-14' key={5} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'variant')}>
        <Tooltip>
          <span className='cursor-pointer' style={{ color: '#276DD7' }}>Product Variant</span>
        </Tooltip>
      </Menu.Item>
    </Menu>
  }

  let entity_menu = (ele, status) => {
    return <Menu>
        {
            (ele.entity_type == 'scene' && status != "incomplete") ? 
            <Menu.Item>
                <a className="manrope f-14" onClick={() => duplicateScene(ele.id, ele.name)}>Duplicate Scene</a>
            </Menu.Item>
            : ''
        }
        {
            (ele.entity_type == 'scene' && status != "incomplete") ? 
            <Menu.Item>
                <a className="manrope f-14" onClick={() => copyProductToNewSpace(ele.id, ele.collection_id, ele.project_id)}>Copy Product To New Space</a>
            </Menu.Item>
            : ''
        }
        <Menu.Item>
            <a className="manrope f-14" onClick={() => {setRemoveItemModal(true); setSelectedItem(ele)}} >Remove from Project</a>
        </Menu.Item>
        {ele && status === "incomplete" ?
            <Menu.Item>
            <a className="manrope f-14" onClick={() => {setDeleteItemModal(true); setSelectedItem(ele)}} >Delete {ele.entity_type}</a>
            </Menu.Item>
        :
        ele ?
          <Menu.Item>
            <a className="manrope f-14" onClick={() => {deleteItem(ele)}}>Delete {FileContants.ENTITY_MAPPING[ele.entity_type]}</a>
          </Menu.Item>
        :
        ''}
    </Menu>
  }

  let nameAvatar = (
    (ele.status == "approved" && !isCopyInProgress) ? 
      <Badge count={<CheckCircleFilled style={{color: "#52C41A", fontSize: "16px"}} />}
        offset={[-3, 3]}>
        <Tooltip placement="bottom" title={<span className='manrope f-12 white'>{ele.customer_username}</span>}>
          <Avatar
              className={`cursor-pointer manrope f-16 white lh-32 circle-bg`}
              style={{ backgroundColor: ele.avatar_color}}>
              {Utilities.getInitials(ele.customer_username)}
            </Avatar>
        </Tooltip>
      </Badge>
    :
    <Tooltip placement="bottom" title={<span className='manrope f-12 white'>{ele.customer_username}</span>}>
      <Avatar
          className={`cursor-pointer manrope f-16 white lh-32 circle-bg`}
          style={{ backgroundColor: ele.avatar_color}}>
          {Utilities.getInitials(ele.customer_username)}
        </Avatar>
    </Tooltip>
  )
  return (
    <>
    <Card
      bodyStyle={{ padding: "5px" }}
      className={`project-entity-card`}
      bordered={false}
      data-tut="reactour__approved_scene"
      style={{ width: "94%", cursor: isCopyInProgress ? 'not-allowed' : "pointer"}}
    >
      {!isCopyInProgress && !props.selectable ?
        <Dropdown className="group-settings more-icon" overlay={entity_menu(ele, ele.status)} trigger={['click']}>
          <MoreIcon />
        </Dropdown> : ''
      }
      <div className="stacked-project-card-inner" onClick={() => handleCardClick()}
        style ={{pointerEvents: isCopyInProgress ? 'none' : ''}}>
        {selectable && (
          <span className="group-settings-checkbox-reorder-items" style={{ zIndex: "10" }}>
            <Checkbox
              className="black-checkbox"
              checked={selected.includes(select_id)}
              id={select_id}
            />
          </span>
        )}
        <div
          className="suggested-product"
          style={{ zIndex: "owned", cursor: "pointer" }}
        >
            {
            isCopyInProgress &&
            <div style = {{    
                position: "absolute",
                zIndex: 2,
                top: "30%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>
            <span style = {{
                padding: "6px 10px",
                border: "1px dashed white",
                borderRadius: "6px",
                color: "white"
                }}
            >Copy in Progress
            </span>
            <span style ={{
                color: "white",
                fontSize: "14px",
                display: "block",
                margin: "10px"
                }}
            >Est. 2 min</span>
            </div>}
          <div className="box">
            <img
              src={
                ele.thumbnail
                  ? getThumbnail()
                  : require("../../../../assets/images/scene_incomplete.svg")
              }
              style={{ objectFit: "contain", width: "100%", height: 290, filter: isCopyInProgress ? "blur(2px) brightness(.7)": ""}}
            />
          </div>
          <div style={{ margin: "2px 10px 0px 10px" }}>
            <div className="tag-section">
              <div className={`tag ${ele.product_model_type == 'props' ? entityData['prop'].className : entityData[ele.entity_type].className}`}>
                <span>{ ele.product_model_type == 'props' ? entityData['prop'].title : entityData[ele.entity_type].title}</span>
              </div>
              <div className={`tag ${updateTime == "Today" && "today-tag"}`}>
                <ClockCircleOutlined />
                <span className="ml-4">{updateTime}</span>
              </div>
            </div>
            <div className="project-name justify-in-start">
              <Tooltip title={<span className='manrope f-12 white'>{ele.name}</span>}>
              <span className="manrope f-14 w-500 clamp-text w-100 card-name">
                {selectable
                  ? ele.entity_type == "room"
                    ? ele.room_name
                    : ele.name
                  : ele.name}
              </span>
              </Tooltip>
            </div>
            <div className="ml-8 mb-4 justify-in-start">
                <span className="manrope f-14 w-500 lh-28 black-73 white-space-text">
                Category
                </span>
                <Tooltip title={<span className='manrope f-12 white'>{ele.category}</span>}>
                  <span className="manrope f-14 w-500 black-d9 clamp-text w-100 ml-8">
                  {ele.category}
                  </span>
                </Tooltip>
            </div>
            {selectable ||
            projectStatus == "approved" ? (
              ""
            ) : (
              <div className="status" style={{ marginBottom: ele.entity_type == 'scene' ? 24 : 20, marginTop: 12 }}>
                  {ele.entity_type !== 'scene' && statusData[ele.status] && (
                <span style={{ display: 'flex', alignItems: 'center'}}>
                    {nameAvatar}
                    <span className="manrope f-14 w-700 ml-8">
                      {statusData[ele.status].title ??
                        statusData.in_review.title}
                    </span>
                </span>
                  )}
                  {!isCopyInProgress && ele.entity_type == 'scene' && sceneStatusData[ele.status] ?
                    <span style={{ display: 'flex', alignItems: 'center'}}>
                      {nameAvatar}
                        <span className="manrope f-14 w-700 ml-8">
                          {sceneStatusData[ele.status].title ??
                            sceneStatusData.in_review.title}
                        </span>
                    </span>
                    : isCopyInProgress ?
                    <span style={{ display: 'flex', alignItems: 'center'}}>
                      {nameAvatar}
                        <span className="manrope f-14 w-700 ml-8">
                          {sceneStatusData['copy_in_progress'].title ??
                            sceneStatusData.copy_in_progress.title}
                        </span>
                    </span> : ''
                  }
              </div>
            )}

          </div>
          
        </div>
      </div>
        {(ele.entity_type == 'product' && (['approved', 'in_review']).includes(ele.status)) ?
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '6px', marginTop: projectStatus == "approved" ? "15px" : "0px" }} className="ml-16 mr-14 mt-4">
            <Dropdown overlay={
              menu(ele.id, ele, props.selectAssetAndSetBasicInfo)
            } trigger={'click'} placement="topLeft">
              <span className="justify-in-start generate-asset-btn" style={{padding: "5px 10px", gap: "5px"}}><PictureOutlined /> Generate</span>
            </Dropdown>
            <div className="justify-in-end" onClick={() => redirectToEntity(ele)}>
              <span className="info-text size-14">{getAssetImageCount(ele)} Images</span>
              <img src='/img/ArrowRight.png' className="icon-instance-node" style={{ marginLeft: '4px' }} />
            </div>

          </div>:
          (ele.entity_type == 'scene' && ele.status == 'approved') ?
          !selectable && 
            <div style={{ display:'flex', justifyContent: 'space-between', padding:'0px 16px 0px 16px', marginTop: projectStatus == "approved" ? "15px" : "0px"}}>
              <div
                className="justify-in-start generate-asset-btn"
                style={isCopyInProgress ? { color: '#c0c0c0', backgroundColor: '#f5f5f5', cursor: 'not-allowed' } : {}}
              >
                <EditOutlined
                  className="icon-instance-node"
                  style={{ marginRight: '5px', color: isCopyInProgress ? '#c0c0c0' : '#276DD7' }}
                />
                {isCopyInProgress ? (
                  <span> Edit Scene </span>
                ) : (
                  <span>
                    <a
                      href={`/scene_builder/` + ele.id + `?entity_id=${ele.id}`}
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      Edit Scene
                    </a>
                  </span>
                )}
              </div>              
              {(!isCopyInProgress && ele.status != 'incomplete') && 
              <div className="justify-in-end"  style= {{ width:'50%', pointerEvents: isCopyInProgress ? 'none' : '', cursor: 'pointer'}}
              onMouseDown={(e) => {
                if ((e.button === 0 && !(e.ctrlKey || e.metaKey))) {
                  handleCardClick();
                }
              }}
              onClick={(e) => {
                  if (e.button === 0 && !(e.ctrlKey || e.metaKey)) {
                      e.preventDefault();
                  }
              }}> 
                  <span className="manrope f-12 black-33 capitalize">
                      {ele.scene_render_count + ' images'}
                  </span>
                  <img src='/img/ArrowRight.png' className="icon-instance-node" style={{marginLeft:'4px', marginRight:'5px'}}/>
              </div>}
            </div> 
          : 
          <div className={`dummy-height ${ele.entity_type == 'scene' ? `scene` : ``} ${ele.entity_type == 'room' && projectStatus == 'approved' ? `approved_space` : ``}`}>
            </div>}
    </Card>

    <Modal
      visible={removeItemModal}
      onCancel={() => setRemoveItemModal(false)}
      footer={
        <div className="justify-in-end">
          <Button  className="modal-okay-gray square font-14 pd-20" onClick={() => {setRemoveItemModal(false)}}>
            Cancel
          </Button>,
          <Button className="modal-okay square font-14" disabled={removingItemLoader} htmlType="submit" onClick={() => removeItemFromProject()} >
            Remove {removingItemLoader && <LoadingOutlined />}
          </Button>
        </div>
      }
    >
      <div>
        <h3>Remove Item From Project</h3>
        <span>Are you sure you want to remove this item from the project?</span>
      </div>
    </Modal>


    {/* Modal for deleting the incomplete item */}
    <Modal
      className="silo-success-modal"
      width={800}
      centered={true}
      visible={deleteItemModal}
      onCancel={() => {setDeleteItemModal(false)}}
      closable={true}
      footer={[
        <span className="justify-in-center">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={() => setDeleteItemModal(false)}>
                Cancel
            </Button>
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => deleteIncompleteItem(selectedItem)}>
                {deleteLoader? <span> Deleting <LoadingOutlined spin /></span>:<span> Yes, Delete {selectedItem != null ? selectedItem.entity_type : ''} </span>}
            </Button>
        </span>
      ]}
        > 
         <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h2 className="manrope f-24 black-55 w-900">
            {`You are about to delete an incomplete ${selectedItem != null ? selectedItem.entity_type : ''}`}
          </h2>
          <p className="manrope f-18 black-33">Are you sure you want to proceed?</p>
        </div>  
      </Modal>

      <Modal
        className="modal-share-success"
        width={600}
        centered={true}
        visible={deleteModal}
        closable={false}
        footer={[
            <span className="justify-in-center">
                <Button style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={() => setDeleteModal(false)}>
                    Cancel
                </Button>
                <Button style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => onDelete(selectedItem)}>
                    {deleteLoader ? <span> Deleting <LoadingOutlined spin /></span> : <span>Yes, Delete {selectedItem != null ? FileContants.ENTITY_MAPPING[selectedItem.entity_type] : ''} </span>}
                </Button>
            </span>
        ]}>
        <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <h2 className="manrope f-16 black-00 w-600">{deleteModalHeading}</h2>
            <p>{deleteModalDescription}</p>

            { 
              ele.entity_type == 'scene' ? 
              <p>The space used in this scene will also be deleted if it is not being used in any other scene</p>
              : null
            }
        </div>
    </Modal>
    </>
  );
};

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(EntitiesList));
