import React, { useEffect, useState, useRef } from 'react';
import 'antd/dist/antd.css';
import CustomerMainLayout from '../CustomerMainLayout'
import { LoadingOutlined, InfoCircleOutlined, PlusOutlined, ShareAltOutlined, PlusCircleFilled, SwapOutlined } from '@ant-design/icons';
import { Input, Col, Row, Card, Select, Radio, Form, Button, Modal, Checkbox, Tooltip, message, Tabs, Menu, Dropdown } from 'antd';
import DottedLoader from "../../DottedLoader";
import '@ant-design/compatible/assets/index.css';
import '../../../../styles/helper.scss'
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import {listScenes,getArrangements,reorderData,createFolder,moveToFolder,renameFolder} from "../../../../redux/actions";
import {connect} from "react-redux";
import ScenesList from './ScenesList';
import {withRouter} from 'react-router-dom'
import FileConstants from '../../../../FileConstants'
import SuccessModal from '../../SuccessModal/SuccessModal';
import TemplateRequestModal from '../TemplateRequestModal';
import _ from "lodash"; // Import the entire lodash library
// import * as Utilities from '../../Utilities';
import ReorderIcon from '../../../../icons/ReorderIcon';
import EmailSelect from '../../FormUtilities/EmailSelect';
import * as Utilities from '../../Utilities';
import BatchSharingModal from '../BatchSharinModal/BatchSharingModal';

const { TabPane } = Tabs;
const { Option } = Select;
const formRef = { useRef };

let SEQUENCE_ID_OTHER = -1;
let SEQUENCE_ID_OWNED = -1;

const ORDER_BY_VALUES = [
    {
        'text': "Latest Created",
        'key': 1,
        'value': 'created_on_stamp desc'
    },
    {
        'text': "Earliest Created",
        'key': 2,
        'value': 'created_on_stamp asc'
    },
    {
        'text': "Latest Updated",
        'key': 3,
        'value': 'last_modified_stamp desc'
    },
    {
        'text': "Earliest Updated",
        'key': 4,
        'value': 'last_modified_stamp asc'
    },
    {
        'text': "Custom Order",
        'key': 5,
        'value': 'library_order asc,entity_order asc, id desc'
    }
]

const CustomerScenes = (props) => {
   
    const [onboarding, setOnboarding] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [sharingModal, setSharingModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [selectedSceneIds, setSelectedSceneIds] = useState([]);
    const [selectable, setSelectable] = useState(false);
    const [defaultSceneIds, setDefaultSceneIds] = useState([]);
    const [currentScene, setCurrentScene] = useState(-1);
    const [currentSceneName, setCurrentSceneName] = useState('');
    const [sceneCompanyID, setSceneCompanyID] = useState(-1);
    const [currentSceneCustomer, setCurrentSceneCustomer] = useState('');
    const [deleteSceneModal, setDeleteSceneModal] = useState(false);
    const [deleteSceneLoader, setDeleteSceneLoader] = useState(false);
    const [duplicateSceneModal, setDuplicateSceneModal] = useState(false);
    const [duplicateSceneLoader, setDuplicateSceneLoader] = useState(false);
    const [sceneSharingOption, setSceneSharingOption] = useState('collaborate');
    const [moveToCustomerSceneModal, setMoveToCustomerSceneModal] = useState(false);
    const [managedCustomers, setManagedCustomers] = useState([]);
    const [buttonLoader,setButtonLoader] = useState(false);
    const [sceneAssetSharingOption, setSceneAssetSharingOption] = useState(true);
    const [selectedCustomerScenes, setSelectedCustomerScenes] = useState([])
    const [loader, setLoader] = useState(true);
    const [defaultEmailValue, setDefaultValue] = useState({});
    const [selectedForSharing, setSelectedForSharing] = useState("yours")
    const [selectedCollection, setSelectedCollection] = useState(-1);
    const [managedCustomersExist, setManagedCustomersExist] = useState(false);
    const [templateModal, setTemplateModal] = useState(false);
    const [addToStoreLoader, setAddToStoreLoader] = useState(false);
    const [spaceCategories, setSpaceCategories] = useState([]);
    const [sceneID, setSceneID] = useState(-1);
    const [renders, setRenders] = useState([]);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [sharedTabType, setSharedTabType] = useState('all');
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [selectedParentScene, setSelectedParentScene] = useState(null);
    const [folderState, setFolderState] = useState(false);
    const [folderScenes, setFolderScenes] = useState([]);
    const [sceneTab, setSceneTab] = useState("customer");
    const [showBottomNavBar, setShowBottomNavBar] = useState(false);
    const [reorderItems, setReorderItems] = useState(false);
    const [selectedFolderScenes, setSelectedFolderScenes] = useState([]);
    const [newFolder, setNewFolder] = useState(false);
    const [existingFolder, setExistingFolder] = useState(false);
    const [viewRenameFolderModal, setViewRenameFolderModal] = useState(false);
    const [viewUnGroupFolderModal, setViewUnGroupFolderModal] = useState(false);
    const [entityArrangement, setEntityArrangement] = useState([]);
    const [ownedScenesEntityArrangement, setOwnedScenesEntityArrangement] =
      useState([]);
    const [otherScenesEntityArrangement, setOtherScenesEntityArrangement] =
      useState([]);
    const [folderArrangement, setFolderArrangement] = useState([]);
    const [ownedScenesFolderArrangement, setOwnedScenesFolderArrangement] =
      useState([]);
    const [otherScenesFolderArrangement, setOtherScenesFolderArrangement] =
      useState([]);
    const [saveOrderedItems, setSaveOrderedItems] = useState(false);
    const [orderSaved, setOrderSaved] = useState(false);
    const [sortedItems, setSortedItems] = useState([]);
    const [newLoader, setNewLoader] = useState(true);
    const [newFolderName, setNewFolderName] = useState(null);
    const [hashUpdated, setHashUpdated] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedFolderParentId, setSelectedFolderParentId] = useState(null);
    const [folderNewName, setFolderNewName] = useState(null);
    const [reorderByModal, setReorderByModal] = useState(false);
    const [reorderByCompany, setReorderByCompany] = useState(false);
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [companyArrangement, setCompanyArrangement] = useState(false);
    const [tabActiveKey, setTabActiveKey] = useState("");
    const [foldersListExceptCurrentFolder, setFoldersListExceptCurrentFolder] = useState([]);
    const [saveByCompany, setSaveByCompany] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [renameError, setRenameError] = useState(null);
    const [triggerGroupSceneUpdate, setTriggerGroupSceneUpdate] = useState(false);
    const [orderUpdated, setOrderUpdated] = useState(false);
    const [checkIfTabChanged, setCheckIfTabChanged] = useState(false);
    const [lastFolderAccessed, setLastFolderAccessed] = useState(null);
    const [folderID, setFolderID] = useState(null);
    const [replicateAssets, setReplicateAssets] = useState(false)
    const [duplicateSpace, setDuplicateSpace] = useState(false)
    const [duplicateSceneWaitModal, setDuplicateSceneWaitModal] = useState(false)
    const [collaborationScenes, setCollaborationScenes] = useState([]);
    const [platform, setPlatform] = useState([]);

    const [initialLoadShared, setInitialLoadShared] = useState(true);
    const [initialLoadOwned, setInitialLoadOwned] = useState(true);
    const [initialLoadIncomplete, setInitialLoadIncomplete] = useState(true);
    const [sharedSceneLoader, setSharedSceneLoader] = useState(true);
    const [ownedSceneLoader, setOwnedSceneLoader] = useState(true);
    const [incompleteSceneLoader, setIncompleteSceneLoader] = useState(true);
    const [ownedSceneData, setOwnedSceneData] = useState([]);
    const [sharedSceneData, setSharedSceneData] = useState([]);
    const [incompleteSceneData, setIncompleteSceneData] = useState([]);
    const [incompleteCustomerSceneData, setIncompleteCustomerSceneData] = useState([]);
    const [ownedOriginalData, setOwnedOriginalData] = useState([]);
    const [sharedOriginalData, setSharedOriginalData] = useState([]);
    const [incompleteOriginalData, setIncompleteOriginalData] = useState([]);
    const [ownedFolderData, setOwnedFolderData] = useState([]);
    const [otherFolderData, setOtherFolderData] = useState([]);
    const [actionLoader, setActionLoader] = useState(false);
    const [checkIfItemsHaveReorderedOnce, setCheckIfItemsHaveReorderedOnce] = useState([]);
    const [folderInfo, setFolderInfo] = useState([]);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [deleteEntityid, setDeleteEntityid] = useState(-1);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [ownedTabType, setOwnedTabType] = useState('all');
    const [currentPage, setCurrentPage] = useState(props.match.path)
    const [projects, setProjects] = useState([]);
    const [tabChangeThroughClick, setTabChangeThroughClick] = useState(false);
    const [tabCount, setTabCount] = useState({selected_customer: -1, collaboration_scenes: -1, owned: -1, shared: -1, incomplete: -1, customer_incomplete: -1});
    const [tempOwnedCount, setTempOwnedCount] = useState(0);
    const [initialLoadMsp, setInitialLoadMsp] = useState(true);
    const [mspDataLoad, setMspDataLoad] = useState(false);
    const [sortBy, setSortBy] = useState(3);
    const [sortingHasSet, setSortingHasSet] = useState(false);
    const [stopDuplicateCall, setStopDuplicateCall] = useState(false);
    const [sharingView, setSharingView] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [prevTabState, setPrevTabState] = useState(null);
    const [form] = Form.useForm();
    const [duplicate_form] = Form.useForm()

    let create_new_scene_options = () => {
        return <Menu>
            <Menu.Item>
                <a className="manrope f-14" onClick={() => props.history.push(`/create-empty-scene?origin=${currentPage}`)}>From Empty Space</a>
            </Menu.Item>
            <Menu.Item>
                <a className="manrope f-14" onClick={() => props.history.push(`/create-scene-from-template?origin=${currentPage}`)}>From Template</a>
            </Menu.Item>
        </Menu>
    }
    
    let sorting_options = () => {
        return <Menu selectedKeys={[ORDER_BY_VALUES.find(item => item.key === sortBy)?.value]}>
            {ORDER_BY_VALUES.map((order) => (
                (SEQUENCE_ID_OTHER == -1 && SEQUENCE_ID_OWNED == -1) && order.key == 5 ? "" :
                <Menu.Item key={order.value} onClick={() => setSortingOfLibrary(order.key)}>
                    <a className='manrope f-14'>{order.text}</a>
                </Menu.Item>
            ))}
        </Menu>
    }

    const setSortingOfLibrary = (value) => {
        if (value != 5) {
            leaveFolderState(true);
        }
        setSortBy(value);
        setStopDuplicateCall(false);
        setSortingHasSet(true);
        setInitialLoadShared(true);
        setInitialLoadOwned(true);
        setInitialLoadIncomplete(true);
        setSharedSceneLoader(true);
        setOwnedSceneLoader(true);
        setIncompleteSceneLoader(true);
        setInitialLoadMsp(true);
        Utilities.deleteParamsKeys();
    }

    // Use useEffect to trigger fetchData after sortBy updates
    useEffect(() => {
        if (sortingHasSet && stopDuplicateCall === false) {
            setStopDuplicateCall(true);
            fetchData();
            let tab = new URLSearchParams(window.location.search).get("tab");
            setPrevTabState(tab);
            console.log("Tab: ", tab);
        }
    }, [sortBy, sortingHasSet, initialLoadMsp, initialLoadShared, initialLoadOwned, initialLoadIncomplete, sharedSceneLoader, ownedSceneLoader, incompleteSceneLoader]); // Dependency array ensures fetchData is called when sortBy changes

    const updateTabCount = (count, tabName) => {
        setTabCount(prevTabCount => ({
            ...prevTabCount,
            [tabName]: count
        }));
    };

    const getOwnedSceneCount = () => {
        const counts = localStorage.getItem("entity_counts");
        if (counts) {
            let entityCounts = JSON.parse(counts);
            let owned_product_count = entityCounts.find(entity => entity.type === "scene").count
            setTempOwnedCount(owned_product_count);
        }
        else {
            let payload = {
                username: localStorage.getItem('username'),
                project_homepage: true
            };
            if (localStorage.getItem('company_id') != undefined) {
                payload.company_id = localStorage.getItem('company_id');
            };
            
            if((localStorage.getItem("is_msprovider") === 'true' ? true : false)){
                if(localStorage.getItem('managed_customer_username')){
                    payload['username'] = localStorage.getItem('managed_customer_username')
                }
                if(localStorage.getItem('managed_company_id')){
                    payload['company_id'] = localStorage.getItem('managed_company_id')
                }
            }

            axios.post(ENVIRONMENT.HOME_SCREEN_STATS, payload).then((res) => {
                let approved_project = {
                    "type": "approved",
                    "count": (res.data.library_data.find(ele => ele.type === "project"))?.count
                }
                res.data.library_data = res.data.library_data.filter(ele => ele.type !== "project")
                let active_project = {
                    "type": "active",
                    "count": (res.data.review_data.find(ele => ele.type === "project"))?.count
                }
                let requested_project = {
                    "type": "requested",
                    "count": (res.data.in_progress_data.find(ele => ele.type === "project"))?.count
                }
                //Setting Entity Counts in localStorage
                let entity_counts = _.cloneDeep(res.data.library_data);
                
                entity_counts.push(approved_project)
                entity_counts.push(active_project)
                entity_counts.push(requested_project)
                
                localStorage.setItem('entity_counts', JSON.stringify(entity_counts));
                setTempOwnedCount(entity_counts.find(entity => entity.type === "scene").count);
            })
        }
    }

    const fetchData = async () => {
        getOwnedSceneCount();
        if ((localStorage.getItem("is_msprovider") === 'true' ? true : false)) {
            getManagedCustomers();
            if (initialLoadMsp) {
                await getMSPCustomerData(true);
                await getCustomerCollaborationScenes(true);
                await getMSPIncompleteScenes(true);
                setInitialLoadMsp(false);
            }
        } 
        // if (localStorage.getItem('sequence_ids')) {
        //     setSequenceIds();
        //     triggerSceneFetchAPI();
        // } else {
            loadSequenceIDs(true);
        // }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(()=>{
        if (!initialLoadMsp) {
            setMspDataLoad(true);
            getMSPCustomerData();
            getCustomerCollaborationScenes();
            getMSPIncompleteScenes();
            setMspDataLoad(false);
        }
    },[initialLoadMsp])

    useEffect(() => {
    if (localStorage.getItem('shared_entities') != undefined && localStorage.getItem('company_id')) {
    const shared = localStorage.getItem('shared_entities').split("_").includes("scene");
      if (shared) {
        setSaveByCompany(true);
        setReorderByCompany(true);
      }
     }
    }, []);

    useEffect(() => {
        if(duplicateSceneModal){
            duplicate_form.resetFields();
        }
    }, [duplicateSceneModal])

    useEffect(() => {
        applySearchFromQueryString();
      }, [ownedOriginalData, sharedOriginalData]);

    const fetchSceneData = (type, sequence_id, initial = false) => {
        if (!initial) {
          setLoader(true);
        } else {
          if (type == 'shared') {
            setInitialLoadShared(true);
          } else if(type == 'owned'){
            setInitialLoadOwned(true);
          } else{
            setInitialLoadIncomplete(true);
          }
        }
        let payload = null;
        if(type == 'incomplete'){
            payload = getIncompleteScenePayload(initial, false)
        }
        else{
            payload = getScenePayload(initial, type, sequence_id);
        }
        axios.post(ENVIRONMENT.GET_SCENE_BATCH, payload)
        .then(res => {
        let jsonData = null;
        if(res.data.isBase64Encoded){
            jsonData = Utilities.decompressData(res);
        } 
        else{
            jsonData = res.data;
        }
          if (jsonData) {
            // setting data
            if (type == 'shared') {
                let scenes = getSceneDataWithKeywords(jsonData)
                setSharedOriginalData(scenes)
            } 
            else if (type == 'owned'){
                let scenes = getSceneDataWithKeywords(jsonData)
                setOwnedOriginalData(scenes)
            }
            else {
                let scenes = getSceneDataWithKeywords(jsonData)
                setIncompleteOriginalData(scenes)
            }
    
            // setting loaders
            if (initial) {
              if (type == 'shared') {
                setInitialLoadShared(false);
              } 
              else if(type == 'owned'){
                setInitialLoadOwned(false);
              }
              else {
                setInitialLoadIncomplete(false);
              }
            } 
            else {
              if (type == 'shared') {
                setSharedSceneLoader(false);
              } 
              else if(type == 'owned'){
                setOwnedSceneLoader(false);
              } 
              else{
                setIncompleteSceneLoader(false);
              }
              setLoader(false)
            }
          }
         
        });
      }

    const applySearchFromQueryString = () => {
        const url = new URL(window.location.href);
        const searchValue = url.searchParams.get('search');
        if (searchValue !== null) {
            let cleanedSearchValue = decodeURIComponent(searchValue);
            if (cleanedSearchValue) {
                setSearchValue(cleanedSearchValue);
            }
        }
      };

      const removeSearchParams = () => {
        const url = new URL(window.location.href);
        url.searchParams.delete('search');
        props.history.replace(url.pathname + url.search);
      };

      const getCompanyUsersEmails = () => {
        let emails = []
        if(localStorage.getItem('managed_customer_username')){
          emails.push({
              'email': localStorage.getItem('managed_customer_email'),
              'username': localStorage.getItem('managed_customer_username')
          })
          setCompanyUsers(emails)
        }
        if(localStorage.getItem('company_id')){
            let payload = {
                "required_fields": ["email", "username"],
                "filter_string": `(company_id__exact=${localStorage.getItem('company_id')})`,
                "order_by": "email asc"
            }
            axios.post(ENVIRONMENT.COMPANY_ENTITY_GET_BATCH, payload)
            .then(res => {
                if(res && res.data){
                    console.log('Company User Emails', res.data);
                    emails = res.data.filter(item => item.username != localStorage.getItem('username'))
                    setCompanyUsers(emails)
                  }
            })
        }
      }
    
    const getUserProjects = () => {
        let payload = {
            order_by: "last_modified_stamp",
            required_fields: [
              "id",
              "name",
              "customer_username",
              "last_modified_stamp"
            ],
            filter_string: `(status__notexact='approved')&&(username__exact='${localStorage.getItem('username')}')&&(access_level__in=['owner','co_owner','editor'])`,
        };
        
    
        axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => 
        {
            let projects = []
            if(localStorage.getItem('managed_customer_username')){
                projects = res.data.map(item => ({
                    ...item,
                    name: `[${item.customer_username}] ${item.name} `
                }));
                
                setProjects(projects)
            }
            else{
                setProjects(res.data)
            }
        });
    }

    const triggerSceneFetchAPI = () => {
        fetchSceneData('owned', SEQUENCE_ID_OWNED, true);  
        fetchSceneData('shared', SEQUENCE_ID_OTHER, true);
        fetchSceneData('incomplete', SEQUENCE_ID_OTHER, true);
        getUserProjects();
      }

    const loadSequenceIDs = (trigger_api, reload_page = false) => {
        let payload = {
            'username': localStorage.getItem('username'),
            'action': 'get_sequence_ids'
          };
          let company_id = localStorage.getItem('company_id');
          if (company_id) {
            payload['company_id'] = company_id;
            payload['shared_entities'] = localStorage.getItem('shared_entities');
          }
          axios.post(ENVIRONMENT.ENTITY_ORGANIZER, payload)
          .then(res => {
            if (res.data && res.data.length > 0) {
              localStorage.setItem('sequence_ids', JSON.stringify(res.data));
              setSequenceIds(JSON.stringify(res.data));
              if (trigger_api) {
                triggerSceneFetchAPI();
              }
              if (reload_page) {
                window.location.reload();
              }
            }
          });
      }
    
    
      const setSequenceIds = (sequence_ids = localStorage.getItem('sequence_ids')) => {
        if (sequence_ids) {
          sequence_ids = JSON.parse(sequence_ids)
          sequence_ids.map((sequence_data) => {
            if (sequence_data['entity_type'] == 'owned_scene') {
              SEQUENCE_ID_OWNED = sequence_data['sequence_id']
              setCompanyArrangement(sequence_data['saved_by_company']);
            } else if (sequence_data['entity_type'] == 'other_scene') {
              SEQUENCE_ID_OTHER = sequence_data['sequence_id']
            }
          })
          // if reordering exists, then select custom sort
          if ((SEQUENCE_ID_OWNED != -1 || SEQUENCE_ID_OTHER != -1) && !sortingHasSet) {
            setSortBy(5);
          }
        }
      }

    const setSpaceCategoriesData = () => {
        let payload = {
          'request_type': 'room_model'
        }
        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE,payload)
        .then(res => {
          let categories = []
          if (res.data) {
            res.data.map((request) => {
              if (!categories.includes(request.category)) {
                categories.push(request.category);
              }
            })
            setSpaceCategories(categories);
          }
        })
    }

    const constructScenePayload = (id) => {
        let order_by = 'id desc'
        if (sortBy != 5) {
            order_by =  ORDER_BY_VALUES.find(item => item.key === sortBy)?.value;
        }
        let payload = {
            "order_by": order_by,
            "filter_string": `(id__exact=${id})&&(model_type__exact='glb'||model_type__exact='low_poly_max'||model_type__isnull=true)`,
            "required_fields": [
                "id",
                "name",
                "created_on_stamp",
                "last_modified_stamp",
                "scene_status",
                "room_max_archive",
                "collection_id",
                "collection_name",
                "scene_template_id",
                "render_generation",
                "designed_for",
                "final_renders",
                "three_sixties",
                "threed_tour",
                "customer_username",
                "company_id",
                "design",
                "platform",
                "tags"
            ]
        }
        return payload;
    }


    const changeCurrentSceneName = (e) => {
        setCurrentSceneName(e.target.value)
    }

    const openTemplateModal = (id) => {
        setTemplateModal(true);
        let payload = constructScenePayload(id);
        setLoadingDetails(true);
        setSceneID(id);
        axios.post(ENVIRONMENT.GET_SCENE_BATCH, payload)
        .then(res => {
            setPlatform(res.data["platform"]);
            let final_renders_json = res.data['final_renders'];
            let final_renders = [];
            let final_renders_obj = {};
            final_renders_obj = [];
            if (final_renders_json !== "" && final_renders_json !== undefined) {
                final_renders_obj = final_renders_json;
                if(final_renders_obj != undefined) {
                    final_renders = final_renders_obj;
                }
            }
            setLoadingDetails(false);
            if (final_renders && final_renders.length > 0) {
                setRenders(final_renders.filter(x => x.status == 'rendered' && x.filename != '360Camera.jpg'))
            }
        });
    }

    const cancelTemplateRequest = () => {
        setTemplateModal(false);
        setAddToStoreLoader(false);
    }

    const sendTemplateRequest = (values) => {
        setAddToStoreLoader(true);
        let payload = {
            name: values.name,
            room_name: values.room_name,
            style: values.style,
            scene_id: sceneID,
            requested_by: localStorage.getItem('username'),
            company_id: (localStorage.getItem("is_msprovider") === 'true' ? true : false) ? 274 : localStorage.getItem('company_id'),
            action: 'create',
            status: '2',
        }
        
        let lifestyle_dict_arr = []
        renders.map((render) => {
          if (values.lifestyle_renders.includes(render['filename'])) {
            lifestyle_dict_arr.push(render);
          }
        })
        payload.lifestyle_renders = JSON.stringify(lifestyle_dict_arr);

        axios.post(ENVIRONMENT.TEMPLATE_ACTION_TRIGGER, payload)
        .then(res => {
            setAddToStoreLoader(false);
            setTemplateModal(false);
           
            message.success('Successfully Sent!');
            window.location.reload();
        });
    }

    const changeSearchValue = (value) => {
        setSearchValue(value);
        const url = new URL(window.location.href);
        if (value) {
          url.searchParams.set('search', encodeURIComponent(value));
        }
        else {
          url.searchParams.delete('search');
        }
        window.history.replaceState({}, "", url);
    }

    const onChangeSharingOption = value => {
        setSceneSharingOption(value)
    };

    const onChangeAssetSharingOption = e => {
        setSceneAssetSharingOption(e.target.checked)
    };

    const getSceneDataWithKeywords = (scenes) => {
        let result = scenes.map(obj => {
            let keywords = []

            if(obj.id){
                keywords.push(obj.id)
            }

            for(let keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
                if (!keywords.includes(keyword)) {
                    if (keyword.length > 1) {
                        keywords.push(keyword.toLowerCase())
                    }
                }
            }

            obj.tags.map(keyword => {
                if (!keywords.includes(keyword)) {
                    if (keyword.length > 1) {
                        // should we split tags or not?
                        keywords = keywords.concat(keyword.toLowerCase().split(" "))
                        // keywords.push(keyword.toLowerCase())  
                    }
                }
            })

            return { ...obj, 'keywords': keywords };
            });
            return result
      }

    useEffect(() => {
        if ((localStorage.getItem("is_msprovider") === 'true' ? true : false) && (localStorage.getItem('managed_customer_username') != null)) {
            let condition = {
                defaultValue: [localStorage.getItem('managed_customer_email')]
            }
            setDefaultValue(condition.defaultValue)
        }
        else {
            setDefaultValue('')
        }
        setSpaceCategoriesData();
        getCompanyUsersEmails();
    },[]);
    
    const getMSPScenePayload = (initial) => {
        let order_by = 'last_modified_stamp desc'
        if (sortBy != 5) {
            order_by =  ORDER_BY_VALUES.find(item => item.key === sortBy)?.value;
        }
        let payload = {
            "order_by": order_by,
            "required_fields": [
                "id",
                "name",
                "scene_status",
                "thumbnail",
                "designed_for",
                "company_id",
                "scene_template_id",
                "render_generation",
                "collection_id",
                "customer_username",
                "img_render_count",
                "three_sixty_count",
                "threed_tour_count",
                "last_modified_stamp",
                "platform",
                "tags",
                "created_on_stamp",
                "category",
                "scene_created_from"
            ]
        }
        let filter_string = "";

        let scene_status = "scene_status__in=[3]";
        let is_hidden = "is_hidden__not=true"
        let customer_username =  `customer_username__exact='${localStorage.getItem('username')}'`;
        let designed_for = `(designed_for__exact='${localStorage.getItem('managed_customer_username')}')`

        if (localStorage.getItem('managed_company_id')) {
            designed_for = `(designed_company_id__exact='${localStorage.getItem('managed_company_id')}')`
        }

        filter_string = `(${scene_status}&&${is_hidden})&&${designed_for}`
        payload["filter_string"] = filter_string;

        if (initial) {
            payload['pagination_filters'] = {
                'limit': 100,
                'offset': 0
            }
        }
        if(ENVIRONMENT.isProduction())
            payload['compress_response'] = true;
        return payload;
    }

    const getMSPCustomerData = async (initial) => {
        if ((localStorage.getItem("is_msprovider") === 'true' ? true : false) && (localStorage.getItem('managed_customer_username') != null || localStorage.getItem('managed_company_id') != null)) {
          let payload = getMSPScenePayload(initial);
          await axios.post(ENVIRONMENT.GET_SCENE_BATCH, payload)
          .then(res => {
            let jsonData = null;
            if(res.data.isBase64Encoded){
                jsonData = Utilities.decompressData(res);
            } 
            else{
                jsonData = res.data;
            }
            if (jsonData) {
              let owned_data = getSceneDataWithKeywords(jsonData)
              setSelectedCustomerScenes(owned_data)
            }
          });
        }
      }

      const getIncompleteScenePayload = (initial, managed_user = false) => {
        let order_by = 'id desc'
        if (sortBy != 5) {
            order_by =  ORDER_BY_VALUES.find(item => item.key === sortBy)?.value;
        }
        let payload = {
            "order_by": order_by,
            "required_fields": [
                "id",
                "name",
                "scene_status",
                "company_id",
                "collection_id",
                "customer_username",
                "platform",
                "tags",
                "preview_thumbnail",
                "created_on_stamp",
                "last_modified_stamp",
                "category"
            ]
        }

        let filter_string = "";
        let scene_status = "scene_status__in=[0,1,4]";
        let is_hidden = "is_hidden__not=true"
        let customer_username =  `customer_username__exact='${localStorage.getItem('username')}'`;

        let managed_customer_username = `customer_username__exact='${localStorage.getItem('managed_customer_username')}'`;


        let designed_for = `designed_for__exact='${localStorage.getItem('managed_customer_username')}'`;
        let designed_for_is_null = `designed_for__isnull=true`;
        filter_string = `(${managed_user ? managed_customer_username : customer_username}&&${scene_status}&&${is_hidden}`;
        if(managed_user){
            filter_string = filter_string + ')||(' + `${designed_for}&&${scene_status}&&${is_hidden}` + ')'
        }
        else{
            filter_string = filter_string + '&&' + designed_for_is_null + ')'
        }
        filter_string = filter_string + "&&(has_active_project__is=false)"
        payload['filter_string'] = filter_string
        if (initial) {
            payload['pagination_filters'] = {
                'limit': 100,
                'offset': 0
            }
        }

        payload["filter_string"] = filter_string;
        if(ENVIRONMENT.isProduction())
            payload['compress_response'] = true;
        return payload;
    }

    const getScenePayload = (initial, type, sequence_id) => {
        let payload = {
            "required_fields": [
                "id",
                "name",
                "scene_status",
                "thumbnail",
                "designed_for",
                "company_id",
                "scene_template_id",
                "render_generation",
                "collection_id",
                "customer_username",
                "img_render_count",
                "three_sixty_count",
                "threed_tour_count",
                "platform",
                "tags",
                "managed_scene",
                "last_modified_stamp",
                "scene_created_from",
                "created_on_stamp",
                "category"
            ]
        }
        let order_by = 'id desc'
        if (sortBy != 5) {
            order_by =  ORDER_BY_VALUES.find(item => item.key === sortBy)?.value;
        }
        if (sequence_id != -1 && sortBy == 5) {
            payload['required_fields'].push('folder_id', 'folder_name','parent_folder_id',
            'library_order', 'entity_order')
            payload["order_by"] = "library_order asc,entity_order asc, id desc"
        } else {
            payload["order_by"] = order_by
        } 

        let filter_string = "";
        let scene_status = "scene_status__in=[3]";
        let company_id = "";
        let shared_username = "";
        let is_hidden = "is_hidden__not=true"
        let shared_hidden = "shared_hidden__not=true"
        let customer_username =  `customer_username__exact='${localStorage.getItem('username')}'`;
        let customer_username_notexact = "customer_username__notexact='" + localStorage.getItem('username') + "'"
        let sequence_id_value = "sequence_id__exact="+sequence_id
        let scene_template_null_true = "(scene_template_id__isnull=true)"
        let scene_template_null_false = "scene_template_id__isnull=false"
        let is_hidden_managed_scene = "(is_hidden__is=true&&managed_scene__is=true)" // case when an msp removes scene after sharing with customer, customer should still be able to see it
        let scene_template_null_false_managed_scene = "((scene_template_id__isnull=true)||(scene_template_id__isnull=false&&designed_for__isnull=false))" // if a scene is a managed and template scene, show them
        let company_id_notexact =  `company_id__notexact=${localStorage.getItem('company_id')}||company_id__isnull=true`;
        let designed_for = '&&(designed_for__isnull=true)'
        if(type == "shared")
        {
            shared_username = "shared_username__exact='" + localStorage.getItem('username') + "'"
            filter_string = `(${shared_username}&&${shared_hidden}&&${customer_username_notexact})||(${customer_username}&&${scene_template_null_false}&&${is_hidden})`
            
            if (localStorage.getItem('shared_entities') != undefined && localStorage.getItem('company_id') != undefined && localStorage.getItem('shared_entities').split("_").includes("scene"))
            {
                company_id =  `company_id__exact=${localStorage.getItem('company_id')}`;
                filter_string = filter_string + `||(${company_id}&&${scene_template_null_false}&&${is_hidden})`
            }
            let not_managed_scene_check = "managed_scene__not=true"
            if(localStorage.getItem("is_msprovider") !== 'true')
                filter_string = "(" + filter_string + ")" + `&&(${not_managed_scene_check})`
        }
        else if (localStorage.getItem('shared_entities') != undefined && localStorage.getItem('company_id') != undefined && localStorage.getItem('shared_entities').split("_").includes("scene"))
        {
            
            company_id =  `owned_by_companies__contains=ARRAY[${localStorage.getItem('company_id')}]||company_id__exact=${localStorage.getItem('company_id')}`;
            filter_string = `(${company_id}||${customer_username})&&(${scene_status})&&((${is_hidden})||${is_hidden_managed_scene})&&${scene_template_null_false_managed_scene}`;
        }
        else if (localStorage.getItem('shared_entities') != undefined && localStorage.getItem('company_id') != undefined && !localStorage.getItem('shared_entities').split("_").includes("scene"))
        {
            shared_username = "shared_username__exact='" + localStorage.getItem('username') + "'"
            filter_string = `(owned_by_companies__contains=ARRAY[${localStorage.getItem('company_id')}]&&${shared_username})||(${customer_username}&&${scene_status}&&${is_hidden})&&${scene_template_null_true}`;
        }
        else
        {
            filter_string = `(${customer_username}&&${scene_status}&&${is_hidden})&&${scene_template_null_true}`;
        }

        if (sequence_id != -1) {
            filter_string = filter_string + "&&(" + sequence_id_value + "||sequence_id__isnull=true)"
        }
        filter_string = filter_string + "&&(has_active_project__is=false)"

        payload['filter_string'] = filter_string
        if (initial) {
            payload['pagination_filters'] = {
              'limit': 100,
              'offset': 0
            }
        }

        if((localStorage.getItem("is_msprovider") === 'true' ? true : false) && type == "owned"){
            filter_string = filter_string + designed_for;
        }

        payload["filter_string"] = filter_string;
        if(ENVIRONMENT.isProduction())
            payload['compress_response'] = true;
        return payload;
    }

    useEffect(() => {
        if (!initialLoadOwned && ownedSceneLoader) {
          fetchSceneData('owned', SEQUENCE_ID_OWNED);
        }
      }, [initialLoadOwned]);
    
    useEffect(() => {
    if (!initialLoadShared && sharedSceneLoader) {
        fetchSceneData('shared', SEQUENCE_ID_OTHER);
    }
    }, [initialLoadShared]);

    useEffect(() => {
        fetchSceneData('incomplete', SEQUENCE_ID_OWNED)
    }, [initialLoadIncomplete])

    const getMSPIncompleteScenes = async (initial) => {
        let payload = getIncompleteScenePayload(initial, true)
        await axios.post(ENVIRONMENT.GET_SCENE_BATCH, payload)
        .then(res => {
            let jsonData = null;
            if(res.data.isBase64Encoded){
                jsonData = Utilities.decompressData(res);
            } 
            else{
                jsonData = res.data;
            }
            let scenes = getSceneDataWithKeywords(jsonData)
            setIncompleteCustomerSceneData(scenes)
        })
    }

    const getCustomerCollaborationScenes = async (initial) => {
        let order_by = 'id desc'
        if (sortBy != 5) {
            order_by =  ORDER_BY_VALUES.find(item => item.key === sortBy)?.value;
        }
        let payload = {
            "order_by": order_by,
            "required_fields": [
              "id",
              "name",
              "scene_status",
              "thumbnail",
              "designed_for",
              "company_id",
              "scene_template_id",
              "render_generation",
              "collection_id",
              "customer_username",
              "img_render_count",
              "three_sixty_count",
              "threed_tour_count",
              "tags",
              "created_on_stamp",
              "last_modified_stamp",
              "scene_created_from"
            ]
        }
        if (localStorage.getItem('managed_company_id')) {
            payload['filter_string'] = "(customer_access__exact='"+localStorage.getItem('username')+"')&&(company_id__exact='"+localStorage.getItem('managed_company_id')+"')&&(is_hidden__not=true)"
        }
        else {
            payload['filter_string'] = "(customer_access__exact='"+localStorage.getItem('username')+"')&&(is_hidden__not=true)"
        }

        if(ENVIRONMENT.isProduction())
            payload['compress_response'] = true;
        if (initial) {
            payload['pagination_filters'] = {
                'limit': 100,
                'offset': 0
            }
        }
        await axios.post(ENVIRONMENT.GET_SCENE_BATCH, payload)
        .then(res => {
            let jsonData = null;
            if(res.data.isBase64Encoded){
                jsonData = Utilities.decompressData(res);
            } 
            else{
                jsonData = res.data;
            }
            let scenes = getSceneDataWithKeywords(jsonData)
            setCollaborationScenes(scenes)
        })
    }

    const getManagedCustomers = () => {
        if ((localStorage.getItem("is_msprovider") === 'true' ? true : false)) {
            
            axios.post(ENVIRONMENT.GET_MANAGED_SERVICE_RELATION,{
                designer_username: localStorage.getItem('username')
            }).then(res => {
                let customer_data_arr = []
                if (res.data) {
                    customer_data_arr = res.data
                    setManagedCustomers(customer_data_arr);
                    if (customer_data_arr.length > 0) {
                        setManagedCustomersExist(true);
                    }
                }
    
            })
        }
    }

    useEffect(() => {
        if (form && form.current && (localStorage.getItem("is_msprovider") === 'true' ? true : false) && (localStorage.getItem('managed_customer_username') != null)) {
            form.current.setFieldsValue({'emails': [localStorage.getItem('managed_customer_email')]})
        }
    },[sharingModal])

    useEffect(() => {
        if(ownedOriginalData.length > 0 && sharedOriginalData > 0){
            let onboarding = new URLSearchParams(window.location.search).get("onboarding");
            if(onboarding != undefined){
                if (typeof(onboarding == 'string') || onboarding instanceof String) {
                    onboarding = (onboarding.toLowerCase() === 'true');
                }
                if (onboarding == false) {
                    setOnboarding(false);
                }
                else {
                    setOnboarding(true);
                }
            }
        }
    }, [ownedOriginalData, sharedOriginalData]);

    const duplicateScene = async () => {
        try{
            await duplicate_form.validateFields(['scene_name']);
            setDuplicateSceneLoader(true)
            let payload = {
                'body': {
                    'action': 'customer_scene_replica',
                    'customer_username': localStorage.getItem('username'),
                    'company_id': localStorage.getItem('company_id'),
                    'scene_id': currentScene,
                    'scene_name': currentSceneName,
                    'collection_name': currentSceneName,
                    'share_company_wide': true
                }
            };

            if(replicateAssets){
                payload['body']['replicate_assets'] = replicateAssets
            }

            if(duplicateSpace){
                payload['body']['duplicate_space'] = duplicateSpace
            }

            axios.post(ENVIRONMENT.SCENE_REPLICA, payload)
            .then((response) => {
                setDuplicateSceneLoader(false)
                setDuplicateSceneModal(false)
                setDuplicateSceneWaitModal(true);
              })
            .catch((error) => {
                setDuplicateSceneLoader(false)
                setDuplicateSceneModal(false)
                setDuplicateSceneWaitModal(true);
            });
        }
        catch(error){
            console.log('Error = ', error)
        }
        
    }

    const isSceneExist = (sceneName) => {
        let sceneExists = false;
        
        ownedOriginalData.map(item => {
            if(item.name == sceneName){
                sceneExists = true
            }
        })

        sharedOriginalData.map(item => {
            if(item.name == sceneName){
                sceneExists = true
            }
        })
        return sceneExists

      };

    const deleteScene = () => {
        setDeleteSceneLoader(true);

        let api_end_point = ENVIRONMENT.UPDATE_SCENE;
        let payload = {
            "scene_id": currentScene,
            "is_hidden": true,
            "username": localStorage.getItem('username')
        };
        payload['sequence_id'] = sceneTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER
        
        let username = localStorage.getItem('username');
        if (username !=  currentSceneCustomer && localStorage.getItem('company_id') != sceneCompanyID) {
            //case of customer deleting the scene added from store or shared by some other customer
            api_end_point = ENVIRONMENT.DELETE_SHARED_ENTITY;
            payload = {
                "scene_id": currentScene,
                "username": username,
            };
        }

        axios.post(api_end_point, payload)
            .then( (response) => {
                // Retrieve the counts array from localStorage
                const counts = JSON.parse(localStorage.getItem("entity_counts"));

                if (counts) {
                const sceneEntry = counts.find(entity => entity.type === "scene");

                if (sceneEntry && sceneEntry.count > 0) {
                    sceneEntry.count -= 1;
                }

                // Store the updated counts array back in localStorage
                localStorage.setItem("entity_counts", JSON.stringify(counts));
                } else {
                console.error("Counts not found in localStorage.");
                }

                message.info('Scene Deleted.');
                setDeleteSceneLoader(false);
                setDeleteSceneModal(false);
                window.location.href = '/my_scenes'
                
            })
            .catch((error) => {
                console.log(error);
                message.error('Error in deleting scene');
                setDeleteSceneLoader(false);
                setDeleteSceneModal(false);

            });
    };
   
    const changeOrderBySelection = (e) => {
        setReorderByCompany(e.target.value);
    };

    //creates entity arrangement when the scenes are first loaded, First time arrangements
    const createEntityArrangement = (scenes, key) => {
        let items = checkIfItemsHaveReorderedOnce;
        items.push(key == "owned" ? "owned_scene" : "other_scene");
        setCheckIfItemsHaveReorderedOnce(items);
        const entityArrangementFirst = [];

        scenes
        .map((item, index) => {
            let obj = {};
            
            obj = {
                entity_id: parseInt(item.id),
                ordering_number: index,
                parent_folder_id: "",
            };
            entityArrangementFirst.push(obj);
        });

        let payload = {
            action: "reorder_first_time",
            entity_type: key == "owned" ? "owned_scene" : "other_scene",
            username: localStorage.getItem('username'),
            entity_arrangement: entityArrangementFirst,
            folder_arrangement: [],
            save_by_company: false,
        };
        if (localStorage.getItem('shared_entities') != undefined && localStorage.getItem('company_id') && key == "owned") {
        if (localStorage.getItem('shared_entities').split("_").includes("scene")) {
            payload.company_id = localStorage.getItem('company_id');
            payload.save_by_company = true
        }
        }

        if (scenes && scenes.length > 0 && entityArrangementFirst && entityArrangementFirst.length > 0) {
            organizeData(payload, key == "owned" ? "owned_scene" : "other_scene", false)
        }
    };

    const moveOutOfReorderState = () => {
        if (reorderItems) {
            setSearchValue('');
            removeSearchParams();
        }
        setShowBottomNavBar(false);
        setReorderItems(false);
        setSelectable(false);
        setSharingView(false);
        setSelectedFolderScenes([]);
        setSelectedSceneIds([]);
        changeSceneTab(sceneTab, 'trigger');
        setOrderUpdated(false);
        
    }

    const getSceneTabData = () => {
        if (tabActiveKey == "customer") {
            return getScenes('selected_customer');
        } else if (tabActiveKey == "collaboration") {
            return getScenes('collaboration_scenes')
        } else if (tabActiveKey == "owned") {
            return getScenes('owned');
        } else if (tabActiveKey == "shared") {
            return getScenes('shared');
        } else if (tabActiveKey == "incomplete") {
            return getScenes('incomplete');
        } else if (tabActiveKey == "customer_incomplete") {
            return getScenes('customer_incomplete');
        }
    }
    

    const selectAllScenes = () => {
        const sceneData = getSceneTabData();
        const ids = sceneData.flatMap((scene) => {
            if (Array.isArray(scene)) {
              return scene.map((item) => item.id);
            } else {
              return scene.id;
            }
          });
        setSelectedSceneIds(ids); // Up
        setAllSelected(true);
    }

    const deselectAllScenes = () => {
        setAllSelected(false);
        setSelectedSceneIds([]); // Up
    }

    useEffect(() => {
        if ((ownedOriginalData.length > 0 || sharedOriginalData.length > 0)) {
            let owned_scenes = ownedOriginalData;
            if (owned_scenes.length > 0 && SEQUENCE_ID_OWNED == -1 && !checkIfItemsHaveReorderedOnce.includes('owned_scene') && !ownedSceneLoader && sortBy == 5) {
                createEntityArrangement(owned_scenes, "owned");
            }
      
            let other_scenes = sharedOriginalData;
            if (other_scenes.length > 0 && SEQUENCE_ID_OTHER == -1 && !checkIfItemsHaveReorderedOnce.includes('other_scene') && !sharedSceneLoader && sortBy == 5) {
                createEntityArrangement(other_scenes, "shared");
            }
          }
    }, [ownedOriginalData, sharedOriginalData, sharedSceneLoader, ownedSceneLoader, sortBy]);

    const getSceneTabCount = (type) => {
        return tabCount[type] !== -1 ? tabCount[type] : getScenesLength(type);
    }

    const getTabTotalCount = () => {
        if ((localStorage.getItem("is_msprovider") === 'true' ? true : false) && (localStorage.getItem('managed_customer_username') != null || localStorage.getItem('managed_company_id') != null)) {
          return getSceneTabCount('selected_customer') + getSceneTabCount('collaboration_scenes')  + getSceneTabCount('owned')  + getSceneTabCount('shared') + getSceneTabCount('incomplete') + getSceneTabCount('customer_incomplete');
        }
        return getSceneTabCount('owned')  + getSceneTabCount('shared') + getSceneTabCount('incomplete'); 
      }
    

    const getScenesLength = (type, tab_options = 'not_all') => {
        if (type == 'selected_customer') {
        return selectedCustomerScenes.length;
        } else if (type == 'owned') {
        // const scenes = libraryScenes.filter((scene) => scene.shared_type == 'not_shared')
            if (ownedTabType == 'all' || tab_options == 'all') {
                const scenes = ownedOriginalData
                return scenes.length;
            }
            else if (ownedTabType == 'self') {
                let scene_temp = ownedOriginalData;
                scene_temp = scene_temp.filter((scene) => (scene.managed_scene === false))
                return scene_temp.length;
            } else {
                let scene_temp = ownedOriginalData;
                scene_temp = scene_temp.filter((scene) => (scene.managed_scene === true))
                return scene_temp.length;
            }
        } else if(type == 'incomplete'){
            const scenes = incompleteOriginalData
            return scenes.length
        } else if (type == 'collaboration_scenes') {
            return collaborationScenes.length;
        } else if(type == 'customer_incomplete'){
            const scenes = incompleteCustomerSceneData
            return scenes.length
        } else {
        let scenes_temp = sharedOriginalData
        if (sharedTabType == "all" || tab_options == 'all') {
            return scenes_temp.length;
        } else if (sharedTabType == "shared") {
            const scenes = scenes_temp.filter(
            (scene) =>  !scene.scene_template_id
            );
            return scenes.length;
        } else {
            const scenes = scenes_temp.filter(
            (scene) =>  scene.scene_template_id
            );
            return scenes.length;
        }
        }
    };

    const getScenes = (type) => {
        if (type == 'selected_customer') {
          return selectedCustomerScenes;
        } else if (type == 'owned') {
            console.log(type, ownedSceneData)
            if (ownedTabType == 'all') {
                return ownedSceneData;
            }
            else if (ownedTabType == 'self') {
                return ownedSceneData.filter((scene) => (scene.managed_scene === false))
            } else {
                return ownedSceneData.filter((scene) => (scene.managed_scene === true))
            }
        } else if (type == 'collaboration_scenes') {
            return collaborationScenes;
        } else if(type == 'incomplete'){
            console.log(incompleteSceneData)
            return incompleteSceneData;
        }else if(type == 'customer_incomplete'){
            return incompleteCustomerSceneData;
        }else {
            //which have different username and company id and 
            let scene_data =  sharedSceneData;

            // let scene_data =  libraryScenes.filter((scene) => (scene.shared_type != 'not_shared'));
            if(sharedTabType == 'all'){
                return sharedSceneData
            }
            else if(sharedTabType == 'shared'){
                return scene_data.filter((scene) => !scene.scene_template_id);
            }
            else{
                return scene_data.filter((scene) => scene.scene_template_id);
            }
        }
    }

    const getSceneCount = () => {
        if (
            (localStorage.getItem("is_msprovider") === 'true' ? true : false) &&
            (localStorage.getItem('managed_customer_username') != null || localStorage.getItem('managed_company_id') != null)
          ) {
            return ownedOriginalData.length + sharedOriginalData.length + selectedCustomerScenes.length + collaborationScenes.length + incompleteOriginalData.length + incompleteCustomerSceneData.length;
          }
      
          return ownedOriginalData.length + sharedOriginalData.length + incompleteOriginalData.length;
    }

    const getFolderSceneCount = () => {
    return folderScenes.length;
    };

    useEffect(() => {
        // if MS provider then select first tab by default
        if ((localStorage.getItem("is_msprovider") === 'true' ? true : false)) {
        Utilities.updateTabUrl("owned");
        setSceneTab("owned");
        setTabActiveKey("owned");
        }
        //if managed customer tab is selected
        if (selectedCustomerScenes && selectedCustomerScenes.length > 0) {
            Utilities.updateTabUrl("customer");
            setSceneTab("customer");
            setTabActiveKey("customer");
            
        } else {
            //if any other tab is selected
            if ((ownedSceneData && ownedSceneData.length > 0) || (sharedSceneData && sharedSceneData.length > 0) 
                || (incompleteSceneData && incompleteSceneData.length > 0)) {
                if(getScenesLength('owned')>0){
                    Utilities.updateTabUrl("owned");
                setSceneTab("owned");
                setTabActiveKey("owned");
                }
                else if(getScenesLength('shared') > 0){
                    Utilities.updateTabUrl("shared");
                setSceneTab("shared");
                setTabActiveKey("shared");
                }
                else if(getScenesLength('incomplete') > 0){
                    Utilities.updateTabUrl("incomplete");
                    setSceneTab("incomplete");
                    setTabActiveKey("incomplete");
                }

                adjustLibraryView(ownedOriginalData, "owned");
                adjustLibraryView(sharedOriginalData, "shared");
                adjustLibraryView(incompleteOriginalData, "incomplete");
            }
            else {
                Utilities.updateTabUrl("owned");
                setSceneTab("owned");
                setTabActiveKey("owned");
              }
        }
        if (sortingHasSet) {
            console.log("jew")
            Utilities.updateTabUrl(prevTabState);
            setSceneTab(prevTabState);
            setTabActiveKey(prevTabState);
            // setPrevTabState(null);
            setCheckIfTabChanged(!checkIfTabChanged)
        }
    }, [ownedSceneData, sharedSceneData, incompleteSceneData,selectedCustomerScenes, prevTabState]);

    const enterFolderState = (element, removeSearhValue=true) => {
        if (sortBy == 5) {
        setFolderState(true);
        let folder_items = element;
        if (folder_items.length > 0) {
        setSelectedParentScene(folder_items[0]);
        if (folder_items[0]) {
            let currentFolderId = getFolderId(folder_items[0]);
            setFolderID(currentFolderId);
            setSelectedFolderParentId(folder_items[0].id);
            getFoldersListExceptCurrentFolder(currentFolderId);
        }
        let folder_products = _.cloneDeep(folder_items);// deep copy
        setFolderScenes(folder_products);
        if (removeSearhValue) {
            removeSearchParams();
            setSearchValue('');
        }
        }
    }
    };

    const leaveFolderState = (removeSearchValue=false) => {
        // remove folder id from parameters while leaving folder 
        setLastFolderAccessed(folderID);
        setFolderState(false);
        setFolderID(null);
        setFolderScenes([]);
        if (removeSearchValue == false) {
            setSearchValue('');
            removeSearchParams();
        }
        
        setSaveOrderedItems(false);
        changeSceneTab(sceneTab, 'trigger');
        setCheckIfTabChanged(!checkIfTabChanged);
    };

  useEffect(() => {
    if (saveOrderedItems) {
      reorderArrangementData();
    }
  }, [saveOrderedItems]);

  useEffect(() => {
        if ((ownedSceneData.length > 0 || sharedSceneData.length > 0) && (ownedScenesEntityArrangement.length > 0 || otherScenesEntityArrangement.length > 0)) {
            if (sceneTab == "owned") {
                setFolderArrangement(ownedScenesFolderArrangement);
                setEntityArrangement(ownedScenesEntityArrangement);
            } else if (sceneTab == "shared") {
                setFolderArrangement(otherScenesFolderArrangement);
                setEntityArrangement(otherScenesEntityArrangement);
            }
        }
      }, [ownedScenesEntityArrangement, ownedScenesFolderArrangement, otherScenesFolderArrangement, otherScenesEntityArrangement, ownedSceneData, sharedSceneData, sceneTab])

  const getFullArrangements = (entity_arr) => {
    let new_entity_arrangement = [];
    let new_folder_arrangement = [];
    let arrangement_info = {};
    let folder_arrangement_info = {};
    let items = sceneTab == "owned" ? ownedSceneData : sharedSceneData;
    items.map((item, index) => {
      if (Array.isArray(item)) {
        folder_arrangement_info = {
          folder_id: parseInt(item[0].folder_id),
          ordering_number: index,
          folder_name: item[0].folder_name,
          sequence_id: sceneTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER
        }
        new_folder_arrangement.push(folder_arrangement_info);
        
        item.map((folder_item, folder_index) => {
          let folder_data = []
          let folder_arr_info = entity_arr.filter((arrangement) => parseInt(arrangement.entity_id) == folder_item.id);
          if (folder_arr_info.length > 0) {
            folder_data = folder_arr_info[0];
            arrangement_info = {
              entity_id: parseInt(folder_data.entity_id),
              ordering_number: folder_data.ordering_number,
              parent_folder_id: parseInt(folder_item.folder_id),
              sequence_id: sceneTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER
            }
          } else {
            folder_data = item;
            arrangement_info = {
              entity_id: parseInt(folder_item.id),
              ordering_number: folder_index,
              parent_folder_id: parseInt(folder_item.folder_id),
              sequence_id: sceneTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER
            }
          }
          new_entity_arrangement.push(arrangement_info);
        })
      } else {
        arrangement_info = {
          entity_id: parseInt(item.id),
          ordering_number: index,
          parent_folder_id: item.folder_id,
          sequence_id: sceneTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER
        }
        new_entity_arrangement.push(arrangement_info);
      }
    })
    return {
      new_entity_arrangement, new_folder_arrangement
    }
  }

  const reorderArrangementData = (entitiesArrangement, folderArrangement) => {
    if (saveOrderedItems && entitiesArrangement && folderArrangement) {
      setSaveOrderedItems(false);
      let payload = {};
      let company_arrangement =
        sceneTab == "owned" ? companyArrangement : false;
      if ((reorderByCompany == company_arrangement && sceneTab == "owned") || sceneTab == "shared") {
        payload = {
          action: "reorder",
          entity_arrangement: entitiesArrangement,
          folder_arrangement: folderArrangement,
          sequence_id: sceneTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER,
        };
        console.log("Re Order Payload ", sceneTab, payload);
      } else {
        let entity_arrangement = []
        let folder_arrangement = [];
        if (folderState) {
          let arrangement_values = getFullArrangements(entitiesArrangement);
          console.log(arrangement_values)
          entity_arrangement = arrangement_values['new_entity_arrangement'];
          folder_arrangement = arrangement_values['new_folder_arrangement'];
        } else {
          entity_arrangement = entitiesArrangement;
          folder_arrangement = folderArrangement;
        }

        let folder_arr = [];
        let folder_dict = {};
        folder_arrangement.map((folder) => {
          let folder_entities = [];
          folder_dict = {
            ordering_number: folder["ordering_number"],
            folder_name: folder["folder_name"],
          };
          let entities = []
          entity_arrangement.map((entity) => {
            if (entity["parent_folder_id"] == folder["folder_id"]) {
              entities.push(entity);
            }
          });
          if (entities.length > 0) {
            entities = entities.sort((a, b) => (a.ordering_number - b.ordering_number))
            entities.map((entity) => {
              folder_entities.push(entity["entity_id"]);
            })
          }

          folder_dict["folder_entities"] = folder_entities;
          folder_arr.push(folder_dict);
        });

        let new_entity_arr = [];
        let entity_dict = {};
        entitiesArrangement.map((entity) => {
          entity_dict = entity;
          entity_dict["parent_folder_id"] = "";
          new_entity_arr.push(entity_dict);
        });

        payload = {
          action: "reorder_first_time",
          entity_type: sceneTab == "owned" ? "owned_scene" : "other_scene",
          username: localStorage.getItem('username'),
          company_id: localStorage.getItem('company_id'),
          entity_arrangement: new_entity_arr,
          folder_arrangement: folder_arr,
          old_sequence_id: sceneTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER,
          save_by_company: sceneTab == "owned" ? reorderByCompany : false,
        };
      }
      console.log('Payload', payload)
      organizeData(payload, sceneTab == "owned" ? "owned_scene" : "other_scene");
    }
  };

    
    const changeSceneTab = (key, event_type) => {
        // key is the tab key, and event type tells us
        // whether tab change was triggered by click or through
        // any other action
        Utilities.updateTabUrl(key);
        setSceneTab(key);
        setTabActiveKey(key);
        if (key == "owned") {
            setFolderArrangement(ownedScenesFolderArrangement);
            setEntityArrangement(ownedScenesEntityArrangement);
        } else if (key == "shared") {
            setFolderArrangement(otherScenesFolderArrangement);
            setEntityArrangement(otherScenesEntityArrangement);
        }
        setSelectedFolderScenes([]);
        setSelectedSceneIds([]);
        if (event_type == 'click') {
            setTabChangeThroughClick(true);
            let url = new URL(window.location);
            url.searchParams.delete("folder_id");
            url.searchParams.delete("library_folder_id");
            url.searchParams.delete("entity_id");
        }
    };


    const onClickShareIcon = () => {
        setSharingView(true);
        setAllSelected(false);
        setSelectable(true);
        setSelectedSceneIds([]);
        setTriggerUpdate(!triggerUpdate);
        setReorderItems(false);
        setShowBottomNavBar(true);
        forceUpdate();
    };

    const createNewFolder = () => {
        setNewFolder(true);
    };

    const checkIfFolderNameAlreadyExists = (folder_name) => {
        let folder_info = sceneTab == "owned" ? ownedFolderData : otherFolderData;
        const folderExist = folder_info.filter((folderInfo)=> folderInfo.folder_name == folder_name);
        if(folderExist.length>0){
            return true;
        }
        else {
            return false;
        }
    }

    const createNewFolderConfirm = () => {

        if (newFolderName) {
                const folderExists = checkIfFolderNameAlreadyExists(newFolderName);
                if(folderExists){
                    setNewFolder(true);
                    setErrorMessage("Folder already exists no duplicate folders are allowed.")
                } else {
                    setErrorMessage("");
                    let folder_entities = selectedFolderScenes.map((folder_entity) => {
                        return parseInt(folder_entity)
                      })
                    const newFolder = {
                        folder_name: newFolderName,
                        folder_entities: folder_entities
                    }


                    let payload = {
                        action: "create_new_folder",
                        folder_arrangement: newFolder,
                        sequence_id: sceneTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER
                    };

                    console.log("New folder payload: ", payload);
                    let url = new URL(window.location);
                    url.searchParams.delete("folder_id");
                    url.searchParams.delete("library_folder_id");
                    url.searchParams.set("entity_id", selectedFolderScenes[0]);
                    window.history.replaceState({}, "", url);

                    setSelectedFolderScenes([]);
                    setReorderItems(false);
                    setShowBottomNavBar(false);
                    setNewFolderName(null);

                    organizeData(payload, sceneTab == "owned" ? "owned_scene" : "other_scene");
                }    
        } else {
        setErrorMessage("Please enter a folder name.");
        }
    };

    const getFoldersListExceptCurrentFolder = (id) =>{
        let folder_info = sceneTab == "owned" ? ownedFolderData : otherFolderData;
        const folder_list = folder_info.filter(info => info.folder_id != id);
        setFoldersListExceptCurrentFolder(folder_list);
    }

    const moveToFolderOnClick = () => {
        setExistingFolder(true);
        if(selectedParentScene){
            let currentFolderId = getFolderId(selectedParentScene);
            getFoldersListExceptCurrentFolder(currentFolderId);
        }
        let folder_info = sceneTab == "owned" ? ownedFolderData : otherFolderData;
        setFolderInfo(folder_info);
    };

    const moveToFolderConfirm = () => {
        if (selectedFolder) {
            const folderId = folderInfo.filter(folderInfo => folderInfo.folder_id == selectedFolder);
            Utilities.updateFolderIDInUrl(selectedFolder);
            
            let folder_entities = selectedFolderScenes.map((folder_entity) => {
                return parseInt(folder_entity)
            })
            let payload = {
                action: 'move_to_folder',
                sequence_id: sceneTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER,
                folder_id: folderId[0].folder_id,
                moved_entities: folder_entities,
            }
            console.log(payload, 'Move to Folder')
            organizeData(payload, sceneTab == "owned" ? 'owned_scene' : 'other_scene');
            setSelectedFolderScenes([]);
            setShowBottomNavBar(false);
            setReorderItems(false);
            setSelectedFolder(null);
        } else {
        alert("Please select a folder first");
        }
    };    

    const organizeData = (payload, entity_type = "owned_scene", reload = true) => {
        console.log('Arrangement Payload', payload)
        setActionLoader(true);
        axios.post(ENVIRONMENT.ENTITY_ORGANIZER, payload)
        .then(res => {
          console.log("Arrangement Data: ", res);
          if (payload.action == 'reorder_first_time') {
            
            loadSequenceIDs(false, reload);
          } else {
            if (folderID) {
              window.location.reload();
            } else {
              window.location.reload();
            }
          }
          setExistingFolder(false);
          setViewUnGroupFolderModal(false);
          setViewRenameFolderModal(false);
          setNewFolder(false);
          setActionLoader(false);

          if(payload.action == 'move_to_folder')
          {
            setTriggerGroupSceneUpdate(true);
          }
          forceUpdate();
        }).catch(err => {
          console.log(err);
        });
    };


    const scrollToItem = (type, id) => {
        let element_id = `${type}-${id}`;
        let element = document.getElementById(element_id)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    
    useEffect(() => {
        // if tab is changed then check if these are present in URL params
        // if yes, then scroll to item or folder
        let scene_id = new URLSearchParams(window.location.search).get("entity_id");
        // if this is present scroll down to the folder in the library
        let folder_id = new URLSearchParams(window.location.search).get("folder_id");
        if (scene_id) {
            if (!lastFolderAccessed) {
                scrollToItem('element', scene_id);
            } else {
                scrollToItem('folder', lastFolderAccessed);
                setLastFolderAccessed(null);
            }
        } else if (lastFolderAccessed || folder_id) {
            let new_folder_id = lastFolderAccessed ? lastFolderAccessed : folder_id;
            scrollToItem('folder', new_folder_id);
            setLastFolderAccessed(null);
        }
    }, [checkIfTabChanged]);

    const convertToArrangement = (scenes, folder_info, type) => {
        let entity_arrangement = [];
        let arrangement_info = {};
        let folder_arrangement = [];
        let folder_arrangement_info = {};
        if (scenes && scenes.length > 0) {
          scenes.map((data) => {
            arrangement_info = {
              entity_id: data.id,
              ordering_number: parseInt(data.library_order),
              parent_folder_id: data.folder_id == "" ? "" : parseInt(data.folder_id),
              sequence_id: type == 'owned' ? SEQUENCE_ID_OWNED :SEQUENCE_ID_OTHER
            }
            entity_arrangement.push(arrangement_info);
          });
    
          folder_info.map((data) => {
            folder_arrangement_info = {
              folder_id: data.folder_id,
              ordering_number: data.library_order == "" ? "" : parseInt(data.library_order),
              folder_name: data.folder_name,
              sequence_id: type == 'owned' ? SEQUENCE_ID_OWNED :SEQUENCE_ID_OTHER
            }
            folder_arrangement.push(folder_arrangement_info);
          });
        }
    
        if (type == 'owned') {
          setOwnedScenesEntityArrangement(entity_arrangement);
          setOwnedScenesFolderArrangement(folder_arrangement);
        } else {
          setOtherScenesEntityArrangement(entity_arrangement);
          setOtherScenesFolderArrangement(folder_arrangement);
        }
      }

    const convertToLibraryFormat = (data) => {
        // data will be converted in this format: let's say 4 products (p) at root and 2 folders (f) at root
        // [p, p, p, f -> [p,p], p, f -> [p, p]]
        let root_items = [];
        let folder_info = [];
        let old_index = 0;
        let index = 0;
        if (data && data.length > 0) {
          let folder_indexes = new Map();
          data.map((scene) => {
            // if item is in folder, then create an array at the folder index (which will be 
            // the same as folder ordering #)
            // push the item in that array
            // otherwise push the scene normally
            if (scene.folder_id) {
              // check if array index has been created for the particular folder id
              // if it's not, then initialize it and create entry for folder_data
              // otherwise push the scene in the appropriate array index
              if (folder_indexes.get(scene.folder_id) == undefined) {
                folder_indexes.set(scene.folder_id, index);
                root_items[folder_indexes.get(scene.folder_id)] = [];
                let folder_data = {
                  'folder_id': scene.folder_id,
                  'folder_name': scene.folder_name,
                  'ordering_number': scene.library_order,
                  'parent_item': scene
                }
                folder_info.push(folder_data);
                index++;
              }
              root_items[folder_indexes.get(scene.folder_id)].push(scene);
            } else {
              root_items.push(scene);
              index++;
            }
          });
        }
        
        return {
          root_items, 
          folder_info
        };
      }

    useEffect(() => {
        let owned_scenes = ownedOriginalData;
        let formatted_data = convertToLibraryFormat(owned_scenes);
        setOwnedSceneData(formatted_data.root_items);
        setOwnedFolderData(formatted_data.folder_info);
        convertToArrangement(owned_scenes, formatted_data.folder_info, 'owned');
    
        let other_scenes = sharedOriginalData;
        formatted_data = convertToLibraryFormat(other_scenes);
        setSharedSceneData(formatted_data.root_items);
        setOtherFolderData(formatted_data.folder_info);
        convertToArrangement(other_scenes, formatted_data.folder_info, 'other');

        setIncompleteSceneData(incompleteOriginalData);
      }, [ownedOriginalData, sharedOriginalData, incompleteOriginalData]);
    
    const adjustLibraryView = (data, tab_key) => {
        // adjust library view (move to the scene or folder that was last clicked by the user in the library)
        let scene_id = new URLSearchParams(window.location.search).get("entity_id");
        let folder_id = new URLSearchParams(window.location.search).get("folder_id");
        let library_folder_id = new URLSearchParams(window.location.search).get("library_folder_id");

        // if scene exists,  scroll down to that room
        if (scene_id && data.length > 0) {
            let scene_data = data.filter(ele=> ele.id == scene_id);
            if (scene_data.length > 0) {
                let folder_id = null;

                if (scene_data[0].folder_id) {
                    folder_id = scene_data[0].folder_id;
                    let folder_items = tab_key == "owned" ? ownedOriginalData : sharedOriginalData;
                    folder_items = folder_items.filter(item => item.folder_id == folder_id);
                    if (folder_items && folder_items.length > 0) {
                      let parent_entity = folder_items[0].parent_item
                      setSelectedParentScene(parent_entity);
                      if (parent_entity) {
                        getFoldersListExceptCurrentFolder(folder_id);
                      }
                      enterFolderState(folder_items, false);
                      Utilities.updateTabUrl(tab_key);
                      setSceneTab(tab_key);
                      setTabActiveKey(tab_key);
                    }
                  } 
                  // otherwise move to that scene
                  else {
                    Utilities.updateTabUrl(tab_key);
                    setSceneTab(tab_key);
                    setTabActiveKey(tab_key);
                  }
                  setCheckIfTabChanged(!checkIfTabChanged)
            }
        } 
        // scroll down to the folder if library_folder_id exists 
        else if ((folder_id || library_folder_id) && data.length > 0) {
            let folder_items = tab_key == "owned" ? ownedOriginalData : sharedOriginalData;
            if (folder_id) {
                folder_items = folder_items.filter(item => item.folder_id == folder_id);
              } else if (library_folder_id) {
                folder_items = folder_items.filter(item => item.id == library_folder_id);
              }
              if (folder_items && folder_items.length > 0) {
                Utilities.updateTabUrl(tab_key);
                setSceneTab(tab_key);
                setTabActiveKey(tab_key);
                setCheckIfTabChanged(!checkIfTabChanged)
              }
          }
    }
    
    const renameFolderOnclick = (element) => {
        let folder_item = element[0];
        Utilities.updateFolderIDInUrl(folder_item.folder_id);
        setSelectedFolderParentId(folder_item.id);
        setSelectedParentScene(folder_item);
        const folderName = getFolderName(folder_item);
        setFolderNewName(folderName);
        setViewRenameFolderModal(true);
    };

    const getFolderName = (element) => {
        if (element.folder_id != "")
            return element.folder_name;

        return '';
    };

    const renameFolderConfirm = () => {
        if (folderNewName) {
        const folderExists =
            checkIfFolderNameAlreadyExists(folderNewName);
        if(folderExists){
            setViewRenameFolderModal(true);
            setRenameError("Folder already exists please enter a unique name.");
        } else {
            setRenameError("");
            const selectedFolderId = getFolderId(selectedParentScene);
            let payload = {
                action: "rename_folder",
                folder_id: parseInt(selectedFolderId),
                folder_name: folderNewName,
                sequence_id: sceneTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER
            };
            organizeData(payload, sceneTab == "owned" ? "owned_scene" : "other_scene");

            setSelectedFolderParentId(null);
            setSelectedParentScene(null)
            }
        } else {
        alert("Please enter a folder name.");
        }
    };

    const unGroupFolderItemsOnclick = (element) => {
        let folder_item = element[0];
        Utilities.updateEntityInUrl(folder_item.id);
        setSelectedParentScene(folder_item);
        setSelectedFolderParentId(folder_item.id);
        setViewUnGroupFolderModal(true);
    };

    const unGroupFolderItemsConfirm = () => {
        const selectedFolderId = getFolderId(selectedParentScene);
        let payload = {
          'action': 'delete_folder',
          deleted_folder_id: parseInt(selectedFolderId),
          sequence_id: sceneTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER
        }
        if (localStorage.getItem('shared_entities') != undefined && localStorage.getItem('company_id') != undefined && sceneTab == "owned") {
            if (localStorage.getItem('shared_entities').split("_").includes("scene")) {
                payload.company_id = localStorage.getItem('company_id');
            }
        }
        organizeData(payload, sceneTab == "owned" ? "owned_scene" : "other_scene");
    };

    const saveArrangements = () => {
        setSaveOrderedItems(true);
        setShowBottomNavBar(false);
        setReorderItems(false);
        setSelectable(false);
        setActionLoader(true);
        setSelectedFolderScenes([]);
        setSelectedSceneIds([]);
    };

    const onChangeFolderName = (value) => {
        setNewFolderName(value);
    };

    const onChangeNewFolderName = (value) => {
        setFolderNewName(value);
    };

    const closeReorderByModal = () => {
        setReorderByModal(false);
    };

    //get folder id of parent id
    const getFolderId = (element) => {
        if (element.folder_id != "") {
            return element.folder_id;
          }
          return -1;
    };

    useEffect(() => {
        let libraryScenes = [...ownedOriginalData, ...sharedOriginalData]
        if (selectable == false) {
            let ids = libraryScenes.map((ele) => {
            return ele.id;
            });
            if(ids.length != 0){
                setDefaultSceneIds(ids);
            }
        }
    },[ownedOriginalData, sharedOriginalData, selectable]);


    const onChangeSharingUser = (e) => {
        setSelectedForSharing(e.target.value);
    }

    const getSelectedSceneIds = () => {
        let scene_ids = []
        let default_ids = defaultSceneIds

        if ((localStorage.getItem("is_msprovider") === 'true' ? true : false) && localStorage.getItem('managed_customer_username') != null) {
            let customer_ids = selectedCustomerScenes.map((scene) => {return scene.id});
            if (customer_ids.length == 0) {
                customer_ids = []
            }
            if (default_ids.length == 0) {
                default_ids = []
            }
            if (selectedForSharing == "customer") {
                if (customer_ids.length != 0) {
                    default_ids = customer_ids;
                }
            } else if (selectedForSharing == "both") {
                default_ids = default_ids.concat(customer_ids);
            }
        }

        if (selectable == false || selectedSceneIds.length == 0) {
            scene_ids = default_ids;
          }
        else {
            let selected_scene_ids = selectedSceneIds;
            scene_ids = selectedSceneIds;
            if (scene_ids.length == 0) 
            {
                scene_ids = default_ids;
            } else {
              scene_ids = selectedSceneIds;
              if (scene_ids.length == 0) {
                scene_ids = default_ids;
              }
            }
        }

        return scene_ids
    }

    const checkIfCompanyExisits = (company_id) => {
        if (company_id != "" && company_id != null) {
            return true;
          } 
        return false;
      }

    const companyMatch = (company_id, local_company_id) => {
        if (checkIfCompanyExisits(local_company_id)) {
          if (company_id == local_company_id)
            return true;
          else 
            return false;
        }
        return false;
    }
     
    
    const cancelMoveToCustomerSceneModal = () => {
        setMoveToCustomerSceneModal(false);
    }

    const updateScene = (values) => {
        setButtonLoader(true);
        let payload = {
            "scene_id": currentScene,
            "designed_for": values.selected_customer,
        };

        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
        .then( (response) => {
            //MOVE COLLECTIONS AS WELL
            let collection_payload = {}
            collection_payload.designed_for = values.selected_customer;
            collection_payload.collection_id = selectedCollection;
            axios.post(ENVIRONMENT.UPDATE_COLLECTION, collection_payload)
                .then((response) => {
                    setButtonLoader(false);
                    message.success('Scene Moved to ' + values.selected_customer);
                    window.location.href = '/my_scenes';
                    setMoveToCustomerSceneModal(false);
                })
                .catch((error) => {
                    console.log(error);
                    message.error('Error in moving scene');
                    setMoveToCustomerSceneModal(false);
                    setButtonLoader(false);
                })
        })
        .catch((error) => {
            console.log(error);
            message.error('Error in moving scene');
            setMoveToCustomerSceneModal(false);
            setButtonLoader(false);
        });
    }

    const onChangeCustomer = (e) => {
        console.log(e)
    }

    const onChangeSharedTabType =(e) => { 
        setSharedTabType(e.target.value);
        setTabChangeThroughClick(true);
    }

    const onChangeOwnedTabType =(e) => { 
        setOwnedTabType(e.target.value);
        setTabChangeThroughClick(true);
    }
    
    const triggerDeleteModel = (id) => {
        setDeleteEntityid(id);
        setDeleteModal(true);
    }

    const onDelete = () => {
    
        setDeleteLoader(true);

        let api_end_point = ENVIRONMENT.DELETE_INCOMPLETE_REQUEST;
        let payload = {
            "entity_id": deleteEntityid,
            "entity_type": 'Scene',
        };
        
        axios.post(api_end_point, payload)
            .then( (response) => {
                message.info('Scene Deleted.');
                window.location.href = '/my_scenes';
            })
            .catch((error) => {
                message.error('Error in deleting scene');
                setDeleteLoader(false);
                setDeleteModal(false);

            });
    }

    const checkFilteredResults = (scenes) => {
        let scenesData = [];
        const allScenesData = Utilities.getDataWithoutFolders(scenes);
        scenesData = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, allScenesData);
        return scenesData;
    }



    const switchTabIfEmpty = (scenes) => {
        return checkFilteredResults(scenes);
    }

    return (
        <div className="my-products">
            <CustomerMainLayout selected='6'>                         
            <Row className="justify-space-between"  style={{marginTop:20,marginBottom: 20}}>          
            {initialLoadShared || initialLoadOwned || initialLoadIncomplete ? (
            ''
            ) : (
            <>
              <Col lg={10} xl={10}>
                <div
                  className="manrope f-24 w-700"
                  style={{
                    display: "flex",
                    alignItems: folderState ? "center" : "flex-end",
                  }}
                >
                  {folderState ? (
                    <img
                      src="/img/back_arrow.png"
                      style={{ marginRight: 20, cursor: "pointer" }}
                      onClick={leaveFolderState}
                    />
                  ) : (
                    ""
                  )}
                  {folderState ? (
                    <Tooltip
                      title={
                        <span className="manrope f-12 white">
                          { selectedParentScene 
                            ? ( getFolderId(selectedParentScene) != -1
                            ? selectedParentScene.folder_name 
                            : "" )
                            : ""
                          }
                        </span>
                      }
                    >
                      <span className="manrope f-24 w-700 clamp-text w-60">
                          { selectedParentScene 
                            ? (getFolderId(selectedParentScene) != -1 
                            ? selectedParentScene.folder_name 
                            : "")
                            : ""
                          }
                      </span>
                    </Tooltip>
                  ) : (
                    "Scene Library"
                  )}

                  {!folderState && !sharedSceneLoader && !ownedSceneLoader && !incompleteSceneLoader ? (
                    <span
                      className="manrope f-20"
                      style={{ marginLeft: 4, paddingBottom: 2 }}
                    >
                     ({(searchValue != '')&&<span className="manrope grey-6b">{getTabTotalCount()} of&nbsp;</span>}{getSceneCount()} scenes)
                    </span>
                  ) : folderState && folderScenes && !loader ? (
                    <span className="manrope f-20" style={{ marginLeft: 4 }}>
                      ({getFolderSceneCount()} scenes)
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col lg={14} xl={14} className='justify-in-end'>
                {!reorderItems && !sharedSceneLoader && !ownedSceneLoader && (
                <div className='search-bar-width mr-14'>
                  <Form>
                    <Input.Group compact size="large" className="d-inline">
                      <Input
                        placeholder="Search All Scenes"
                        onChange={(e) => {
                          changeSearchValue(e.target.value);
                        }}
                        value={searchValue}
                        className="manrope f-14 mat-search-bar"
                        style={{marginLeft: ((localStorage.getItem("is_msprovider") === 'true' ? true : false)? 5 : '')}}
                      ></Input>
                    </Input.Group>
                  </Form>
                </div>
                )}
                {!reorderItems && !sharedSceneLoader && !ownedSceneLoader && !folderState && 
                <Tooltip title={<span className="manrope f-12 white">Sort by</span>}>
                    <Dropdown
                        overlay={sorting_options()}
                        trigger={["hover"]}>
                        <div className="icon-button-container mr-8">
                            <SwapOutlined className='reorder-icon' style={{ transform: 'rotate(90deg)' }} />
                        </div>
                    </Dropdown>
                </Tooltip>}
                
                {!reorderItems && !sharedSceneLoader && !ownedSceneLoader && tabActiveKey != 'incomplete' && (SEQUENCE_ID_OTHER != -1 || SEQUENCE_ID_OWNED != -1) && (
                <Tooltip title={<span className="manrope f-12 white">{(sortBy != 5) ? 'Select Custom Order to enable reorder' : 'Reorder Items'}</span>}>
                    <div className={(sortBy != 5) ? 'not-allowed-setting ' : ''}>
                        <div
                        // sortBy!=5 && (SEQUENCE_ID_OTHER != -1 || SEQUENCE_ID_OWNED != -1)
                            className={`${(sortBy != 5) ? 'pointer-none-setting' : ''}`}
                            onClick={() => {
                            setSearchValue('');
                            removeSearchParams();
                            setReorderItems(true);
                            setShowBottomNavBar(true);
                            setSelectedSceneIds([]);
                            setSaveOrderedItems(false);
                            Utilities.updateTabUrl("owned");
                            setTabActiveKey("owned");
                            setSceneTab("owned");
                            
                            }}
                        >
                            <div className={`icon-button-container mr-8`}>
                                <ReorderIcon className='reorder-icon'/>
                            </div>
                        </div>
                  </div>
                  </Tooltip>
                )}
                

                {!reorderItems && !sharedSceneLoader && !ownedSceneLoader && tabActiveKey != 'incomplete' && (
                  <div>
                    <Tooltip title={<span className="manrope f-12 white">Share Items</span>}>
                    <div
                      className="icon-button-container mr-8"
                      onClick={onClickShareIcon}
                    >
                        <ShareAltOutlined/>
                    </div>
                    </Tooltip>
                  </div>
                )}
                {!reorderItems && !sharedSceneLoader && !ownedSceneLoader && (
                    <div>
                        <Tooltip title={<span className="manrope f-12 white">Create New Scene</span>}>
                            <Dropdown  
                            overlay={create_new_scene_options()}
                            trigger={["click"]}>
                                <Button className='new-product-btn black f-14'>
                                    <PlusCircleFilled />&nbsp;New Scene
                                </Button>
                            </Dropdown>
                        </Tooltip>
                    </div>
                )}
              </Col>
            </>
          )} 
          
            </Row>
                <div>
                    <div className="text-center"> {initialLoadOwned || initialLoadShared ? <DottedLoader/> : (
                        <span>
                            {reorderItems && (
                            <Row
                                type="flex"
                                style={{
                                justifyContent: "flex-start",
                                height: "40px",
                                }}
                            >
                                <p className="manrope f-20 ">Reorder Items</p>
                            </Row>
                            )}
                            {folderState == false ? (
                            <Col span={24}>
                            {getSceneCount() > 0 ? 
                                <Tabs onChange={(key) => changeSceneTab(key, 'click')}
                                activeKey={tabActiveKey}
                                className="product-tab library" 
                                tabBarGutter={16} 
                                size="large">
                                    {(localStorage.getItem("is_msprovider") === 'true' ? true : false) && localStorage.getItem('managed_customer_username') != null && !reorderItems ?
                                    <TabPane forceRender={true} tab={`Designed For ` + localStorage.getItem('managed_customer_username')+ ` (${getSceneTabCount('selected_customer')}${searchValue != '' ? ` of ${getScenesLength('selected_customer')} scenes` : ``})`} key={"customer"} style={{padding: "10px"}}>
                                        {selectable && sharingView && 
                                        <Row className='justify-in-end'>
                                            <Col span={12} className='justify-in-end'>
                                            {!allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={selectAllScenes}>Select All</p> : 
                                             allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={deselectAllScenes}>Deselect All</p> : ''}
                                            </Col>
                                        </Row>}
                                        <Row className="product-tab-content-scrollable" type="flex" style={{ marginTop: "10px", justifyContent: "start" }}>
                                            {
                                                initialLoadMsp ? <DottedLoader /> :
                                                <ScenesList
                                                tabChangeThroughClick={tabChangeThroughClick}
                                                setTabChangeThroughClick={setTabChangeThroughClick}
                                                checkFilteredResults={checkFilteredResults}
                                                switchTabIfEmpty={switchTabIfEmpty}
                                                setOrderUpdated={setOrderUpdated}
                                                addToStore={openTemplateModal}
                                                type='customers'
                                                managedCustomersExist={managedCustomersExist}
                                                selectedCollection={selectedCollection}
                                                setSelectedCollection={setSelectedCollection}
                                                setSceneCompanyID={setSceneCompanyID}
                                                moveToCustomerSceneModal={moveToCustomerSceneModal}
                                                setMoveToCustomerSceneModal={setMoveToCustomerSceneModal}
                                                selectable={selectable}
                                                onboarding={onboarding}
                                                selectedSceneIds= {selectedSceneIds}
                                                setSelectedSceneIds={setSelectedSceneIds}
                                                libraryScenes={getScenes('selected_customer')} 
                                                searchValue={searchValue}
                                                setDeleteSceneModal={setDeleteSceneModal}
                                                setCurrentScene={setCurrentScene}
                                                setCurrentSceneCustomer={setCurrentSceneCustomer}
                                                folderState={folderState}
                                                enterFolderState={enterFolderState}
                                                reorderItems={reorderItems}
                                                selectedFolderScenes={selectedFolderScenes}
                                                setSelectedFolderScenes={setSelectedFolderScenes}
                                                saveOrderedItems={saveOrderedItems}
                                                setSaveOrderedItems={setSaveOrderedItems}
                                                sceneTab={sceneTab}
                                                hashUpdated={hashUpdated}
                                                entityArrangement={entityArrangement}
                                                folderArrangement={folderArrangement}
                                                setHashUpdated={setHashUpdated}
                                                renameFolderOnclick={renameFolderOnclick}
                                                unGroupFolderItemsOnclick={
                                                    unGroupFolderItemsOnclick
                                                }
                                                setNewLoader={setNewLoader}
                                                setSortedItems={setSortedItems}
                                                setOrderSaved={setOrderSaved}
                                                reorderByCompany={reorderByCompany}
                                                companyArrangement={companyArrangement}
                                                organizeData={organizeData}
                                                reorderArrangementData={reorderArrangementData}
                                                setDuplicateSceneModal={setDuplicateSceneModal}
                                                setCurrentSceneName={setCurrentSceneName}
                                                updateTabCount={updateTabCount}
                                                tabKey={"selected_customer"}
                                                projects={projects}
                                                />  }
                                                { (mspDataLoad && !initialLoadMsp) &&
                                                    <div style={{position:"fixed", width:"100%", textAlign:"center", padding:"20px", bottom:0}}>
                                                        <span className="manrope f-18 black-55" style={{marginRight: "200px"}}>
                                                        Loading complete data...  <LoadingOutlined/>
                                                        </span>
                                                    </div>
                                                }
                                        </Row>
                                    </TabPane>: ''}

                                    {(localStorage.getItem("is_msprovider") === 'true' ? true : false) && localStorage.getItem('managed_customer_username') != null && !reorderItems ?
                                    <TabPane forceRender={true} tab={`Collaboration With ` + localStorage.getItem('managed_customer_username')+ ` (${getSceneTabCount('collaboration_scenes')}${searchValue != '' ? ` of ${getScenesLength('collaboration_scenes')} scenes` : ``})`} key={"collaboration"} style={{padding: "10px"}}>
                                        {selectable && sharingView && 
                                          <Row className='justify-in-end'>
                                            <Col span={12} className='justify-in-end'>
                                            {!allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={selectAllScenes}>Select All</p> : 
                                              allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={deselectAllScenes}>Deselect All</p> : ''}
                                            </Col>
                                          </Row>}
                                        <Row className="product-tab-content-scrollable" type="flex" style={{ marginTop: "10px", justifyContent: "start" }}>
                                            <ScenesList
                                                tabChangeThroughClick={tabChangeThroughClick}
                                                setTabChangeThroughClick={setTabChangeThroughClick}
                                                checkFilteredResults={checkFilteredResults}
                                                switchTabIfEmpty={switchTabIfEmpty}
                                                sequence_id_other={SEQUENCE_ID_OTHER}
                                                sequence_id_owned={SEQUENCE_ID_OWNED}
                                                setOrderUpdated={setOrderUpdated}
                                                addToStore={openTemplateModal}
                                                type='customers'
                                                managedCustomersExist={managedCustomersExist}
                                                selectedCollection={selectedCollection}
                                                setSelectedCollection={setSelectedCollection}
                                                setSceneCompanyID={setSceneCompanyID}
                                                moveToCustomerSceneModal={moveToCustomerSceneModal}
                                                setMoveToCustomerSceneModal={setMoveToCustomerSceneModal}
                                                selectable={selectable}
                                                onboarding={onboarding}
                                                selectedSceneIds= {selectedSceneIds}
                                                setSelectedSceneIds={setSelectedSceneIds}
                                                libraryScenes={getScenes('collaboration_scenes')} 
                                                searchValue={searchValue}
                                                setDeleteSceneModal={setDeleteSceneModal}
                                                setCurrentScene={setCurrentScene}
                                                setCurrentSceneCustomer={setCurrentSceneCustomer}
                                                folderState={folderState}
                                                enterFolderState={enterFolderState}
                                                reorderItems={reorderItems}
                                                selectedFolderScenes={selectedFolderScenes}
                                                setSelectedFolderScenes={setSelectedFolderScenes}
                                                saveOrderedItems={saveOrderedItems}
                                                setSaveOrderedItems={setSaveOrderedItems}
                                                sceneTab={sceneTab}
                                                hashUpdated={hashUpdated}
                                                entityArrangement={entityArrangement}
                                                folderArrangement={folderArrangement}
                                                setHashUpdated={setHashUpdated}
                                                renameFolderOnclick={renameFolderOnclick}
                                                unGroupFolderItemsOnclick={
                                                    unGroupFolderItemsOnclick
                                                }
                                                setNewLoader={setNewLoader}
                                                setSortedItems={setSortedItems}
                                                setOrderSaved={setOrderSaved}
                                                reorderByCompany={reorderByCompany}
                                                companyArrangement={companyArrangement}
                                                organizeData={organizeData}
                                                reorderArrangementData={reorderArrangementData}
                                                setDuplicateSceneModal={setDuplicateSceneModal}
                                                setCurrentSceneName={setCurrentSceneName}
                                                updateTabCount={updateTabCount}
                                                tabKey={"collaboration_scenes"}
                                                projects={projects}
                                                />
                                        </Row>
                                    </TabPane>: ''}

                                    <TabPane forceRender={true} tab={"Your Scenes " + (!ownedSceneLoader ? `(${getSceneTabCount('owned')}${searchValue != '' || ownedTabType != 'all' ? ` of ${getScenesLength('owned', 'all')} scenes` : ``})` : `(${tempOwnedCount})`)} key={"owned"}  style={{padding: "10px"}}>
                                        
                                            <Row className={(!reorderItems && (localStorage.getItem('company_id') != undefined) && !(localStorage.getItem("is_msprovider") === 'true' ? true : false)) ? `justify-space-between` : `justify-in-end`}>
                                            {(!reorderItems && (localStorage.getItem('company_id') != undefined) && !(localStorage.getItem("is_msprovider") === 'true' ? true : false)) && 
                                                <Col className="shared-models-tab manrope f-14 black-55 j-center-a-baseline">
                                                    <span className="shared-models-tab-span">
                                                        Show Items:
                                                    </span>
                                                    <Radio.Group onChange={onChangeOwnedTabType} value={ownedTabType}>
                                                        <Radio value = 'all'>
                                                        All
                                                        </Radio>
                                                        <Radio value = 'self'>
                                                        Created by You
                                                        </Radio>
                                                        <Radio value = 'managed'>
                                                        Created by Managed Service
                                                        </Radio>
                                                    </Radio.Group>
                                                </Col>}
                                                <Col span={4} className='justify-in-end'>
                                                {selectable && sharingView && !allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={selectAllScenes}>Select All</p> : 
                                                selectable && sharingView && allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={deselectAllScenes}>Deselect All</p> : ''}
                                                </Col>
                                            </Row>
                                    { getScenesLength('owned') != 0 ? (
                                        <>
                                            <ScenesList 
                                            tabChangeThroughClick={tabChangeThroughClick}
                                            setTabChangeThroughClick={setTabChangeThroughClick}
                                            checkFilteredResults={checkFilteredResults}
                                            switchTabIfEmpty={switchTabIfEmpty}
                                            sequence_id_other={SEQUENCE_ID_OTHER}
                                            sequence_id_owned={SEQUENCE_ID_OWNED}
                                            setOrderUpdated={setOrderUpdated}
                                            addToStore={openTemplateModal}
                                            type='owned'
                                            managedCustomersExist={managedCustomersExist}
                                            selectedCollection={selectedCollection}
                                            setSelectedCollection={setSelectedCollection}
                                            setSceneCompanyID={setSceneCompanyID}
                                            ownedSceneLoader={ownedSceneLoader}
                                            owned_scenes={true}
                                            moveToCustomerSceneModal={moveToCustomerSceneModal}
                                            setMoveToCustomerSceneModal={setMoveToCustomerSceneModal}
                                            selectable={selectable}
                                            onboarding={onboarding}
                                            selectedSceneIds = {selectedSceneIds}
                                            setSelectedSceneIds={setSelectedSceneIds}
                                            libraryScenes={getScenes('owned')} 
                                            searchValue={searchValue}
                                            setDeleteSceneModal={setDeleteSceneModal}
                                            setCurrentScene={setCurrentScene}
                                            setCurrentSceneCustomer={setCurrentSceneCustomer}
                                            folderState={folderState}
                                            enterFolderState={enterFolderState}
                                            reorderItems={reorderItems}
                                            selectedFolderScenes={selectedFolderScenes}
                                            setSelectedFolderScenes={setSelectedFolderScenes}
                                            saveOrderedItems={saveOrderedItems}
                                            setSaveOrderedItems={setSaveOrderedItems}
                                            sceneTab={sceneTab}
                                            hashUpdated={hashUpdated}
                                            entityArrangement={entityArrangement}
                                            folderArrangement={folderArrangement}
                                            setHashUpdated={setHashUpdated}
                                            renameFolderOnclick={renameFolderOnclick}
                                            unGroupFolderItemsOnclick={
                                                unGroupFolderItemsOnclick
                                            }
                                            setNewLoader={setNewLoader}
                                            setSortedItems={setSortedItems}
                                            setOrderSaved={setOrderSaved}
                                            reorderByCompany={reorderByCompany}
                                            companyArrangement={companyArrangement}
                                            organizeData={organizeData}
                                            reorderArrangementData={reorderArrangementData}
                                            setDuplicateSceneModal={setDuplicateSceneModal}
                                            setCurrentSceneName={setCurrentSceneName}
                                            updateTabCount={updateTabCount}
                                            tabKey={"owned"}
                                            projects={projects}
                                            />
                                            {ownedSceneLoader && searchValue == "" && <div className="justify-in-center" style={{width: '100%'}}>
                                            <p className="empty-section-text manrope f-16 black-55">
                                            Please wait while we load all your scenes.
                                            <LoadingOutlined style={{fontSize: 24, marginLeft: 14}}/>
                                            </p>
                                        </div>}
                                        </>
                                        ) :
                                        <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:'-webkit-center'}}>
                                            <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Spaces.svg"/>
                                            <p className="empty-section-text manrope f-14 black-55">
                                                Nothing to show
                                            </p>
                                            <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
                                                There are no scenes of your own. To get started create a new scene.
                                            </p>
                                            <Tooltip title={<span className="manrope f-12 white">Create New Scene</span>}>
                                                <Dropdown
                                                    overlay={create_new_scene_options()}
                                                    trigger={["click"]}>
                                                    <Button className='new-product-btn black f-14'>
                                                        <PlusCircleFilled />&nbsp;New Scene
                                                    </Button>
                                                </Dropdown>
                                            </Tooltip>
    
                                        </div>
                                        }
                                    </TabPane>
                                    
                                    <TabPane forceRender={true} tab={"Other Scenes " + (!sharedSceneLoader ? `(${getSceneTabCount('shared')}${searchValue != '' || sharedTabType != 'all' ? ` of ${getScenesLength('shared', 'all')} scenes` : ``})` : '')} key={"shared"}  style={{padding: "10px"}}>
                                    
                                        {!reorderItems && <Row className='justify-space-between'>
                                            <Col className="shared-models-tab manrope f-14 black-55 j-center-a-baseline">
                                                <span className="shared-models-tab-span">
                                                    Show Items:
                                                </span>
                                                <Radio.Group onChange={onChangeSharedTabType} value={sharedTabType}>
                                                    <Radio value = 'all'>
                                                    All
                                                    </Radio>
                                                    <Radio value = 'store'>
                                                    Added from Store
                                                    </Radio>
                                                    <Radio value = 'shared'>
                                                    Shared
                                                    </Radio>
                                                </Radio.Group>
                                            </Col>
                                            <Col span={4} className='justify-in-end'>
                                                {selectable && sharingView && !allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={selectAllScenes}>Select All</p> :
                                                    selectable && sharingView && allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={deselectAllScenes}>Deselect All</p> : ''}
                                            </Col>
                                        </Row>}
                                            { getScenesLength('shared') != 0 ? (
                                                <>
                                                <ScenesList 
                                                tabChangeThroughClick={tabChangeThroughClick}
                                                setTabChangeThroughClick={setTabChangeThroughClick}
                                                checkFilteredResults={checkFilteredResults}
                                                switchTabIfEmpty={switchTabIfEmpty}
                                                setOrderUpdated={setOrderUpdated}
                                                addToStore={openTemplateModal}
                                                type='shared'
                                                sharedSceneLoader={sharedSceneLoader}
                                                managedCustomersExist={managedCustomersExist}
                                                selectedCollection={selectedCollection}
                                                setSceneCompanyID={setSceneCompanyID}
                                                setSelectedCollection={setSelectedCollection}
                                                moveToCustomerSceneModal={moveToCustomerSceneModal}
                                                setMoveToCustomerSceneModal={setMoveToCustomerSceneModal}
                                                selectable={selectable}
                                                onboarding={onboarding}
                                                selectedSceneIds = {selectedSceneIds}
                                                setSelectedSceneIds={setSelectedSceneIds}
                                                libraryScenes={getScenes('shared')} 
                                                searchValue={searchValue}
                                                setDeleteSceneModal={setDeleteSceneModal}
                                                setCurrentScene={setCurrentScene}
                                                setCurrentSceneCustomer={setCurrentSceneCustomer}
                                                folderState={folderState}
                                                enterFolderState={enterFolderState}
                                                reorderItems={reorderItems}
                                                selectedFolderScenes={selectedFolderScenes}
                                                setSelectedFolderScenes={setSelectedFolderScenes}
                                                saveOrderedItems={saveOrderedItems}
                                                setSaveOrderedItems={setSaveOrderedItems}
                                                sceneTab={sceneTab}
                                                hashUpdated={hashUpdated}
                                                entityArrangement={entityArrangement}
                                                folderArrangement={folderArrangement}
                                                setHashUpdated={setHashUpdated}
                                                renameFolderOnclick={renameFolderOnclick}
                                                unGroupFolderItemsOnclick={
                                                    unGroupFolderItemsOnclick
                                                }
                                                setNewLoader={setNewLoader}
                                                setSortedItems={setSortedItems}
                                                setOrderSaved={setOrderSaved}
                                                reorderByCompany={reorderByCompany}
                                                companyArrangement={companyArrangement}organizeData={organizeData}reorderArrangementData={reorderArrangementData}
                                                setDuplicateSceneModal={setDuplicateSceneModal}
                                                setCurrentSceneName={setCurrentSceneName}
                                                updateTabCount={updateTabCount}
                                                tabKey={"shared"}
                                                projects={projects}
                                                />
                                                 {sharedSceneLoader && searchValue == "" && <div className="justify-in-center" style={{width: '100%'}}>
                                                    <p className="empty-section-text manrope f-16 black-55">
                                                    Please wait while we load all your scenes.
                                                    <LoadingOutlined style={{fontSize: 24, marginLeft: 14}}/>
                                                    </p>
                                                </div>}
                                                </>
                                        ):
                                        <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:'-webkit-center'}}>
                                            <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Spaces.svg"/>
                                            <p className="empty-section-text manrope f-14 black-55">
                                                Nothing to show
                                            </p>
                                            <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
                                                There are no scenes that have been shared with you.
                                            </p>

                                        </div>
                                        }
                                    </TabPane>

                                    <TabPane forceRender={true} tab={"Incomplete Scenes " + (!incompleteSceneLoader ? `(${getSceneTabCount('incomplete')}${searchValue != '' ? ` of ${getScenesLength('incomplete')} scenes` : ``})` : '')} key={"incomplete"}  style={{padding: "10px"}}>
                                    { getScenesLength('incomplete') != 0 ? (
                                        <>
                                            <ScenesList 
                                            tabChangeThroughClick={tabChangeThroughClick}
                                            setTabChangeThroughClick={setTabChangeThroughClick}
                                            checkFilteredResults={checkFilteredResults}
                                            switchTabIfEmpty={switchTabIfEmpty}
                                            type='incomplete'
                                            ownedSceneLoader={incompleteSceneLoader}
                                            libraryScenes={getScenes('incomplete')} 
                                            searchValue={searchValue}
                                            sceneTab={sceneTab}
                                            setNewLoader={setNewLoader}
                                            setCurrentSceneName={setCurrentSceneName}
                                            saveOrderedItems={true}
                                            setDeleteModal={triggerDeleteModel}
                                            updateTabCount={updateTabCount}
                                            tabKey={"incomplete"}
                                            projects={projects}
                                            />
                                            {incompleteSceneLoader && searchValue == "" && <div className="justify-in-center" style={{width: '100%'}}>
                                            <p className="empty-section-text manrope f-16 black-55">
                                            Please wait while we load all your scenes.
                                            <LoadingOutlined style={{fontSize: 24, marginLeft: 14}}/>
                                            </p>
                                        </div>}
                                        </>
                                        ) :
                                        <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:'-webkit-center'}}>
                                            <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Spaces.svg"/>
                                            <p className="empty-section-text manrope f-14 black-55">
                                                Nothing to show
                                            </p>
                                            <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
                                                There are no incomplete scenes.
                                            </p>
                                        </div>
                                        }
                                    </TabPane>

                                    {(localStorage.getItem("is_msprovider") === 'true' ? true : false) && localStorage.getItem('managed_customer_username') != null ? 
                                    <TabPane forceRender={true} tab={localStorage.getItem('managed_customer_username') + `'s Incomplete Scenes` + `(${getSceneTabCount('customer_incomplete')}${searchValue != '' ? ` of ${getScenesLength('customer_incomplete')} scenes` : ``})`} key={"customer_incomplete"}  style={{padding: "10px"}}>
                                    
                                    <Row className="product-tab-content-scrollable" type="flex" style={{ marginTop: "10px", justifyContent: "start" }}>
                                    <ScenesList
                                            tabChangeThroughClick={tabChangeThroughClick}
                                            setTabChangeThroughClick={setTabChangeThroughClick}
                                            checkFilteredResults={checkFilteredResults}
                                            switchTabIfEmpty={switchTabIfEmpty}
                                            type='incomplete'
                                            ownedSceneLoader={incompleteSceneLoader}
                                            libraryScenes={getScenes('customer_incomplete')} 
                                            searchValue={searchValue}
                                            sceneTab={sceneTab}
                                            setNewLoader={setNewLoader}
                                            setCurrentSceneName={setCurrentSceneName}
                                            saveOrderedItems={true}
                                            setDeleteModal={triggerDeleteModel}
                                            updateTabCount={updateTabCount}
                                            tabKey={"customer_incomplete"}
                                            projects={projects}
                                            />
                                    </Row>
                                    </TabPane> : ''}
                                </Tabs>
                                :
                                <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:'-webkit-center'}}>
                                    <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Spaces.svg"/>
                                    <p  className="empty-section-text manrope f-14 black-55">
                                                Nothing to show
                                            </p>
                                    <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
                                        There are no scenes. To get started create a new scene.
                                    </p>
                                    <Tooltip title={<span className="manrope f-12 white">Create New Scene</span>}>
                                        <Dropdown
                                            overlay={create_new_scene_options()}
                                            trigger={["click"]}>
                                            <Button className='new-product-btn black f-14'>
                                                <PlusCircleFilled />&nbsp;New Scene
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                </div>
                                }
                            </Col>
                            ):(
                            <Col span={24}>
                                <div className="product-tab library">
                                    <Row
                                    className="product-tab-content-scrollable"
                                    type="flex"
                                    style={{
                                    paddingTop: "20px",
                                    justifyContent: "start",
                                    }}
                                >   
                                <ScenesList
                                    tabChangeThroughClick={tabChangeThroughClick}
                                    setTabChangeThroughClick={setTabChangeThroughClick}
                                    checkFilteredResults={checkFilteredResults}
                                    switchTabIfEmpty={switchTabIfEmpty}
                                    sequence_id_other={SEQUENCE_ID_OTHER}
                                    sequence_id_owned={SEQUENCE_ID_OWNED}
                                    setOrderUpdated={setOrderUpdated}
                                    addToStore={openTemplateModal}
                                    type='folder'
                                    managedCustomersExist={managedCustomersExist}
                                    selectedCollection={selectedCollection}
                                    setSceneCompanyID={setSceneCompanyID}
                                    setSelectedCollection={setSelectedCollection}
                                    moveToCustomerSceneModal={moveToCustomerSceneModal}
                                    setMoveToCustomerSceneModal={setMoveToCustomerSceneModal}
                                    selectable={selectable}
                                    onboarding={onboarding}
                                    selectedSceneIds = {selectedSceneIds}
                                    setSelectedSceneIds={setSelectedSceneIds}
                                    libraryScenes={folderScenes} 
                                    searchValue={searchValue}
                                    setDeleteSceneModal={setDeleteSceneModal}
                                    setCurrentScene={setCurrentScene}
                                    setCurrentSceneCustomer={setCurrentSceneCustomer}
                                    folderState={folderState}
                                    enterFolderState={enterFolderState}
                                    reorderItems={reorderItems}
                                    selectedFolderScenes={selectedFolderScenes}
                                    setSelectedFolderScenes={setSelectedFolderScenes}
                                    saveOrderedItems={saveOrderedItems}
                                    setSaveOrderedItems={setSaveOrderedItems}
                                    sceneTab={sceneTab}
                                    hashUpdated={hashUpdated}
                                    entityArrangement={entityArrangement}
                                    folderArrangement={folderArrangement}
                                    setHashUpdated={setHashUpdated}
                                    renameFolderOnclick={renameFolderOnclick}
                                    unGroupFolderItemsOnclick={
                                        unGroupFolderItemsOnclick
                                    }
                                    setNewLoader={setNewLoader}
                                    setSortedItems={setSortedItems}
                                    setOrderSaved={setOrderSaved}
                                    reorderByCompany={reorderByCompany}
                                    companyArrangement={companyArrangement}
                                    organizeData={organizeData}
                                    reorderArrangementData={reorderArrangementData}
                                    setDuplicateSceneModal={setDuplicateSceneModal}
                                    setCurrentSceneName={setCurrentSceneName}
                                    foldersListExceptCurrentFolder={foldersListExceptCurrentFolder}
                                    projects={projects}
                                    />
                                </Row>
                                </div>
                            </Col>
                            )}
                            {showBottomNavBar && (
                            <div className="fixed-row-2">
                                <Row
                                 className='row-margins-2'
                                >
                                    <Col span={18} className="justify-in-end">
                                        <Button
                                            onClick={moveOutOfReorderState}
                                            className="modal-okay-gray square font-14 mb-20"
                                        >
                                            Cancel
                                        </Button>
                                    {reorderItems ? <>
                                    {folderState ? '' :
                                    <Button
                                        disabled={
                                        selectedFolderScenes.length == 0 ||
                                        folderState
                                        }
                                        style={{ marginLeft: "5px", marginBottom: 20 }}
                                        onClick={createNewFolder}
                                        className="modal-okay-invert square-disabled font-14"
                                    >
                                        Create New Folder
                                    </Button>}
                                    <Button
                                        disabled={
                                        selectedFolderScenes.length == 0 || folderArrangement.length == 0
                                        || folderState && foldersListExceptCurrentFolder.length == 0
                                        }
                                        style={{ marginLeft: "5px", marginBottom: 20 }}
                                        onClick={moveToFolderOnClick}
                                        className="modal-okay-invert square-disabled font-14"
                                    >
                                        Move To Folder
                                    </Button>
                                    {searchValue? "" : 
                                    <Button
                                        disabled={!orderUpdated}
                                        style={{ marginLeft: "5px", marginBottom: 20 }}
                                        onClick={() => setReorderByModal(true)}
                                        className="modal-okay square font-14"
                                    >
                                        Reorder
                                    </Button>}
                                    </> :    
                                    <Tooltip
                                        title={
                                        selectable && selectedSceneIds.length == 0 ? (
                                            <span className="manrope f-12 white">
                                            Share selected scenes
                                            </span>
                                        ) : ("")}>
                                        <span>
                                        <Button
                                            disabled={selectedSceneIds.length == 0}
                                            style={{
                                            marginLeft: "5px",
                                            marginBottom: 20,
                                            }}
                                            onClick={() => setSharingModal(true)}
                                           className="modal-okay square font-14"
                                        >
                                            Share{" "}
                                            {selectable
                                            ? `(${selectedSceneIds.length}) ${selectedSceneIds.length > 1 ? 'Scenes' : 'Scene'}`
                                            : ``}
                                        </Button>
                                        </span>
                                    </Tooltip>}
                                    </Col>
                                </Row>
                            </div>
                            )}
                        </span>
                        )}
                    </div>
                </div>
            </CustomerMainLayout>

            <Modal
                className="modal-share-success"
                width={600}
                centered={true}
                visible={viewUnGroupFolderModal}
                closable={false}
                footer={[
                <span className="justify-in-center">
                    <Button
                    key="back"
                    style={{ textAlign: "center" }}
                    className="modal-okay-gray square font-12"
                    onClick={() => setViewUnGroupFolderModal(false)}
                    >
                    Cancel
                    </Button>
                    <Button
                    disabled={actionLoader}
                    key="back"
                    style={{ textAlign: "center" }}
                    className="modal-okay square font-12"
                    onClick={() => unGroupFolderItemsConfirm()}
                    >
                    Confirm {actionLoader && <LoadingOutlined/>}
                    </Button>
                </span>,
                ]}
            >
                <div
                style={{
                    margin: "30px auto 0px auto",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                >
                <h2 className="manrope f-16 black-00 w-600">
                    Are you sure you want to delete this folder?
                </h2>
                <p className="manrope f-14 black-55">
                    This will unstack all your folder items to the root folder
                </p>
                </div>
            </Modal>

            <Modal
                visible={viewRenameFolderModal}
                onCancel={() => setViewRenameFolderModal(false)}
                className="modal-share"
                footer={[
                <div className="justify-in-end">
                    <Button
                    key="back"
                    className="modal-okay-gray square font-12"
                    onClick={() => setViewRenameFolderModal(false)}
                    >
                    Cancel
                    </Button>
                    <Button
                    disabled={actionLoader}
                    key="back"
                    className="modal-okay square font-12"
                    style={{ marginRight:8 }}
                    onClick={renameFolderConfirm}
                    >
                    Confirm {actionLoader && <LoadingOutlined/>}
                    </Button>
                </div>,
                ]}
            >
                <div style={{ padding: "16px 16px 0 16px" }}>
                <h2 className="manrope f-18 black-00 w-600">Rename Folder</h2>
                <p
                    className="manrope f-14 grey-6b"
                    style={{ marginBottom: 5, marginTop: 25 }}
                >
                    Enter New Folder Name{" "}
                </p>
                <Input
                    value={folderNewName}
                    onChange={(e) => {
                    onChangeNewFolderName(e.target.value);
                    }}
                    className="manrope f-12 grey-6b folder-name-input"
                    type="text"
                    placeholder="Type here"
                />
                {renameError?          
                <div className="manrope w-500 f-12" style={{ marginTop: 20 }}>
                    <p style={{ color: "#E54547", marginBottom:0 }}>{renameError}</p>
                </div> :""}
                </div>
            </Modal>

            <Modal
                visible={newFolder}
                onCancel={() => setNewFolder(false)}
                className="modal-share"
                footer={[
                <div className="justify-in-end">
                    <Button
                    key="back"
                    className="modal-okay-gray square font-12"
                    onClick={() => setNewFolder(false)}
                    >
                    Cancel
                    </Button>
                    <Button
                    disabled={actionLoader}
                    key="back"
                    className="modal-okay square font-12"
                    style={{ marginRight:8 }}
                    onClick={createNewFolderConfirm}
                    >
                    Confirm {actionLoader && <LoadingOutlined/>}
                    </Button>
                </div>,
                ]}
            >
                <div style={{ padding: "16px 16px 0 16px" }}>
                <h2 className="manrope f-18 black-00 w-600">Create New Folder</h2>
                <p
                    className="manrope f-14 grey-6b"
                    style={{ marginBottom: 5, marginTop: 25 }}
                >
                    Folder name{" "}
                </p>
                <Input
                    onChange={(e) => {
                    onChangeFolderName(e.target.value);
                    }}
                    className="manrope f-12 grey-6b folder-name-input"
                    type="text"
                    placeholder="Type here"
                />
                {errorMessage?          
                <div className="manrope w-500 f-12" style={{ marginTop: 20 }}>
                    <p style={{ color: "#E54547", marginBottom:0 }}>{errorMessage}</p>
                </div> :""}
                </div>
            </Modal>

            <Modal
                visible={existingFolder}
                onCancel={() => setExistingFolder(false)}
                className="modal-share"
                footer={[
                <div className="justify-in-end">
                    <Button
                    className="modal-okay-gray square font-12"
                    onClick={() => setExistingFolder(false)}
                    >
                    Cancel
                    </Button>
                    <Button
                    disabled={actionLoader}
                    className="modal-okay square font-12"
                    style={{ marginRight:8 }}
                    onClick={moveToFolderConfirm}
                    >
                    Confirm {actionLoader && <LoadingOutlined/>}
                    </Button>
                </div>,
                ]}
            >
                <div style={{ padding: "16px 16px 0 16px" }}>
                <h2 className="manrope f-18 black-00 w-600">
                    Move Scene(s) To Folder
                </h2>
                <Form className="input-form-2" onSubmit={() => { }}>
                    <Form.Item name="collection_id" colon={false}>
                    <Select
                        showSearch
                        onChange={(value) => {
                        setSelectedFolder(value);
                        }}
                        placeholder="Select Folder"
                    >
                        {folderState ? foldersListExceptCurrentFolder.map((folderInfo,index) => (
                        <Option key={index} value={folderInfo.folder_id}>
                            {folderInfo.folder_name}
                        </Option>)) :folderArrangement.map((folderInfo, index) => (
                        <Option key={index} value={folderInfo.folder_id}>
                            {folderInfo.folder_name}
                        </Option>
                        ))}
                    </Select>
                    </Form.Item>
                </Form>
                </div>
            </Modal>

            <Modal
                visible={reorderByModal}
                onCancel={closeReorderByModal}
                className="modal-share"
                footer={[
                <div className="justify-in-end">
                    <Button
                    className="modal-okay-gray square font-12"
                    htmlType="submit"
                    onClick={closeReorderByModal}
                    >
                    Cancel
                    </Button>
                    <Button
                    disabled={saveOrderedItems}
                    className="modal-okay square font-12"
                    style={{ marginRight:8 }}
                    onClick={saveArrangements}
                    >
                    Reorder {actionLoader && <LoadingOutlined/>}
                    </Button>
                </div>,
                ]}
            >
                {saveByCompany && sceneTab == "owned" ? (
                <div style={{ padding: "16px 16px 0 16px" }}>
                <h2 className="manrope f-18 black-00 w-600">
                    Do you want to reorder your data by company or by user?
                </h2>
                {!folderState && <p className="blue-note-bg">
                   <span className="scene-note flag">
                        Note: <span style={{color:"#333333"}}>Scenes in {sceneTab == "owned" ? "'Your Scenes'": "'Other Scenes'"} tab will be reordered since that tab is selected.</span>
                    </span>
                </p>}
                <Radio.Group
                    defaultValue={ sceneTab == "owned" ? companyArrangement : false}
                    onChange={changeOrderBySelection}
                    className="modal-radio-text"
                >
                    <Radio
                    className="manrope f-12 black-55"
                    value={true}
                    style={{ marginBottom: "8px" }}
                    >
                    By Company
                    </Radio>
                    <br />
                    <Radio
                    className="manrope f-12 black-55"
                    value={false}
                    style={{ marginBottom: "8px" }}
                    >
                    By User
                    </Radio>
                </Radio.Group>
                </div>) : (
                <div style={{ padding: "16px 16px 0 16px" }}>
                <h2 className="manrope f-18 black-00 w-600">
                    Do you want to reorder your data?
                </h2>
                {!folderState && <p className="blue-note-bg">
                    <span className="scene-note flag">
                        Note: <span style={{color:"#333333"}}>Scenes in {sceneTab == "owned" ? "'Your Scenes'": "'Other Scenes'"} tab will be reordered since that tab is selected.</span>
                    </span>
                </p>}
                
                </div>
                )}
            </Modal>

            <TemplateRequestModal
             submit_button_loader={addToStoreLoader}
             scene_id={sceneID}
             renders={renders}
             space_categories={spaceCategories}
             visible={templateModal}
             onCancel={cancelTemplateRequest}
             onOk={sendTemplateRequest}
             getting_details_loader={loadingDetails}
             platform={platform}
            />
    
            <BatchSharingModal
                entityType='scene'
                modalVisible={sharingModal}
                setSharingModal={setSharingModal}
                getEntityIds={getSelectedSceneIds}
              />
            <SuccessModal
             visible={successModal}
             heading={"Scene(s) Shared Successfully!"}
             text={"Your scene(s) have been shared with the customer successfully!"}
             footer={[
                <div className='justify-in-center'>
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-16" onClick={() => setSuccessModal(false)}>
                    Okay
                </Button>
                </div>
             ]}
            />
            {/* Modal for Duplicate scene assets wait */}
            <SuccessModal
             visible={duplicateSceneWaitModal}
             heading={"Duplicate Scene Requested!"}
             text={"You have successfully requested a duplicate of the scene. Scene imagery are being replicated which can take upto a minute."}
             footer={[
                <div className='justify-in-center'>
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-16" 
                onClick={() => {
                        setDuplicateSceneWaitModal(false);
                        window.location.href = '/my_scenes'
                    }}>
                    Okay
                </Button>
                </div>
             ]}
            />
            <Modal
                className="modal-share-success"
                width={600}
                visible={deleteSceneModal}
                closable={false}
                footer={[
                    <span className="justify-in-center">
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={() => setDeleteSceneModal(false)}>
                            Cancel
                        </Button>
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => deleteScene()}>
                            {deleteSceneLoader? <span> Deleting <LoadingOutlined spin /></span>:<span> Okay </span>}
                        </Button>
                    </span>
                ]}
                centered={true}
                > 
                <div style={{margin:"30px auto 0px auto",textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                    <h2 className="manrope f-16 black-00 w-600">You're about to delete a Scene</h2>
                    {localStorage.getItem('company_id') == sceneCompanyID ?
                        <p className="manrope f-14 black-55">This scene and all of it's assets like lifestyle images, 360s and 3D tours will be deleted for all the users of your company.</p>
                    :
                        <p className="manrope f-14 black-55">Deleting this scene would delete all assets generated with it like lifestyle images, 360s and 3D tours.</p>
                    }
                    {
                        tabActiveKey == "owned" ? 
                        <p className="manrope f-14 black-55">The space used in this scene will also be deleted if it is not being used in any other scene</p>
                        : ''
                    }
                </div>           
            </Modal>

            <Modal
                visible={duplicateSceneModal}
                onCancel={() => {
                    if(!duplicateSceneLoader){
                        setDuplicateSceneModal(false)  
                    }}}
                width={700}
                className="modal-space"
                bodyStyle={{padding: 0}}
                maskClosable={false}
                footer={[
                    <div className="justify-in-end">
                        <Button className="modal-okay-gray square font-12" key="back" onClick={() => setDuplicateSceneModal(false)} disabled={duplicateSceneLoader}>
                        Cancel
                        </Button>
                        <Button className="modal-okay square font-12" key="ok" onClick={duplicateScene} disabled={duplicateSceneLoader}>
                            Duplicate Scene {duplicateSceneLoader ? <LoadingOutlined /> : ''}
                        </Button>
                    </div>
                ]}>
                <Form
                    form={duplicate_form}
                    initialValues={{
                        scene_name: currentSceneName,
                        replicate_assets: replicateAssets
                    }}
                >
                    <div style={{padding:"30px",display:"flex"}}>
                        <Col span={24}>
                            <h2 className="modal-heading manrope f-14 black-00 w-600">Add Scene Name</h2>
                            <h5 className='manrope f-12 bg grey' style={{lineHeight: 'inherit'}}><InfoCircleOutlined/>&nbsp;This will be the name of your scene. The duplicated scene will appear in 'Your Scenes' tab.</h5>
                            <h5 className='manrope f-12 bg grey' style={{lineHeight: 'inherit'}}><InfoCircleOutlined/>&nbsp;A copy of the space being used in this scene will be created and added to your space library.</h5>
                            <Form.Item
                                name="scene_name"
                                rules={[{ required: true, message: 'Please add scene name.' },
                                {
                                    validator: async(_, value) => {
                                      // if scene with same name exists
                                      if(value){
                                        let isSceneDuplicate = isSceneExist(value);
                                        if (isSceneDuplicate) {
                                            throw new Error('You already have a scene with same name!');
                                        }
                                    }
                                    }
                                }]}
                            >
                                <Input onChange={changeCurrentSceneName} className='manrope f-12 black-55' placeholder='Enter Scene Name' style={{width: "100%",height:"40px"}}/>

                            </Form.Item>
                            <Form.Item>
                                <Checkbox name="replicate_assets" className="black-checkbox manrope f-12 black-55" onChange={e => setReplicateAssets(e.target.checked)}>
                                    Copy Lifestyles, 360s and 3D Tour
                                </Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox name="duplicate_space" className="black-checkbox manrope f-12 black-55" onChange={e => setDuplicateSpace(e.target.checked)}>
                                    Create a copy of the space used
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </div>
                </Form>
            </Modal>

            <Modal
            visible={moveToCustomerSceneModal}
            className="modal-share"
            width={620}
            closable={false}
            maskClosable={false}
            destroyOnClose={true}
            onCancel={cancelMoveToCustomerSceneModal}
            footer={[
                <div className="justify-in-end">
                    <Button disabled={buttonLoader} className="modal-okay-gray square font-12" onClick={cancelMoveToCustomerSceneModal}>
                        Cancel
                    </Button>
                    <Button disabled={buttonLoader} className="modal-okay square font-12" onClick={() => document.getElementById("move-scene").click()}>
                        Set&nbsp;{buttonLoader ? <LoadingOutlined/> : ''}
                    </Button>
                </div>
            ]}>
            <div style={{padding:"16px 16px 0 16px"}}>
                <div className="justify-in-start" style={{marginBottom: 20}}>
                    <h2 className="manrope f-16 black-14 w-600">Choose the customer who you want to set this managed scene for</h2>
                </div>
                    
                <Form 
                onFinish={updateScene}
                layout="vertical">
                    <Row type='flex' gutter={12}>
                        <Col span={24}>
                            <Form.Item label={''} name="selected_customer" rules={[{ required: true,  message: 'Please select customers.' }]}>
                                <Select showArrow  onChange={onChangeCustomer} showSearch disabled={buttonLoader} className="manrope f-12 black-55 tree-select-material f-14" placeholder="Search Customers">
                                    {managedCustomers.map((customer, index) => {
                                        return <Option value={customer.customer_username} className="manrope f-12 select-view">{customer.customer_username}&nbsp;
                                            <div className="manrope f-12 black-55 w-600">{customer.company_name}</div>
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>        
                        <Button id="move-scene" type="primary" htmlType="submit" style={{"display": "none"}}>
                            Set
                        </Button>
                    </Row>
                </Form>
            </div>
            </Modal>

        <Modal
            className="silo-success-modal"
            width={800}
            centered={true}
            visible={deleteModal}
            closable={false}
            footer={[
              <span className="justify-in-center">
                  <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={() => setDeleteModal(false)}>
                      Cancel
                  </Button>
                  <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => onDelete()}>
                      {deleteLoader? <span> Deleting <LoadingOutlined spin /></span>:<span> Okay </span>}
                  </Button>
              </span>
          ]}
          > 
            <div style={{margin:"30px auto 0px auto",textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                <h2 className="manrope f-16 black-00 w-600">{'You are about to delete a Scene Request'}</h2>
                <p className="manrope f-14 black-55">Are you sure you want to delete this request?</p>
            </div>   
        </Modal>
        </div>
    );
}

const mapStateToProps = state => state;
const mapDispatchToProps = (dispatch) => ({
  listScenes: (payload) => {
    dispatch(listScenes(payload));
  },
  getArrangements: (payload) => {
    dispatch(getArrangements(payload));
  },
  reorderData: (payload) => {
    dispatch(reorderData(payload));
  },
  createFolder: (payload) => {
    dispatch(createFolder(payload));
  },
  moveToFolder: (payload) => {
    dispatch(moveToFolder(payload));
  },
  renameFolder: (payload) => {
    dispatch(renameFolder(payload));
  },
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(CustomerScenes)
)
