import { InfoCircleOutlined } from "@ant-design/icons";
import ENVIRONMENT from "./environments";
import React from 'react';
import { Tooltip } from "antd";

const FileConstants = new Object();

FileConstants.init = () => {

    FileConstants.cameraNearDefaultValue = 0.01;
    FileConstants.modelRequestTokens = 10;
    FileConstants.UploadValidationMessage = {
        0: "Your uploaded file contains multiple 3D models or representing different states of the model. We took the liberty of showing you one model. Next time please only upload one 3D model or one state or SKU.",
        1: "Model validation and render generation has been done sucessfully. Please review the item.",
        2: "The images with the materials were not found. The only way to process this is to create a new model."
    }

    FileConstants.UploadValidationTitle = {
        0: "Model Validation Warning",
        1: "Model Validation Success",
        2: "Model Validation Failed"
    }

    FileConstants.lightRigUrl = {
        "white-bg-shadow-off": ENVIRONMENT.WHITE_BG_SHADOW_OFF,
        "grey-bg-shadow-off": ENVIRONMENT.GREY_BG_SHADOW_OFF,
        "white-bg-shadow-on": ENVIRONMENT.WHITE_BG_SHADOW_ON,
        "grey-bg-shadow-on": ENVIRONMENT.GREY_BG_SHADOW_ON,
        "white-bg-dropShadow-on": ENVIRONMENT.WHITE_BG_DROPSHADOW_ON
    }

    if (localStorage.getItem("is_msprovider")) {
        FileConstants.isMSProvider = localStorage.getItem("is_msprovider") === 'true' ? true : false
        FileConstants.COMPANY_ID_FOR_TEMPLATES = 274;
    }
    if (localStorage.getItem('company_id')) {
        FileConstants.COMPANY_ID = localStorage.getItem('company_id');
    }
    if (localStorage.getItem("username")) {
        FileConstants.CUSTOMER_USERNAME = localStorage.getItem("username");
    }
    if (localStorage.getItem('managed_customer_username')) {
        FileConstants.MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
    }
    if (localStorage.getItem('managed_company_id')) {
        FileConstants.MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
    }
    if (localStorage.getItem('shared_entities')) {
        FileConstants.SHARED_ENTITIES = localStorage.getItem('shared_entities');
    }
    if (localStorage.getItem('managed_customer_email')) {
        FileConstants.MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
    }
    if (localStorage.getItem('sequence_ids')) {
        FileConstants.SEQUENCE_IDS = localStorage.getItem('sequence_ids');
    }

    FileConstants.temperatureColors = {
        1000: "#ff3800",
        1200: "#ff5300",
        1400: "#ff6500",
        1600: "#ff7300",
        1800: "#ff7e00",
        2000: "#ff8912",
        2200: "#ff932c",
        2400: "#ff9d3f",
        2600: "#ffa54f",
        2800: "#ffad5e",
        3000: "#ffb46b",
        3200: "#ffbb78",
        3400: "#ffc184",
        3600: "#ffc78f",
        3800: "#ffcc99",
        4000: "#ffd1a3",
        4200: "#ffd5ad",
        4400: "#ffd9b6",
        4600: "#ffddbe",
        4800: "#ffe1c6",
        5000: "#ffffff",
        5200: "#ffe8d5",
        5400: "#ffebdc",
        5500: "#ffffff",
        5800: "#fff0e9",
        6000: "#fff3ef",
        6200: "#fff5f5",
        6400: "#fff8fb",
        6600: "#fef9ff",
        6800: "#f9f6ff",
        7000: "#f5f3ff",
        7200: "#f0f1ff",
        7400: "#edefff",
        7600: "#e9edff",
        7800: "#e6ebff",
        8000: "#e3e9ff",
        8200: "#e0e7ff",
        8400: "#dde6ff",
        8600: "#dae4ff",
        8800: "#d8e3ff",
        9000: "#d6e1ff",
        9200: "#d3e0ff",
        9400: "#d1dfff",
        9600: "#cfddff",
        9800: "#cedcff",
        10000: "#ccdbff"
    };

    FileConstants.creative_commons_text = [
        {
            'name': 'Public Domain',
            'description': 'Public domain is the purest form of open/free, since no one owns or controls the material in any way. You give the users the license to anything with your work or 3D model',
            'icon': '/img/license_1.png'
        },
        {
            'name': 'Attribution License',
            'description': 'This means that you let other users use the model only if they give you attribution - Other uses include others copy, distribute, display, and perform your copyrighted work - and derivative works based upon it - but only if they give you credit. ie it is your model when used anywhere',
            'icon': '/img/license_2.png'

        },
        {
            'name': 'Attribution - NonDerivs License',
            'description': 'This means the user give your credit and attribution if the model is as if they make  any changes to the model no attribution to you.',
            'icon': '/img/license_3.png'

        },
        {
            'name': 'Attribution - NonCommercial-NonDerivs License',
            'description': 'You let others copy, distribute, display, and perform only verbatim copies of your work/model, not derivative works based upon it - but for noncommercial purposes only.',
            'icon': '/img/license_4.png'

        },
        {
            'name': 'Attribution - NonCommercial License',
            'description': 'You let others copy, distribute, display, and perform your copyrighted work - and derivative works based upon it - but only if they give you credit - only for noncommercial purposes only.',
            'icon': '/img/license_5.png'
        },
        {
            'name': 'Attribution - NonCommercial-ShareAlike License',
            'description': 'You allow others to distribute derivative works only under a license identical to the license that governs your work- but for non commercial purposes only',
            'icon': '/img/license_6.png'
        },
        {
            'name': 'Attribution - ShareAlike License',
            'description': 'You allow others to distribute derivative works only under a license identical to the license that governs your work.',
            'icon': '/img/license_7.png'
        },
    ];

    FileConstants.getLicensingIcon = (license) => {
        let icons = {
            'Public Domain': '/img/license_1.png',
            'Attribution License': '/img/license_2.png',
            'Attribution - NonDerivs License': '/img/license_3.png',
            'Attribution - NonCommercial-NonDerivs License': '/img/license_4.png',
            'Attribution - NonCommercial License': '/img/license_5.png',
            'Attribution - NonCommercial-ShareAlike License': '/img/license_6.png',
            'Attribution - ShareAlike License': '/img/license_7.png',
            'forSale': '/img/for_sale.png'
        }
        return icons[license]
    };

    FileConstants.DescriptionText = {
        1: "Items that have not been worked on yet. Please review all details, create a model and upload.",
        2: "Items rejected by Admin or Customer. Please review rejection details and make fixes.",
        3: "Items that the system has checked and created test renders for. Please review and submit your models.",
        4: "Items being processed by the ALL3D system.",
        5: "Items being reviewed by Admins of ALL3D",
        6: "Items whose automation job has failed or been aborted."
    }

    FileConstants.highres_usecases = [
        'Product Photography',
        'Lifestyle Photography'
    ];

    FileConstants.ar_usecases = [
        'AR',
        'Customization of Product (Web)',
        'Customization of Product (AR)'
    ];

    FileConstants.highResModelApplications = [
        {
            'value': 'perspective_images',
            'display': 'Product Perspective Photos'
        },
        {
            'value': 'pre-rendered-360',
            'display': 'Pre-Rendered Product 360'
        },
        {
            'value': 'mp4',
            'display': 'Mp4 Video'
        },
        {
            'value': 'dimensional_image',
            'display': 'Dimensional Image'
        }
    ];

    FileConstants.arModelApplications = [
        {
            'value': 'ar',
            'display': 'AR Model (GLB)'
        }
    ];


    FileConstants.viewOutsideImages = [
        {
            'name': 'Plain Sunight (Default)',
            'image': ENVIRONMENT.STATIC_FILES_BASE_URL + 'outside-plain.JPG'
        },
        {
            'name': 'Countryside',
            'image': ENVIRONMENT.STATIC_FILES_BASE_URL + 'outside3.JPG'
        },
        {
            'name': 'Garden',
            'image': ENVIRONMENT.STATIC_FILES_BASE_URL + 'outside4.JPG'
        }
    ];

    FileConstants.getUser = (value) => {
        let user_type_dict = {
            'admin': 'Admin',
            'artist': 'Artist',
            'customer': 'Customer',
            'msprovider': 'Managed Service Provider',
            'superartist': 'SuperArtist',
            'superadmin': 'SuperAdmin',
            'test_artist': 'Artist',
            'agency_admin': 'Artist'
        }

        return user_type_dict[value];
    }

    FileConstants.setRequestTypeText = (value, summaryState = false) => {
        let request_type_labels = {
            'product_silo': summaryState ? 'Total Silo Images' : 'Silo Image',
            'product_silo_4k': 'Silo Image (4K)',
            'product_silo_3k': 'Silo Image (3K)',
            'product_silo_2k': 'Silo Image (2K)',
            'product_silo_1k': 'Silo Image (1K)',
            'product_silo_0k': 'Silo Image (Custom)',
            'managed_product_silo': summaryState ? 'Total Managed Silo Images' : 'Managed Silo Image',
            'managed_product_silo_4k': 'Managed Silo Image (4K)',
            'managed_product_silo_3k': 'Managed Silo Image (3K)',
            'managed_product_silo_2k': 'Managed Silo Image (2K)',
            'managed_product_silo_1k': 'Managed Silo Image (1K)',
            'managed_product_silo_0k': 'Managed Silo Image (Custom)',
            'bulk_product_silo': summaryState ? 'Total Bulk Silo Images' : 'Bulk Silo Image',
            'bulk_product_silo_4k': 'Bulk Silo Image (4K)',
            'bulk_product_silo_3k': 'Bulk Silo Image (3K)',
            'bulk_product_silo_2k': 'Bulk Silo Image (2K)',
            'bulk_product_silo_1k': 'Bulk Silo Image (1K)',
            'bulk_product_silo_0k': 'Bulk Silo Image (Custom)',
            'material_swatch_silo': 'Material Swatch for Silos',

            'product_configuration_silo': 'Product Configuration Renders',

            'product_video': summaryState ? 'Total Product MP4 Videos' : 'Product MP4 Video',
            'product_video_1k': 'Product MP4 Video',
            'product_video_4k': 'Product MP4 Video',
            'product_dimensional': summaryState ? 'Total Product Dimensional Images' : 'Product Dimensional Image',
            'product_360_spin': summaryState ? 'Total Product 360 Spins' : 'Product 360 Spin',

            'managed_product_video': summaryState ? 'Total Managed Product MP4 Videos' : 'Managed Product MP4 Video',
            'managed_product_video_1k': 'Managed Product MP4 Video',
            'managed_product_video_4k': 'Managed Product MP4 Video',
            'managed_product_dimensional': summaryState ? 'Total Managed Product Dimensional Images' : 'Managed Product Dimensional Image',
            'managed_product_360_spin': summaryState ? 'Total Managed Product 360 Spins' : 'Managed Product 360 Spin',

            'product_dimensional_1k': 'Product Dimensional (1K)',
            'product_dimensional_2k': 'Product Dimensional (2K)',
            'product_dimensional_3k': 'Product Dimensional (3K)',
            'product_dimensional_4k': 'Product Dimensional (4K)',

            'product_360_spin_2k': 'Product 360 Spin (2K)',
            'product_360_spin_4k': 'Product 360 Spin (4K)',
            'product_360_spin_1k': 'Product 360 Spin (1K)',

            'managed_product_dimensional_1k': 'Managed Product Dimensional (1K)',
            'managed_product_dimensional_2k': 'Managed Product Dimensional (2K)',
            'managed_product_dimensional_3k': 'Managed Product Dimensional (3K)',
            'managed_product_dimensional_4k': 'Managed Product Dimensional (4K)',

            'managed_product_360_spin_2k': 'Managed Product 360 Spin (2K)',
            'managed_product_360_spin_4k': 'Managed Product 360 Spin (4K)',
            'managed_product_360_spin_1k': 'Managed Product 360 Spin (1K)',

            'product_model_ar': 'Product Model Creation (AR)',
            'product_model_high_res': 'Product Model Creation (High Res)',
            'product_model_high_res_and_ar': 'Product Model Creation (AR and High Res)',
            'product_model_high_pbr_and_ar': 'Product Model Creation (High Res + PBR and AR)',
            'product_model_high_pbr': 'Product Model Creation (High Res + PBR)',
            'existing_product_model_fix_ar': 'User Uploaded Model Fix (AR)',
            'existing_product_model_fix_high_res': 'User Uploaded Model Fix (High Res)',
            'existing_product_model_fix_high_res_and_ar': 'User Uploaded Model Fix (High Res & AR)',
            'existing_product_model_ar': 'User Uploaded Model (AR)',
            'existing_product_model_high_res': 'User Uploaded Model (High Res)',
            'existing_product_model_fix_high_res_and_ar': 'User Uploaded Model (High Res & AR)',
            'existing_product_model': 'User Uploaded Model',
            'existing_product_model_fix': 'User Uploaded Model Fix',
            'app_product_model': 'Scanned Model Creation',
            'product_model_high_res_and_ar_beds': 'Product Model AR & High Res (Custom Bedding)',
            'product_model_high_pbr_and_ar_beds': 'Product Model High Res + PBR and AR (Custom Bedding)',
            'product_model_ar_beds': 'Product Model AR (Custom Bedding)',
            'product_model_high_res_beds': 'Product Model High Res (Custom Bedding)',
            'existing_product_model_beds': 'User Uploaded Model (Custom Bedding)',
            'product_model_bed': 'Product Model (Custom Bedding)',
            'product_model_ar_bed': 'Product Model AR (Custom Bedding)',
            'product_model_high_res_and_ar_bed': 'Product Model AR & High Res (Custom Bedding)',
            'product_model_high_pbr_and_ar_bed': 'Product Model High Res + PBR and AR (Custom Bedding)',
            'app_product_model_bed': 'Scanned Product Model (Custom Bedding)',
            'variation_product_model': 'Product Model Variation',
            'variation_product_model_': 'Product Model Variation',
            'variation_product_model_color': 'Product Model Variation (Color)',
            'variation_product_model_hardware': 'Product Model Variation (Hardware)',
            'variation_product_model_material': 'Product Model Variation (Material)',
            'variation_product_model_size': 'Product Model Variation (Size/ Dimensions)',
            'variation_product_model_bedding': 'Product Model Variation (Bedding)',
            'variation_product_model_ar_conversion': 'Product Model Variation (AR Conversion)',
            'variation_product_model_high_conversion': 'Product Model Variation (High Res Conversion)',
            'variation_product_model_other': 'Product Model Variation (Other)',
            'variation_product_model_state': 'Product Model Variation (State)',
            'existing_product_model_fix_high_pbr': 'User Uploaded Model Fix (High PBR)',
            'existing_product_model_fix_high_pbr_and_ar': 'User Uploaded Model Fix (High PBR and AR)',
            'room_model': 'Space Model Creation',
            'variation_room_model': 'Space Model Variation',
            "room_model_backyard/sideyard": "Space Model (Backyard/Sideyard)",
            "room_model_entranceway_/_foyer": "Space Model (Entranceway / Foyer)",
            "room_model_front_porch": "Space Model (Front Porch)",
            "room_model_porch": "Space Model (Porch)",
            "room_model_garage": "Space Model (Garage)",
            "room_model_attic": "Space Model (Attic)",
            "room_model_baby_nursery": "Space Model (Baby Nursery)",
            "room_model_bar": "Space Model (Bar)",
            "room_model_balcony": "Space Model (Balcony)",
            "room_model_basement": "Space Model (Basement)",
            "room_model_casita": "Space Model (Casita)",
            "room_model_closet": "Space Model (Closet)",
            "room_model_dorm_room": "Space Model (Dorm Room)",
            "room_model_driveway": "Space Model (Driveway)",
            "room_model_game_room": "Space Model (Game Room)",
            "room_model_greenhouse": "Space Model (Greenhouse)",
            "room_model_gym": "Space Model (Gym)",
            "room_model_home_exterior": "Space Model (Home Exterior)",
            "room_model_home_office": "Space Model (Home Office)",
            "room_model_kids_bedroom": "Space Model (Kids Bedroom)",
            "room_model_laundry_room": "Space Model (Laundry Room)",
            "room_model_library": "Space Model (Library)",
            "room_model_locker_room": "Space Model (Locker Room)",
            "room_model_multi-use": "Space Model (Multi-use)",
            "room_model_pantry": "Space Model (Pantry)",
            "room_model_rooftop": "Space Model (Rooftop)",
            "room_model_staircase": "Space Model (Staircase)",
            "room_model_storage_room": "Space Model (Storage Room)",
            "room_model_sunroom": "Space Model (Sunroom)",
            "room_model_theater": "Space Model (Theater)",
            "room_model_wall_/_surface": "Space Model (Wall / Surface)",


            "variation_room_model_backyard/sideyard": "Space Model Variation (Backyard/Sideyard)",
            "variation_room_model_entranceway_/_foyer": "Space Model Variation (Entranceway / Foyer)",
            "variation_room_model_front_porch": "Space Model Variation (Front Porch)",
            "variation_room_model_porch": "Space Model Variation (Porch)",
            "variation_room_model_garage": "Space Model Variation (Garage)",
            "variation_room_model_attic": "Space Model Variation (Attic)",
            "variation_room_model_baby_nursery": "Space Model Variation (Baby Nursery)",
            "variation_room_model_bar": "Space Model Variation (Bar)",
            "variation_room_model_balcony": "Space Model Variation (Balcony)",
            "variation_room_model_basement": "Space Model Variation (Basement)",
            "variation_room_model_casita": "Space Model Variation (Casita)",
            "variation_room_model_closet": "Space Model Variation (Closet)",
            "variation_room_model_dorm_room": "Space Model Variation (Dorm Room)",
            "variation_room_model_driveway": "Space Model Variation (Driveway)",
            "variation_room_model_game_room": "Space Model Variation (Game Room)",
            "variation_room_model_greenhouse": "Space Model Variation (Greenhouse)",
            "variation_room_model_gym": "Space Model Variation (Gym)",
            "variation_room_model_home_exterior": "Space Model Variation (Home Exterior)",
            "variation_room_model_home_office": "Space Model Variation (Home Office)",
            "variation_room_model_kids_bedroom": "Space Model Variation (Kids Bedroom)",
            "variation_room_model_laundry_room": "Space Model Variation (Laundry Room)",
            "variation_room_model_library": "Space Model Variation (Library)",
            "variation_room_model_locker_room": "Space Model Variation (Locker Room)",
            "variation_room_model_multi-use": "Space Model Variation (Multi-use)",
            "variation_room_model_pantry": "Space Model Variation (Pantry)",
            "variation_room_model_rooftop": "Space Model Variation (Rooftop)",
            "variation_room_model_staircase": "Space Model Variation (Staircase)",
            "variation_room_model_storage_room": "Space Model Variation (Storage Room)",
            "variation_room_model_sunroom": "Space Model Variation (Sunroom)",
            "variation_room_model_theater": "Space Model Variation (Theater)",
            "variation_room_model_wall_/_surface": "Space Model Variation (Wall / Surface)",

            "room_model_apartment": "Space Model (Apartment)",
            "room_model_kitchen": "Space Model (Kitchen)",
            "variation_room_model_apartment": "Space Model Variation (Apartment)",
            "variation_room_model_bathroom": "Space Model Variation (Bathroom)",
            "room_model_bathroom": "Space Model (Bathroom)",
            "room_model_bedroom": "Space Model (Bedroom)",
            "variation_room_model_bedroom": "Space Model Variation (Bedroom)",
            "room_model_cabin": "Space Model (Cabin)",
            "variation_room_model_cabin": "Space Model Variation (Cabin)",
            "room_model_conference_room": "Space Model (Conference Room)",
            "variation_room_model_conference_room": "Space Model Variation (Conference Room)",
            "room_model_dining_room": "Space Model (Dining Room)",
            "variation_room_model_dining_room": "Space Model Variation (Dining Room)",
            "variation_room_model_entry": "Space Model Variation (Entry)",
            "room_model_entry": "Space Model (Entry)",
            "room_model_garden": "Space Model (Garden)",
            "variation_room_model_garden": "Space Model Variation (Garden)",
            "room_model_hallway": "Space Model (Hallway)",
            "variation_room_model_hallway": "Space Model Variation (Hallway)",
            "room_model_hotel_room": "Space Model (Hotel Room)",
            "variation_room_model_hotel_room": "Space Model Variation (Hotel Room)",
            "room_model_house_(4_rooms_+,_including_kitchen)": "Space Model (House - 4 Rooms including Kitchen)",
            "variation_room_model_house_(4_rooms_+,_including_kitchen)": "Space Model Variation (House - 4 Rooms including Kitchen)",
            "variation_room_model_kitchen": "Space Model Variation (Kitchen)",
            "room_model_living_room": "Space Model (Living Room)",
            "variation_room_model_living_room": "Space Model Variation (Living Room)",
            "variation_room_model_office": "Space Model Variation (Office)",
            "room_model_office": "Space Model (Office)",
            "variation_room_model_other": "Space Model Variation (Other)",
            "room_model_other": "Space Model (Other)",
            "room_model_outdoors": "Space Model Variation (Outdoors)",
            "variation_room_model_outdoors": "Space Model Variation (Outdoors)",
            "variation_room_model_patio": "Space Model Variation (Patio)",
            "room_model_patio": "Space Model (Patio)",
            "variation_room_model_pool": "Space Model Variation (Pool)",
            "room_model_pool": "Space Model (Pool)",
            "variation_room_model_restaurant_(1_room)": "Space Model Variation (Restaurant - 1 Room)",
            "variation_room_model_restaurant_(more_than_1_room)": "Space Model Variation (Restaurant - More Than 1 Room)",
            "variation_room_model_store_(1_room)": "Space Model Variation (Store - 1 Room)",
            "variation_room_model_store_(more_than_1_room)": "Space Model Variation (Store - More Than 1 Room)",
            "variation_room_model_studio": "Space Model Variation (Studio)",
            "room_model_restaurant_(1_room)": "Space Model (Restaurant - 1 Room)",
            "room_model_restaurant_(more_than_1_room)": "Space Model (Restaurant - More Than 1 Room)",
            "room_model_store_(1_room)": "Space Model (Store - 1 Room)",
            "room_model_store_(more_than_1_room)": "Space Model (Store - More Than 1 Room)",
            "room_model_studio": "Space Model (Studio)",

            'new_material': 'New Material Creation',
            'scanned_material': 'Scanned Material Creation',

            'scene_lifestyle_image': summaryState ? 'Total Scene Lifestyle Photos' : 'Scene Lifestyle Photo',
            'scene_lifestyle_image_4k': 'Scene Lifestyle Photo (4K)',
            'scene_lifestyle_image_2k': 'Scene Lifestyle Photo (2K)',
            'scene_lifestyle_image_1k': 'Scene Lifestyle Photo (1K)',
            'scene_lifestyle_image_0k': 'Scene Lifestyle Photo (Custom)',

            'scene_lifestyle_360': summaryState ? 'Total Scene Lifestyle 360s' : 'Scene Lifestyle 360',
            'scene_lifestyle_360_4k': 'Scene Lifestyle 360 (4K)',
            'scene_lifestyle_360_2k': 'Scene Lifestyle 360 (2K)',
            'scene_lifestyle_360_1k': 'Scene Lifestyle 360 (1K)',
            'scene_lifestyle_360_0k': 'Scene Lifestyle 360 (Custom)',

            'scene_lifestyle_3d_tour': summaryState ? 'Total 3D Tours' : '3D Tour',
            'scene_lifestyle_3d_tour_4k': '3D Tour (4K)',
            'scene_lifestyle_3d_tour_2k': '3D Tour (2K)',
            'scene_lifestyle_3d_tour_1k': '3D Tour (1K)',
            'scene_lifestyle_3d_tour_0k': '3D Tour (Custom)',

            'shared_material': 'Shared Material',
            'shared_product': 'Shared Product',
            'product_added_from_store': 'Product Added from Store',
            'shared_room': 'Shared Space',
            'room_added_from_store': 'Space Added from Store',

            'managed_scene': 'Managed Scene',
            'customer_scene': 'Customer Scene',

            'flagged_lifestyle_image': 'Flagged Scene Lifestyle Photos',
            'flagged_lifestyle_image_0k': 'Flagged Scene Lifestyle Photo (Custom)',
            'flagged_lifestyle_image_1k': 'Flagged Scene Lifestyle Photo (1K)',
            'flagged_lifestyle_image_2k': 'Flagged Scene Lifestyle Photo (2K)',
            'flagged_lifestyle_image_4k': 'Flagged Scene Lifestyle Photo (4K)',

            'flag_resolved_lifestyle_image': 'Complaint Resolved Lifestyle Photos',
            'flag_resolved_lifestyle_image_0k': 'Complaint Resolved Lifestyle Photo (Custom)',
            'flag_resolved_lifestyle_image_1k': 'Complaint Resolved Lifestyle Photo (1K)',
            'flag_resolved_lifestyle_image_2k': 'Complaint Resolved Lifestyle Photo (2K)',
            'flag_resolved_lifestyle_image_4k': 'Complaint Resolved Lifestyle Photo (4K)',


            'flagged_lifestyle_360': 'Flagged Lifestyle 360s',
            'flagged_lifestyle_360_0k': 'Flagged Lifestyle 360 (Custom)',
            'flagged_lifestyle_360_1k': 'Flagged Lifestyle 360 (1K)',
            'flagged_lifestyle_360_2k': 'Flagged Lifestyle 360 (2K)',
            'flagged_lifestyle_360_4k': 'Flagged Lifestyle 360 (4k)',

            'flag_resolved_lifestyle_360': 'Complaint Resolved Lifestyle 360s',
            'flag_resolved_lifestyle_360_0k': 'Complaint Resolved Lifestyle 360 (Custom)',
            'flag_resolved_lifestyle_360_1k': 'Complaint Resolved Lifestyle 360 (1K)',
            'flag_resolved_lifestyle_360_2k': 'Complaint Resolved Lifestyle 360 (2K)',
            'flag_resolved_lifestyle_360_4k': 'Complaint Resolved Lifestyle 360 (4K)',

            'flag_resolved_lifestyle_360_shared': '(Shared) Complaint Resolved 360s',
            'flag_resolved_lifestyle_360_shared_0k': '(Shared) Complaint Resolved 360 (Custom)',
            'flag_resolved_lifestyle_360_shared_1k': '(Shared) Complaint Resolved 360 (1K)',
            'flag_resolved_lifestyle_360_shared_2k': '(Shared) Complaint Resolved 360 (2K)',
            'flag_resolved_lifestyle_360_shared_4k': '(Shared) Complaint Resolved 360 (4K)',


            'flagged_lifestyle_360_shared': '(Shared) Flagged Lifestyle 360s',
            'flagged_lifestyle_360_shared_0k': '(Shared) Flagged Lifestyle 360 (Custom)',
            'flagged_lifestyle_360_shared_1k': '(Shared) Flagged Lifestyle 360 (1K)',
            'flagged_lifestyle_360_shared_2k': '(Shared) Flagged Lifestyle 360 (2K)',
            'flagged_lifestyle_360_shared_4k': '(Shared) Flagged Lifestyle 360 (4k)',

            'flag_resolved_lifestyle_image_shared': '(Shared) Complaint Resolved Lifestyle Photos',
            'flag_resolved_lifestyle_image_shared_0k': '(Shared) Complaint Resolved Lifestyle Photos (Custom)',
            'flag_resolved_lifestyle_image_shared_1k': '(Shared) Complaint Resolved Lifestyle Photos (1K)',
            'flag_resolved_lifestyle_image_shared_2k': '(Shared) Complaint Resolved Lifestyle Photos (2K)',
            'flag_resolved_lifestyle_image_shared_4k': '(Shared) Complaint Resolved Lifestyle Photos (4K)',

            'flagged_lifestyle_image_shared': '(Shared) Flagged Lifestyle Photos',
            'flagged_lifestyle_image_shared_0k': '(Shared) Flagged Lifestyle Photos (Custom)',
            'flagged_lifestyle_image_shared_1k': '(Shared) Flagged Lifestyle Photos (1K)',
            'flagged_lifestyle_image_shared_2k': '(Shared) Flagged Lifestyle Photos (2K)',
            'flagged_lifestyle_image_shared_4k': '(Shared) Flagged Lifestyle Photos (4K)',

            'scene_lifestyle_3d_tour_shared': summaryState ? '(Shared) Total 3D Tours' : '(Shared) 3D Tour',
            'scene_lifestyle_3d_tour_shared_0k': '(Shared) 3D Tour (Custom)',
            'scene_lifestyle_3d_tour_shared_1k': '(Shared) 3D Tour (1K)',
            'scene_lifestyle_3d_tour_shared_2k': '(Shared) 3D Tour (2K)',
            'scene_lifestyle_3d_tour_shared_4k': '(Shared) 3D Tour (4K)',

            'scene_lifestyle_image_shared': summaryState ? '(Shared) Total Lifestyle Photos' : '(Shared) Lifestyle Photo',
            'scene_lifestyle_image_shared_0k': '(Shared) Lifestyle Photo (Custom)',
            'scene_lifestyle_image_shared_1k': '(Shared) Lifestyle Photo (1K)',
            'scene_lifestyle_image_shared_2k': '(Shared) Lifestyle Photo (2K)',
            'scene_lifestyle_image_shared_4k': '(Shared) Lifestyle Photo (4K)',

            'scene_lifestyle_360_shared': summaryState ? '(Shared) Total Scene 360s' : '(Shared) Scene 360',
            'scene_lifestyle_360_shared_0k': '(Shared) Scene 360 (Custom)',
            'scene_lifestyle_360_shared_1k': '(Shared) Scene 360 (1K)',
            'scene_lifestyle_360_shared_2k': '(Shared) Scene 360 (2K)',
            'scene_lifestyle_360_shared_4k': '(Shared) Scene 360 (4K)',

            'managed_service_lifestyle_image': summaryState ? 'Total Managed Lifestyle Photos' : 'Managed Lifestyle Photo',
            'managed_service_lifestyle_image_0k': 'Managed Lifestyle Photo (Custom)',
            'managed_service_lifestyle_image_1k': 'Managed Lifestyle Photo (1K)',
            'managed_service_lifestyle_image_2k': 'Managed Lifestyle Photo (2K)',
            'managed_service_lifestyle_image_4k': 'Managed Lifestyle Photo (4K)',

            'managed_service_flagged_lifestyle_image': 'Managed Flagged Lifestyle Photos',
            'managed_service_flagged_lifestyle_image_0k': 'Managed Flagged Lifestyle Photo (Custom)',
            'managed_service_flagged_lifestyle_image_1k': 'Managed Flagged Lifestyle Photo (1K)',
            'managed_service_flagged_lifestyle_image_2k': 'Managed Flagged Lifestyle Photo (2K)',
            'managed_service_flagged_lifestyle_image_4k': 'Managed Flagged Lifestyle Photo (4K)',

            'managed_service_flag_resolved_lifestyle_image': 'Managed Complaint Resolved Lifestyle Photos',
            'managed_service_flag_resolved_lifestyle_image_0k': 'Managed Complaint Resolved Lifestyle Photo (Custom)',
            'managed_service_flag_resolved_lifestyle_image_1k': 'Managed Complaint Resolved Lifestyle Photo (1K)',
            'managed_service_flag_resolved_lifestyle_image_2k': 'Managed Complaint Resolved Lifestyle Photo (2K)',
            'managed_service_flag_resolved_lifestyle_image_4k': 'Managed Complaint Resolved Lifestyle Photo (4K)',

            'managed_service_lifestyle_360': summaryState ? 'Total Managed Lifestyle 360s' : 'Managed Lifestyle 360',
            'managed_service_lifestyle_360_0k': 'Managed Lifestyle 360 (Custom)',
            'managed_service_lifestyle_360_1k': 'Managed Lifestyle 360 (1K)',
            'managed_service_lifestyle_360_2k': 'Managed Lifestyle 360 (2K)',
            'managed_service_lifestyle_360_4k': 'Managed Lifestyle 360 (4K)',

            'managed_service_flagged_lifestyle_360': 'Managed Flagged Lifestyle 360s',
            'managed_service_flagged_lifestyle_360_0k': 'Managed Flagged Lifestyle 360 (Custom)',
            'managed_service_flagged_lifestyle_360_1k': 'Managed Flagged Lifestyle 360 (1K)',
            'managed_service_flagged_lifestyle_360_2k': 'Managed Flagged Lifestyle 360 (2K)',
            'managed_service_flagged_lifestyle_360_4k': 'Managed Flagged Lifestyle 360 (4K)',

            'managed_service_flag_resolved_lifestyle_360': 'Managed Complaint Resolved Lifestyle 360s',
            'managed_service_flag_resolved_lifestyle_360_0k': 'Managed Complaint Resolved Lifestyle 360 (Custom)',
            'managed_service_flag_resolved_lifestyle_360_1k': 'Managed Complaint Resolved Lifestyle 360 (1K)',
            'managed_service_flag_resolved_lifestyle_360_2k': 'Managed Complaint Resolved Lifestyle 360 (2K)',
            'managed_service_flag_resolved_lifestyle_360_4k': 'Managed Complaint Resolved Lifestyle 360 (4K)',

            'managed_service_lifestyle_image_shared': summaryState ? '(Shared) Total Managed Lifestyle Photos' : '(Shared) Managed Lifestyle Photo',
            'managed_service_lifestyle_image_shared_0k': '(Shared) Managed Lifestyle Photo (Custom)',
            'managed_service_lifestyle_image_shared_1k': '(Shared) Managed Lifestyle Photo (1K)',
            'managed_service_lifestyle_image_shared_2k': '(Shared) Managed Lifestyle Photo (2K)',
            'managed_service_lifestyle_image_shared_4k': '(Shared) Managed Lifestyle Photo (4K)',

            'managed_service_flagged_lifestyle_image_shared': '(Shared) Managed Flagged Lifestyle Photos',
            'managed_service_flagged_lifestyle_image_shared_0k': '(Shared) Managed Flagged Lifestyle Photo (Custom)',
            'managed_service_flagged_lifestyle_image_shared_1k': '(Shared) Managed Flagged Lifestyle Photo (1K)',
            'managed_service_flagged_lifestyle_image_shared_2k': '(Shared) Managed Flagged Lifestyle Photo (2K)',
            'managed_service_flagged_lifestyle_image_shared_4k': '(Shared) Managed Flagged Lifestyle Photo (4K)',

            'managed_service_flag_resolved_lifestyle_image_shared': '(Shared) Managed Complaint Resolved Lifestyle Photos',
            'managed_service_flag_resolved_lifestyle_image_shared_0k': '(Shared) Managed Complaint Resolved Lifestyle Photo (Custom)',
            'managed_service_flag_resolved_lifestyle_image_shared_1k': '(Shared) Managed Complaint Resolved Lifestyle Photo (1K)',
            'managed_service_flag_resolved_lifestyle_image_shared_2k': '(Shared) Managed Complaint Resolved Lifestyle Photo (2K)',
            'managed_service_flag_resolved_lifestyle_image_shared_4k': '(Shared) Managed Complaint Resolved Lifestyle Photo (4K)',

            'managed_service_lifestyle_360_shared': '(Shared) Managed Lifestyle 360s',
            'managed_service_lifestyle_360_shared_0k': '(Shared) Managed Lifestyle 360 (Custom)',
            'managed_service_lifestyle_360_shared_1k': '(Shared) Managed Lifestyle 360 (1K)',
            'managed_service_lifestyle_360_shared_2k': '(Shared) Managed Lifestyle 360 (2K)',
            'managed_service_lifestyle_360_shared_4k': '(Shared) Managed Lifestyle 360 (4K)',

            'managed_service_flagged_lifestyle_360_shared': '(Shared) Managed Flagged Lifestyle 360s',
            'managed_service_flagged_lifestyle_360_shared_0k': '(Shared) Managed Flagged Lifestyle 360 (Custom)',
            'managed_service_flagged_lifestyle_360_shared_1k': '(Shared) Managed Flagged Lifestyle 360 (1K)',
            'managed_service_flagged_lifestyle_360_shared_2k': '(Shared) Managed Flagged Lifestyle 360 (2K)',
            'managed_service_flagged_lifestyle_360_shared_4k': '(Shared) Managed Flagged Lifestyle 360 (4K)',

            'managed_service_flag_resolved_lifestyle_360_shared': '(Shared) Managed Complaint Resolved Lifestyle 360s',
            'managed_service_flag_resolved_lifestyle_360_shared_0k': '(Shared) Managed Complaint Resolved Lifestyle 360 (Custom)',
            'managed_service_flag_resolved_lifestyle_360_shared_1k': '(Shared) Managed Complaint Resolved Lifestyle 360 (1K)',
            'managed_service_flag_resolved_lifestyle_360_shared_2k': '(Shared) Managed Complaint Resolved Lifestyle 360 (2K)',
            'managed_service_flag_resolved_lifestyle_360_shared_4k': '(Shared) Managed Complaint Resolved Lifestyle 360 (4K)',


            'managed_service_3d_tour_shared': summaryState ? '(Shared) Total Managed 3D Tours' : '(Shared) Managed 3D Tour',
            'managed_service_3d_tour_shared_0k': '(Shared) Managed 3D Tour (Custom)',
            'managed_service_3d_tour_shared_1k': '(Shared) Managed 3D Tour (1K)',
            'managed_service_3d_tour_shared_2k': '(Shared) Managed 3D Tour (2K)',
            'managed_service_3d_tour_shared_4k': '(Shared) Managed 3D Tour (4K)',


            'managed_service_3d_tour': summaryState ? 'Total Managed 3D Tours' : 'Managed 3D Tour',
            'managed_service_3d_tour_0k': 'Managed 3D Tour (Custom)',
            'managed_service_3d_tour_1k': 'Managed 3D Tour (1K)',
            'managed_service_3d_tour_2k': 'Managed 3D Tour (2K)',
            'managed_service_3d_tour_4k': 'Managed 3D Tour (4K)',
        }

        return request_type_labels[value];
    }


    FileConstants.getRequestLinks = (record, entity_name = "") => {
        let value = record.request_type;
        if (value.includes('silo')) {
            if (entity_name != "") {
                return ENVIRONMENT.PORTAL_LINK + 'products/' + record.id;
            }
            return ENVIRONMENT.PORTAL_LINK + 'products/' + record.id + '?type=silo&camera=' + record.camera_name;
        }
        else if (value.includes('product') || value.includes('scanned_model') || value.includes('customer_uploaded')) {
            return ENVIRONMENT.PORTAL_LINK + 'product_page/' + record.id;
        }
        else if (value.includes('space') || value.includes('room')) {
            return ENVIRONMENT.PORTAL_LINK + 'rooms/' + record.id;
        }
        else if (value.includes('lifestyle_image') || value.includes('lifestyle_360')) {
            if (entity_name != "") {
                return ENVIRONMENT.PORTAL_LINK + 'scene_renders/' + record.id;
            }
            return ENVIRONMENT.SCENE_BASE_URL + record.id + '/' + record.camera_name;
        }
        else if (value == 'new_material' || value == 'scanned_material' || value == 'shared_material')
            return ENVIRONMENT.PORTAL_LINK + 'material-library?material_id=' + record.id;
        else if (value.includes("3d_tour")) {
            if (entity_name != "") {
                return ENVIRONMENT.PORTAL_LINK + 'scene_renders/' + record.id;
            }
            return ENVIRONMENT.PORTAL_LINK + '3d_tour_viewer/' + record.id
        }
        else
            return ENVIRONMENT.PORTAL_LINK + 'product_page/' + record.id;
    }

    FileConstants.typesOfServices = [
        'product_models',
        'product_photography',
        'space_models',
        'materials',
        'lifestyle_photography',
        'managed_services',
        'shared_items',
        'scenes'
    ]

    FileConstants.typeOfDict = (value) => {
        let request_type_labels = {
            'product_models': 'Product Models',
            'product_photography': 'Product Photography',
            'space_models': 'Space Models',
            'materials': 'Materials',
            'scenes': 'Scenes',
            'lifestyle_photography': 'Lifestyle Photography',
            'managed_services': 'Managed Services',
            'shared_items': 'Shared Items'
        }

        return request_type_labels[value];

    }

    FileConstants.STANDRAD_DOMAINS = [
        'gmail.com',
        'aol.com',
        'excite.com',
        'fastmail.com',
        'gmx.com',
        'hushmail.com',
        'icloud.com',
        'laposte.net',
        'lycos.com',
        'mail.com',
        'mail.ru',
        'mailfense.com',
        'outlook.com',
        'protonmail.com',
        'rackspace.com',
        'rediffmail.com',
        'runbox.com',
        'tutanota.com',
        'yahoo.com',
        'yandex.com',
        'zoho.com',
        'hotmail.com'
    ]

    FileConstants.HIDDEN_CATEGORY_2 = {
        'Bed': {
            'Bedding Type':
                [{
                    'type': 'Bed Frame Only',
                    'heading': 'Bed Frame only',
                    'price_text': '',
                    'subtext': 'The bed model will be without bedding and will be based on the image shown below',
                    'image': '/img/bedframe.jpg',
                    'attachment': false
                }, {
                    'type': 'White Mattress and Pillows',
                    'heading': 'White Mattress and Pillows',
                    'price_text': '',
                    'subtext': 'The bedding on your model will be based on the mattress and pillows shown below',
                    'image': '/img/simple_bedding.png',
                    'attachment': false
                }, {
                    'type': 'Bed with Bedding',
                    'heading': 'Custom Bedding',
                    'price_text': '',
                    'subtext': 'Upload the reference images that you want the bedding to be based on',
                    'image': '/img/custom_bedding.png',
                    'attachment': true
                }]
        },
        'Beds': {
            'Bedding Type':
                [{
                    'type': 'Bed Frame Only',
                    'heading': 'Bed Frame Only',
                    'price_text': '',
                    'subtext': 'The bed model will be without bedding and will be based on the image shown below',
                    'image': '/img/bedframe.jpg',
                    'attachment': false
                }, {
                    'type': 'White Mattress and Pillows',
                    'heading': 'White Mattress and Pillows',
                    'price_text': '',
                    'subtext': 'The bedding on your model will be based on the mattress and pillows shown below',
                    'image': '/img/simple_bedding.png',
                    'attachment': false
                }, {
                    'type': 'Bed with Bedding',
                    'heading': 'Custom Bedding',
                    'price_text': '',
                    'subtext': 'Upload the reference images that you want the bedding to be based on',
                    'image': '/img/custom_bedding.png',
                    'attachment': true
                }]
        }
    }

    FileConstants.getRedirectUrls = (value, id) => {
        let redirect_urls = {
            'product_model': '/add-new-product/' + id,
            'existing_product_model': '/upload-product-model/' + id,
            'variation_product_model': '/products/' + id + '?request_type=variation_product_model',
            'room_model': '/add-new-room?step=4&room_id=' + id,
            'variation_room_model': '/rooms/' + id + '?request_type=variation_room_model',
            'scene_lifestyle_image': '/scene_builder/' + id + '?request_type=scene_lifestyle_image',
            'scene_lifestyle_360': '/scene_builder/' + id + '?request_type=scene_lifestyle_360',
            'scene_lifestyle_3dtour': '/scene_builder/' + id + '?request_type=scene_lifestyle_3dtour',
            'new_material': '/create-new-material/' + id,
            'scanned_material': '/upload-scanned-material/' + id,
            'product_silo': '/silo-photography/' + id + '?request_type=product_silo',
            'managed_product_silo': '/silo-photography/' + id + '?request_type=product_silo',
            'product_video': '/products/' + id + '?request_type=product_video',
            'product_360_spin': '/products/' + id + '?request_type=product_360_spin',
            'product_dimensional': '/products/' + id + '?request_type=product_dimensional',
            'managed_product_video': '/products/' + id + '?request_type=product_video',
            'managed_product_360_spin': '/products/' + id + '?request_type=product_360_spin',
            'managed_product_dimensional': '/products/' + id + '?request_type=product_dimensional',
            'collection_room': '/store?request_type=collection_room',
            'collection_product': '/store?request_type=collection_product',
            'account_details': '/account-details',
            'product_prop': '/store?request_type=product_prop',
            'room_prop': '/store?request_type=room_prop'
        }

        return redirect_urls[value];
    }

    FileConstants.getidType = (value) => {
        let id_type = {
            'product_model': 'product_id',
            'existing_product_model': 'product_id',
            'variation_product_model': 'product_id',
            'variation_room_model': 'room_id',
            'room_model': 'room_id',
            'scene_lifestyle_image': 'scene_id',
            'scene_lifestyle_360': 'scene_id',
            'scene_lifestyle_3dtour': 'scene_id',
            'new_material': 'material_id',
            'scanned_material': 'material_id',
            'product_silo': 'product_id',
            'product_video': 'product_id',
            'product_360_spin': 'product_id',
            'product_dimensional': 'product_id',
            'collection_room': 'collection_id',
            'collection_product': 'collection_id',
            'room_prop': 'room_id',
            'product_prop': 'product_id',
        }
        return id_type[value];
    }

    // FileConstants.USAGE_QUOTAS = {
    //     'Basic': {
    //         'Lifestyle Renders': '10 2K Renders',
    //         'Product Custom Silos': '10 2K Renders',
    //         'Product 360s/MP4 Videos': '0 Free',
    //         'Props Model Usage':'50 free ALL3D models from Store',
    //         'Space Models Usage': '5 free ALL3D spaces from Store',
    //         'Upload Existing 3D Models': '20 Free',
    //         '3D Tour': '0 free',
    //         'Scene Creator': 'Unlimited Usage of Scene Creator / Layout Tool',
    //         '3D Product Model Creation': 'In-App Purchase',
    //         '3D Product Model SKU Creation': 'In-App Purchase',
    //         '3D Space Creation': 'In-App Purchase',
    //         'AR Model Creation': 'In-App Purchase',
    //         'Sharing Capability': 'Free',
    //         'Cloud Storage': 'Yes',
    //         'Model Creation / SLA': 'Standard -  Contact Us',
    //         'Seat License': '1',
    //         'Design Services':'N/A',
    //         '3D Configurator': 'N/A'
    //     },
    //     'Pro': {
    //         'Lifestyle Renders': '10 4K Renders and 20 2K Renders',
    //         'Product Custom Silos': '10 4K Renders and 20 2K Renders',
    //         'Product 360s/MP4 Videos': '2 Free',
    //         'Props Model Usage':'200 free ALL3D models from Store',
    //         'Space Models Usage': '10 free ALL3D spaces from Store',
    //         'Upload Existing 3D Models': '50 Free',
    //         '3D Tour': '1 free',
    //         'Scene Creator': 'Unlimited Usage of Scene Creator / Layout Tool',
    //         '3D Product Model Creation': 'In-App Purchase',
    //         '3D Procut Model SKU Creation': 'In-App Purchase',
    //         '3D Space Creation': 'In-App Purchase',
    //         'AR Model Creation': 'In-App Purchase',
    //         'Sharing Capability': 'Free',
    //         'Cloud Storage': 'Yes',
    //         'Model Creation / SLA': 'Standard -  Contact Us',
    //         'Seat License': '1-5',
    //         'Design Services':'N/A',
    //         '3D Configurator': 'N/A'
    //     }

    // }

    FileConstants.USAGE_QUOTAS = [
        ['', 'Basic', 'Pro'],
        ['Lifestyle Renders', '10 2K Renders', '10 4K Renders and 20 2K Renders'],
        ['Product Custom Silos', '10 2K Renders', '10 4K Renders and 20 2K Renders'],
        ['Product 360s/MP4 Videos', '0 Free', '2 Free'],
        ['Props Model Usage', '50 free ALL3D models from Store', '200 free ALL3D models from Store'],
        ['Space Models Usage', '5 free ALL3D spaces from Store', '10 free ALL3D spaces from Store'],
        ['Upload Existing 3D Models', '20 Free', '50 Free'],
        ['3D Tour', '0 free', '1 free'],
        ['Scene Creator', 'Unlimited Usage of Scene Creator / Layout Tool', 'Unlimited Usage of Scene Creator / Layout Tool'],
        // ['3D Product Model Creation', 'In-App Purchase','In-App Purchase'],
        // ['3D Product Model SKU Creation', 'In-App Purchase','In-App Purchase'],
        // ['3D Space Creation', 'In-App Purchase','In-App Purchase'],
        // ['AR Model Creation', 'In-App Purchase','In-App Purchase'],
        ['Sharing Capability', 'Free', 'Free'],
        ['Cloud Storage', 'Yes', 'Yes'],
        ['Model Creation / SLA', 'Standard -  Contact Us', 'Standard -  Contact Us'],
        ['Seat License', '1', '1-5'],
        ['Design Services', 'N/A', 'N/A'],
        ['3D Configurator', 'N/A', 'N/A']
    ]
    FileConstants.getModelType = (value) => {
        let model_type = {
            'ar': 'AR Model',
            'high_res': 'High Res Model',
            'high_res_and_ar': 'High Res and AR',
        }

        return model_type[value];
    }
    FileConstants.variationDict = (value, needToModel = null) => {
        let request_type_labels = {
            'color': 'Color',
            'material': 'Material',
            'size': 'Size/Dimension',
            'state': 'State',
            'bedding': 'Bedding',
            'ar_conversion': 'AR Conversion',
            'high_conversion': 'High Res Conversion',
            'hardware': 'Hardware',
            'other': 'Other'
        }

        // Check if the value is 'ar_conversion' and apply the condition
        if (value === 'ar_conversion' && needToModel && needToModel == 'high_pbr') {
            return 'High PBR Conversion';
        }

        return request_type_labels[value];
    }

    FileConstants.arVariationDict = (value) => {
        let request_type_labels = {
            'ar': 'AR Conversion',
            'pbr': 'PBR Conversion',
        }

        return request_type_labels[value];

    }
    FileConstants.VARIATION_TYPES = [
        {
            'heading': 'Colors',
            'text': 'If your require the model variation to have different colors',
            'image': '/img/color_change.png',
            'value': 'color',
            'price': 15
        },
        {
            'heading': 'Material',
            'text': 'If your require the model variation to have different materials',
            'image': '/img/material_change.png',
            'value': 'material',
            'price': 50
        },
        {
            'heading': 'Size/Dimensions',
            'text': 'If your require the model variation to have different size and/or dimensions',
            'image': '/img/size_change.png',
            'value': 'size',
            'price': 50
        },
        {
            'heading': 'State Change',
            'text': 'For example, drawer open or close, light bulb turned on or off',
            'image': '/img/state_change.png',
            'value': 'state',
            'price': 50
        },
        {
            'heading': 'Bedding Change',
            'text': "If you need different types of bedding for the same bed design",
            'image': '/img/bedding_change.png',
            'value': 'bedding',
            'price': 160
        },
        {
            'heading': 'PBR Conversion',
            'text': 'If you need to convert a high resolution model into an AR model',
            'image': '/img/ar_change.png',
            'value': 'ar_conversion',
            'price': 60
        },
        {
            'heading': 'High Resolution Conversion',
            'text': 'If you need to convert an AR model into a high resolution model',
            'image': '/img/high_res_change.png',
            'value': 'high_conversion',
            'price': 60
        },
        {
            'heading': 'Hardware Change',
            'text': "For example, if you want to have a different style of legs for the same chair",
            'image': '/img/hardware_change.png',
            'value': 'hardware',
            'price': 70
        },
        {
            'heading': 'Others',
            'text': 'Any other variation, that does not fit the given types',
            'image': '',
            'value': 'other',
            'price': 110
        }
    ]

    FileConstants.modelOutputTypes = [
        {
            'name': 'For 3D Photography',
            'type': 'high_res',
            'use': '',//'Uses VRAY Textures',
            'materials': [{
                'name': 'Vray',
                'type': 'high_res',
            },
            {
                'name': 'PBR',
                'type': 'high_pbr',
            }],
            'supported_usecases': [{
                'name': 'Silo Photography',
                'icon': '/img/silo.png',
                'type': '',
                'link': ''
            }, {
                'name': 'Lifestyle Photography',
                'icon': '/img/lifestyle.png',
                'type': '',
                'link': ''
            }]
        },
        {
            'name': 'For Realtime / AR / VR',
            'type': 'ar',
            'use': '',//'Uses PBR Textures',
            'supported_usecases': [{
                'name': 'Embeddable 3D Viewer',
                'icon': '/img/3d_viewer.png',
                'type': '',
                'link': 'https://app.all3d.ai/product_public_viewer/product_3d_model/10754'
            }, {
                'name': 'AR (USDZ)',
                'icon': '/img/ar_use.png',
                'type': '',
                'link': 'https://d48f7equ64qjl.cloudfront.net/product_assets/usdz/10754.usdz'
            }]
        },
        {
            'name': 'For Configurator',
            'type': '',
            'use': '',
            'supported_usecases': [{
                'name': 'Image Based Configurator',
                'icon': '/img/2d_configurator.png',
                'materials': [{
                    'name': 'Vray',
                    'type': 'high_res_segmented'
                }, {
                    'name': 'PBR',
                    'type': 'high_pbr_segmented'
                }],
                'use': '',//'Uses VRAY or PBT Textures',
                'link': ''
            }, {
                'name': 'Realtime 3D Configurator',
                'icon': '/img/3d_configurator.png',
                'type': 'ar_segmented',
                'use': '',//'Uses PBR Textures',
                'link': 'https://vispring-fabricator.all3d.ai/'
            }]
        },

    ];

    FileConstants.modelOutputOptions = [
        { label: 'For 3D Photography', value: 'high_res' },
        { label: 'For Realtime / AR / VR', value: 'ar' },
    ];

    FileConstants.getTourHeading = (value) => {
        let headings = {
            'virtual_product': 'Create Virtual Product',
            'generate_product_assets': 'Generate Product Assets',
            'generate_product_variants': 'Generate Product Variants',
            'use_all3d_store': 'Use ALL3D Store',
            'create_virtual_space': 'Create Virtual Space',
            'create_3d_tours': 'Create 3D Tours',
            'create_lifestyle_images': 'Create Lifestyle Images'
        }

        return headings[value];
    };

    FileConstants.congratsMessage = (value) => {
        let headings = {
            'virtual_product': 'You just learned how to create your first product model!',
            'generate_product_assets': 'You just learned how to generate product imagery and assets!',
            'generate_product_variants': 'You just learned how to create product variations!',
            'use_all3d_store': 'You just learned how to use ALL3D Store!',
            'create_virtual_space': 'You just learned how to create space models!',
            'create_3d_tours': 'You just learned how to generate 3D Tours!',
            'create_lifestyle_images': 'You just learned how to create Lifestyle Images!'
        }

        return headings[value];
    };

    FileConstants.exploreTourOptions = [
        'virtual_product',
        'generate_product_assets',
        'generate_product_variants',
        'use_all3d_store',
        'create_virtual_space',
        'create_3d_tours',
        'create_lifestyle_images'
    ]

    FileConstants.TOUR_FEATURES = [
        {
            'text': 'Create Virtual Product',
            'image': '/img/7.png',
            'value': 'virtual_product'
        },
        {
            'text': 'Generate Product Assets',
            'image': '/img/6.png',
            'value': 'generate_product_assets'
        },
        {
            'text': 'Create Product Variants',
            'image': '/img/5.png',
            'value': 'generate_product_variants'
        },
        {
            'text': 'Use ALL3D Store',
            'image': '/img/1.png',
            'value': 'use_all3d_store'
        },
        {
            'text': 'Create Virtual Space',
            'image': '/img/2.png',
            'value': 'create_virtual_space'
        },
        {
            'text': 'Create 3D Tours',
            'image': '/img/3.png',
            'value': 'create_3d_tours'
        }, {
            'text': 'Create Lifestyle Images',
            'image': '/img/4.png',
            'value': 'create_lifestyle_images'
        },

    ]


    FileConstants.getLogoWidth = (value) => {
        let brands = {
            'uttermost.com': '15%',
            'hillsdalefurniture.com': '10%',
            'hookerfurnishings.com': '15%',
            'hookerfurniture.com': '20%',
            'bassettfurniture.com': '10%',
            'bassettmirror.com': '25%',
            'nafurniture.com': '20%',
            'butlerspecialty.net': '20%',
            'skylinefurnituremfg.com': '15%',
            'linon.com': '10%',
            'flexsteel.com': '20%',
            'myhomefurnishings.net': '10%',
            'all3d.ai': '20%',
            'safavieh.com': '20%'
        }

        return brands[value];
    };

    FileConstants.requestTypeCategory = (request_type) => {
        return (request_type == 'material_swatch_silo'
            || request_type.includes('product_silo')
            || request_type.includes('managed_product_silo')
            || request_type.includes('_lifestyle_image')
            || request_type.includes('lifestyle_360')
            || request_type.includes('_3d_tour'));
    }

    FileConstants.requestTypeResolution = (request_type) => {
        return (request_type.includes('material')
            || request_type.includes('product_silo')
            || request_type.includes('managed_product_silo')
            || request_type == 'product_dimensional'
            || request_type.includes('managed_product_dimensional')
            || request_type.includes('_lifestyle_image')
            || request_type.includes('lifestyle_360')
            || request_type.includes('_3d_tour'));
    }

    FileConstants.HIDDEN_CATEGORY = {
        'Crib': ['Crib Frame Only', 'White Mattress and Pillows', 'Bed with Bedding'],
        'Bed': ['Bed Frame Only', 'White Mattress and Pillows', 'Bed with Bedding'],
        'Beds': ['Bed Frame Only', 'White Mattress and Pillows', 'Bed with Bedding'],
        'Daybeds': ['Daybed Frame Only', 'White Mattress and Pillows', 'Bed with Bedding'],
    }

    FileConstants.HIDDEN_BEDDING_CATEGORY = {
        'Bedding Type':
            [{
                'type': 'Bed Frame Only',
                'heading': 'Bed Frame only',
                'price_text': '',
                'subtext': 'The bed model will be without bedding and will be based on the image shown below',
                'image': '/img/bedframe.jpg',
                'attachment': false
            }, {
                'type': 'White Mattress and Pillows',
                'heading': 'White Mattress and Pillows',
                'price_text': '',
                'subtext': 'The bedding on your model will be based on the mattress and pillows shown below',
                'image': '/img/simple_bedding.png',
                'attachment': false
            }, {
                'type': 'Bed with Bedding',
                'heading': 'Custom Bedding',
                'price_text': '',
                'subtext': 'Upload the reference images that you want the bedding to be based on',
                'image': '/img/custom_bedding.png',
                'attachment': true
            }]
    }

    FileConstants.MODEL_STATUS = {
        1: 'Incomplete Model',
        2: 'Submitted By Customer',
        3: 'In Admin QA',
        4: 'In Customer QA',
        '-4': 'Rejected by Admin',
        '-5': 'Rejected by Customer',
        5: 'Approved by Customer',
        6: 'Pending from System',
        7: 'In Pre-processing',
        8: 'In Artist Review',
        10: 'Sent Back to Customer',
        11: 'Job Failed'
    }

    FileConstants.PRODUCT_MODEL_STATUS = {
        1: '1 - Incomplete Model',
        2: '2 - Submitted By Customer',
        3: '3 - In Admin QA',
        4: '4 - In Customer QA',
        '-4': '-4 - Rejected by Admin',
        '-5': '-5 - Rejected by Customer',
        5: '5 - Approved by Customer',
        6: '6 - Pending from System',
        7: '7 - In Pre-processing',
        8: '8 - In Artist Review',
        10: '10 - Sent Back to Customer',
        11: '11 - Job Failed'
    }

    FileConstants.getErrors = {
        'uploading': 'Uploads are in progress, please wait till file uploads are completed.',
        'error': 'Error occured in uploading files, please re-upload your files.',
        'not_started': 'Please upload image to proceed'
    }

    FileConstants.MODEL_STATUS_STRING = {
        '1': 'Incomplete Model',
        '2': 'Submitted By Customer',
        '3': 'In Admin QA',
        '4': 'In Customer QA',
        '-4': 'Rejected by Admin',
        '-5': 'Rejected by Customer',
        '5': 'Approved by Customer',
        '6': 'Pending from System',
        '-6': 'Validations Failed',
        '7': 'In Pre-processing',
        '8': 'In Artist Review',
        '10': 'Sent Back to Customer',
        '11': 'Job Failed'
    }

    FileConstants.MODEL_STATUS_STRING_FOR_CUSTOMER = {
        '1': 'Incomplete Model',
        '2': 'Submitted By Customer',
        '3': 'Pending from System',
        '4': 'In Customer QA',
        '-4': 'Pending from System',
        '-5': 'Rejected by Customer',
        '5': 'Approved by Customer',
        '6': 'Pending from System',
        '-6': 'Pending from System',
        '7': 'Pending from System',
        '8': 'Pending from System',
        '10': 'Sent Back to Customer',
        '11': 'Pending from System'
    }


    FileConstants.SCENE_STATUS_STRING = {
        'in_library': 'In Library/ Project',
        'first_render_in_progress': 'First Render in Progress',
        'incomplete': 'Incomplete',
        'deleted': 'Deleted'
    }

    
    FileConstants.SCENE_RENDER_STATUS = {
        'rendered': 'Rendered',
        'hidden': 'Deleted',
        'pending': 'In Progress',
        'flagged': 'Flagged',
        'flag_resolved': 'Flag Resolved',
        'flag_rejected': 'Rendered'
    }



    FileConstants.availableModelLabels = {
        "high_res": "High Poly",
        "ar": "Low Poly"
    }

    FileConstants.allRequiredAssets = [
        'High Poly Geometry',
        'Low Poly Geometry',
        'Vray Materials',
        'PBR Textures connected to Physical Materials',
    ];

    FileConstants.highRequiredAssets = ['High Poly Geometry',
        'Vray Materials',
    ];

    FileConstants.highPBRRequiredAssets = ['High Poly Geometry',
        'PBR Textures connected to Vray Materials',
    ];

    FileConstants.allPBRRequiredAssets = ['High Poly Geometry',
        'PBR Textures connected to Vray Materials',
        'Low Poly Geometry',
        'PBR Textures connected to Physical Materials',
    ];

    FileConstants.lowRequiredAssets = ['Low Poly Geometry',
        'PBR Textures connected to Physical Materials',
    ];

    FileConstants.artist_notes = {
        'state': 'Please use the provided product model link and make the requested state changes mentioned below.',
        'ar_conversion': 'Please use the provided product model link and make the requested AR Conversion changes mentioned below.',
        'high_conversion': 'Please use the provided product model link and make the requested High Res changes mentioned below.',
        'color': 'Please use the provided product model link and make the requested color changes mentioned below.',
        'hardware': 'Please use the provided product model link and make the requested changes mentioned below.',
        'other': 'Please use the provided product model link and make the requested changes mentioned below.',
        'material': 'Please use the provided product model link and make the requested material changes mentioned below.',
        'bedding': 'Please use the provided product model link and make the requested bedding changes mentioned below.',
        'size': 'Please use the provided product model link and make the requested size changes mentioned below.',
        'group_id': 'Please create a new model by using the materials from the following model.',
        'new_model': 'Please create a new model.'
    }
    FileConstants.ARTIST_EXPERTISE_LEVEL = {
        '1': 'Bad',
        '2': 'Average',
        '3': 'Good'
    }

    FileConstants.mapComplaintStatus = {
        pending: { name: 'Pending', color: 'yellow' },
        in_progress: { name: 'Pending', color: 'yellow' },
        admin_qa: { name: 'Pending', color: 'yellow' },
        sent_back_to_artist: { name: 'Pending', color: 'yellow' },
        resolved: { name: 'Complaint Resolved', color: 'green' },
        rejected: { name: 'Complaint Reviewed & Resolved', color: 'red' }
    }

    // Define filename format for thumbnail image
    FileConstants.THUMBNAIL_FILENAME = 'thumbnail'

    // Display label for each material category
    FileConstants.MATERIAL_TYPE_LABELS = {
        'vray': 'VRAY',
        'pbr': 'PBR Textures (Physical Materials)',
        'vray_and_pbr': 'VRAY, PBR Textures (Physical Materials)',
        'high_pbr': 'PBR Textures (VRAY Materials)',
        'high_pbr_and_ar': 'PBR Textures (VRAY Materials), PBR Textures (Physical Materials)'
    }

    FileConstants.MATERIAL_TYPE_LABELS_IMAGE = {
        'vray': 'VRAY',
        'pbr': 'PBR',
        'vray_and_pbr': 'VRAY, PBR',
        'high_pbr': 'PBR',
        'high_pbr_and_ar': 'PBR'
    }

    // Define all materials types required as outputs against given material category
    FileConstants.MATERIAL_TYPE_REQUIRED_OUTPUTS = {
        'vray': ['vray'],
        'pbr': ['pbr'],
        'vray_and_pbr': ['vray'],
        'high_pbr': ['vray'],
        'high_pbr_and_ar': ['vray'],
    }

    // Deployed site for material inspector
    FileConstants.MaterialInspector = 'https://d1rzyeqsmooian.cloudfront.net/material_inspector?id='
    FileConstants.ModelInspector = 'https://d1rzyeqsmooian.cloudfront.net/model_inspector?id='


    // Define material output category against given model type
    FileConstants.MATERIAL_OUTPUT_FOR_MODEL = {
        'ar': 'pbr',
        'high_res': 'vray',
        'high_res_and_ar': 'vray_and_pbr',
        'ar_segmented': 'pbr',
        'high_res_segmented': 'vray',
        'high_pbr': 'high_pbr',
        'high_pbr_and_ar': 'high_pbr_and_ar',
        'high_pbr_segmented': 'high_pbr'
    }

    FileConstants.AR_MATERIAL_OUTPUT_TYPES = [
        'high_pbr_and_ar',
        'vray_and_pbr',
        'pbr'
    ]


    FileConstants.RENDERING_MATERIAL_OUTPUT_TYPES = [
        'high_pbr_and_ar',
        'high_pbr',
        'vray',
        'vray_and_pbr'
    ]


    // Define all the material types
    FileConstants.MATERIAL_TYPES = [
        'vray',
        'pbr',
        'high_pbr'
    ]

    // Get the category/ type of the given material render
    FileConstants.materialRenderTypeResolution = (materialRender) => {
        let materialRenderType = 'vray';
        if (materialRender.toLowerCase().includes('_pbr')) {
            materialRenderType = 'pbr';
        }
        return materialRenderType
    }

    // Check if the given material contains PBR
    FileConstants.containsPBRMaterial = (materialCategory) => {
        return (materialCategory.toLowerCase().includes('pbr') && !materialCategory.toLowerCase().includes('high_pbr'))
    }

    // Check if the given material contains VRAY
    FileConstants.containsVRAYMaterial = (materialCategory) => {
        return materialCategory.toLowerCase().includes('vray')
    }
    // Check if the given material contains VRAY
    FileConstants.containsHighPBRMaterial = (materialCategory) => {
        return materialCategory.toLowerCase().includes('high_pbr')
    }

    // Define render extension for material type
    FileConstants.MATERIAL_TYPES_RENDERS_EXT = {
        'vray': '',
        'pbr': '_pbr',
        'vray_and_pbr': ''
    }

    FileConstants.REVERSE_MAPPING_FOR_EXPERTISE = {
        'Bad': 1,
        'Average': 2,
        'Good': 3
    }

    FileConstants.STYLE_CATEGORY = [
        'Mid Century',
        'Traditional',
        'Farmhouse',
        'Modern',
        'Glam',
        'Rustic',
        'Global',
        'Bohemian',
        'Ecclectic',
        'Industrial',
        'Coastal',
        'Transitional',
        'Other'
    ]

    FileConstants.LINE_PLOT_CONFIG = (dataSource, xValues, yValues, seriesCol) => {
        let config = {
            data: dataSource,
            xField: xValues,
            yField: yValues,
            yAxis: {
                label: {
                    formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                },
            },
            smooth: true,
            seriesField: seriesCol,
            legend: {
                position: "top",
            },
            animation: {
                appear: {
                    animation: "path-in",
                    duration: 500,
                },
            },
        };
        return config;
    };

    FileConstants.MATERIAL_PROPERTIES = {
        "minRepeat": 1,
        "maxRepeat": 500,
        "defaultRepeat": 39.37,
        "minRoughness": 0,
        "maxRoughness": 100
    }

    FileConstants.MODEL_TYPE_DESCRIPTION = {
        'high_res': 'High Poly + Vray Materials',
        'ar': 'Low Poly + PBR Materials',
        'high_res_and_ar': 'High Poly + Vray Materials , Low Poly + PBR Materials',
        'high_pbr': 'High Poly + PBR Materials',
        'high_pbr_and_ar': 'High Poly + PBR Materials, Low Poly + PBR Materials'
    }

    FileConstants.ARTIST_VIDEO_TUTORIALS = [
        {
            id: "by96hLsk5A8",
            title: "Space Creation UV Guidelines",
            link: "https://www.youtube.com/embed/by96hLsk5A8"
        }
    ]

    FileConstants.artistTourOptions = [
        "job_desk",
        "upload_model",
        "model_validation",
    ];

    FileConstants.ARTIST_TOUR_FEATURE = [
        {
            text: "Artist Job Desk",
            image: "/img/job_desk.png",
            value: "job_desk",
        },
        {
            text: "How To Upload Model",
            image: "/img/upload_model.png",
            value: "upload_model",
        },
        {
            text: "Model Validations",
            image: "/img/model_validation.png",
            value: "model_validation",
        }
    ]

    FileConstants.get_artist_tour_image = (value) => {
        let headings = {
            'job_desk': [
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/job_desk/1.png",
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/job_desk/2.png",
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/job_desk/3.png",
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/job_desk/4.png",
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/job_desk/5.png",
            ],
            'upload_model': [
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/upload_model/1.png",
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/upload_model/2.png",
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/upload_model/3.png",
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/upload_model/4.png",
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/upload_model/5.png",
            ],
            'model_validation': [
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/model_validation/1.png",
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/model_validation/2.png",
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/model_validation/3.png",
                "https://d48f7equ64qjl.cloudfront.net/static_files/artist_tour/model_validation/4.png",
            ]
        }
        return headings[value];
    }

    FileConstants.getTourHeading = (value) => {
        let headings = {
            job_desk: "Artist Job Desk",
            upload_model: "How To Upload Model",
            model_validation: "Model Validation",
        };

        return headings[value];
    };

    FileConstants.congratsMessage = (value) => {
        let headings = {
            job_desk: "You just learned how to pick a modeling job from the job desk!",
            upload_model: "You just learned how to upload a model!",
            model_validation: "You just learned the basics of model validation!",
        };

        return headings[value];
    };


    FileConstants.artistGuidelinesSections = [
        {
            section_heading: 'Guidelines',
            items: [
                {
                    name: 'Creating 3D Assets',
                    image: '/img/product-guidelines-icon.png',
                    link: 'https://docs.google.com/document/d/172FTkwKl0g9yh2Bq7BoWHmCKGj6NGJiwdSqYkDrp52c/edit?usp=share_link'
                },
                {
                    name: 'Creating 3D Spaces',
                    image: '/img/space-guidelines-icon.png',
                    link: 'https://docs.google.com/document/d/1vu7GpeyR6vq1m83ym5Q0EotVFTZ3K9T0gd5vfeLkMSo/edit?usp=share_link'
                }
            ]
        },
        {
            section_heading: 'Cheatsheets',
            items: [
                {
                    name: 'Product Creation',
                    image: '/img/product-cheatsheet.png',
                    link: 'https://drive.google.com/file/d/18kk_2QSqr2qQKu5xG3p3Wa13sag4q9Ri/view?usp=share_link'
                },
                {
                    name: 'Material Creation',
                    image: '/img/material-cheatsheet.png',
                    link: 'https://drive.google.com/file/d/1sQTT6DzxozqxBA_R9PFPq2jQConTeknI/view?usp=share_link'
                }
            ]
        },
        {
            section_heading: 'Videos',
            items: [
                {
                    name: 'ALL3D Asset Pipeline',
                    image: '/img/video-icon.png',
                    link: 'https://drive.google.com/file/d/1aLlDqjGr4p3GQjHRYhOCuZmXzyUx8YPB/view?usp=share_link'
                },
                {
                    name: 'Space Creation Guidelines',
                    image: '/img/video-icon.png',
                    link: 'https://www.youtube.com/embed/by96hLsk5A8'
                }
            ]
        }
    ]

    FileConstants.ARTIST_TEST_STATES = {
        SUCCESSFUL: 'successful',
        FAILED: 'failed',
        IN_PROGRESS: 'in_progress',
        NOT_STARTED: 'not_started',
        PENDING: 'pending'
    }

    FileConstants.REQUEST_TYPE_LABELS = {
        'product_silo': 'Product Silo',
        'product_dimensional': 'Product Dimensional',
        'product_video': 'Product Video',
        'product_360_spin': 'Product 360 Spin',
        'product_base_assets': 'Product QA Asset',
        'room_base_assets': 'Room QA Assets',
        'product_asset_convertor': 'Existing Model Conversion',
        'scene_renders': 'Scene Renders',
        'product_quick_renders': 'Artist Quick Renders',
        'admin_render_service': 'Admin Render Service',
        'room_quick_renders': 'Space Valiation',
        'product_preprocessing': 'Product Color Extraction',
        'material_renders': 'Material QA Render',
        'material_export': 'Product Materials Export',
        'product_configurations_renders': 'Product Configuration Renders',
        'artist_test_renders': 'Artist Test Render',
        'product_material_variation': 'Product Material Variation',
        'product_ar_integration': 'Product AR Integration',
        'room_variation': 'Room Variation',
        'product_configuration_silo':'Product Configuration Silo'
    }

    FileConstants.MSP_RENDER_STATUS = {
        'not_started': '',
        'complete': 'Complete',
        'ready_for_review': 'Ready for Review',
        'flagged': 'Flagged'
    }

    FileConstants.SERVICES_LIST = {
        'shared_items': [
            'shared_product',
            'product_added_from_store',
            'shared_room',
            'room_added_from_store',
            'shared_space',
            'shared_material'
        ],
        'product_models': [
            'product_model_ar',
            'product_model_high_res',
            'product_model_high_res_and_ar',
            'existing_product_model_fix_ar',
            'existing_product_model_fix_high_res',
            'existing_product_model_fix_high_res_and_ar',
            'product_model_high_pbr_and_ar',
            'product_model_high_pbr',
            'existing_product_model_ar',
            'existing_product_model_high_res',
            'existing_product_model_fix_high_res_and_ar',
            'existing_product_model',
            'existing_product_model_fix',
            'app_product_model',
            'variation_product_model',
            'variation_product_model_',
            'variation_product_model_color',
            'variation_product_model_hardware',
            'variation_product_model_material',
            'variation_product_model_size',
            'variation_product_model_bedding',
            'variation_product_model_state',
            'variation_product_model_ar_conversion',
            'variation_product_model_high_conversion',
            'variation_product_model_other',
            'product_model_high_res_and_ar_beds',
            'product_model_ar_beds',
            'product_model_high_pbr_and_ar_beds',
            'product_model_high_pbr_beds',
            'product_model_high_res_beds',
            'existing_product_model_beds',
            'product_model_beds',
            'product_model_ar_beds',
            'product_model_high_res_ar_beds',
            'product_model_high_res_and_ar_beds',
            'app_product_model_beds',
            'existing_product_model_fix_high_pbr',
            'existing_product_model_fix_high_pbr_and_ar'
        ],
        'product_photography': [
            'product_silo',
            'product_silo_4k',
            'product_silo_3k',
            'product_silo_2k',
            'product_silo_1k',
            'product_silo_0k',
            'bulk_product_silo',
            'bulk_product_silo_4k',
            'bulk_product_silo_3k',
            'bulk_product_silo_2k',
            'bulk_product_silo_1k',
            'bulk_product_silo_0k',
            'managed_product_silo',
            'managed_product_silo_4k',
            'managed_product_silo_3k',
            'managed_product_silo_2k',
            'managed_product_silo_1k',
            'managed_product_silo_0k',
            'material_swatch_silo',
            'product_video',
            'product_video_1k',
            'product_video_4k',
            'managed_product_video',
            'managed_product_video_1k',
            'managed_product_video_4k',
            'product_dimensional',
            'product_dimensional_1k',
            'product_dimensional_2k',
            'product_dimensional_3k',
            'product_dimensional_4k',
            'managed_product_dimensional',
            'managed_product_dimensional_1k',
            'managed_product_dimensional_2k',
            'managed_product_dimensional_3k',
            'managed_product_dimensional_4k',
            'product_360_spin',
            'product_360_spin_2k',
            'product_360_spin_4k',
            'product_360_spin_1k',
            'managed_product_360_spin',
            'managed_product_360_spin_2k',
            'managed_product_360_spin_4k',
            'managed_product_360_spin_1k',
        ],
        'space_models': [
            'room_model',
            'variation_room_model',
            "room_model_backyard/sideyard",
            "room_model_entranceway_/_foyer",
            "room_model_front_porch",
            "room_model_porch",
            "room_model_garage",
            "room_model_attic",
            "room_model_baby_nursery",
            "room_model_bar",
            "room_model_balcony",
            "room_model_basement",
            "room_model_casita",
            "room_model_closet",
            "room_model_dorm_room",
            "room_model_driveway",
            "room_model_game_room",
            "room_model_greenhouse",
            "room_model_gym",
            "room_model_home_exterior",
            "room_model_home_office",
            "room_model_kids_bedroom",
            "room_model_laundry_room",
            "room_model_library",
            "room_model_locker_room",
            "room_model_multi-use",
            "room_model_pantry",
            "room_model_rooftop",
            "room_model_staircase",
            "room_model_storage_room",
            "room_model_sunroom",
            "room_model_theater",
            "room_model_wall_/_surface",


            "variation_room_model_backyard/sideyard",
            "variation_room_model_entranceway_/_foyer",
            "variation_room_model_front_porch",
            "variation_room_model_porch",
            "variation_room_model_garage",
            "variation_room_model_attic",
            "variation_room_model_baby_nursery",
            "variation_room_model_bar",
            "variation_room_model_balcony",
            "variation_room_model_basement",
            "variation_room_model_casita",
            "variation_room_model_closet",
            "variation_room_model_dorm_room",
            "variation_room_model_driveway",
            "variation_room_model_game_room",
            "variation_room_model_greenhouse",
            "variation_room_model_gym",
            "variation_room_model_home_exterior",
            "variation_room_model_home_office",
            "variation_room_model_kids_bedroom",
            "variation_room_model_laundry_room",
            "variation_room_model_library",
            "variation_room_model_locker_room",
            "variation_room_model_multi-use",
            "variation_room_model_pantry",
            "variation_room_model_rooftop",
            "variation_room_model_staircase",
            "variation_room_model_storage_room",
            "variation_room_model_sunroom",
            "variation_room_model_theater",
            "variation_room_model_wall_/_surface",
            'room_model_kitchen',
            "room_model_apartment",
            "variation_room_model_apartment",
            "variation_room_model_bathroom",
            "room_model_bathroom",
            "room_model_bedroom",
            "variation_room_model_bedroom",
            "room_model_cabin",
            "variation_room_model_cabin",
            "room_model_conference_room",
            "variation_room_model_conference_room",
            "room_model_dining_room",
            "variation_room_model_dining_room",
            "variation_room_model_entry",
            "room_model_entry",
            "room_model_garden",
            "variation_room_model_garden",
            "room_model_hallway",
            "variation_room_model_hallway",
            "room_model_hotel_room",
            "variation_room_model_hotel_room",
            "room_model_house_(4_rooms_+,_including_kitchen)",
            "variation_room_model_house_(4_rooms_+,_including_kitchen)",
            "variation_room_model_kitchen",
            "room_model_living_room",
            "variation_room_model_living_room",
            "variation_room_model_office",
            "room_model_office",
            "variation_room_model_other",
            "room_model_other",
            "room_model_outdoors",
            "variation_room_model_outdoors",
            "variation_room_model_patio",
            "room_model_patio",
            "variation_room_model_pool",
            "room_model_pool",
            "variation_room_model_restuarant_(1_room)",
            "variation_room_model_restuarant_(more_than_1_room)",
            "variation_room_model_store_(1_room)",
            "variation_room_model_store_(more_than_1_room)",
            "variation_room_model_studio",
            "room_model_restuarant_(1_room)",
            "room_model_restuarant_(more_than_1_room)",
            "room_model_store_(1_room)",
            "room_model_store_(more_than_1_room)",
            "room_model_studio",
        ],
        'scenes': [
            'managed_scene',
            'customer_scene'
        ],
        'materials': [
            'new_material',
            'scanned_material',
        ],
        'lifestyle_photography': [
            'scene_lifestyle_image',
            'scene_lifestyle_image_4k',
            'scene_lifestyle_image_2k',
            'scene_lifestyle_image_1k',
            'scene_lifestyle_image_0k',

            'scene_lifestyle_360',
            'scene_lifestyle_360_4k',
            'scene_lifestyle_360_2k',
            'scene_lifestyle_360_1k',
            'scene_lifestyle_360_0k',

            'scene_lifestyle_3d_tour',
            'scene_lifestyle_3d_tour_4k',
            'scene_lifestyle_3d_tour_2k',
            'scene_lifestyle_3d_tour_1k',
            'scene_lifestyle_3d_tour_0k',

            'flagged_lifestyle_image',
            'flag_resolved_lifestyle_image',
            'flag_resolved_lifestyle_image_4k',
            'flag_resolved_lifestyle_image_2k',
            'flag_resolved_lifestyle_image_1k',
            'flag_resolved_lifestyle_image_0k',

            'flagged_lifestyle_360',
            'flag_resolved_lifestyle_360',
            'flag_resolved_lifestyle_360_4k',
            'flag_resolved_lifestyle_360_2k',
            'flag_resolved_lifestyle_360_1k',
            'flag_resolved_lifestyle_360_0k',

            'flag_resolved_lifestyle_360_shared',
            'flag_resolved_lifestyle_360_shared_4k',
            'flag_resolved_lifestyle_360_shared_2k',
            'flag_resolved_lifestyle_360_shared_1k',
            'flag_resolved_lifestyle_360_shared_0k',

            'flagged_lifestyle_image_shared',
            'flagged_lifestyle_image_shared_4k',
            'flagged_lifestyle_image_shared_2k',
            'flagged_lifestyle_image_shared_1k',
            'flagged_lifestyle_image_shared_0k',

            'flagged_lifestyle_360_shared',
            'flagged_lifestyle_360_shared_4k',
            'flagged_lifestyle_360_shared_2k',
            'flagged_lifestyle_360_shared_1k',
            'flagged_lifestyle_360_shared_0k',

            'scene_lifestyle_3d_tour_shared',
            'scene_lifestyle_3d_tour_shared_0k',
            'scene_lifestyle_3d_tour_shared_1k',
            'scene_lifestyle_3d_tour_shared_2k',
            'scene_lifestyle_3d_tour_shared_4k',

            'scene_lifestyle_image_shared',
            'scene_lifestyle_image_shared_0k',
            'scene_lifestyle_image_shared_1k',
            'scene_lifestyle_image_shared_2k',
            'scene_lifestyle_image_shared_4k',

            'scene_lifestyle_360_shared',
            'scene_lifestyle_360_shared_0k',
            'scene_lifestyle_360_shared_1k',
            'scene_lifestyle_360_shared_2k',
            'scene_lifestyle_360_shared_4k',
            
            'managed_service_lifestyle_image',
            'managed_service_lifestyle_image_4k',
            'managed_service_lifestyle_image_2k',
            'managed_service_lifestyle_image_1k',
            'managed_service_lifestyle_image_0k',

            'managed_service_lifestyle_360',
            'managed_service_lifestyle_360_4k',
            'managed_service_lifestyle_360_2k',
            'managed_service_lifestyle_360_1k',
            'managed_service_lifestyle_360_0k',

            'managed_service_3d_tour',
            'managed_service_3d_tour_4k',
            'managed_service_3d_tour_2k',
            'managed_service_3d_tour_1k',
            'managed_service_3d_tour_0k',

            'managed_service_flagged_lifestyle_image',
            'managed_service_flag_resolved_lifestyle_image',

            'managed_service_flagged_lifestyle_360',
            'managed_service_flag_resolved_lifestyle_360',

            'managed_service_flagged_lifestyle_image_shared',
            'managed_service_flag_resolved_lifestyle_image_shared',

            'managed_service_flagged_lifestyle_360_shared',
            'managed_service_flag_resolved_lifestyle_360_shared',

            'managed_service_3d_tour_shared',
            'managed_service_3d_tour_shared_0k',
            'managed_service_3d_tour_shared_1k',
            'managed_service_3d_tour_shared_2k',
            'managed_service_3d_tour_shared_4k',

            'managed_service_lifestyle_image_shared',
            'managed_service_lifestyle_image_shared_0k',
            'managed_service_lifestyle_image_shared_1k',
            'managed_service_lifestyle_image_shared_2k',
            'managed_service_lifestyle_image_shared_4k',

            'managed_service_lifestyle_360_shared',
            'managed_service_lifestyle_360_shared_0k',
            'managed_service_lifestyle_360_shared_1k',
            'managed_service_lifestyle_360_shared_2k',
            'managed_service_lifestyle_360_shared_4k',
        ],
        'managed_services': [
            'managed_service_lifestyle_image',
            'managed_service_lifestyle_image_4k',
            'managed_service_lifestyle_image_2k',
            'managed_service_lifestyle_image_1k',
            'managed_service_lifestyle_image_0k',

            'managed_service_lifestyle_360',
            'managed_service_lifestyle_360_4k',
            'managed_service_lifestyle_360_2k',
            'managed_service_lifestyle_360_1k',
            'managed_service_lifestyle_360_0k',

            'managed_service_3d_tour',
            'managed_service_3d_tour_4k',
            'managed_service_3d_tour_2k',
            'managed_service_3d_tour_1k',
            'managed_service_3d_tour_0k',

            'managed_service_flagged_lifestyle_image',
            'managed_service_flag_resolved_lifestyle_image',

            'managed_service_flagged_lifestyle_360',
            'managed_service_flag_resolved_lifestyle_360',

            'managed_service_flagged_lifestyle_image_shared',
            'managed_service_flag_resolved_lifestyle_image_shared',

            'managed_service_flagged_lifestyle_360_shared',
            'managed_service_flag_resolved_lifestyle_360_shared',

            'managed_service_3d_tour_shared',
            'managed_service_3d_tour_shared_0k',
            'managed_service_3d_tour_shared_1k',
            'managed_service_3d_tour_shared_2k',
            'managed_service_3d_tour_shared_4k',

            'managed_service_lifestyle_image_shared',
            'managed_service_lifestyle_image_shared_0k',
            'managed_service_lifestyle_image_shared_1k',
            'managed_service_lifestyle_image_shared_2k',
            'managed_service_lifestyle_image_shared_4k',

            'managed_service_lifestyle_360_shared',
            'managed_service_lifestyle_360_shared_0k',
            'managed_service_lifestyle_360_shared_1k',
            'managed_service_lifestyle_360_shared_2k',
            'managed_service_lifestyle_360_shared_4k',
        ]
    }


    FileConstants.RESOLUTION_REQUESTS = [
        "product_silo",
        'managed_product_silo',
        'product_video',
        'managed_product_video',
        'product_dimensional',
        'managed_product_dimensional',
        'product_360_spin',
        'managed_product_360_spin',
        "scene_lifestyle_image",
        'scene_lifestyle_360',
        'scene_lifestyle_3d_tour',
        'flagged_lifestyle_image',
        'flagged_lifestyle_360',
        'flag_resolved_lifestyle_360_shared',
        'flagged_lifestyle_image_shared',
        'flagged_lifestyle_360_shared',
        'scene_lifestyle_3d_tour_shared',
        'scene_lifestyle_image_shared',
        'scene_lifestyle_360_shared',
        'managed_service_lifestyle_image',
        'managed_service_lifestyle_360',
        'managed_service_3d_tour',
        'managed_service_flagged_lifestyle_image',
        'managed_service_flagged_lifestyle_360',
        'managed_service_flagged_lifestyle_image_shared',
        'managed_service_flagged_lifestyle_360_shared',
        'managed_service_3d_tour_shared',
        'managed_service_lifestyle_image_shared',
        'managed_service_lifestyle_360_shared',
    ]

    FileConstants.SERVICES_RESOLUTION_REQUESTS = {
        'materials': [],
        'scenes': [],
        'space_models': [],
        'shared_items': [],
        'product_models': [],
        'product_photography': [
            "product_silo",
            'managed_product_silo',
            'product_video',
            'managed_product_video',
            'product_dimensional',
            'managed_product_dimensional',
            'product_360_spin',
            'managed_product_360_spin',
        ],
        'lifestyle_photography': [
            "scene_lifestyle_image",
            'scene_lifestyle_360',
            'scene_lifestyle_3d_tour',
            'flagged_lifestyle_image',
            'flagged_lifestyle_360',
            'flag_resolved_lifestyle_360_shared',
            'flagged_lifestyle_image_shared',
            'flagged_lifestyle_360_shared',
            'scene_lifestyle_3d_tour_shared',
            'scene_lifestyle_image_shared',
            'scene_lifestyle_360_shared',
           
        ],
        'managed_services': [
            'managed_service_lifestyle_image',
            'managed_service_lifestyle_360',
            'managed_service_3d_tour',
            'managed_service_flagged_lifestyle_image',
            'managed_service_flagged_lifestyle_360',
            'managed_service_flagged_lifestyle_image_shared',
            'managed_service_flagged_lifestyle_360_shared',
            'managed_service_3d_tour_shared',
            'managed_service_lifestyle_image_shared',
            'managed_service_lifestyle_360_shared'
        ]
    }

    FileConstants.DATE_FILTERS_CUSTOMER = [
        {
            value: 'last_modified',
            label: 'Last Modified Date',
        },
        {
            value: 'created_on',
            label: 'Created On',
        }
    ]

    FileConstants.USAGE_DATA_TYPE = [
        {
            value: 'overall',
            label: 'All Projects',
        },
        {
            value: 'project',
            label: 'Project Data',
        }
    ]

    FileConstants.DATE_FILTERS_ADMIN = [
        {
            value: 'last_modified',
            label: 'Last Modified Date',
        },
        {
            value: 'billing_date',
            label: 'Billing Date'
        },
        {
            value: 'created_on',
            label: 'Created On',
        }
    ]

    FileConstants.DATE_FILTER_INFO = {
        'usage_details': 'Data can be filtered by last modified and created on dates for your Usage.',
        'shared_items': 'Data can be filtered by last modified and created on dates for Shared Items.',
        'managed_services': 'Data can be filtered by last modified date for Managed Services.',
    }

    FileConstants.REQUIRED_FIELD_ERRORS = {
        'bedding_category': 'Please upload a custom bedding image that you would like to be modelled on the bed - It is required before your submission.',
    }

    FileConstants.BULK_REQUEST_ELIGIBLE_COMPANIES = ['1', '35']


    FileConstants.PRODUCT_VARIANT_AUTOMATION_STATUS = {
        "REJECTED": "rejected",
        "COMPLETED": "completed",
        "PENDING": "pending",
        "APPROVED": "approved"
    }

    FileConstants.AUTOMATED_VARIANT_CUSTOMER_REJECT_STATUS = {
        "material": "3",
    }

    FileConstants.DEFAULT_SPACE_MATERIALS = [1, 2, 3, 4, 5]

    FileConstants.DEFAULT_ADMIN_SPACE_MATERIALS = [67, 68, 100, 173]

    FileConstants.SPACE_QA_CHECKLIST = [
        "Wall Art attaches to all the space Walls",
        "Ceiling props attach to all the space Ceilings",
        "Floor props move correctly on all the space Floors",
        "Props place correctly on Fireplace/ Kitchen Counters",
        "Space Walls/Floors/Ceilings are configurable"
    ]

    FileConstants.SPACE_QA_CHECKLIST_INSTRUCTION_LOG = [
        "Check that the Wall art and items attach and move on all the walls in the space",
        "Check that the Ceiling props attach and move on all the ceilings in the space",
        "Check that the Floor props move correctly on all the floors in the space",
        "Check that props move correctly on different space structures such as Kitchen counters/ Fireplaces etc",
        "Check that colors and textures apply correctly on all the Walls/Floors/Ceilings in the space"
    ]

    FileConstants.SPACE_STYLES = [
        "Eclectic",
        "Art Deco",
        "Baroque",
        "Boho",
        "Chinoiserie/Asian",
        "Coastal",
        "Contemporary",
        "Country",
        "Farmhouse",
        "Glam",
        "Global",
        "Industrial",
        "Japandi/Fung Shui",
        "Kids",
        "Maximalism",
        "Mediterranean",
        "Mid-century",
        "Minimalist",
        "Modern",
        "Rustic",
        "Scandinavian",
        "Southwestern",
        "Spanish",
        "Traditional",
        "Transitional",
        "Tropical",
        "Victorian",
        "Vintage"
    ]
    FileConstants.isProduction = () => document.referrer.includes('app.all3d.ai')
    FileConstants.isLocalOrStaging = () => document.referrer.includes('localhost') || document.referrer.includes('cloudfront.net')

    FileConstants.ALL3D_COMPANY_IDS = () => ENVIRONMENT.ENV == 'production' ? ['274', '2378'] : ['1', '67']

    FileConstants.ALL3D_COMPANY = 'ALL3D Company'

    FileConstants.PRODUCT_MODEL_TYPE = {
        "product": "Product",
        "accessorized_product": "Accessorized Product",
        "props": "Prop",
        "multiple_products": "Multiple products",
        "hero": "Hero"
    }

    FileConstants.SCENE_CREATION_FLOW = {
        "new-empty-scene": "empty_space",
        "template-scene": "empty_scene_template",
        "new-scene-from-existing-products": "copy_products_to_new_space"
    }
    
    FileConstants.SCENE_CREATOR_CAMERA_MODES = {
        "ThreeD": "three_d",
        "TopDown": "top_down",
        "FlatView": "flat_view"
    }

    FileConstants.SUN_DIRECTION_LABEL_STYLE = {
        position: "absolute",
        color: "#1f79e0",
        fontSize: `1.25rem`,
        userSelect: 'none',
    }

    FileConstants.flag_text_color = {
        'Issue Reported': 'red-d9',
        'Issue Resolved': 'green',
        'Issue Reviewed & Resolved': 'yellow-a'
    }

    FileConstants.ENTITY_MAPPING = {
        "product": "Product",
        "room": "Space",
        "scene": "Scene"
      }
};

FileConstants.init();

FileConstants.SILO_LIGHTING_CONFIG_JSON = "https://d48f7equ64qjl.cloudfront.net/static_files/LightConfig_2.json"


export default FileConstants;
