import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Breadcrumb, Tooltip, Row, Col, Button, Dropdown, Menu, Divider, Space } from 'antd';
import { ArrowLeftOutlined, DownOutlined, DownloadOutlined, FlagOutlined, LoginOutlined, MailOutlined, ShareAltOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';
import './ProductComponents.scss'
import ENVIRONMENT from '../../../../environments';
import { getBaseURL } from '../../../../environments/env';

const CUSTOMER_USERNAME = localStorage.getItem('username');
const IS_MSPROVIDER = localStorage.getItem('is_msprovider');
const COMPANY_ID = localStorage.getItem('company_id');
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const QUERY_PARAMS_TO_RETAIN = ['category', 'style', 'color', 'type', 'material', 'search', 'variant_of']

const ProductPageNavigation = () => {
    const { productAssetData, isStorePage, downloadImagesArchive, downloadSiloImages, openShareModal, product_id,
        downloadCryptoImagesArchive, download360Frames, statusOf360Spin, openFlagModal, viewComplaintsListener, complaints } = useContext(CustomerProductContext);
    
    const tabNameMapping={
        'active': 'Active Projects',
        'requested': 'Requested Projects',
        'approved': 'Approved Projects',
        'mspActive': 'Active Projects',
        'mspApproved': 'Approved Projects'
        }

    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get("project_id")
    const projectName = urlParams.get("project_name")
    const projectTab = urlParams.get("projectTab")
    const selectedUser = urlParams.get("selectedUser")

    const menu = (
        <Menu style={{ width: "200px" }}>
            <Menu.ItemGroup key={"assets"} title={<span className='manrope f-12 grey-8c'>Assets</span>} >
                <Menu.Item key={0}>
                    <div className='manrope f-14 black-55' onClick={() => downloadImagesArchive()}> All Images</div>
                </Menu.Item>
                <Menu.Item key={1}>
                    <div className='manrope f-14 black-55' onClick={() => downloadCryptoImagesArchive()}> Cryptomatte</div>
                </Menu.Item>
                {productAssetData['silo_data'] && productAssetData['silo_data'].filter(ele => ele.image_status == 'completed').length > 0 &&
                    <Menu.Item key={2}>
                        <div className='manrope f-14 black-55' onClick={() => downloadSiloImages()}> Silo Images</div>
                    </Menu.Item>}
                {statusOf360Spin == "completed" ?
                    <Menu.Item key={3}>
                        <div className='manrope f-14 black-55' onClick={() => download360Frames()}> 360 Frames</div>
                    </Menu.Item> : ''}
            </Menu.ItemGroup>
            <Menu.ItemGroup key={"model"} title={<span className='manrope f-12 grey-8c'>Model</span>} >
                {(productAssetData.download_links || []).map((ele, index) => {
                    return (
                        <Menu.Item key={index + 3}>
                            <a className='manrope f-14' target="_blank"
                                download
                                href={ele.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(productAssetData['platform']))} >{ele.display_name}</a>
                        </Menu.Item>
                    );
                })}
            </Menu.ItemGroup>
        </Menu>
    );

    const getLibraryUrl = () => {
        console.log('get library URL')
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        let queryString = ''
        console.log('search params =', searchParams)
        let variantOf = '';
        for(const [key, value] of searchParams.entries()){
            if(key != 'entity_id' && QUERY_PARAMS_TO_RETAIN.includes(key)){
                if (key == 'variant_of') {
                    variantOf = encodeURIComponent(value)
                }
                else { 
                    queryString = `${queryString}&${key}=${encodeURIComponent(value)}`
                }
            }
        }

        if (variantOf != '') {
            if (document.referrer === '' || document.referrer === null) {
                return `/products/${variantOf}?entity_id=${variantOf}`;
            }

            return document.referrer;
        }

        return '/list-products?entity_id=' + product_id + queryString;
    }

    return (<Header className='product-nav-header'>
        <Row className='display-flex j-s-b a-c'>
            <Col span={12}>
                <Breadcrumb className='display-flex j-s a-c'>
                    {   projectName && projectId ? 
                        <>
                        <Breadcrumb.Item className="manrope f-14 lh-28 black-73 white-space-text">
                            <a
                                className="manrope f-14 lh-28 black-73 white-space-text"
                                href={projectTab === "approved" || projectTab === "mspApproved" ? `/library-projects?tab=${projectTab}` : `/projects?tab=${projectTab}`}
                            >
                                <ArrowLeftOutlined className='mr-8'/>
                                {IS_MSPROVIDER && MANAGED_CUSTOMER_USERNAME && (projectTab == 'mspActive' || projectTab == 'mspApproved') ? `${MANAGED_CUSTOMER_USERNAME}'s ${tabNameMapping[projectTab]}` : `Your ${tabNameMapping[projectTab]}`}
                            </a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className="manrope f-14 lh-28 black-73 white-space-text">
                                <a
                                    className="manrope f-14 lh-28 black-73 white-space-text"
                                    href={`/projects/${projectId}?tab=${projectTab}&selectedUser=${selectedUser}`}
                                >
                                    {projectName.substring(0, 15) + (projectName.length > 15 ? '...' : '')}
                                </a>
                            </Breadcrumb.Item></>
                        :
                        <Breadcrumb.Item>
                            <a className='manrope f-14 lh-28 black-73 white-space-text'
                                href={isStorePage ? '/store' : getLibraryUrl()}>
                                <ArrowLeftOutlined className='mr-8'/>
                                {isStorePage ? "Store" : "Product Library"}
                            </a>
                        </Breadcrumb.Item>}
                    <Breadcrumb.Item className='manrope f-14 lh-28 black-d9 limit-lines limit-1'>{productAssetData['product_name']}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col span={12} className='display-flex j-e a-c'>

                {!isStorePage && (complaints && complaints.length > 0) && (productAssetData.is_store_item == -1 || productAssetData.customer_username == CUSTOMER_USERNAME)
                    &&
                    <Tooltip title={
                        <span className='manrope f-12 white'>Complaint History</span>
                    }>
                        <Button ghost
                            onClick={viewComplaintsListener}
                            className="nav-btns grey f-14"
                            icon={<img className="complaint-icon" src="/img/view-complaints.svg" />}
                        />
                    </Tooltip>}
                    {/* <Button style={{padding:"0px 10px"}} className="nav-btns light-blue f-14 ml-16" >
                        <img style={{height:"40px"}} src={require("../../../../assets/images/aws-logo.svg")} alt="aws-logo"></img>
                    </Button> */}
                {!isStorePage && productAssetData['silo_data'] && productAssetData['silo_data'].filter(ele => ele.image_status == 'completed').length > 0
                    && <Tooltip title={
                        <span className='manrope f-12 white'>Flag if unhappy</span>
                    }>
                        <Button ghost
                            onClick={openFlagModal}
                            className="nav-btns red f-14 ml-16"
                            icon={<FlagOutlined />}
                        />
                    </Tooltip>}
                {!isStorePage && <Tooltip title={
                    <span className='manrope f-12 white'>Share product</span>
                }>
                    <Button ghost
                        onClick={openShareModal}
                        className="nav-btns light-blue f-14 ml-16"
                        icon={<ShareAltOutlined />}
                    />
                </Tooltip>}
                {!isStorePage && <Dropdown overlay={
                    menu
                } trigger={['click']} className='ml-16'>
                    <Button className='product-btn light-blue f-14 br-2' ghost>Download <DownOutlined /></Button>
                </Dropdown>}
            </Col>
        </Row>
    </Header>)
}

export default ProductPageNavigation;