import * as THREE from 'three';
import Constants from "../Constants.js";
import PillowPlacementManager from "./PillowPlacement/PillowPlacementManager.js";
import ObjectSnappingManager from "./ObjectSnapping/ObjectSnappingManager.js";
import { ConvexGeometry } from "../../ConvexGeometry";
import { PVB } from '../../PVB.js';
import MovementManager from './MovementManager';
import StackingManager from './StackingManager';
import PlacementCorrectionManager from './PlacementCorrectionManager';
import MouseEventsManager from './MouseEventsManager.js';
import CollisionManager from './CollisionManager';
import PlacementValidationManager from './PlacementValidationManager';
import TransformSnappingManager from "./ObjectSnapping/TransformSnappingManager.js"
import {roundedEqual, getRootNodeFromObject, isAncestor, isConvexHull, getNormalForIntersect, setHighlightedState, isSnapped, rounded, multiplyVectorsElementWise, getObjectFromRootByName, disposeScene} from "../HelperFunctions"
import SceneCreator from "../../SceneCreatorAgent.js"

var TransformHelperPlane = function () {
    'use strict';

    THREE.Mesh.call(this,
        new THREE.PlaneBufferGeometry(1000, 1000, 2, 2),
        new THREE.MeshBasicMaterial({ visible: false, wireframe: true, side: THREE.DoubleSide, transparent: true, opacity: 0.1 })
    );

    this._item = null;

    var unitY = new THREE.Vector3(0, 1, 0);
    var unitZ = new THREE.Vector3(0, 0, 1);

    var tempVector = new THREE.Vector3();
    var dirVector = new THREE.Vector3();
    var alignVector = new THREE.Vector3();
    var tempMatrix = new THREE.Matrix4();
    var identityQuaternion = new THREE.Quaternion();

    this.position.set(0, 0, 0);
    unitY.set(0, 1, 0).applyQuaternion(identityQuaternion);
    unitZ.set(0, 0, 1).applyQuaternion(identityQuaternion);

    // Align the plane for current transform mode, axis and space.

    alignVector.copy(unitZ);
    dirVector.copy(unitY);

    tempMatrix.lookAt(tempVector.set(0, 0, 0), dirVector, alignVector);
    this.quaternion.setFromRotationMatrix(tempMatrix);

    this.attachHelperPlane = function (item) {
        this._item = item;
    };

    this.detachHelperPlane = function () {
        this._item = null;
    };
};

TransformHelperPlane.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
    constructor: TransformHelperPlane,
});

export default class ObjectPlacementManager {
    scene = null;
    sceneContainer = null;
    sceneCreator = null;
    sceneAssets = null;

    mouse = null;

    rotationControls = null;
    transformControls = null;
    navControl = null;
    assetManager = null;
    helperPlane = null;
    spaceManager = null;
    raycastManager = null;
    pillowPlacementManager = null;
    objectSnappingManager = null;
    transformSnappingManager = null;
    LFAAreaManager = null;
    debugEngine = null;
    setDisclaimer = null;
    autoPlacementManager = null;
    ignoreList = ["door", "frame", "handle", "wall", "moulding", "cap", "ceiling", "rod"];
    ignoreStackingList = ["curtain", "drape"];
    focusedAsset = null;
    isPlacementCorrectionRequired = false;
    isMultipleSelectionKeyPressed = false;

    /**
     * Struct that contains references to all the event callbacks for this module.
     */
    events = {
        scope: null,

        onMouseDown() {
            this.scope.mouseEvents.onMouseDown();
        },
        onMouseMove() {
            this.scope.mouseEvents.onMouseMove();
        },
        onMouseUp() {
            this.scope.mouseEvents.onMouseUp();
        }
    };

    // Variables for keeping track of movement origin & termination
    pointStart = new THREE.Vector3();
    pointEnd = new THREE.Vector3();
    pullOrigin = new THREE.Vector3();
    pullVector = new THREE.Vector3();

    // Array for tracking intersect targets for movement of scene assets
    placementInfoTargets = null;

    /**
     * Object Placement Manager allows you to translate and stack assets in scene
     * @param {SceneCreator} sceneCreator - The main scene creator instance.
     * @param {THREE.Scene} scene - The main Three JS scene that contains all of the objects.
     * @param {HTMLElement} sceneContainer - The HTML Div that contains the Three JS Scene.
     * @param {Array} sceneAssets - An array that contains references to all the assets currently in the scene.
     * @param {THREE.Vector2} mouse - Reference to vector2 containing mouse position in NDC (normalized device coordinates).
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    constructor(sceneCreator, scene, sceneContainer, sceneAssets, fixNormal, mouse, managersDict, setDisclaimer, actionManager, setIsSelectedAsset) {
        this.currentWallIntersect = null;
        this.selectedObjectPositions = [];
        this.selectedObjectPreviousParent = null;
        this.selectedObjectRotation = null;
        this.selectedCameraPosition = null;
        this.selectedCameraRotation = null;

        this.sceneCreator = sceneCreator;
        this.scene = scene;
        this.sceneContainer = sceneContainer;
        this.sceneAssets = sceneAssets;
        this.mouse = mouse;
        this.setDisclaimer = setDisclaimer;
        this.events.scope = this;
        this.fixNormal = fixNormal;
        this.clipping = false;
        this.enableTransformMenu = false;
        this.actionManager = actionManager;
        this.selectionManager = null;
        this.setIsSelectedAsset = setIsSelectedAsset;
        this.isMultipleItemsMoving = false;

        this.objectMovementSpeed = 0.01;
        this.wallNormal = undefined;
        this.helperPlane = this.buildHelperPlane();
        this.scene.add(this.helperPlane);

        this.setupRequiredManagers(managersDict);

        this.mouseEvents = new MouseEventsManager(this);
    }

    setSelectionManager(selectionManager) {
        this.selectionManager = selectionManager;
        this.objectSnappingManager.setSelection(selectionManager.selection);
        this.movementManager = new MovementManager(
            this,
            this.sceneCreator,
            this.scene,
            selectionManager,
            this.raycastManager,
            this.objectSnappingManager,
            this.pillowPlacementManager,
            this.getPlacementInfo.bind(this),
            this.validatePlacement.bind(this),
            this.pointStart,
            this.pointEnd,
            this.pullOrigin,
            this.pullVector,
            this.currentWallIntersect,
            this.LFAAreaManager,
            this.fixNormal,
            this.sceneAssets
        );
        this.stackingManager = new StackingManager(this.sceneCreator, selectionManager, this.raycastManager);
        this.placementCorrectionManager = new PlacementCorrectionManager(
            selectionManager,
            () => this.isPlacementCorrectionRequired // Pass a function
        );

        this.collisionManager = new CollisionManager(
            this.scene,
            this.selectionManager,
            this.raycastManager
        );

        this.placementValidationManager = new PlacementValidationManager(
            this.scene,
            this.selectionManager,
            this.raycastManager,
            this.spaceManager,
            this.pillowPlacementManager,
            this.sceneCreator
        );
    }

    /**
     * Setup the managers required by this module to work.
     */
    setupRequiredManagers(managersDict) {
        this.rotationControls = managersDict[Constants.Manager.RotationControls];
        this.transformControls = managersDict[Constants.Manager.TransformControls];
        this.navControl = managersDict[Constants.Manager.NavigationControl];
        this.assetManager = managersDict[Constants.Manager.AssetManager];
        this.spaceManager = managersDict[Constants.Manager.SpaceManager];
        this.raycastManager = managersDict[Constants.Manager.RaycastManager];
        this.debugEngine = managersDict[Constants.Manager.DebugEngine];
        this.LFAAreaManager = managersDict[Constants.Manager.LostAndFoundAreaManager];
        this.autoPlacementManager = managersDict[Constants.Manager.AutoPlacementManager];

        this.pillowPlacementManager = this.buildPillowPlacementManager(managersDict);
        this.objectSnappingManager = this.buildObjectSnappingManager(managersDict);
        this.transformSnappingManager = this.buildTransformSnappingManager(managersDict);
    }

    /**
     * Build pillow placement manager used for auto orientation & position validation of pillows.
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    buildPillowPlacementManager(managersDict) {
        const manager = new PillowPlacementManager(this.sceneCreator, this.sceneAssets, managersDict);
        managersDict[Constants.Manager.PillowPlacementManager] = manager;
        return manager;
    }

    /**
     * Build object snapping manager used for auto snapping(translation and rotation) of objects to walls and identical objects.
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    buildObjectSnappingManager(managersDict) {
        const manager = new ObjectSnappingManager(this.scene, managersDict);
        managersDict[Constants.Manager.ObjectSnappingManager] = manager;
        return manager;
    }

    buildTransformSnappingManager(managersDict) {
        const manager = new TransformSnappingManager(this, this.sceneCreator, this.scene, this.mouse, managersDict);
        managersDict[Constants.Manager.TransformSnappingManager] = manager;
        return manager;
    }

    /**
     * Build the helper plane used for moving the item on floor surface.
     */
    buildHelperPlane() {
        const plane = new TransformHelperPlane();
        plane.name = "helperPlane";
        return plane;
    }

    /**
     * Get selection
     */
    getSelection() {
        return this.selectionManager.selection;
    }

    /**
     * Get direction from the raycast intersect
     * @param {Object} intersect - Raycast intersection result
     */
    getDirectionForIntersect(intersect) {
        const direction = getNormalForIntersect(intersect, this.LFAAreaManager, this.fixNormal);
        return direction;
    }

    attachFreeModeControls(mode) {
        this.sceneCreator.attachFreeModeControls(mode, this.selectionManager.selection.objects[0]);
    }

    detachFreeModeControls = () => {
        this.sceneCreator.detachFreeModeControls();
        if (this.selectionManager.selection.objects.length > 0 && this.selectionManager.selection.placementType != Constants.PlacementType.WALL) {
            this.sceneCreator.attachRotationControls(this.selectionManager.selection.objects[0]);
        }
    };

    resetFreeModeTransform = (mode) => {
        this.restoreAssetPreviousState();
        if (this.selectionManager.selection.placementType == Constants.PlacementType.FLOOR || this.selectionManager.selection.placementType == Constants.PlacementType.CEILING) {
            this.selectionManager.selection.objects[0].quaternion.copy(new THREE.Quaternion());
        }
        let selectionObj = getObjectFromRootByName(this.selectionManager.selection.objects[0], this.selectionManager.selection.objects[0].name) || this.selectionManager.selection.objects[0];
        setHighlightedState(selectionObj, true, Constants.defaultHighLightColor);
    };

    /**
     * Filter raycast intersection results based on selection placement type
     * @param {List} intersections - Raycast intersection results list
     */
    filterIntersectionsByPlacementType = (intersections) => {
        const placementType = this.selectionManager.selection.objects[0].userData.placementType;
        if(placementType == Constants.PlacementType.FLOOR) {
            intersections = intersections.filter((item) => this.isValidNonRugIntersection(item));
        }
        return intersections;
    }

    setSnapToPointMode = (state) => {
        this.transformSnappingManager.enableSnapToPointMode(state);
        this.sceneCreator.showSnapToPointMode(state);
        if (state) {
            this.sceneContainer.style.cursor = "crosshair";
        }
        else {
            this.sceneContainer.style.cursor = "auto";
        }
    }

    snapToSurface = () => {
        this.transformSnappingManager.snapToSurface();
        if (this.selectionManager.selection.objects[0] != null && this.selectionManager.selection.objects[0].userData.isPillow) {
            this.pillowPlacementManager.adjustPillowHeight(this.selectionManager.selection.objects[0]);
        }
    }

    showTransformMenu (state) {
        this.enableTransformMenu = state;
    }

    /**
     * Preserve last state of selected asset;
     */
    preserveAssetPreviousState() {
        this.selectionManager.selection.refreshSelectionTransform();
        this.selectionManager.selection.objects[0].userData.lastValidPosition.copy(this.selectionManager.selection.worldPosition);
        this.selectionManager.selection.objects[0].userData.lastValidQuaternion.copy(this.selectionManager.selection.worldQuaternion.clone());
        this.selectionManager.selection.objects[0].userData.lastValidParent = this.selectionManager.selection.objects[0].parent;
    }

    /**
     * Restore last state of selected asset;
     */
    restoreAssetPreviousState() {
        if (this.selectionManager.selection.objects[0].userData.lastValidPosition && this.selectionManager.selection.objects[0].userData.lastValidQuaternion) {
            this.scene.attach(this.selectionManager.selection.objects[0]);
            this.selectionManager.selection.objects[0].position.copy(this.selectionManager.selection.objects[0].userData.lastValidPosition);
            this.selectionManager.selection.objects[0].quaternion.copy(this.selectionManager.selection.objects[0].userData.lastValidQuaternion);
            this.updateParent(this.selectionManager.selection.objects[0], this.selectionManager.selection.objects[0].userData.lastValidParent);
            if (this.selectionManager.selection.objects[0].parent != this.scene) {
                this.selectionManager.selection.objects[0].userData.isStacked = true;
            } else {
                this.selectionManager.selection.objects[0].userData.isStacked = false;
            }
        }
    }

    /**
     * Check if item asset is completely on a rug within its bounds
     * @param {THREE.Scene} rugObj - the rug object 
     * @param {THREE.Scene} item - floor item to check if it is completely on the rug or not
     */
    isItemIntersectingRugBounding(rugObj, item) {
        // if object is bounded in the rug place it on rug else place on the floor;
        var bbox = new THREE.Box3().setFromObject(item);
        var corners = [
            new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.max.z),
            new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.min.z),
            new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.max.z),
            new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.min.z)
        ];
        let itemsArray = [rugObj];
        for (let i in corners) {
            let rugIntersect = this.raycastManager.setAndIntersectAll(corners[i], new THREE.Vector3(0, -1, 0), itemsArray);
            if (rugIntersect == null || (rugIntersect != null && rugIntersect.length == 0)) {
                // not on rug place on floor 
                return true;
            }
        }
        return false;
    }

    /**
     * Check if item is intersecting a rug object
     * @param {THREE.Scene} object - object to check if it is a rug or not
     * @param {THREE.Scene} item - the item to check if it is on a rug or floor
     */
    isItemIntersectingRug(object, item) {
        // return false on rug, true on floor
        if (item != null && (item.userData.isPillow || item.userData.isRug)) {
            return true;
        }
        let isRugVal = false;
        if (object.userData.isSceneAsset) {
            let objectNode = getRootNodeFromObject(this.scene, object);
            if (objectNode != false) {
                isRugVal = objectNode.userData.isRug;
            }
            if (isRugVal == true) {
                isRugVal = this.isItemIntersectingRugBounding(object, item);
            }
        }
        return !isRugVal;
    }

    /**
     * to detect if the wall item is being attempted to be placed on a moulding on the wall
     * @param {THREE.Vector3} wallPosition - vector position of the object on the wall
     * @param {THREE.Vector3} directionVectorsWall - list of direction vectors
     * @param {THREE.Vector3} direction - world direction of wall item object
     * @param {THREE.Scene} wallMesh - wall object
     * @param {THREE.Scene} objectMesh - wall item object
     */
    detectWallMoulding(itemPositionOnWall, directionVectors, wallMesh, raycastLengths) {
        let mouldingIntersect = null;
        for (let i = 0; i < directionVectors.length; i++) {
            // raycast to find intersection from the point on the wall to left right up and down directions to detect moulding on the wall
            this.raycastManager.updateRaycasterProperties(itemPositionOnWall, directionVectors[i], raycastLengths[i]);
            mouldingIntersect = this.raycastManager.setAndIntersect(itemPositionOnWall, directionVectors[i], wallMesh);
            this.raycastManager.resetFarValue();
            if (mouldingIntersect != false) {
                return true;
            }
        }
        return false;
    }

    /**
     * try to adjust wall item incase it is placed on the moulding on the wall
     */
    adjustWallItemIntersectingMoulding() {
        if (this.selectionManager.selection.placementType == Constants.PlacementType.WALL && !this.selectionManager.selection.objects[0]?.userData?.isFrozen) {
            this.selectionManager.selection.refreshSelectionTransform();
            let pvb = this.selectionManager.selection.objects[0].userData.pvb;
            let objBoundsData = pvb.getDataForSnapTest();
            // locate wall object
            this.raycastManager.updateRaycasterProperties(this.selectionManager.selection.worldPosition, objBoundsData.backDir, pvb.halfDepth);
            let wallIntersect = this.raycastManager.setAndIntersect(this.selectionManager.selection.worldPosition, objBoundsData.backDir, this.sceneCreator.space.walls);
            this.raycastManager.resetFarValue();
            if (wallIntersect != false) {
                var leftDir = objBoundsData.leftDir;
                var rightDir = objBoundsData.rightDir;
                var upDir = objBoundsData.topDir;
                var downDir = objBoundsData.bottomDir;
                var directionVectors = [leftDir, rightDir, upDir, downDir];
                var raycastLengths = [pvb.halfWidth, pvb.halfWidth, pvb.halfHeight, pvb.halfHeight];
                let itemPositionOnWall = new THREE.Vector3();
                var wallMesh = [wallIntersect.object];
                let previousPosition = this.selectionManager.selection.worldPosition.clone();
                let offset = 0.03;
                itemPositionOnWall.copy(this.selectionManager.selection.objects[0].position).add(objBoundsData.backDir.multiplyScalar(pvb.halfDepth));
                while (this.detectWallMoulding(itemPositionOnWall, directionVectors, wallMesh, raycastLengths) == true) {
                    this.selectionManager.selection.objects[0].position.copy(previousPosition).add(this.selectionManager.selection.worldDirection.multiplyScalar(offset));
                    this.selectionManager.selection.objects[0].updateMatrixWorld();
                    offset += 0.01;
                    // move in from center to the back of the item to detect moulding accurately
                    itemPositionOnWall.copy(this.selectionManager.selection.objects[0].position).add(objBoundsData.backDir.multiplyScalar(pvb.halfDepth));
                }
                this.selectionManager.selection.refreshSelectionTransform();
            }
        }
    }

    /**
     * check if the selected item is intersecting with any wall. if yes then find intersection with the wall and then move it
     */
    validateFloorItemIntersectingWall() {
        return this.placementValidationManager.validateFloorItemIntersectingWall();
    }

    /**
     * Get placement info for some Constants.PlacementType.
     * @param {PlacementType} placementType - The PlacementType to use.
     */
    getPlacementInfo(placementType) {
        let results = this.getRaycastResults();
        let placementInfo = this.initializePlacementInfo();

        if (placementType === Constants.PlacementType.FLOOR) {
            return this.getFloorPlacementInfo(results, placementInfo);
        }
        else if (placementType === Constants.PlacementType.WALL) {
            return this.getWallPlacementInfo(results, placementInfo);
        }
        else if (placementType === Constants.PlacementType.CEILING) {
            return this.getCeilingPlacementInfo(results, placementInfo);
        }

        return false;
    }

    /**
     * Get wall intersect
     */
    getWallIntersect(item) {
        if(item === undefined || item === null) {
            return null;
        }
        //get direction of item
        let direction = new THREE.Vector3();
        item.getWorldDirection(direction);
        //get raycast results from the item to the opposite direction of the item
        let raycastResults = this.raycastManager.setAndIntersectAll(item.getWorldPosition(new THREE.Vector3()), direction.negate(), this.sceneCreator.space.walls);
        if (raycastResults.length > 0) {
            return raycastResults[0];
        }
        return null;
    }

    /**
     * Get object under contact
     */
    getObjectUnderContact() {

        let results = this.raycastManager.setFromCameraAndIntersect(this.mouse, this.sceneCreator.activeCamera, this.selectionManager.selection.objects, true);
        if (results.length > 0) {
            return results[0];
        }
        return false;
    }

    setLastValidParentForSelectedObjects() {
        this.selectionManager.selection.objects.forEach((item) => {
            item.userData.lastValidParent = item.parent;
        });
    }

    /**
     * Set selected object in multiple selection
     */
    setSelectedObjectInMultipleSelection() {
        let result = this.getObjectUnderContact();
        if (result) {
            this.selectionManager.selectedObjectInMultipleSelection = getRootNodeFromObject(this.scene, result.object);
        }
        else {
            this.selectionManager.selectedObjectInMultipleSelection = null;
        }
    }

    /**
     * check if any of the selected objects is intersecting with any wall.
     */
    checkIfWallIntersected() {
        let intersectedWall = false;
        this.selectionManager.selection.objects.forEach((item) => {
            for (let i = 0; i < this.spaceManager.walls.length; i++) {
                let wall = this.spaceManager.walls[i];
                let boundingBox = new THREE.Box3().setFromObject(item);
                let wallBoundingBox = new THREE.Box3().setFromObject(wall);
                if (wallBoundingBox.intersectsBox(boundingBox)) {
                    intersectedWall = true;
                    break;
                }
            }
        });
        return intersectedWall;
    }

    /**
     * update last valid position of selected objects by checking any intersections with walls.
     */
    updateLastValidPositionWithWallIntersections() {
        let intersectedWall = this.checkIfWallIntersected();
        this.selectionManager.selection.objects.map((item, index) => {
            let selectedObject = getObjectFromRootByName(item, item.name);
            if (item.userData.lastValidPosition == undefined || !intersectedWall) {
                item.userData.lastValidPosition = item.position.clone();
                item.userData.lastValidParent = item.parent;
            }
            if (intersectedWall) {
                item.userData.isCollidingWithWall = true;
                setHighlightedState(selectedObject, true, Constants.invalidHighLightColor);
            } else {
                item.userData.isCollidingWithWall = false;
                setHighlightedState(selectedObject, true, Constants.defaultHighLightColor);
            }
        });
    }

    /**
     * move all selected objects to the raycast results of floors, misc nodes and scene assets
     */
    moveMultipleFloorItems() {
        this.moveSelectedObjectsHorizontally();
        this.adjustSelectedObjectsVertically();
        this.updateLastValidPositionWithWallIntersections();
        this.selectionManager.selection.refreshSelectionTransformArray();

    }

    /**
     * Move all selected objects horizontally by raycasting to the mouse position
     */
    moveSelectedObjectsHorizontally() {
        const raycastTargets = this.getRaycastTargetsForHorizontalMovement();
        const raycastResults = this.raycastManager.setFromCameraAndIntersect(
            this.mouse, 
            this.sceneCreator.activeCamera, 
            raycastTargets, 
            true
        );

        if (raycastResults.length > 0) {
            const horizontalOffset = this.calculateHorizontalOffset(raycastResults[0]);
            this.applyHorizontalOffsetToSelection(horizontalOffset);
        }

    }

    /**
     * Get raycast targets for horizontal movement
     */
    getRaycastTargetsForHorizontalMovement() {
        let raycastTargets = this.sceneCreator.space.floors.concat(this.spaceManager.miscNodes).concat(this.sceneAssets.filter(item => !this.selectionManager.selection.objects.includes(item)));
        return raycastTargets;
    }

    /**
     * Calculate horizontal offset based on raycast result
     */
    calculateHorizontalOffset(raycastResult) {
        const offset = new THREE.Vector3()
            .copy(raycastResult.point)
            .sub(this.selectionManager.selectedObjectInMultipleSelection.getWorldPosition(new THREE.Vector3));
        offset.y = 0; // Only keep horizontal movement
        return offset;
    }

    /**
     * Apply horizontal offset to all selected objects
     */
    applyHorizontalOffsetToSelection(offset) {
        this.selectionManager.selection.objects.forEach((item) => {
            item.position.add(offset);
        });
        this.selectionManager.selection.refreshSelectionTransformArray();
    }

    /**
     * Adjust the vertical position of all selected objects by checking for collisions with other objects
     */
    adjustSelectedObjectsVertically() {
        const collisionTargets = this.getCollisionTargetsForVerticalAdjustment();

        this.selectionManager.selection.objects.forEach((item) => {
            this.handleVerticalCollision(item, collisionTargets);
        });
    }

    /**
     * Get collision targets for vertical adjustment
     */
    getCollisionTargetsForVerticalAdjustment() {
        const miscNodes = this.spaceManager.miscNodes.filter(item => !this.ignoreList.includes(item));
        const nonSelectedAssets = this.sceneAssets.filter(
            asset => !this.selectionManager.selection.objects.includes(asset)
            && asset.userData.placementType == Constants.PlacementType.FLOOR
        );
        return miscNodes.concat(nonSelectedAssets).concat(this.spaceManager.floors);
    }

    /**
     * Handle vertical collision with floor
     */
    handleVerticalCollisionFloor(item, collisionTargets) {
        let raycastResult = this.findNearestSurfaceBelow(item, collisionTargets);
        if (raycastResult.length > 0) {
            item.position.y = raycastResult[0].point.y;
        }
    }

    /**
     * Adjusts the vertical position of an item by finding the nearest surface below it
     * and moving it to that surface, if a surface is found.
     * 
     * @param {THREE.Object3D} item - The item whose vertical position is being adjusted.
     * @param {Array} allCollisionTargets - Array of all potential collision targets.
     */

    handleVerticalCollision(item, allCollisionTargets) {
        // Find nearest surface below item
        const raycastResult = this.findNearestSurfaceBelow(item, allCollisionTargets);
        // If there is a surface below item, move item to the closest surface
        if (raycastResult.length > 0) {
            item.position.y = raycastResult[0].point.y;
        }
    }

    /**
     * Set parent before vertical collision
     */
    setParentBeforeVerticalCollision() {
        this.selectionManager.selection.placementType === Constants.PlacementType.FLOOR && this.selectionManager.selection.objects.forEach((item) => {
            this.scene.attach(item);
        });
    }

    /**
     * Set parent after vertical collision
     */
    setParentAfterVerticalCollision() {
        this.selectionManager.selection.objects.forEach((item) => {
            // set parent to the raycasted item below
            let collisionTargets = this.getCollisionTargetsForVerticalAdjustment();
            let raycastResult = this.findNearestSurfaceBelow(item, collisionTargets);
            if (raycastResult.length > 0) {
                let newParent = getRootNodeFromObject(this.scene, raycastResult[0].object);
                if (newParent !== undefined && !newParent.userData.isRug) {
                    item.userData.lastValidParent = newParent;
                    item.userData.isStacked = true;
                    newParent.attach(item);
                } else {
                    item.userData.lastValidParent = this.scene;
                    item.userData.isStacked = false;
                    this.scene.attach(item);
                }
            }
        });
    }

    findNearestSurfaceBelow(item, collisionTargets) {
        let itemPositionWithSpaceY = new THREE.Vector3().copy(item.getWorldPosition(new THREE.Vector3()));
        itemPositionWithSpaceY.y = this.sceneCreator.getSpaceHeight();
        // const raycastTargets = collisionTargets.concat(this.sceneCreator.space.floors);
        const raycastResult = this.raycastManager.setAndIntersectAll(
            itemPositionWithSpaceY,
            new THREE.Vector3(0, -1, 0),
            collisionTargets
        );
        
        return item.userData.isRug ? 
            this.filterRugResults(raycastResult) : 
            this.filterNonRugResults(raycastResult);
    }

    moveMultipleCeilingItems() {
        let raycastResult = this.raycastManager.setFromCameraAndIntersect(this.mouse, this.sceneCreator.activeCamera, this.sceneCreator.space.ceilings, true);
        if (raycastResult.length > 0) {
            let offset = raycastResult[0].point.clone().sub(this.selectionManager.selectedObjectInMultipleSelection.position);
            offset.y = 0;
            this.selectionManager.selection.objects.forEach((item) => {
                item.position.add(offset);
            });
            this.selectionManager.selection.refreshSelectionTransformArray();
        }
    }

    getMultipleObjectsPlacementInfo() {
        this.isMultipleItemsMoving = true;
        if (this.selectionManager.selection.placementType == Constants.PlacementType.FLOOR) {
            this.moveMultipleFloorItems();
        }
        if(this.selectionManager.selection.placementType == Constants.PlacementType.WALL) {
            this.moveMultipleWallItems();
        }
        if(this.selectionManager.selection.placementType == Constants.PlacementType.CEILING) {
            this.moveMultipleCeilingItems();
        }
    }

    moveMultipleWallItems() {
        let raycastResult = this.raycastManager.setFromCameraAndIntersect(this.mouse, this.sceneCreator.activeCamera, this.sceneCreator.space.walls, true);
        
        if (raycastResult.length > 0 && raycastResult[0].object == this.selectionManager.wallIntersect) {
            let offset = raycastResult[0].point.clone().sub(this.selectionManager.selectedObjectInMultipleSelection.position);
            let allItemsOnSameWall = true;
            this.selectionManager.selection.objects.forEach((item) => {
                // move the item according to mouse position only if it is on the same wall
                let wallIntersect = this.getWallIntersect(item);
                if(!wallIntersect || (wallIntersect && wallIntersect.object != this.selectionManager.wallIntersect)) {
                    allItemsOnSameWall = false;
                }
            });

            this.selectionManager.selection.objects.forEach((item) => {
                if(allItemsOnSameWall) {
                    item.userData.lastValidPosition = item.position.clone();
                    let selectionObj = getObjectFromRootByName(item, item.name) || item;
                    setHighlightedState(selectionObj, true, Constants.defaultHighLightColor);
                } else {
                    let selectionObj = getObjectFromRootByName(item, item.name) || item;
                    setHighlightedState(selectionObj, true, Constants.invalidHighLightColor);
                }
                item.position.add(offset);
            });
            this.selectionManager.selection.refreshSelectionTransformArray();
        
        }
    }


    setParentMultipleItemsAfterMovement() {
        this.selectionManager.selection.objects.forEach((item) => {
            if (item.userData.lastValidParent !== undefined) {
                this.updateParent(item, item.userData.lastValidParent);
            }
        });
    }

    /**
     * Initialize empty placement info object
     */
    initializePlacementInfo() {
        return {
            helperPoint: null,
            intersectionPoint: null,
            intersectedObj: null,
            direction: null,
            isMiscAsset: false
        };
    }

    /**
     * Get raycast results based on selection state
     */
    getRaycastResults() {
        // Sample over a small area around mouse position for finding intersects when item is stacked
        if (this.selectionManager.selection.objects.length > 0 && this.selectionManager.selection.objects[0].userData.isStacked) {
            return this.getStackedRaycastResults();
        } else {
            return this.raycastManager.setFromCameraAndIntersect(this.mouse, this.sceneCreator.activeCamera, this.placementInfoTargets, true);
        }
    }

    /**
     * Get raycast results for stacked objects by sampling multiple points
     */
    getStackedRaycastResults() {
        let numOfRaycasts = 3;
        let offset = 0.01;
        let offsetForCurrentIteration = offset * Math.floor(numOfRaycasts / 2) * -1;

        let raycastPoint = new THREE.Vector2();
        let raycastResultsWithSmallestDistance;

        for (let index = 0; index < numOfRaycasts; index++) {
            raycastPoint.copy(this.mouse);
            raycastPoint.x += offsetForCurrentIteration;
            raycastPoint.y += offsetForCurrentIteration;

            let results = this.raycastManager.setFromCameraAndIntersect(raycastPoint, this.sceneCreator.activeCamera, this.placementInfoTargets, true);
            
            if (raycastResultsWithSmallestDistance == null) {
                raycastResultsWithSmallestDistance = results;
            }
            if (results != false && results.length > 0 && raycastResultsWithSmallestDistance != false && raycastResultsWithSmallestDistance.length > 0) {
                if (results[0].distance < raycastResultsWithSmallestDistance[0].distance) {
                    raycastResultsWithSmallestDistance = results;
                }
            }

            this.debugEngine.debugLog(results[0]);
            this.debugEngine.drawRayCastGizmo(this.raycastManager.raycaster);

            offsetForCurrentIteration += offset;
        }

        return raycastResultsWithSmallestDistance;
    }

    /**
     * Get placement info for floor placement type
     */
    getFloorPlacementInfo(results, placementInfo) {
        if (results.length > 0) {
            this.updateHelperPlanePosition(results);
            this.updateHelperPoint(results, placementInfo);
            
            results = this.filterFloorResults(results);
            
            let firstContact = results[0];
            if (firstContact == undefined || firstContact == null) {
                return this.handleNoValidFloorIntersection(placementInfo);
            }

            if (!this.spaceManager.objectBelongsToFloor(firstContact.object)) {
                return this.handleStackingOnItem(firstContact, placementInfo);
            }

            return placementInfo;
        }
        return false;
    }

    /**
     * Update helper plane position based on floor intersections
     */
    updateHelperPlanePosition(results) {
        for (var result of results) {
            if (this.spaceManager.objectBelongsToFloor(result.object)) {
                this.helperPlane.position.y = result.point.y;
                break;
            }
        }
    }

    /**
     * Update helper point in placement info if helper plane is intersected
     */
    updateHelperPoint(results, placementInfo) {
        let intersectsHelper = results.find((item) => { return (item.object instanceof TransformHelperPlane); });
        if (intersectsHelper) {
            placementInfo.helperPoint = new THREE.Vector3().copy(intersectsHelper.point);
        }
    }

    /**
     * Filter results for floor placement
     */
    filterFloorResults(results) {
        if (this.selectionManager.selection.objects[0].userData.isRug) {
            return this.filterRugResults(results);
        }
        return this.filterNonRugResults(results);
    }

    /**
     * Filter results specifically for rug objects
     */
    filterRugResults(results) {
        return results.filter((item) => {
            if (item.object.userData && item.object.userData.isSceneAsset) {
                return getRootNodeFromObject(this.scene, item.object).userData.isRug;
            }
            else {
                return this.isValidNonRugIntersection(item);
            }
        });
    }

    /**
     * Filter results for non-rug objects
     */
    filterNonRugResults(results) {
        return results.filter((item) => {
            return this.isValidNonRugIntersection(item);
        });
    }

    /**
     * Check if intersection is valid for non-rug objects
     */
    isValidNonRugIntersection(item) {
        return (!this.spaceManager.ceilings.includes(item.object) &&
            !(item.object instanceof TransformHelperPlane) &&
            !this.spaceManager.walls.includes(item.object) &&
            !this.spaceManager.doors.includes(item.object) &&
            !this.spaceManager.windows.includes(item.object) &&
            !(item.object.userData && item.object.userData.isGrid) &&
            !isAncestor(this.selectionManager.selection.objects[0], item.object, this.scene) &&
            (!isConvexHull(item.object)));
    }

    /**
     * Handle case when no valid floor intersection is found
     */
    handleNoValidFloorIntersection(placementInfo) {
        let results = this.raycastManager.setAndIntersectAll(
            this.selectionManager.selection.objects[0].position.clone(), 
            new THREE.Vector3(0, -1, 0), 
            this.placementInfoTargets
        );
        results = results.filter((item) => this.isValidNonRugIntersection(item));
        let firstContact = results[0];
        if (firstContact == undefined || firstContact == null) {
            return placementInfo;
        }
        return this.handleStackingOnItem(firstContact, placementInfo);
    }

    /**
     * Handle stacking on another item
     */
    handleStackingOnItem(firstContact, placementInfo) {
        if (true || firstContact.face.normal.z >= 0.95 && firstContact.face.normal.z <= 1.05) {
            if (firstContact.object.userData.isSceneAsset) {
                return this.handleStackingOnSceneAsset(firstContact, placementInfo);
            }
            else if (firstContact.object.userData.isSceneMisc) {
                return this.handleStackingOnMiscAsset(firstContact, placementInfo);
            }
        }
        return null;
    }

    /**
     * Handle stacking specifically on scene assets
     */
    handleStackingOnSceneAsset(firstContact, placementInfo) {
        let intersectedObjRoot = getRootNodeFromObject(this.scene, firstContact.object);
        placementInfo.intersectedObj = intersectedObjRoot;
        placementInfo.intersectionPoint = new THREE.Vector3().copy(firstContact.point);
        if (this.selectionManager.selection.objects[0].userData.isRug && 
            intersectedObjRoot.userData.isRug && 
            this.selectionManager.selection.objects[0].userData.size.y < intersectedObjRoot.userData.size.y) {
            placementInfo.intersectionPoint.y += 0.005;
        }
        placementInfo.direction = getNormalForIntersect(firstContact, this.LFAAreaManager);
        return placementInfo;
    }

    /**
     * Handle stacking specifically on misc assets
     */
    handleStackingOnMiscAsset(firstContact, placementInfo) {
        let firstContactName = firstContact.object.name.toLowerCase();

        for (let ignoreKeyword of this.ignoreList) {
            if (firstContactName.includes(ignoreKeyword)) {
                return null;
            }
        }
        for (let ignoreKeyword of this.ignoreStackingList) {
            if (firstContactName.includes(ignoreKeyword)) {
                this.selectionManager.selection.objects[0].userData.isStacked = false;
                return null;
            }
        }
        placementInfo.intersectedObj = getRootNodeFromObject(this.scene, firstContact.object);
        placementInfo.intersectionPoint = new THREE.Vector3().copy(firstContact.point);
        placementInfo.direction = getNormalForIntersect(firstContact, this.LFAAreaManager, this.fixNormal);
        placementInfo.isMiscAsset = true;
        return placementInfo;
    }

    /**
     * Get placement info for wall placement type
     */
    getWallPlacementInfo(results, placementInfo) {
        if (results.length > 0) {
            results = results.filter((item) => {
                return (!this.spaceManager.ceilings.includes(item.object) && 
                        !(item.object.userData && item.object.userData.isGrid) && 
                        !(item.object.geometry instanceof ConvexGeometry));
            });

            this.updateHelperPoint(results, placementInfo);

            let firstContact = results[0];

            if (firstContact != null && this.spaceManager.walls.includes(firstContact.object)) {
                placementInfo.direction = getNormalForIntersect(firstContact, this.LFAAreaManager, this.fixNormal);
                if (Math.round(placementInfo.direction.y) == 0) {
                    placementInfo.intersectionPoint = new THREE.Vector3().copy(firstContact.point);
                }
            }

            return placementInfo;
        }
        return false;
    }

    /**
     * Get placement info for ceiling placement type
     */
    getCeilingPlacementInfo(results, placementInfo) {
        if (results.length > 0) {
            for (var result of results) {
                if (this.spaceManager.objectBelongsToCeiling(result.object)) {
                    if (result.object.name == "LFAArea_Ceiling" && result.face.normal.round().y == 1) {
                        result.point.y -= 0.1;
                    }
                    else if (result.face.normal.round().z == 1) {
                        result.point.y -= result.object.userData.height;

                        let ceilingResult = this.raycastManager.setAndIntersectAll(
                            result.point.clone(), 
                            new THREE.Vector3(0,1,0), 
                            this.sceneCreator.space.ceilings
                        );
                        if (ceilingResult != null && ceilingResult.length > 0) {
                            result.point.y += ceilingResult[0].distance;
                        }
                    }
                    this.helperPlane.position.y = result.point.y;
                    break;
                }
            }

            this.updateHelperPoint(results, placementInfo);
            return placementInfo;
        }
        return false;
    }

    /**
     * Update movement origin variables according to placement info.
     */
    setMovementOrigin() {
        this.movementManager.setMovementOrigin();
    }

    /**
     * Reset selection.
     */
    resetSelection () {
        
        if ( this.selectionManager.selection.objects.length > 0 ) {
            this.detachFreeModeControls();
            this.sceneCreator.showProductSizeControls(false);
            // this.sceneCreator.transformControls.hide();
            this.sceneCreator.detachRotationControls();
        }

        this.selectionManager.resetSelection();
        this.selectionManager.selection.state = Constants.AssetState.PLACED;
        this.setIsSelectedAsset(false);
    }

    /**
     * Select an asset.
     * @param {THREE.Scene} asset - The asset to select.
     */
    setSelection ( asset ) {
        
        if (asset == null || asset == undefined) return;

        if ( this.selectionManager.selectionMode === Constants.SelectionMode.SINGLE && this.selectionManager.selection.objects[0] == asset ) {
            this.objectSnappingManager.snapGuide.setTarget( asset );
            return;
        }

        else if ( this.selectionManager.selection.objects.length > 1 ) {
            this.detachFreeModeControls();
            // this.sceneCreator.detachRotationControls();
        }

        this.selectionManager.setSelection(asset);

        this.setIsSelectedAsset(true);

        // this.sceneCreator.showSelectedAssetUI();
        if(this.selectionManager.selection.placementType != Constants.PlacementType.WALL) {
            this.sceneCreator.attachRotationControls( this.selectionManager.selection.objects[0] );
        }
        else {
            this.detachFreeModeControls();
            this.sceneCreator.detachRotationControls();
        }
        if ( this.selectionManager.selection.objects.length > 0 && this.selectionManager.selection.placementType.toLowerCase() == Constants.PlacementType.WALL.toLowerCase()) { 
            const forwardDirection = this.selectionManager.selection.worldDirection.clone(); // Clone the forward direction
            const backwardDirection = forwardDirection.negate(); // Negate the vector to get the backward direction
            this.currentWallIntersect = this.raycastManager.setAndIntersect(this.selectionManager.selection.worldPosition, backwardDirection, this.sceneCreator.space.walls);
        }

        this.objectSnappingManager.snapGuide.setTarget( asset );
        if (this.selectionManager.selection.objects[0] && (!this.selectionManager.selection.objects[0].userData.lastValidPosition || !this.selectionManager.selection.objects[0].userData.lastValidQuaternion)) {
            this.selectionManager.selection.objects[0].userData.lastValidPosition = new THREE.Vector3();
            this.selectionManager.selection.objects[0].userData.lastValidQuaternion = new THREE.Quaternion();
            this.preserveAssetPreviousState();
        }

        if (this.enableTransformMenu) {
            this.sceneCreator.showProductSizeControls(true);
        }

        if (this.sceneCreator.activeCamera.name == "topDown" || this.sceneCreator.activeCamera.name == "topDownOrtho") {
            this.spaceManager.disableCeiling();
        }
        
        this.selectedObjectPositions = this.selectionManager.selection.objects.map(obj => ({
            object: obj,
            position: obj.position.clone(),
            rotation: obj.rotation.clone(),
            parent: obj.parent
        }));
        this.selectedObjectRotation = this.selectionManager.selection.objects[0].rotation.clone();
        this.selectedObjectPreviousParent = this.selectionManager.selection.objects[0].parent;
    }

    applyActionPreservePosition(action) {

        this.selectionManager.selection.refreshSelectionTransform();
        const parent = this.selectionManager.selection.objects[0].parent;
        this.scene.attach(this.selectionManager.selection.objects[0]);
        const prevPositionY = this.selectionManager.selection.worldPosition.y;
        let assetObj = getObjectFromRootByName(this.selectionManager.selection.objects[0], this.selectionManager.selection.objects[0].name) || this.selectionManager.selection.objects[0];
        const prevSize = new THREE.Box3().setFromObject(assetObj).getSize();
        const prevCenter = new THREE.Box3().setFromObject(assetObj).getCenter();
        action();
        const size = new THREE.Box3().setFromObject(assetObj).getSize();
        const center = new THREE.Box3().setFromObject(assetObj).getCenter();
        this.selectionManager.selection.objects[0].position.y = prevPositionY + ((prevCenter.y - (prevSize.y/2.0)) - ((center.y - (size.y/2.0)))) ;
        if (parent != this.scene) {
            this.updateParent(this.selectionManager.selection.objects[0], parent);
        }
    }

    setProductSize(length, height, depth) {
        if (this.selectionManager.selection.objects.length > 0) {
            let assetSize = this.selectionManager.selection.objects[0].userData.size;
            let lengthInScale = length / assetSize.x; 
            let heightInScale = height / assetSize.y; 
            let depthInScale = depth / assetSize.z; 

            let resizeAction = () => this.setProductScale(this.selectionManager.selection.objects[0],lengthInScale, heightInScale, depthInScale);
            this.applyActionPreservePosition(resizeAction);

            if (this.transformControls.enabled) {
                this.sceneCreator.setFreeControlsSize(this.selectionManager.selection.objects[0]);
            }
            else {
                this.sceneCreator.attachRotationControls(this.selectionManager.selection.objects[0]);
            }
        }
    }

    getProductSize() {
        if (this.selectionManager.selection.objects.length > 0) {
            let assetSize = this.selectionManager.selection.objects[0].userData.size;
            let scale = this.getProductScale(this.selectionManager.selection.objects[0]);
            let length =  scale.x * assetSize.x ;
            let height =  scale.y *  assetSize.y ;
            let depth = scale.z *  assetSize.z;
            
            let productSize = {
                'length': length,
                'height': height,
                'depth': depth
            }
            return productSize;
        }
    }

    resetProductSize() {
        if (this.selectionManager.selection.objects[0] != null) {

            let resizeAction = () => this.setProductScale(this.selectionManager.selection.objects[0],1,1,1);
            this.applyActionPreservePosition(resizeAction);
            if (this.transformControls.enabled) {
                this.sceneCreator.setFreeControlsSize(this.selectionManager.selection.objects[0]);
            }
            else {
                this.sceneCreator.attachRotationControls(this.selectionManager.selection.objects[0]);
            }
        }
    }

    getProductScale(asset) {
        let assetObj = getObjectFromRootByName(asset, asset.name);
        if (assetObj) {
            return assetObj.scale;
        }
        return new THREE.Vector3(1,1,1);
    }

    setProductScale(asset, lengthInScale, heightInScale, depthInScale) {
        let assetObj = getObjectFromRootByName(asset, asset.name);
        if (assetObj) {
            assetObj.scale.set(lengthInScale, heightInScale, depthInScale);
            asset.userData.scale.set(lengthInScale, heightInScale, depthInScale);
            asset.userData.pvb.update();
        }
    }
    
    /**
     * Clone currently selected asset.
     */
    cloneSelectedAsset() {
        if ( this.selectionManager.selection.objects.length > 0 ) {
            let clonedAsset = this.autoPlacementManager.cloneSelectedAsset( this.selectionManager.selection.objects[0], this.selectionManager.selection.placementType );
            this.setSelection( clonedAsset );
            this.selectionManager.selection.objects[0].userData.isFrozen = false;
            setHighlightedState( this.selectionManager.selection.objects[0], true );
            this.actionManager.addAction({
                transformation: "clone",
                callback: () => {
                    this.setSelection( clonedAsset );
                    this.sceneCreator.deleteSelectedAsset(false);
                },
                resetTransform: () => this.selectionManager.selection.refreshSelectionTransform()
            })
            console.log("action manager", this.actionManager.actionStack)
        }
    }

    /*
    *load New asset, delete the selected one and change selection
    */
    swapSelectedAsset(swappedAssetId) {
        if ( this.selectionManager.selection.objects.length > 0 ) {

            let swapAction = () => {
                let swappedAsset = this.autoPlacementManager.swapSelectedAsset( this.selectionManager.selection.objects[0], swappedAssetId );
                this.deleteSelectedAsset(true);
                this.setSelection( swappedAsset );
            }

            this.applyActionPreservePosition(swapAction);
        }
    }

    /**
     * Delete currently selected asset.
      * @param {THREE.Scene} isSwapped - Boolean for asset deleted on swap
     */
    deleteSelectedAsset(isSwapped = false) {
        if ( this.selectionManager.selection.objects.length > 0 ) {
            let itemToDelete = this.selectionManager.selection.objects[0];
            itemToDelete.userData.isSwapped = isSwapped;
            itemToDelete.userData.visible = false;

            let objectToDelete = getObjectFromRootByName(this.selectionManager.selection.objects[0], this.selectionManager.selection.objects[0].name) || itemToDelete;

            this.resetSelection();

            objectToDelete.traverse( function ( child ) {
                if ( child.isMesh ) {
                    // Make invisible
                    child.visible = false;
                }
            });

            this.raycastManager.buildFocusTargetList();
            return itemToDelete;
        }
        return null;
    }

    /**
     * Reset focused asset
     */
    resetFocusedAsset() {
        if (this.focusedAsset != null && this.focusedAsset != this.selectionManager.selection.objects[0] ) {
            let focusedAssetObj = getObjectFromRootByName(this.focusedAsset, this.focusedAsset.name) || this.focusedAsset;
            setHighlightedState( focusedAssetObj, false );
            this.focusedAsset = null;
        }
    }

    /**
     * Returns true if the currently focused asset is also the selected asset.
     */
    isFocusedAssetSelected() {
        // return true if focused asset is part of any of the selected assets
        return (this.focusedAsset != null && this.selectionManager.selection.objects.length > 0 && this.selectionManager.selection.objects.includes(this.focusedAsset) );
    }

    /**
     * Checks wether the currently selected asset is inside valid space bounds & update the AssetState & highlighted color accordingly.
     */
    validatePlacement () {
        this.placementValidationManager.validatePlacement();
    }

    /***
     * Checks wether the currently selected pillow has valid placement on rotation.
     */
    validatePillowPlacementOnRotation() {
        this.placementValidationManager.validatePillowPlacementOnRotation();
    }

    /**
     * Translate asset to some position. (The asset may not be translated to the exact position if any snapping conditions are met).
     * @param {THREE.Scene} asset - The asset to translate.
     * @param {THREE.Vector3} newPosition - The position to translate to.
     */
    moveAsset ( asset , newPosition ) {
        this.movementManager.moveAsset(asset, newPosition);
    }

    positionSelectionVertically () {
        this.movementManager.positionSelectionVertically();
    }

    rotateSelectionVertically ( rotation = THREE.MathUtils.degToRad(90) ) {
        this.movementManager.rotateSelectionVertically(rotation);
    }

    detectObjectBelow(intersectObjects) {
        return this.collisionManager.detectObjectBelow(intersectObjects);
    }
    

    detectCollision(intersectObjects) {
        return this.collisionManager.detectCollision(intersectObjects);
    }

    detectCollisionInFreeMode() {
        this.collisionManager.detectCollisionInFreeMode(this.spaceManager);
    }

    flipProduct() {
        this.rotateSelectionVertically();
    }

    buildPVBObject = (object) => {
        let pvb = object.userData.pvb;
        disposeScene(pvb);
        object.remove(pvb);
        object.userData.pvb = null;
        object.userData.pvb = new PVB (object)
    }

    getPlacementCorrectedHeight() {
        return this.placementCorrectionManager.getPlacementCorrectedHeight();
    }

    applyPlacementCorrection(position) {
        this.placementCorrectionManager.applyPlacementCorrection(position);
    }

    changeObjectPlacementType (placementType) {
        const selectedObject = this.selectionManager.selection.objects[0];
        let isOrignalPlacement = true

        // if changed placement type is same as current placement then return 
        if (selectedObject.userData.placementType == placementType) { 
            return 
        }

        // save the original placement of object to reset its value
        if (!selectedObject.userData.originalPlacement) {
            selectedObject.userData.originalPlacement = selectedObject.userData.placementType;
        }

        // reset rotation for floor items
        if ( selectedObject.userData.placementType == Constants.PlacementType.FLOOR) {
            selectedObject.rotation.set( 0, 0, 0 )
        }
        
        // if placement is changed back to its original placement else apply change
        if (selectedObject.userData.originalPlacement == placementType) {
            selectedObject.userData.placementType = placementType;
            selectedObject.userData.currentPlacement = null;
        } else {
            isOrignalPlacement = false;
            selectedObject.userData.currentPlacement = placementType;
            selectedObject.userData.placementType = placementType;
        }

        // build new pov according to placement type
        this.buildPVBObject(selectedObject);

        // reset stack if stacked
        if (selectedObject.userData.isStacked) {
            this.scene.attach(selectedObject);
            selectedObject.userData.isStacked = false;
        }

        this.placeObjectsOnPlacementChange(placementType, isOrignalPlacement);
        this.resetSelection();
        this.setSelection(selectedObject);
        this.setMovementOrigin();
    }

    placeObjectsOnPlacementChange (placementType, isOrignalPlacement) {
        const selectedObject = this.selectionManager.selection.objects[0];
        this.isPlacementCorrectionRequired = true;

        if (placementType == Constants.PlacementType.WALL) {
            this.autoPlacementManager.autoPlaceWallAssets([selectedObject]);
        } else if (placementType == Constants.PlacementType.FLOOR) {
            selectedObject.position.y = 0;
        } else if (placementType == Constants.PlacementType.CEILING) {
            const spaceArea = this.spaceManager.areas[Object.keys(this.spaceManager.areas)[0]];
            const spaceBoundingBox = new THREE.Box3().setFromObject(spaceArea.root);
            const spaceSize = new THREE.Vector3();
            spaceBoundingBox.getSize(spaceSize);
            selectedObject.position.y = spaceSize.y - 0.2;
        }

        if (!isOrignalPlacement) {
            this.applyPlacementCorrection(selectedObject.position);
        }
    }

    autoPlaceLostAssets(items) {
        this.autoPlacementManager.autoPlaceLostAssets(items);
    }

    setBabylonExported(value) {
        this.autoPlacementManager.setBabylonExported(value);
    }

    /**
     * Check if asset is being moved from floor surface to a scene asset surface
     */
    isJumpingToItem ( placementInfo ) {
        if(placementInfo.intersectedObj != null && this.selectionManager.selection.objects[0].parent != placementInfo.intersectedObj) {
            // Only allow stacking on floor items if intersected object is an item
            if(placementInfo.intersectedObj.userData != null) {
                if(placementInfo.intersectedObj.userData.placementType == Constants.PlacementType.FLOOR || this.isValidBaseItemForStacking(placementInfo.intersectedObj)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Check if asset is being moved from a scene asset surface to floor surface
     */
    isJumpingToFloor ( placementInfo ) {
        return ( ( placementInfo.intersectionPoint == null ) &&
            ( this.selectionManager.selection.objects[0].parent != this.scene ||
                rounded( this.selectionManager.selection.worldPosition.y ) != rounded( this.helperPlane.position.y ) ) );
    }

    /**
     * Check if asset is being moved on floor surface
     */
    isMovingOnFloor ( placementInfo ) {
        return ( placementInfo.intersectionPoint == null );
    }

    /**
     * Check if asset is being moved on a scene asset surface
     */
    isMovingOnItem ( placementInfo ) {
        return ( placementInfo.intersectedObj != null && this.selectionManager.selection.objects[0].parent == placementInfo.intersectedObj );
    }

    /**
     * Check if asset is being moved on misc area surface
     */
    isJumpingToMisc ( placementInfo ) {
        return ( placementInfo.isMiscAsset );
    }

    /**
     * NOTE: Use this with the assumption that isMovingOnItem == true
     * Check if asset is changing height on a scene asset surface
     */
    isChangingHeightOnItem ( placementInfo ) {
        return ( rounded( this.selectionManager.selection.worldPosition.y ) != rounded( placementInfo.intersectionPoint.y ) );
    }

    updateParent(object, newParent) {
        this.scene.attach(object);
        if (newParent != this.scene) {
            newParent.attach(object);
        }
    }

    /**
     * Check if item is valid for stacking.
     * @param {THREE.Scene} item - The asset to check stacking validation on. 
     */
    isValidBaseItemForStacking ( item ) {
        return this.stackingManager.isValidBaseItemForStacking(item);
    }  
          
    /**
     * Toggle clipping.
     * @param {boolean} clipping - The value of clipping ( true / false ).
     */
    toggleClipping ( clipping ) {
        this.clipping = clipping;
    }

    /**
     * Check if one asset can be stacked on the other.
     * @param {THREE.Scene} itemToBeStacked - The asset to stack.
     * @param {THREE.Scene} baseItem - The base asset on which to stack the itemToBeStacked.
     */
    compareForStacking ( itemToBeStacked, baseItem ) {
        return this.stackingManager.compareForStacking(itemToBeStacked, baseItem);
    }

    
    setObjectMovementSpeed = (speed) => {
        this.movementManager.setObjectMovementSpeed(speed);
    }

    moveSelectedObject(direction) {
        return this.movementManager.moveSelectedObject(direction);
    }

    moveSelectedObjectOnSnap(placementInfo) {
        this.movementManager.moveSelectedObjectOnSnap(placementInfo);
        const placementType = this.selectionManager.selection.objects[0].userData.placementType;
        if (placementType == Constants.PlacementType.WALL && 
            (this.selectionManager.selection.objects[0].userData.originalPlacement && 
            this.selectionManager.selection.objects[0].userData.originalPlacement != Constants.PlacementType.WALL)) {
            this.isPlacementCorrectionRequired = true;
        }
        else if (placementType == Constants.PlacementType.CEILING) {
            this.isPlacementCorrectionRequired = true;
        }
        this.applyPlacementCorrection(this.selectionManager.selection.objects[0].position);
        this.preserveAssetPreviousState();
    }

}
