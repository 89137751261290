import { connect } from "react-redux";
import { withRouter} from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import AddCollection from "./AddCollection";
import CustomerMainLayout from '../CustomerMainLayout'
import ProductItemLinkList from './ProductItemLinkList';
import { listProducts, getScannedProducts, getArrangements, reorderData, createFolder, moveToFolder, renameFolder, getProductRender } from "../../../../redux/actions";
import { LoadingOutlined, PlusOutlined, FilterOutlined, ShareAltOutlined, CloseOutlined, FolderOpenOutlined, AppstoreOutlined, SwapOutlined } from '@ant-design/icons';
import '../../../../styles/helper.scss'
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import CustomerProductContext from "../../ContextFiles/CustomerProductContext";
import {
  Menu,
  Input,
  Row,
  Select,
  Radio,
  Button,
  Tabs,
  Col,
  Modal,
  Form,
  Tooltip,
  message,
  Empty,
  Drawer,
  TreeSelect,
  Tag, Dropdown
} from 'antd';
import DottedLoader from "../../DottedLoader";
import FileConstants from '../../../../FileConstants';
import _ from "lodash"; // Import the entire lodash library
import SuccessModal from "../../SuccessModal/SuccessModal";
import AddItemToStore from "../../AddItemToStore";
import * as Utilities from '../../Utilities';
import ReorderIcon from "../../../../icons/ReorderIcon";
import * as Styles from '../../../../Styles';
import ColorSelect from "../../FormUtilities/ColorSelect";
import StyleCategory from "../../FormUtilities/StyleCategorySelect";
import EmailSelect from "../../FormUtilities/EmailSelect";
import PaymentFailureModal from "../../PaymentFailureModal/PaymentFailureModal";
import InAppPurchaseLimitModal from "../InAppPurchaseLimitModal/InAppPurchaseLimitModal";
import { PlusCircleFilled } from "@ant-design/icons";
import BatchSharingModal from "../BatchSharinModal/BatchSharingModal";
import { WALMART_COMPANY_ID } from "../../../../environments/env";
import ProductAssetModals from "../ProductComponents/ProductAssetModals";

const { TabPane } = Tabs;

const formRef = { useRef };
let SEQUENCE_ID_OTHER = -1;
let SEQUENCE_ID_OWNED = -1;
const categoryStyle = Styles.categoryStyle;

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem("username");
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
const SHARED_ENTITIES = localStorage.getItem('shared_entities');
const MANAGED_SHARED_ENTITES = localStorage.getItem("managed_shared_entites");
const SEQUENCE_IDS = localStorage.getItem('sequence_ids');
const FILTER_KEYS_FOR_QUERY_PARAMS = ['category', 'style', 'color', 'type', 'material']
const { Option } = Select;
const { SubMenu } = Menu;

const ORDER_BY_VALUES = [
  {
      'text': "Latest Created",
      'key': 1,
      'value': 'created_on_stamp desc'
  },
  {
      'text': "Earliest Created",
      'key': 2,
      'value': 'created_on_stamp asc'
  },
  {
      'text': "Latest Updated",
      'key': 3,
      'value': 'last_modified_stamp desc'
  },
  {
      'text': "Earliest Updated",
      'key': 4,
      'value': 'last_modified_stamp asc'
  },
  {
      'text': "Custom Order",
      'key': 5,
      'value': 'library_order asc,entity_order asc, id desc'
  }
]

const CustomerProducts = (props) => {

  let create_new_product_options = () => {
    return <Menu>
      <SubMenu title={<span>Create New Model</span>} popupOffset={[0, 0]} >
            <Menu.Item key="8" onClick={() => props.history.push('/create-existing-physical-product?origin=/list-products')}>
                <span>Existing Physical Product</span>
            </Menu.Item>
            <Menu.Item key="9" onClick={() => props.history.push('/add-new-product?origin=/list-products')}>
                <span>Design A New Product</span>
            </Menu.Item>
        </SubMenu>
        <Menu.Item>
            <a className="manrope f-14" onClick={() => props.history.push('/upload-product-model?origin=/list-products')}>Upload Existing Model</a>
        </Menu.Item>
    </Menu>
  }

   let sorting_options = () => {
      return <Menu selectedKeys={[ORDER_BY_VALUES.find(item => item.key === sortBy)?.value]}>
          {ORDER_BY_VALUES.map((order) => (
              (SEQUENCE_ID_OTHER == -1 && SEQUENCE_ID_OWNED == -1) && order.key == 5 ? "" :
              <Menu.Item key={order.value} onClick={() => setSortingOfLibrary(order.key)}>
                  <a className='manrope f-14'>{order.text}</a>
              </Menu.Item>
          ))}
      </Menu>
  }
  

  const [selectedProductIdForCollection, setSelectedProductIdForCollection] = useState(null);
  const [collectionModalVisible, setCollectionModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sharingModal, setSharingModal] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState('download_and_embed');
  const [successModal, setSuccessModal] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [defaultProductIds, setDefaultProductIds] = useState([]);
  const [selectable, setSelectable] = useState(false);
  const [mspCustomerOwnedProducts, setMspCustomerOwnedProducts] = useState([])
  const [mspCustomerOtherProducts, setMspCustomerOtherProducts] = useState([])
  const [mspCustomerOwnedOrgProducts, setMspCustomerOwnedOrgProducts] = useState([])
  const [mspCustomerOtherOrgProducts, setMspCustomerOtherOrgProducts] = useState([])
  const [sharingLoader, setSharingLoader] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [companyID, setCompanyID] = useState(-1);
  const [currentCustomer, setCurrentCustomer] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [loader, setLoader] = useState(true);
  const [sharedProductLoader, setSharedProductLoader] = useState(true);
  const [ownedProductLoader, setOwnedProductLoader] = useState(true);
  const [productTab, setProductTab] = useState("customer_owned");
  const [tabActiveKey, setTabActiveKey] = useState("");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [groupedProducts, setGroupedProducts] = useState([]);
  const [folderState, setFolderState] = useState(false);
  const [selectedParentProduct, setSelectedParentProduct] = useState(null);
  const [folderProducts, setFolderProducts] = useState([]);
  const [sharedTabType, setSharedTabType] = useState('all');
  const [mspCustomerSharedTabType, setMspCustomerSharedTabType] = useState('all');
  const [sellVisible, setSellVisible] = useState(false);
  const [showProductDetails, setShowProductDetails] = useState(null);
  const [productID, setProductID] = useState(-1);
  const [sellLoader, setSellLoader] = useState(false);
  const [price, setPrice] = useState(0);
  const [removeFromStore, setRemoveFromStore] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [showBottomNavBar, setShowBottomNavBar] = useState(false);
  const [reorderItems, setReorderItems] = useState(false);
  const [selectedFolderProducts, setSelectedFolderProducts] = useState([]);
  const [newFolder, setNewFolder] = useState(false);
  const [existingFolder, setExistingFolder] = useState(false);
  const [viewRenameFolderModal, setViewRenameFolderModal] = useState(false);
  const [viewUnGroupFolderModal, setViewUnGroupFolderModal] = useState(false);
  const [entityArrangement, setEntityArrangement] = useState([]);
  const [ownedProductsEntityArrangement, setOwnedProductsEntityArrangement] = useState([]);
  const [otherProductsEntityArrangement, setOtherProductsEntityArrangement] = useState([]);
  const [mspOwnedProductsEntityArrangement, setMspOwnedProductsEntityArrangement] = useState([]);
  const [mspOtherProductsEntityArrangement, setMspOtherProductsEntityArrangement] = useState([]);
  const [folderArrangement, setFolderArrangement] = useState([]);
  const [ownedProductsFolderArrangement, setOwnedProductsFolderArrangement] = useState([]);
  const [otherProductsFolderArrangement, setOtherProductsFolderArrangement] = useState([]);
  const [mspOwnedProductsFolderArrangement, setMspOwnedProductsFolderArrangement] = useState([]);
  const [mspOtherProductsFolderArrangement, setMspOtherProductsFolderArrangement] = useState([]);
  const [saveOrderedItems, setSaveOrderedItems] = useState(false);
  const [orderSaved, setOrderSaved] = useState(false);
  const [newFolderName, setNewFolderName] = useState(null)
  const [hashUpdated, setHashUpdated] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedFolderParentId, setSelectedFolderParentId] = useState(null);
  const [folderNewName, setFolderNewName] = useState(null);
  const [reorderByModal, setReorderByModal] = useState(false);
  const [reorderByCompany, setReorderByCompany] = useState(false);
  const [mspCustomerReorderByCompany, setMspCustomerReorderByCompany] = useState(false);
  const [companyArrangement, setCompanyArrangement] = useState(false);
  const [customerCompanyArrangement, setCustomerCompanyArrangement] = useState(false);
  const [foldersListExceptCurrentFolder, setFoldersListExceptCurrentFolder] = useState([]);
  const [saveByCompany, setSaveByCompany] = useState(false);
  const [mspCustomerSaveByCompany, setMspCustomerSaveByCompany] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [renameError, setRenameError] = useState(null);
  const [orderUpdated, setOrderUpdated] = useState(false);
  const [ownedFolderData, setOwnedFolderData] = useState([]);
  const [otherFolderData, setOtherFolderData] = useState([]);
  const [mspCustomerOwnedFolderData, setMspCustomerOwnedFolderData] = useState([]);
  const [mspCustomerOtherFolderData, setMspCustomerOtherFolderData] = useState([]);
  const [ownedProductData, setOwnedProductData] = useState([]);
  const [sharedProductData, setSharedProductData] = useState([]);
  const [ownedOriginalData, setOwnedOriginalData] = useState([]);
  const [sharedOriginalData, setSharedOriginalData] = useState([]);
  const [folderInfo, setFolderInfo] = useState([]);
  const [initialLoadShared, setInitialLoadShared] = useState(true);
  const [initialLoadOwned, setInitialLoadOwned] = useState(true);
  const [initialLoadMsp, setInitialLoadMsp] = useState(true);
  const [mspDataLoad, setMspDataLoad] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const [checkIfItemsHaveReorderedOnce, setCheckIfItemsHaveReorderedOnce] = useState([]);
  const [folderID, setFolderID] = useState(null);
  const [rearrangementLoader, setRearrangementLoader] = useState(false);
  const [checkIfTabChanged, setCheckIfTabChanged] = useState(false);
  const [lastFolderAccessed, setLastFolderAccessed] = useState(null);
  const [filtersDrawer, setFiltersDrawer] = useState(false);
  const [productCategoriesData, setProductCategoriesData] = useState([])
  const [productParentCateogries, setProductParentCateogries] = useState({})
  const [productCategoryValue, setProductCategoryValue] = useState([]);
  const [productCategoryValueWithChilds, setProductCategoryValueWithChilds] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [categorySearchValue, setCategorySearchValue] = useState([]);
  const [productStyleCategory, setProductStyleCategory] = useState([]);
  const [colorValue, setColorValue] = useState([]);
  const [productMaterialsData, setProductMaterialsData] = useState([]);
  const [productMaterials, setProductMaterials] = useState([]);
  const [materialsCategoryValueWithChilds, setMaterialsCategoryValueWithChilds] = useState([]);
  const [productModelType, setProductModelType] = useState([]);
  const [appliedFiltersList, setAppliedFiltersList] = useState([])
  const [currentProductId, setCurrentProductId] = useState(0);
  const [folderParentID, setFolderParentID] = useState(null);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [sceneCreated, setSceneCreated] = useState(-1);
  const [sceneAddedID, setSceneAddedID] = useState(-1);
  const [customerSequences, setCustomerSequences] = useState({});
  const [currentView, setCurrentView] = useState("folder_view");
  const [filtersAppliedFlag, setFiltersAppliedFlag] = useState(false);
  const [filteredViewInitialLoader, setFilteredViewInitialLoader] = useState(false);
  const [productBasicInfo, setProductBasicInfo] = useState(null);
  const [selectedAssetModal, setSelectedAssetModal] = useState(null);
  const [tabCount, setTabCount] = useState({owned: -1, shared: -1, customer_owned: -1, customer_shared: -1});
  const [currentPage, setCurrentPage] = useState(props.match.path)
  const [projects, setProjects] = useState([]);
  const [tempOwnedCount, setTempOwnedCount] = useState(0);
  const [tabChangeThroughClick, setTabChangeThroughClick] = useState(false);
  const [sharingView, setSharingView] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [sortBy, setSortBy] = useState(3);
  const [sortingHasSet, setSortingHasSet] = useState(false);
  const [stopDuplicateCall, setStopDuplicateCall] = useState(false);
  const [prevTabState, setPrevTabState] = useState(null);
  useEffect(() => {
    getOwnedProductCount();

    if (IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
      getCustomerData();
    }
    // if (SEQUENCE_IDS) {
    //   setSequenceIds();
    //   triggerProductFetchAPI();
    // } else {
      loadSequenceIDs(CUSTOMER_USERNAME, COMPANY_ID, SHARED_ENTITIES, false, true);
    // }
  }, []);


  const getOwnedProductCount = () => {
    const counts = localStorage.getItem("entity_counts");
    if (counts) {
        let entityCounts = JSON.parse(counts);
        let owned_product_count = entityCounts.find(entity => entity.type === "product").count
        setTempOwnedCount(owned_product_count);
    }
    else {
        let payload = {
            username: CUSTOMER_USERNAME,
            project_homepage: true
        };
        if (COMPANY_ID != undefined) {
            payload.company_id = COMPANY_ID;
        };
        
        if(IS_MS_PROVIDER){
            if(MANAGED_CUSTOMER_USERNAME){
                payload['username'] = MANAGED_CUSTOMER_USERNAME
            }
            if(MANAGED_COMPANY_ID){
                payload['company_id'] = MANAGED_COMPANY_ID
            }
        }

        axios.post(ENVIRONMENT.HOME_SCREEN_STATS, payload).then((res) => {
            let approved_project = {
                "type": "approved",
                "count": (res.data.library_data.find(ele => ele.type === "project"))?.count
            }
            res.data.library_data = res.data.library_data.filter(ele => ele.type !== "project")
            let active_project = {
                "type": "active",
                "count": (res.data.review_data.find(ele => ele.type === "project"))?.count
            }
            let requested_project = {
                "type": "requested",
                "count": (res.data.in_progress_data.find(ele => ele.type === "project"))?.count
            }
            //Setting Entity Counts in localStorage
            let entity_counts = _.cloneDeep(res.data.library_data);
            
            entity_counts.push(approved_project)
            entity_counts.push(active_project)
            entity_counts.push(requested_project)
            
            localStorage.setItem('entity_counts', JSON.stringify(entity_counts));
            setTempOwnedCount(entity_counts.find(entity => entity.type === "product").count);
        })
    }
  }

  useEffect(() => {
    applyFiltersFromQueryStrings()
    applySearchFromQueryString()
  }, [productParentCateogries, productMaterialsData])

  const updateTabCount = (count, tabName) => {
    setTabCount(prevTabCount => ({
      ...prevTabCount,
      [tabName]: count
    }));
  };

  const applySearchFromQueryString = () => {
    const url = new URL(window.location.href);
    let searchValue = url.searchParams.get("search");
    if (searchValue !== null) {
      let cleanedSearchValue = decodeURIComponent(searchValue);
      if(cleanedSearchValue){
        setSearchValue(cleanedSearchValue)
      }
    }
  }

  const removeSearchParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('search');
    props.history.replace(url.pathname + url.search);
  };

  const applyFiltersFromQueryStrings = () => {
    const filters = {};
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    
    for (const [key, value] of searchParams.entries()) {
      if(FILTER_KEYS_FOR_QUERY_PARAMS.includes(key)){
        if (value.includes(',')) {
          filters[key] = value.split(',').map(decodeURIComponent);
        } else {
            filters[key] = [decodeURIComponent(value)];
        }
      }
    }

    if(Object.keys(filters).length > 0 && Object.keys(productParentCateogries).length > 0 && Object.keys(productMaterialsData).length > 0){
      // Get the applied filters and set their values
      
      if(filters['category']){
        // category filter was applied, get all the children of the parent category
        let filterCategoryChildren = []
        filters['category'].map((name) => {
          const targetItemName = name;
          const targetItem = productParentCateogries.find(item => item.name === targetItemName);
    
          if (targetItem) {
            const result = findItemsByParentId(productParentCateogries, targetItemName, targetItem.category_id);
            result.unshift(name);
            filterCategoryChildren = [...filters['category'], ...result]
          }
        });
        setProductCategoryValue(filters['category'])
        setProductCategoryValueWithChilds(filterCategoryChildren)

      }

      if(filters['material']){

        let filterMaterialChildren = []
        filters['material'].map((name) => {
          const targetItemTitle = name;
          const targetItem = productMaterialsData.find(item => item.title === targetItemTitle);
          if (targetItem) {
            const result = findChildrenByParentTitle(targetItemTitle, targetItem);
            result.unshift(name);
            filterMaterialChildren = [...filters['material'], ...result]
          }

        });
        if (filterMaterialChildren.length <= 0) {
          filterMaterialChildren = filters['material'];
        }
        setProductMaterials(filters['material']);
        setMaterialsCategoryValueWithChilds(filterMaterialChildren);
      }

      if(filters['style']){
        setProductStyleCategory(filters['style'])
      }

      if(filters['type']){
        setProductModelType(filters['type'])
      }

      if(filters['color']){
        setColorValue(filters['color'])
      }

      setFiltersApplied(true);
      updateFiltersFlag();
      forceUpdate();
      setAppliedFiltersList(getAppliedFilters())
    }
  }


  const getUserProjects = () => {
    let payload = {
      order_by: "last_modified_stamp",
      required_fields: [
        "id",
        "name",
        "customer_username",
        "last_modified_stamp"
      ],
      filter_string: `(status__notexact='approved')&&(username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','co_owner','editor'])`,
    };

  axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => 
  {
      let projects = []
      if(MANAGED_CUSTOMER_USERNAME){
          projects = res.data.map(item => ({
              ...item,
              name: `[${item.customer_username}] ${item.name} `
          }));
          setProjects(projects)
      }
      else{
          setProjects(res.data)
      }
  });
  }

  useEffect(() => {
    let flag = false
    if(ownedProductLoader || sharedProductLoader){
      let url = new URL(window.location.href)
      let searchValue = url.searchParams.get("search");
      if (searchValue !== null) {
        let cleanedSearchValue = decodeURIComponent(searchValue);
        if(cleanedSearchValue){
          flag = true
        }
      }

      const searchParams = new URLSearchParams(url.search);
      for (const [key, value] of searchParams.entries()) {
        if(FILTER_KEYS_FOR_QUERY_PARAMS.includes(key)){
          flag = true
        }
      }
    }
    setFilteredViewInitialLoader(flag)
  }, [ownedProductLoader, sharedProductLoader])

  const scrollToItem = (type, id) => {
    let element = document.getElementById(`${type}-${id}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const triggerProductFetchAPI = () => {
    fetchProductData('owned', SEQUENCE_ID_OWNED, true);
    fetchProductData('shared', SEQUENCE_ID_OTHER, true);
    getUserProjects();
  }

  useEffect(() => {
    if (!initialLoadOwned && ownedProductLoader) {
      fetchProductData('owned', SEQUENCE_ID_OWNED);
    }
  }, [initialLoadOwned]);

  useEffect(() => {
    if (!initialLoadShared && sharedProductLoader) {
      fetchProductData('shared', SEQUENCE_ID_OTHER);
    }
  }, [initialLoadShared]);


  const loadSequenceIDs = (username, company_id, shared_entities, managed_customer, trigger_api, reload_page = false) => {
    let payload = {
      'username': username,
      'action': 'get_sequence_ids'
    };
    if (company_id) {
      payload['company_id'] = company_id;
      payload['shared_entities'] = shared_entities;
    }

    axios.post(ENVIRONMENT.ENTITY_ORGANIZER, payload).then(res => {
      if (res.data && res.data.length > 0) {
        if (managed_customer == true) {
          let sequences = (res.data);
          let customer_seq = {};
          sequences.map ((sequence_data) => {
            if (sequence_data['entity_type'] == 'owned_product') {
              customer_seq["owned"] = sequence_data['sequence_id']
              setCustomerCompanyArrangement(sequence_data['saved_by_company']);
            } else if (sequence_data['entity_type'] == 'other_product') {
              customer_seq["other"] = sequence_data['sequence_id']
            }
          });
          setCustomerSequences(customer_seq);
          // if (reload_page) {
          //   window.location.reload();
          // }
        } else {
          localStorage.setItem('sequence_ids', JSON.stringify(res.data));
          setSequenceIds(JSON.stringify(res.data));
          if (trigger_api) {
            triggerProductFetchAPI();
          }
          if (reload_page) {
            window.location.reload();
          }
        }
      }
    })
  }

  const setSequenceIds = (sequence_ids = SEQUENCE_IDS) => {
    if (sequence_ids) {
      sequence_ids = JSON.parse(sequence_ids)
      sequence_ids.map((sequence_data) => {
        if (sequence_data['entity_type'] == 'owned_product') {
          SEQUENCE_ID_OWNED = sequence_data['sequence_id']
          setCompanyArrangement(sequence_data['saved_by_company']);
        } else if (sequence_data['entity_type'] == 'other_product') {
          SEQUENCE_ID_OTHER = sequence_data['sequence_id']
        }
      })
       // if reordering exists, then select custom sort
       if ((SEQUENCE_ID_OWNED != -1 || SEQUENCE_ID_OTHER != -1) && !sortingHasSet) {
        setSortBy(5);
      }
    }
  }

  const convertToLibraryFormat = (data) => {
    // data will be converted in this format: let's say 4 products (p) at root and 2 folders (f) at root
    // [p, p, p, f -> [p,p], p, f -> [p, p]]
    let root_items = [];
    let folder_info = [];
    let old_index = 0;
    let index = 0;
    if (data && data.length > 0) {
      let folder_indexes = new Map();
      data.map((product) => {
        // if item is in folder, then create an array at the folder index (which will be 
        // the same as folder ordering #)
        // push the item in that array
        // otherwise push the product normally
        if (product.folder_id) {
          // check if array index has been created for the particular folder id
          // if it's not, then initialize it and create entry for folder_data
          // otherwise push the product in the appropriate array index
          if (folder_indexes.get(product.folder_id) == undefined) {
            folder_indexes.set(product.folder_id, index);
            root_items[folder_indexes.get(product.folder_id)] = [];
            let folder_data = {
              'folder_id': product.folder_id,
              'folder_name': product.folder_name,
              'ordering_number': product.library_order,
              'parent_item': product
            }
            folder_info.push(folder_data);
            index++;
          }
          root_items[folder_indexes.get(product.folder_id)].push(product);
        } else {
          root_items.push(product);
          index++;
        }
      });
    }

    return {
      root_items,
      folder_info
    };
  }

  useEffect(() => {
    let owned_products = ownedOriginalData;
    let formatted_data = convertToLibraryFormat(owned_products);
    setOwnedProductData(formatted_data.root_items);
    setOwnedFolderData(formatted_data.folder_info);
    convertToArrangement(owned_products, formatted_data.folder_info, 'owned');

    let other_products = sharedOriginalData;
    formatted_data = convertToLibraryFormat(other_products);
    setSharedProductData(formatted_data.root_items);
    setOtherFolderData(formatted_data.folder_info);
    convertToArrangement(other_products, formatted_data.folder_info, 'shared');
  }, [ownedOriginalData, sharedOriginalData]);

  useEffect (() => {
    setFolderViewForCustomerItems();
  }, [mspCustomerOwnedOrgProducts, mspCustomerOtherOrgProducts])

  const setFolderViewForCustomerItems = () => {
    let customer_owned_products = mspCustomerOwnedOrgProducts;
    let formatted_data = convertToLibraryFormat(customer_owned_products);
    setMspCustomerOwnedProducts(formatted_data.root_items);
    setMspCustomerOwnedFolderData(formatted_data.folder_info);
    convertToArrangement(customer_owned_products, formatted_data.folder_info, 'customer_owned');

    let customer_other_products = mspCustomerOtherOrgProducts;
    formatted_data = convertToLibraryFormat(customer_other_products);
    setMspCustomerOtherProducts(formatted_data.root_items);
    setMspCustomerOtherFolderData(formatted_data.folder_info);
    convertToArrangement(customer_other_products, formatted_data.folder_info, 'customer_shared');
  }

  const getCompanyUsersEmails = () => {
    let emails = []
    if(MANAGED_CUSTOMER_USERNAME){
      emails.push({
          'email': MANAGED_CUSTOMER_EMAIL,
          'username': MANAGED_CUSTOMER_USERNAME
      })
      setCompanyUsers(emails)
    }
    if(COMPANY_ID){
        let payload = {
            "required_fields": ["email", "username"],
            "filter_string": `(company_id__exact=${COMPANY_ID})`,
            "order_by": "email asc"
        }
        axios.post(ENVIRONMENT.COMPANY_ENTITY_GET_BATCH, payload)
        .then(res => {
            if(res && res.data){
                console.log('Company User Emails', res.data);
                emails = res.data.filter(item => item.username != CUSTOMER_USERNAME)
                setCompanyUsers(emails)
              }
        })
    }
  }


  // adjust library view (move to the product or folder that was last clicked by the user in the library)
  const adjustLibraryView = (data, tab_key) => {
    let product_id = new URLSearchParams(window.location.search).get("entity_id");
    let folder_id = new URLSearchParams(window.location.search).get("folder_id");
    let folder_product_id = new URLSearchParams(window.location.search).get("folder_entity_id");

    if (product_id && data.length > 0) {
      let product_data = data.filter(ele => ele.id == product_id);
      if (product_data.length > 0) {
        let folder_id = null;
        // if product exists inside a folder, then open that folder and scroll to that product
        // in the folder
        if (product_data[0].folder_id) {
          folder_id = product_data[0].folder_id;
          let folder_items = [];
          if (tab_key == "owned") { folder_items = ownedOriginalData }
          else if (tab_key == "shared") { folder_items = sharedOriginalData }
          else if (tab_key == "customer_owned") { folder_items = mspCustomerOwnedOrgProducts }
          else { folder_items = mspCustomerOtherOrgProducts }
          folder_items = folder_items.filter(item => item.folder_id == folder_id);
          if (folder_items && folder_items.length > 0) {
            let parent_entity = folder_items[0].parent_item
            setSelectedParentProduct(parent_entity);
            if (parent_entity) {
              getFoldersListExceptCurrentFolder(folder_id);
            }
            setFolderState(true);
            enterFolderState(folder_items, false);
            setProductTab(tab_key);
            setTabActiveKey(tab_key);
            Utilities.updateTabUrl(tab_key);
          }
        }
        // otherwise move to that product
        else {
          setProductTab(tab_key);
          setTabActiveKey(tab_key);
          Utilities.updateTabUrl(tab_key);
        }

        setCheckIfTabChanged(!checkIfTabChanged)
      }
    }
    // if folder id is present, then just open that folder
    // if folder_product_id is present, then just scroll the library to that folder
    // without opening it - used when folder is closed or when new folder is created
    else if ((folder_id || folder_product_id) && data.length > 0) {
      let folder_items = [];
      if (tab_key == "owned") { folder_items = ownedOriginalData }
      else if (tab_key == "shared") { folder_items = sharedOriginalData }
      else if (tab_key == "customer_owned") { folder_items = mspCustomerOwnedOrgProducts }
      else { folder_items = mspCustomerOtherOrgProducts }
      if (folder_id) {
        folder_items = folder_items.filter(item => item.folder_id == folder_id);
      } else if (folder_product_id) {
        folder_items = folder_items.filter(item => item.id == folder_product_id);
      }
      if (folder_items && folder_items.length > 0) {
        setProductTab(tab_key);
        setTabActiveKey(tab_key);
        Utilities.updateTabUrl(tab_key);
        setCheckIfTabChanged(!checkIfTabChanged)
      }
    }
  }

  useEffect(() => {
    // if tab is changed then check if these are present in URL params
    // if yes, then scroll to item or folder
    let product_id = new URLSearchParams(window.location.search).get("entity_id");
    let folder_id = new URLSearchParams(window.location.search).get("folder_id");
    if (product_id) {
      if (!lastFolderAccessed) {
        scrollToItem('element', product_id);
      } else {
        setTimeout(() => {
          scrollToItem('folder', lastFolderAccessed);
          setLastFolderAccessed(null);
        }, 1000)
      }
    } else if (lastFolderAccessed || folder_id) {
      let new_folder_id = lastFolderAccessed ? lastFolderAccessed : folder_id;
      setTimeout(() => {
        scrollToItem('folder', new_folder_id);
        setLastFolderAccessed(null);
      }, 1000)
      setLastFolderAccessed(null);
    }
  }, [checkIfTabChanged])

  const convertToArrangement = (products, folder_info, type) => {
    let entity_arrangement = [];
    let arrangement_info = {};
    let folder_arrangement = [];
    let folder_arrangement_info = {};
    let sequence_id = getTabSequenceID(type);
    if (products && products.length > 0) {
      products.map((data) => {
        arrangement_info = {
          entity_id: data.id,
          ordering_number: parseInt(data.library_order),
          parent_folder_id: data.folder_id == "" ? "" : parseInt(data.folder_id),
          sequence_id: sequence_id
        }
        entity_arrangement.push(arrangement_info);
      });

      folder_info.map((data) => {
        folder_arrangement_info = {
          folder_id: data.folder_id,
          ordering_number: data.library_order == "" ? "" : parseInt(data.library_order),
          folder_name: data.folder_name,
          sequence_id: sequence_id
        }
        folder_arrangement.push(folder_arrangement_info);
      });
    }

    if (type == 'owned') {
      setOwnedProductsEntityArrangement(entity_arrangement);
      setOwnedProductsFolderArrangement(folder_arrangement);
    } else if (type == 'shared') {
      setOtherProductsEntityArrangement(entity_arrangement);
      setOtherProductsFolderArrangement(folder_arrangement);
    } else if (type == 'customer_owned') {
      setMspOwnedProductsEntityArrangement(entity_arrangement);
      setMspOwnedProductsFolderArrangement(folder_arrangement)
    } else {
      setMspOtherProductsEntityArrangement(entity_arrangement);
      setMspOtherProductsFolderArrangement(folder_arrangement);
    }
  }

  const enterReorderingState = () => {
    setReorderItems(true);
    setCurrentView('folder_view');
    setShowBottomNavBar(true);
    setSelectedProductIds([]);
    setSaveOrderedItems(false);
    setSharedTabType('all');
    setMspCustomerSharedTabType('all');
    setSearchValue('');
    removeSearchParams();
    setCurrentTabArrangements(productTab);
  }

  const getCustomerData = () => {
    loadSequenceIDs(MANAGED_CUSTOMER_USERNAME, MANAGED_COMPANY_ID, MANAGED_SHARED_ENTITES, true, false, false);
  }

  const fetchData = async (only_msp_data = false) => {
    if (Object.keys(customerSequences).length > 0) {
      if (initialLoadMsp) {
        await getMSPCustomerData("owned", true);
        await getMSPCustomerData("shared", true);
        setInitialLoadMsp(false);
      } else {
        setMspDataLoad(true);
        await getMSPCustomerData("owned", false);
        await getMSPCustomerData("shared", false);
        setMspDataLoad(false);
      }
    }

    if (only_msp_data == false) {
      loadSequenceIDs(CUSTOMER_USERNAME, COMPANY_ID, SHARED_ENTITIES, false, true);
    }
  }

  useEffect (() => {
    fetchData();
  }, [])

  useEffect (() => {
    fetchData(true);
  }, [customerSequences, initialLoadMsp])

  // Use useEffect to trigger fetchData after sortBy updates
  useEffect(() => {
      if (sortingHasSet && stopDuplicateCall === false) {
          fetchData();
          setStopDuplicateCall(true);
          let tab = new URLSearchParams(window.location.search).get("tab");
          setPrevTabState(tab);
      }
  }, [sortBy, sortingHasSet, initialLoadMsp, initialLoadShared, initialLoadOwned, ownedProductLoader, sharedProductLoader]); // Dependency array ensures fetchData is called when sortBy changes


  const getMSPCustomerData = async (type, initial) => {
      let payload = getProductPayload(initial, type, type == "owned" ? customerSequences["owned"] : customerSequences["other"], MANAGED_CUSTOMER_USERNAME, MANAGED_COMPANY_ID, MANAGED_SHARED_ENTITES, true);
      await axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
        .then(res => {
        let jsonData = null;
        if(res.data.isBase64Encoded){
          jsonData = Utilities.decompressData(res);
        } 
        else{
          jsonData = res.data;
        }
        
          if (jsonData) {
            let products = Utilities.getProductsDataWithKeywords(jsonData);
            if (type == "owned") {
              if (products.length > 0 && customerSequences["owned"] == -1) {
                createFirstArrangementOrder(products, "customer_owned");
              }
              setMspCustomerOwnedProducts(products);
              setMspCustomerOwnedOrgProducts(products);
            } else {
              if (products.length > 0 && customerSequences["other"] == -1) {
                createFirstArrangementOrder(products, "customer_shared");
              }
              setMspCustomerOtherProducts(products);
              setMspCustomerOtherOrgProducts(products);
            }
          }
    });
  }

  const fetchProductData = (type, sequence_id, initial = false) => {
    if (!initial) {
      setLoader(true);
    } else {
      if (type == 'shared') {
        setInitialLoadShared(true);
      } else {
        setInitialLoadOwned(true);
      }
    }

    let payload = getProductPayload(initial, type, sequence_id, CUSTOMER_USERNAME, COMPANY_ID, SHARED_ENTITIES);
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        let jsonData = null;
        
        if(res.data.isBase64Encoded){
          jsonData = Utilities.decompressData(res);
        } 
        else{
          jsonData = res.data;
        }
        if (jsonData) {
          // setting data
          if (type == 'shared') {
            let products = Utilities.getProductsDataWithKeywords(jsonData)
            setSharedOriginalData(products)
          } else {
            let products = Utilities.getProductsDataWithKeywords(jsonData)
            setOwnedOriginalData(products)
          }

          // setting loaders
          if (initial) {
            if (type == 'shared') {
              setInitialLoadShared(false);
            } else {
              setInitialLoadOwned(false);
            }
          } else {
            if (type == 'shared') {
              setSharedProductLoader(false);
            } else {
              setOwnedProductLoader(false);
            }
            setLoader(false)
          }
        }

      });
  }

  const getProductPayload = (initial, type, sequence_id, current_username, current_company_id, shared_entities, is_customer_data = false) => {
    let payload = {
      "required_fields": ["id","name","brand_id", "category", "height",  "customer_username",
      "width","depth", "model_status","thumbnail","last_modified_stamp", "variant_of",
      "is_store_item","group_id", "dimensions", "company_id", "color_name", "materials", 
      "platform", "style_category", "gtin", "tags","product_model_type", "created_on_stamp"],
    }
    let order_by = 'last_modified_stamp desc';
    if (sortBy != 5) {
      order_by =  ORDER_BY_VALUES.find(item => item.key === sortBy)?.value;
    }
    if (sequence_id != -1 && sortBy == 5) {
      payload['required_fields'].push('folder_id', 'folder_name', 'parent_folder_id',
        'library_order', 'entity_order')
      payload["order_by"] = "library_order asc,entity_order asc"
    } else {
      payload["order_by"] = order_by;
    }
    let filter_string = "";
    let shared_username = "";
    let asset_username = "asset_username__exact='" + current_username + "'||asset_username__isnull=true";
    let customer_username = "customer_username__exact='" + current_username + "'"
    let customer_username_notexact = "customer_username__notexact='" + current_username + "'"
    let company_id_notexact = ""
    let asset_company = ""

    let company_id = "";
    let model_status = "model_status__in=[5]"
    let is_hidden = "is_hidden__not=true"
    let shared_hidden = "shared_hidden__not=true"
    let sequence_id_value = "sequence_id__exact=" + sequence_id
    
    if (type == 'shared') {
      payload['required_fields'].push('shared_product_type')
      shared_username = "shared_username__exact='" + current_username + "'"
      filter_string = "(" + shared_username + "&&" + shared_hidden + ")"
      
      if (shared_entities != undefined && current_company_id && shared_entities.split("_").includes("product")) {
        company_id_notexact = "company_id__notexact=" + current_company_id + "||company_id__isnull=true"
        filter_string = filter_string + "&&(" + company_id_notexact + ")&&("+customer_username_notexact+")";

      } else {
        filter_string = filter_string + "&&("+customer_username_notexact+")";
      }
    } else if (shared_entities != undefined && current_company_id && shared_entities.split("_").includes("product")) {
      company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
      filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")"
    } else {
      if (IS_MS_PROVIDER && !is_customer_data) {
        company_id = "company_id__isnull=true"
        let requested_for = "requested_for__isnull=true"
        filter_string = "("+company_id+"&&"+customer_username+")&&(" + is_hidden  + ")&&(" + requested_for + ")"
      } else {
        filter_string = "("+customer_username+ "&&" + is_hidden +")"
      }
    }
    filter_string = filter_string + "&&(" + model_status + ")"
    if (sequence_id != -1) {
      filter_string = filter_string + "&&(" + sequence_id_value + "||sequence_id__isnull=true)"
    }
    if(current_company_id == ENVIRONMENT.WALMART_COMPANY_ID)
    {
        if(type == 'shared'){
            company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
            filter_string = '(' + filter_string + ')||(' + `(${company_id}||${customer_username})&&(${is_hidden})&&(${model_status})&&(product_model_type__exact='props')` + ')'
        }
        else{
            filter_string = '(' + filter_string + `)&&(product_model_type__notexact='props')`
        }
    }
    filter_string = filter_string + "&&(has_active_project__is=false)"

    payload['filter_string'] = filter_string
    if (initial) {
      payload['pagination_filters'] = {
        'limit': 100,
        'offset': 0
      }
    }

    if(localStorage.getItem('super_admin_username')){
      payload['is_customer_assumed_role'] = true
      payload['super_admin_username'] = localStorage.getItem('super_admin_username')
    }

    if(current_company_id){
      payload['required_fields'].push('company_render_count')
      payload['required_fields'].push('company_lifestyle_render_count')
      asset_company = "asset_company__exact=" + current_company_id + "||asset_company__isnull=true"
      payload['filter_string'] = payload['filter_string'] + "&&(" + asset_company + ")"
    }

    if(ENVIRONMENT.isProduction()){
      payload['compress_response'] = true;
    }
    return payload;
  }


  useEffect(() => {
    let scene_added = new URLSearchParams(window.location.search).get("scenes_added")
      if(scene_added)
      {
        setSceneCreated(scene_added)
        if(scene_added == 1)
        {
            let scene_added_id = new URLSearchParams(window.location.search).get("scene_id")
            setSceneAddedID(scene_added_id)
        }
      }
    if (SHARED_ENTITIES != undefined && COMPANY_ID) {
      const shared = SHARED_ENTITIES.split("_").includes("product");
      if (shared) {
        setSaveByCompany(true);
        setReorderByCompany(true);
      }
    }
    if (MANAGED_SHARED_ENTITES != undefined && MANAGED_COMPANY_ID) {
      const shared = MANAGED_SHARED_ENTITES.split("_").includes("product");
      if (shared) {
        setMspCustomerSaveByCompany(true);
        setMspCustomerReorderByCompany(true);
      }
    }
  }, []);

  const onDelete = () => {
    setDeleteLoader(true);
    let api_end_point = ENVIRONMENT.UPDATE_PRODUCT;
    let username = CUSTOMER_USERNAME;

    let payload = {
      "product_id": currentId,
      "is_hidden": true,
      "username": username,
    };
    payload['sequence_id'] = productTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER

    let company_id = COMPANY_ID;
    if ((username != currentCustomer) && (company_id != companyID)) {
      //case of customer deleting the product added from store or shared by some other customer
      api_end_point = ENVIRONMENT.DELETE_SHARED_ENTITY;
      payload = {
        "product_id": currentId,
        "username": username,
      };
    }

    axios.post(api_end_point, payload)
      .then((response) => {
        // Retrieve the counts array from localStorage
        const counts = JSON.parse(localStorage.getItem("entity_counts"));

        if (counts) {
        const productEntry = counts.find(entity => entity.type === "product");

        if (productEntry && productEntry.count > 0) {
            productEntry.count -= 1;
        }

        // Store the updated counts array back in localStorage
        localStorage.setItem("entity_counts", JSON.stringify(counts));
        } else {
        console.error("Counts not found in localStorage.");
        }

        message.info('Product Deleted.');
        setDeleteLoader(false);
        setDeleteModal(false);
        window.location.href = '/list-products';
      })
      .catch((error) => {
        console.log(error);
        message.error('Error in deleting product');
        setDeleteLoader(false);
        setDeleteModal(false);
      });
  };

  const changeOrderBySelection = (e) => {
    setReorderByCompany(e.target.value);
  }

  const changeMSPCustomerOrderBySelection = (e) => {
    setMspCustomerReorderByCompany(e.target.value);
  }

  //creates entity arrangement when the products are first loaded, First time arrangements
  const createFirstArrangementOrder = (products, key) => {
    let items = checkIfItemsHaveReorderedOnce;
    items.push(key == "owned" ? "owned_product" : "other_product");
    setCheckIfItemsHaveReorderedOnce(items);
    const first_entity_arrangement = [];
    const first_folder_arrangement = [];
    let grouped_products = getGroupedProductData(products);
    // if product is not a variant or if product has is a variant but its parent does not exist in the library
    products.map((item, index) => {
      if(item.variant_of == "" || (item.variant_of != "" && !checkIfExistsInLibrary(products, item.variant_of)))
      { 
      if (!checkIfVariantsExistOfParent(products, item.id)) {
        // if variants do not exist for this product
        let obj = {
          entity_id: item.id,
          ordering_number: index,
          parent_folder_id: ""
        };
        first_entity_arrangement.push(obj);
      }
      else {
        // if variant for this product exists in the library, 
        // then add it in folder arrangement
        let folderEntities = [item.id]
        let foldername = products.filter(product => product.id == item.id);
        let folderData = grouped_products.filter((product) => product.parent_product_id === item.id.toString())
        if (folderData.length > 0) {
          folderData[0].products.map((product) => folderEntities.push(product.id))
          let obj = {
            ordering_number: folderData[0]['index'],
            folder_name: "Variations of " + foldername[0].name,
            folder_entities: folderEntities
          };
          first_folder_arrangement.push(obj);
        }

      }
    }
    });

    // products that are variants
    // ***also need to check if only child item is present but parent is not present
    const variations = products.filter((product) => product.variant_of != "" && checkIfExistsInLibrary(products, product.variant_of));

    const folderCount = {};
    variations.map((item) => {
      if (folderCount[item.variant_of] === undefined) {
        // adding parent item at the first index
        const obj = {
          entity_id: parseInt(item.variant_of),
          ordering_number: 0,
          parent_folder_id: ""
        }
        first_entity_arrangement.push(obj);
        folderCount[item.variant_of] = 1;

      } else {
        // updating ordering number of the rest of the items in the folder
        folderCount[item.variant_of]++;
      }
      let data = first_entity_arrangement && first_entity_arrangement.filter(
        (arrangement) => {
          return parseInt(item.id) == parseInt(arrangement.entity_id)
        });
      if (data && data.length == 0) {
        {/* add arrangement info & ordering number of variation product if its not
        already present in the arrangement */}
        const obj = {
          entity_id: item.id,
          ordering_number: folderCount[item.variant_of],
          parent_folder_id: ""
        }
        first_entity_arrangement.push(obj);
      }
    })
    let payload = {
      action: 'reorder_first_time',
      entity_type: getEntityType(key),
      entity_arrangement: first_entity_arrangement,
      folder_arrangement: first_folder_arrangement,
      username: getCurrentUserUsername(key),
      save_by_company: false,
    };
    if (SHARED_ENTITIES != undefined && COMPANY_ID && key == "owned") {
      if (SHARED_ENTITIES.split("_").includes("product")) {
        payload.company_id = COMPANY_ID;
        payload.save_by_company = true
      }
    } else if (MANAGED_SHARED_ENTITES != undefined && MANAGED_COMPANY_ID && key == "customer_owned") {
      if (MANAGED_SHARED_ENTITES.split("_").includes("product")) {
        payload.company_id = MANAGED_COMPANY_ID;
        payload.save_by_company = true
      }
    }
    if (products && products.length > 0 && first_entity_arrangement && first_entity_arrangement.length > 0) {
      organizeData(payload, getEntityType(key), false);
    }
  }


  const checkIfVariantsExistOfParent = (products, id) => {
    //check if variations exists for the product against this particular id
    if (products && products.length > 0) {
      let product = products.filter((product) => product.variant_of == id.toString());
      if (product.length > 0)
        return true;
    }
    return false;
  }

  const checkIfExistsInLibrary = (products, parent_product_id) => {
    // check if parent product exists in the library
    if (products && products.length > 0) {
      let product = products.filter(
        (product) => product.id == parseInt(parent_product_id)
      );
      if (product.length > 0) return true;
    }
    return false;
  }

  useEffect(() => {
    if ((ownedProductData.length > 0 || sharedProductData.length > 0) && (ownedProductsEntityArrangement.length > 0 || otherProductsEntityArrangement.length > 0)) {
      changeProductTab(productTab, 'trigger');
    }
  }, [ownedProductsEntityArrangement, ownedProductsFolderArrangement, otherProductsFolderArrangement, otherProductsEntityArrangement, ownedProductData, sharedProductData, productTab])

  const moveOutOfReorderState = () => {
    if (reorderItems) {
      setSearchValue('');
      removeSearchParams();
    }
    setShowBottomNavBar(false);
    setReorderItems(false);
    setSelectable(false);
    setSharingView(false);

    setSelectedFolderProducts([]);
    setSelectedProductIds([]);
    changeProductTab(productTab, 'trigger');
    setOrderUpdated(false);
  }

  useEffect(() => {
    setDefaultIdsForSharing();
  }, [ownedOriginalData, sharedOriginalData, productTab]);

  const setDefaultIdsForSharing = () => {
    let data = productTab == "owned" ? ownedOriginalData : sharedOriginalData;
    if (selectable === false) {
      let ids = data.map((ele) => {
        return parseInt(ele.id);
      });
      if (ids.length != 0) {
        setDefaultProductIds(ids);
      }
    }
  }

  const changeSearchValue = (value) => {
    setSearchValue(value);
    
    const url = new URL(window.location.href);
    if(value){
      url.searchParams.set('search', encodeURIComponent(value));
    }
    else {
      url.searchParams.delete('search')
    }
    window.history.replaceState({}, "", url);
  }

  const getGroupedProductData = (products) => {
    let grouped_products = [];
    let folder_indexes = {}
    if (products && products.length > 0) {
      // this will return the data for all folders and their parent models
      // compile data for when no arrangement data exists
      let groups = products.reduce((groups, product, index) => {
        let parent_product_id = product.variant_of;
        if (!groups[parent_product_id] && checkIfParentProductExistsInData(products, parent_product_id)) {
          groups[parent_product_id] = [];
          folder_indexes[parent_product_id] = products.length + 1
        }
        if (checkIfParentProductExistsInData(products, parent_product_id)) {
          groups[parent_product_id].push(product);
          if(index < folder_indexes[parent_product_id]){
            folder_indexes[parent_product_id] = index
          }
        }
        return groups;
      }, {});

      // To add it in the array format instead
      let groupArrays = Object.keys(groups).map((parent_product_id) => {
        return {
          parent_product_id,
          products: groups[parent_product_id],
          index: folder_indexes[parent_product_id]
        };
      });
      console.log('Grouped Product Array', groupArrays);
      setGroupedProducts(groupArrays);
      grouped_products = groupArrays;
    }
    return grouped_products;
  }

  useEffect(() => {
    // if MS provider then select first tab by default
    if (IS_MS_PROVIDER) {
      setProductTab("owned");
      setTabActiveKey("owned");
      Utilities.updateTabUrl("owned");
    }
    //if managed customer tab is selected
    if (IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME) {
      Utilities.updateTabUrl("customer_owned");
      setProductTab("customer_owned");
      setTabActiveKey("customer_owned");
      adjustLibraryView(mspCustomerOwnedProducts, "customer_owned");

    } else {
      //if any other tab is selected
      if ((ownedProductData && ownedProductData.length > 0) || (sharedProductData && sharedProductData.length > 0)) {
        if (getProductsLength("owned") > 0) {
          setProductTab("owned");
          setTabActiveKey("owned");
          Utilities.updateTabUrl("owned");
        }
        else if (getProductsLength("shared") > 0) {
          setProductTab("shared");
          setTabActiveKey("shared");
          Utilities.updateTabUrl("shared");
        }
        adjustLibraryView(ownedOriginalData, "owned");
        adjustLibraryView(sharedOriginalData, "shared");

      } else {
        setProductTab("owned");
        setTabActiveKey("owned");
        Utilities.updateTabUrl("owned");
      }
    }
    if (sortingHasSet) {
      console.log("jew")
      Utilities.updateTabUrl(prevTabState);
      setProductTab(prevTabState);
      setTabActiveKey(prevTabState);
      // setPrevTabState(null);
      setCheckIfTabChanged(!checkIfTabChanged)
    }
  }, [ownedProductData, sharedProductData, mspCustomerOwnedProducts])

  const onAddCollectionClick = (id) => {
    setSelectedProductIdForCollection(id);
    setCollectionModalVisible(true);
  }

  const sellOnStore = (data) => {
    setShowProductDetails(data);
    setProductID(data.id);
    setSellVisible(true);
    let product_id = data.id.toString();
    axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
      .then(res => {
        console.log(res.data);
        setShowProductDetails(res.data);
      });
  }

  const cancelSellModal = () => {
    setSellVisible(false);
  }

  const onChangePrice = (e) => {
    setPrice(e.target.value);
  }

  const removeItemFromStore = (product_data) => {
    setRemoveFromStore(true);
    setShowProductDetails(product_data);
    setProductID(product_data.id);
    let product_id = product_data.id.toString();
    axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
      .then(res => {
        console.log(res.data);
        setShowProductDetails(res.data);
      });
  }

  const setStoreRequests = () => {
    let payload = {
      product_id: productID.toString(),
    }
    if (showProductDetails.is_store_item == -2) {
      payload.is_store_item = -2;
    } else if (showProductDetails.is_store_item == 1) {
      payload.is_store_item = 0;
    } else {
      payload.is_store_item = -2;
    }

    let price_payload = {
      entity_id: productID.toString(),
      customer_username: CUSTOMER_USERNAME,
      entity_type: 'product',
      proposed_price: price,
    }
    setRemoveLoader(true);
    setSellLoader(true);
    axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
      .then(res => {
        if (showProductDetails.is_store_item == -2) {
          price_payload.action = 'update';
        } else if (showProductDetails.is_store_item == 1) {
          price_payload.action = 'remove';
        } else {
          price_payload.action = 'insert';
        }

        updateStore(price_payload);
      })
      .catch((error) => {
        setRemoveLoader(false);
        console.log(error);
        message.error('Error in removing from store.');
      });
  }

  const updateStore = (price_payload) => {
    axios.post(ENVIRONMENT.SET_STORE_PRICE, price_payload)
      .then((response) => {
        setSellLoader(false);
        if (showProductDetails.is_store_item == -2) {
          message.info('Price updated successfully!');
        } else if (showProductDetails.is_store_item == 1) {
          message.info('Item removed from store successfully!');
        } else {
          message.info('Price submitted successfully!');
        }

        window.location.href = '/list-products';
      })
      .catch((error) => {
        setSellLoader(false);
        console.log(error);
        message.error('Error.');
      });
  }

  const onClickShareIcon = () => {
    setSharingView(true);
    setAllSelected(false);
    setSelectable(true);
    setSelectedProductIds([]);
    setTriggerUpdate(!triggerUpdate);
    setReorderItems(false);
    setShowBottomNavBar(true);
    forceUpdate();
  };

  const checkIfParentProductExistsInData = (products, parent_product_id) => {
    let parent_product_exists = false;
    products.map((product) => {
      if (parseInt(product.id) == parseInt(parent_product_id)) {
        parent_product_exists = true
      }
    })
    return parent_product_exists;
  }

  const onChangeSharedTabType = (e) => {
    setTabChangeThroughClick(true);
    setSharedTabType(e.target.value);
  }

  const onMSPChangeSharedTabType = (e) => {
    setTabChangeThroughClick(true);
    setMspCustomerSharedTabType(e.target.value);
  }

  const getSelectedProductIds = () => {
    let product_ids = []
    if (selectable === false || selectedProductIds.length === 0) {
      if (folderState) {
        let folder_ids = []
        folderProducts.map((product) => folder_ids.push(product.id))
        product_ids = folder_ids;
      } else {
        product_ids = defaultProductIds;
      }
    }
    else {
      let selected_product_ids = selectedProductIds;
      product_ids = selected_product_ids;
      if (product_ids.length === 0) {
        if (folderState) {
          let folder_ids = []
          folderProducts.map((product) => folder_ids.push(product.id))
          product_ids = folder_ids;
        } else {
          product_ids = defaultProductIds;
        }
      } else {
        //if the user has not opened a stack and the shared entity is a folder stack then share all its products too
        if (!folderState) {
          groupedProducts.map((product) => {
            if (selected_product_ids.includes(parseInt(product.parent_product_id))) {
              product.products.map((element) => {
                if (!selected_product_ids.includes(element.id)) {
                  selected_product_ids.push(element.id);
                }
              })
            }
          });
        }
        product_ids = selected_product_ids;
      }
    }

    return product_ids
  }


  useEffect(() => {
    // set to initial product data that is being returned
    if ((ownedOriginalData.length > 0 || sharedOriginalData.length > 0)) {
      let owned_products = ownedOriginalData;
      if (owned_products.length > 0 && SEQUENCE_ID_OWNED == -1 && !checkIfItemsHaveReorderedOnce.includes('owned_product') && !ownedProductLoader) {
        createFirstArrangementOrder(owned_products, "owned");
      }

      let other_products = sharedOriginalData;
      if (other_products.length > 0 && SEQUENCE_ID_OTHER == -1 && !checkIfItemsHaveReorderedOnce.includes('other_product') && !sharedProductLoader) {
        createFirstArrangementOrder(other_products, "shared");
      }
    }
  }, [ownedOriginalData, sharedOriginalData, sharedProductLoader, ownedProductLoader])

  const getProductsLength = (type, tab_options = 'not_all') => {
    if (type == "customer_owned") {
      return mspCustomerOwnedOrgProducts.length;
    } else if (type == "customer_shared") {
      if (mspCustomerSharedTabType == "all" || tab_options == "all") {
        return mspCustomerOtherOrgProducts.length;
      } else if (mspCustomerSharedTabType == "shared") {
        const products = mspCustomerOtherOrgProducts.filter((product) => (product.shared_product_type == 'shared_by_customer'));
        return products.length;
      } else if (mspCustomerSharedTabType == 'props') {
        const products = mspCustomerOtherOrgProducts.filter((product) => (product.product_model_type == 'props' && product.is_store_item != 1));
        return products.length;
      } else {
        const products = mspCustomerOtherOrgProducts.filter((product) => (product.shared_product_type == 'added_from_store'));
        return products.length;
      }
    } else if (type == "owned") {
      return ownedOriginalData.length;
    } else {
      let products_temp =   sharedOriginalData;
      if (sharedTabType == 'all' || tab_options == 'all') {
        return products_temp.length;
      }
      else if (sharedTabType == 'shared') {
        const products = products_temp.filter((product) => (product.shared_product_type == 'shared_by_customer'));
        return products.length;
      }
      else if (sharedTabType == 'props') {
        const products = products_temp.filter((product) => (product.product_model_type == 'props' && product.is_store_item != 1));
        return products.length;
      }
      else {
        const products = products_temp.filter((product) => (product.shared_product_type == 'added_from_store'));
        return products.length;
      }
    }
  }

  const getProducts = (type) => {
    if (type == "customer_owned") {
      if (currentView == "folder_view") {
        return mspCustomerOwnedProducts;
      } else {
        return mspCustomerOwnedOrgProducts;
      }
    } else if (type == "customer_shared") {
      if (mspCustomerSharedTabType == "all") {
        if (currentView == "folder_view") {
          return mspCustomerOtherProducts;
        } else {
          return mspCustomerOtherOrgProducts;
        }
      } else if (mspCustomerSharedTabType == "shared") {
        const products = mspCustomerOtherOrgProducts.filter((product) => (product.shared_product_type == 'shared_by_customer'))
        return products;
      }  else if (mspCustomerSharedTabType == 'props') {
        const products = mspCustomerOtherOrgProducts.filter((product) => (product.product_model_type == 'props' && product.is_store_item != 1));
        return products;
      } else {
        const products = mspCustomerOtherOrgProducts.filter((product) => (product.shared_product_type == 'added_from_store'))
        return products;
      }
    }
    else if (type == "owned") {
      return ownedProductData;
    } else {
      if (sharedTabType == 'all') {
        return sharedProductData;
      }
      else if (sharedTabType == 'shared') {
        const products = sharedOriginalData.filter((product) => (product.shared_product_type == 'shared_by_customer'))
        return products;
      }
      else if (sharedTabType == 'props') {
        const products = sharedOriginalData.filter((product) => ((product.customer_username == CUSTOMER_USERNAME || product.company_id == COMPANY_ID) && product.product_model_type == 'props'));
        return products;
      }
      else {
        const products = sharedOriginalData.filter((product) => (product.shared_product_type == 'added_from_store'))
        return products;
      }
    }
  }

  const getProductCount = () => {
    if (IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
      return ownedOriginalData.length + sharedOriginalData.length + mspCustomerOwnedOrgProducts.length + mspCustomerOtherOrgProducts.length
    }
    return ownedOriginalData.length + sharedOriginalData.length;
  }

  const getTabTotalCount = () => {
    if (IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
      return getProductTabCount('customer_shared') + getProductTabCount('customer_owned')  + getProductTabCount('owned')  + getProductTabCount('shared'); 
    }
    return getProductTabCount('owned')  + getProductTabCount('shared'); 
  }

  const getFolderProductCount = () => {
    return folderProducts.length;
  }

  const enterFolderState = (element, removeSearchValue=true) => {
    setFolderState(true);
    let folder_items = element;
    if (folder_items.length > 0) {
      setSelectedParentProduct(folder_items[0]);
      if (folder_items[0]) {
        let currentFolderId = getFolderId(folder_items[0]);
        setFolderID(currentFolderId);
        setSelectedFolderParentId(folder_items[0].id);
        getFoldersListExceptCurrentFolder(currentFolderId);
      }
      let folder_products = _.cloneDeep(folder_items);// deep copy
      setFolderProducts(folder_products);
      if (removeSearchValue) {
        removeSearchParams();
        setSearchValue('');
      }
    }
  }

  const leaveFolderState = (removeSearchValue=false) => {
    setLastFolderAccessed(folderID);
    setFolderID(null);
    setFolderState(false);
    setFolderProducts([]);
    
    if (removeSearchValue == false) {
      setSearchValue('');
      removeSearchParams();
      resetFilters();
    }
    setSaveOrderedItems(false);
    changeProductTab(productTab, 'trigger')
    setCheckIfTabChanged(!checkIfTabChanged);
    
  }

  const setSortingOfLibrary = (value) => {
      if (value != 5) {
        leaveFolderState(true);
      }
      setSortBy(value);
      setStopDuplicateCall(false);
      setSortingHasSet(true);
      setInitialLoadShared(true);
      setInitialLoadOwned(true);
      setOwnedProductLoader(true);
      setSharedProductLoader(true);
      setInitialLoadMsp(true);
      Utilities.deleteParamsKeys();
  }

  useEffect(() => {
    if (saveOrderedItems) {
      reorderArrangementData();
    }
  }, [saveOrderedItems])

  const getTabSequenceID = (tabType) => {
    let sequence_id = -1;
    if (tabType == "owned") {
      sequence_id = SEQUENCE_ID_OWNED;
    } else if (tabType == "shared") {
      sequence_id = SEQUENCE_ID_OTHER;
    } else if (tabType == "customer_owned") {
      sequence_id = customerSequences["owned"];
    } else {
      sequence_id = customerSequences["other"];
    }
    return sequence_id;
  };

  const getEntityType = (tabType) => {
    if (tabType == "owned" || tabType == "customer_owned") {
      return "owned_product";
    } else {
      return "other_product";
    }
  };

  const getCurrentUserUsername = (tabType) => {
    if (tabType == "owned" || tabType == "shared") {
      return CUSTOMER_USERNAME;
    } else {
      return MANAGED_CUSTOMER_USERNAME;
    }
  };

  const getCurrentUserCompany = (tabType) => {
    if (tabType == "owned" || tabType == "shared") {
      return COMPANY_ID;
    } else {
      return MANAGED_COMPANY_ID;
    } 
  };

  const getReorderByCompany = (tabType) => {
    if (tabType == "owned") {
      return reorderByCompany;
    } else if (tabType == "customer_owned") {
      return mspCustomerReorderByCompany;
    } else {
      return false;
    }
  };

  const reorderArrangementData = (entitiesArrangement, folderArrangement) => {
    if (saveOrderedItems && entitiesArrangement && folderArrangement) {
      setSaveOrderedItems(false);
      let payload = {};
      let company_arrangement = false;
      if (productTab == "owned") {
        company_arrangement = companyArrangement
      } else if (productTab == "customer_owned") {
        company_arrangement = customerCompanyArrangement
      }

      if (
        (reorderByCompany == company_arrangement && productTab == "owned") || 
        (mspCustomerReorderByCompany == company_arrangement && productTab == "customer_owned") ||
        (productTab == "shared") ||
        (productTab == "customer_shared")
        )  {
          payload = {
          action: "reorder",
          entity_arrangement: entitiesArrangement,
          folder_arrangement: folderArrangement,
          sequence_id: getTabSequenceID(productTab)
        };
        console.log("Re Order Payload ", productTab, payload);
      } else {
        let entity_arrangement = []
        let folder_arrangement = [];
        if (folderState) {
          let arrangement_values = getFullArrangements(entitiesArrangement);
          console.log(arrangement_values)
          entity_arrangement = arrangement_values['new_entity_arrangement'];
          folder_arrangement = arrangement_values['new_folder_arrangement'];
        } else {
          entity_arrangement = entitiesArrangement;
          folder_arrangement = folderArrangement;
        }
        let folder_arr = [];
        let folder_dict = {};
        folder_arrangement.map((folder) => {
          let folder_entities = [];
          folder_dict = {
            ordering_number: folder["ordering_number"],
            folder_name: folder["folder_name"],
          };
          let entities = []
          entity_arrangement.map((entity) => {
            if (entity["parent_folder_id"] == folder["folder_id"]) {
              entities.push(entity);

            }
          });
          if (entities.length > 0) {
            entities = entities.sort((a, b) => (a.ordering_number - b.ordering_number))
            entities.map((entity) => {
              folder_entities.push(entity["entity_id"]);
            })
          }

          folder_dict["folder_entities"] = folder_entities;
          folder_arr.push(folder_dict);
        });
        let new_entity_arr = [];
        let entity_dict = {};
        entity_arrangement.map((entity) => {
          entity_dict = entity;
          entity_dict["parent_folder_id"] = "";
          new_entity_arr.push(entity_dict);
        });

        payload = {
          action: "reorder_first_time",
          entity_type: getEntityType(productTab),
          username: getCurrentUserUsername(productTab),
          company_id: getCurrentUserCompany(productTab),
          entity_arrangement: new_entity_arr,
          folder_arrangement: folder_arr,
          old_sequence_id: getTabSequenceID(productTab),
          save_by_company: getReorderByCompany(productTab),
        };
      }
      console.log('Payload', payload)
      organizeData(payload, getEntityType(productTab));
    }
  }

  const getFullArrangements = (entity_arr) => {
    let new_entity_arrangement = [];
    let new_folder_arrangement = [];
    let arrangement_info = {};
    let folder_arrangement_info = {};
    let items = getProducts(productTab);
    items.map((item, index) => {
      if (Array.isArray(item)) {
        folder_arrangement_info = {
          folder_id: parseInt(item[0].folder_id),
          ordering_number: index,
          folder_name: item[0].folder_name,
          sequence_id: getTabSequenceID(productTab)
        }
        new_folder_arrangement.push(folder_arrangement_info);

        item.map((folder_item, folder_index) => {
          let folder_data = []
          let folder_arr_info = entity_arr.filter((arrangement) => parseInt(arrangement.entity_id) == folder_item.id);
          if (folder_arr_info.length > 0) {
            folder_data = folder_arr_info[0];
            arrangement_info = {
              entity_id: parseInt(folder_data.entity_id),
              ordering_number: folder_data.ordering_number,
              parent_folder_id: parseInt(folder_item.folder_id),
              sequence_id: getTabSequenceID(productTab)
            }
          } else {
            folder_data = item;
            arrangement_info = {
              entity_id: parseInt(folder_item.id),
              ordering_number: folder_index,
              parent_folder_id: parseInt(folder_item.folder_id),
              sequence_id: getTabSequenceID(productTab)
            }
          }
          new_entity_arrangement.push(arrangement_info);
        })
      } else {
        arrangement_info = {
          entity_id: parseInt(item.id),
          ordering_number: index,
          parent_folder_id: item.folder_id,
          sequence_id: getTabSequenceID(productTab)
        }
        new_entity_arrangement.push(arrangement_info);
      }
    })
    return {
      new_entity_arrangement, new_folder_arrangement
    }
  }

  const changeProductTab = (key, event_type) => {
    // key is the tab key, and event type tells us
    // whether tab change was triggered by click or through
    // any other action
    setProductTab(key);
    setTabActiveKey(key);
    Utilities.updateTabUrl(key);
    setCurrentTabArrangements(key);
    setSelectedFolderProducts([]);
    setSelectedProductIds([]);

    if (event_type == 'click') {
      setTabChangeThroughClick(true);
      let url = new URL(window.location);
      url.searchParams.delete("folder_entity_id");
      url.searchParams.delete("folder_id");
      url.searchParams.delete("entity_id");
      window.history.replaceState({}, "", url);
    }
  }

  const setCurrentTabArrangements = (key) => {
    if (key == "owned") {
      setFolderArrangement(ownedProductsFolderArrangement);
      setEntityArrangement(ownedProductsEntityArrangement);
    } else if (key == "shared") {
      setFolderArrangement(otherProductsFolderArrangement);
      setEntityArrangement(otherProductsEntityArrangement);
    } else if (key == "customer_owned") {
      setFolderArrangement(mspOwnedProductsFolderArrangement);
      setEntityArrangement(mspOwnedProductsEntityArrangement);
    } else if (key == "customer_shared") {
      setFolderArrangement(mspOtherProductsFolderArrangement);
      setEntityArrangement(mspOtherProductsEntityArrangement);
    }
  }

  const createNewFolder = () => {
    setNewFolder(true)
  }

  const checkIfFolderNameAlreadyExists = (folder_name) => {
    let folder_info = getFoldersData(productTab);
    const folder_exists = folder_info.filter(info => info.folder_name == folder_name);
    if (folder_exists.length > 0) {
      return true;
    }
    return false;
  }

  const getFoldersData = (tab_key) => {
    if (tab_key == "owned") {
      return ownedFolderData;
    } else if (tab_key == "shared"){
      return otherFolderData;
    } else if (tab_key == "customer_owned") {
      return mspCustomerOwnedFolderData;
    } else { 
      return mspCustomerOtherFolderData;
    }
  }

  const createNewFolderConfirm = () => {
    if (newFolderName) {
      const folderExists = checkIfFolderNameAlreadyExists(newFolderName);
      if (folderExists) {
        setNewFolder(true);
        setErrorMessage("Folder already exists no duplicate folders are allowed.")
      } else {
        setErrorMessage("");
        let folder_entities = selectedFolderProducts.map((folder_entity) => {
          return parseInt(folder_entity)
        })
        const newFolder = {
          folder_name: newFolderName,
          folder_entities: folder_entities
        }

        let payload = {
          action: "create_new_folder",
          folder_arrangement: newFolder,
          sequence_id: getTabSequenceID(productTab)
        };

        let url = new URL(window.location);
        let product_id = new URLSearchParams(window.location.search).get("entity_id");
        url.searchParams.set("folder_entity_id", product_id);
        url.searchParams.delete("entity_id");
        url.searchParams.delete("folder_id");
        window.history.replaceState({}, "", url);
        setSelectedFolderProducts([]);
        setReorderItems(false);
        setShowBottomNavBar(false);
        setNewFolderName(null);

        organizeData(payload, getEntityType(productTab));
      }
    }
    else {
      setErrorMessage("Please enter a folder name.");
    }
  }

  const getFoldersListExceptCurrentFolder = (id) => {
    let folder_info = getFoldersData(productTab);
    const folder_list = folder_info.filter(info => info.folder_id != id);
    setFoldersListExceptCurrentFolder(folder_list);
  }

  const moveToFolderOnClick = () => {
    setExistingFolder(true);
    if (selectedParentProduct) {
      let currentFolderId = getFolderId(selectedParentProduct);
      getFoldersListExceptCurrentFolder(currentFolderId);
    }
    let folder_info = getFoldersData(productTab);
    setFolderInfo(folder_info);
  }

  const moveToFolderConfirm = () => {
    if (selectedFolder) {

      const folderId = folderInfo.filter(folderInfo => folderInfo.folder_id == selectedFolder);
      Utilities.updateFolderIDInUrl(selectedFolder);

      let folder_entities = selectedFolderProducts.map((folder_entity) => {
        return parseInt(folder_entity)
      })
      let payload = {
        action: 'move_to_folder',
        sequence_id: getTabSequenceID(productTab),
        folder_id: folderId[0].folder_id,
        moved_entities: folder_entities,
      }
      console.log(payload, 'Move to Folder')
      organizeData(payload, getEntityType(productTab));
      setSelectedFolderProducts([]);
      setShowBottomNavBar(false);
      setReorderItems(false);
      setSelectedFolder(null);
    } else {
      alert("Please select a folder first")
    }
  }

  const organizeData = (payload, entity_type = 'owned_product', reload = true) => {
    console.log('Arrangement Payload', payload)
    setActionLoader(true);
    axios.post(ENVIRONMENT.ENTITY_ORGANIZER, payload)
      .then(res => {
        console.log("Arrangement Data: ", res);
        if (payload.action == 'reorder_first_time') {
          if (productTab == "owned" || productTab == "shared") {
            loadSequenceIDs(CUSTOMER_USERNAME, COMPANY_ID, SHARED_ENTITIES, false, false, reload);
          } else {
            loadSequenceIDs(MANAGED_CUSTOMER_USERNAME, MANAGED_COMPANY_ID, MANAGED_SHARED_ENTITES, true, false, reload);
          }
        } else {
          if (folderID) {
            window.location.reload();
          } else {
            window.location.reload();
          }
        }
        setExistingFolder(false);
        setViewUnGroupFolderModal(false);
        setViewRenameFolderModal(false);
        setNewFolder(false);
        setActionLoader(false);
      }).catch(err => {
        console.log(err);
      });
  }

  const getFolderName = (element) => {
    if (element.folder_id != "") {
      return element.folder_name;
    }
    return '';
  };

  //get folder id of parent id
  const getFolderId = (element) => {
    if (element.folder_id != "") {
      return element.folder_id;
    }
    return -1;
  }

  const renameFolderOnclick = (element) => {
    let folder_item = element[0];
    Utilities.updateFolderIDInUrl(folder_item.folder_id);

    setSelectedFolderParentId(folder_item.id);
    setSelectedParentProduct(folder_item)
    const folderName = getFolderName(folder_item);
    setFolderNewName(folderName);
    setViewRenameFolderModal(true);
  }

  const renameFolderConfirm = () => {
    if (folderNewName) {
      const folderExists = checkIfFolderNameAlreadyExists(folderNewName);
      if (folderExists) {
        setViewRenameFolderModal(true);
        setRenameError("Folder name already exists. Please enter a unique name.");
      } else {
        setRenameError("");
        const selectedFolderId = getFolderId(selectedParentProduct)
        let payload = {
          action: 'rename_folder',
          folder_id: parseInt(selectedFolderId),
          folder_name: folderNewName,
          sequence_id: getTabSequenceID(productTab)

        }
        organizeData(payload, getEntityType(productTab))
        setSelectedFolderParentId(null);
        setSelectedParentProduct(null);
      }
    }
    else {
      setRenameError('Please enter a folder name.')
    }
  }

  const unGroupFolderItemsOnclick = (element) => {
    let folder_item = element[0];
    Utilities.updateEntityInUrl(folder_item.id);
    setSelectedParentProduct(folder_item);
    setSelectedFolderParentId(folder_item.id);
    setViewUnGroupFolderModal(true);
  }

  const unGroupFolderItemsConfirm = () => {
    const selectedFolderId = getFolderId(selectedParentProduct);
    let payload = {
      'action': 'delete_folder',
      deleted_folder_id: parseInt(selectedFolderId),
      sequence_id: getTabSequenceID(productTab)
    }

    if (productTab == "owned") {
      if (SHARED_ENTITIES != undefined && COMPANY_ID != undefined) {
        payload.company_id = COMPANY_ID;
      }
    } else if (productTab == "customer_owned") {
      if (MANAGED_SHARED_ENTITES != undefined && MANAGED_COMPANY_ID != undefined) {
        payload.company_id = MANAGED_COMPANY_ID;
      }
    }
    organizeData(payload, getEntityType(productTab));
  }

  const saveArrangements = () => {
    setSaveOrderedItems(true);
    setShowBottomNavBar(false);
    setReorderItems(false);
    setSelectable(false);
    setSelectedFolderProducts([]);
    setSelectedProductIds([]);
    setRearrangementLoader(true);
  }

  const getProductTabData = () => {
    if (tabActiveKey == "customer_owned") {
        return getProducts("customer_owned");
    } else if (tabActiveKey == "customer_shared") {
        return getProducts('customer_shared')
    } else if (tabActiveKey == "owned") {
        return getProducts('owned');
    } else if (tabActiveKey == "shared") {
        return getProducts('shared');
    }
}


const selectAllProducts = () => {
    const data = getProductTabData(); // Fetch the scene data
    const ids = data.flatMap((product) => {
      if (Array.isArray(product)) {
        return product.map((item) => item.id);
      } else {
        return product.id;
      }
    });
    
    console.log(ids)
    setSelectedProductIds(ids); // Up
    setAllSelected(true);
}

const deselectAllProducts = () => {
    setAllSelected(false);
    setSelectedProductIds([]); // Up
}

  const onChangeFolderName = (value) => {
    setNewFolderName(value);
  }

  const onChangeNewFolderName = (value) => {
    setFolderNewName(value);
  }

  const closeReorderByModal = () => {
    setReorderByModal(false);
  }

  useEffect(() => {
    setProductCategoryData();
    loadProductMaterialsData();
    getCompanyUsersEmails();
  }, []);

  useEffect(() => {
    //display tags when this is clicked
    if (productCategoryValue.length > 0
      || (productStyleCategory.length > 0)
      || (colorValue.length > 0)
      || (productMaterials.length > 0)
      || (productModelType.length > 0)) {
      setFiltersApplied(true);
      updateFiltersFlag();
      forceUpdate();
      setAppliedFiltersList(getAppliedFilters())
    } else {
      setFiltersApplied(false);
      updateFiltersFlag();
      setAppliedFiltersList([])
    }
  }, [productCategoryValue, productStyleCategory, colorValue, productMaterials, productModelType])

  useEffect(() => {
    updateFiltersInQueryString()
  }, [filtersAppliedFlag])

  const openFilters = () => {
    setFiltersDrawer(true);
  };

  const updateFiltersInQueryString = () => {
    let filters = {
      'category': productCategoryValue,
      'style': productStyleCategory,
      'color': colorValue,
      'material': productMaterials, 
      'type': productModelType
    }

    const url = new URL(window.location.href);
  
    if(!initialLoadShared && !initialLoadOwned){
      // Iterate over each filter variable and add its values to the URL query parameters
      for (const filter in filters) {
  
            const values = filters[filter];
            if (values && values.length > 0) {
                if (Array.isArray(values)) {
                    // If multiple values are present for a filter, add them together under the same key
                    const combinedValues = values.join(',');
                    url.searchParams.set(filter, encodeURIComponent(combinedValues));
                } else {
                    // If a single value is present for a filter, set it, overwriting any existing value
                    if (values !== null && values !== undefined && values !== "") {
                        url.searchParams.set(filter, encodeURIComponent(values));
                    }
                }
            } else {
                // If no values are provided for a filter, remove it from the URL
                url.searchParams.delete(filter);
            }
  
      }
      window.history.replaceState({}, "", url);
    }
  }

  const switchView = () => {
    if (currentView === 'folder_view') {
      setCurrentView("flat_view");
    } else {
      setCurrentView("folder_view");
    }
  }

  const closeFiltersDrawer = () => {
    setFiltersDrawer(false);
  }

  const setProductCategoryData = () => {

    let payload = {
      output: 'tree'
    };
    axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
      .then(res => {
        setProductCategoriesData(res.data);
      });

    payload = {
      output: 'serial'
    };
    axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
      .then(res => {
        setProductParentCateogries(res.data);
      });
  }

  const loadProductMaterialsData = () => {
    let data = [];
    axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
    })
      .then(res => {
        let categoriesData = Utilities.getMaterialsData(res.data);
        for (let i = 1; i <= (res.data).length; i++) {
          if (res.data[i - 1].parent_id == "") {
            data.push({
              key: res.data[i - 1]['material_id'],
              title: res.data[i - 1]['name'],
              value: res.data[i - 1]['name'],
              children: categoriesData[res.data[i - 1]['material_id']],
            });
          }

        }
        setProductMaterialsData(data);
      });
  }

  const closeTags = (entity, type) => {
    //remove the tag when this is clicked from showing result section
    if (type == 'product_category') {
      let arr = productCategoryValue.filter(category => {return entity != category});
      changeProductCategory(arr)
    } else if (type == 'product_style_category') {
      let arr = productStyleCategory.filter(style_category => { return entity != style_category });
      setProductStyleCategory(arr);
    } else if (type == 'product_color') {
      let arr = colorValue.filter(color => { return entity != color });
      setColorValue(arr);
    } else if (type == 'product_material') {
      let arr = productMaterials.filter(material => { return entity != material });
      setProductMaterials(arr);
    } else if (type == 'product_model_type') {
      let arr = productModelType.filter(model => { return entity != model });
      setProductModelType(arr);
    }
  }

  const changeProductCategory = (value) => {
    let values = []

    value.map((name) => {
      const targetItemName = name;
      const targetItem = productParentCateogries.find(item => item.name === targetItemName);

      if (targetItem) {
        const result = findItemsByParentId(productParentCateogries, targetItemName, targetItem.category_id);
        result.unshift(name);
        values = [...values, ...result]
      }
    });

    if (value.length > 0
      || (productStyleCategory.length > 0)
      || (colorValue.length > 0)
      || (productMaterials.length > 0)
      || (productModelType.length > 0)) {
      setFiltersApplied(true);
      updateFiltersFlag();
    } else {
      setFiltersApplied(false);
      updateFiltersFlag();
    }
    setProductCategoryValue(value);
    setProductCategoryValueWithChilds(values);
  }

  const changeProductStyleCategory = (value) => {
    if (value.length > 0
      || (colorValue.length > 0)
      || (productMaterials.length > 0)
      || (productModelType.length > 0)) {
      setFiltersApplied(true);
      updateFiltersFlag();
    } else {
      setFiltersApplied(false);
      updateFiltersFlag();
    }

    setProductStyleCategory(value);
  }

  const changeColorValue = (value) => {
    if (value.length > 0
      || (productStyleCategory.length > 0)
      || (productMaterials.length > 0)
      || (productModelType.length > 0)) {
      setFiltersApplied(true);
      updateFiltersFlag();
    } else {
      setFiltersApplied(false);
      updateFiltersFlag();
    }

    setColorValue(value);
  }

  const changeProductModelType = (value) => {
    if (value.length > 0
      || (productStyleCategory.length > 0)
      || (productMaterials.length > 0)
      || (colorValue.length > 0)) {
      setFiltersApplied(true);
      updateFiltersFlag();
    } else {
      setFiltersApplied(false);
      updateFiltersFlag();
    }
    setProductModelType(value);
  }

  const findChildrenByParentTitle = (parentTitle, node) => {
    if (node.title === parentTitle) {
      return node.children.map(child => child.title);
    } else if (node.children) {
      let result = [];
      for (const childNode of node.children) {
        result = result.concat(findChildrenByParentTitle(parentTitle, childNode));
      }
      return result;
    } else {
      return [];
    }
  }

  const changeProductMaterial = (value) => {

    let values = []

    value.map((name) => {
      const targetItemTitle = name;
      const targetItem = productMaterialsData.find(item => item.title === targetItemTitle);
      if (targetItem) {
        const result = findChildrenByParentTitle(targetItemTitle, targetItem);
        result.unshift(name);
        values = [...values, ...result]
      }
    });

    if (value.length > 0
      || (productStyleCategory.length > 0)
      || (colorValue.length > 0)
      || (productModelType.length > 0)) {
      setFiltersApplied(true);
      updateFiltersFlag();
    } else {
      setFiltersApplied(false);
      updateFiltersFlag();
    }

    if (values.length <= 0) {
      values = value
    }

    setProductMaterials(value);
    setMaterialsCategoryValueWithChilds(values);

  }

  const returnSearchValue = (entity) => {
    //return value according to the filters applied
    if (getProductSearchValue(entity)) {
      return true;
    } else {
      return false;
    }
  }

  const getAppliedFilters = () => {
    let appliedFilters = [...productCategoryValueWithChilds, ...productStyleCategory, ...colorValue,
                          ...materialsCategoryValueWithChilds, ...productModelType]
    appliedFilters = appliedFilters.map(item => item.toLowerCase())
    return appliedFilters;
  }

  const getProductSearchValue = (entity) => {
    let category = entity.category;
    let style_category = entity.style_category;
    let color = entity.color_name;
    let materials = []
    if (entity.materials && entity.materials.length > 0) {
      materials = entity.materials
    }
    let product_model_type = entity.product_model_type;
    let product_category = false;
    let style_category_matched = false;
    let color_matched = false;
    let material_matched = false;
    let model_type_matched = false;

    if (category != '') {
      if ((productCategoryValueWithChilds.length > 0 && productCategoryValueWithChilds.includes(category)) || productCategoryValueWithChilds.length == 0) {
        product_category = true;
      }
    }

    if ((productStyleCategory.length > 0 && productStyleCategory.includes(style_category)) || productStyleCategory.length == 0) {
      style_category_matched = true;
    }

    if ((colorValue.length > 0 && colorValue.map(value => value.toLowerCase()).includes(color.toLowerCase())) || colorValue.length == 0) {
      color_matched = true;
    }

    if ((materialsCategoryValueWithChilds.length > 0 && materialsCategoryValueWithChilds.some(element => materials.includes(element))) || materialsCategoryValueWithChilds.length == 0) {
      material_matched = true;
    }

    if ((productModelType.length > 0 && productModelType.includes(product_model_type) && entity.is_store_item != 1) || productModelType.length == 0) {
      model_type_matched = true;
    }

    if (product_category && style_category_matched && material_matched && color_matched && model_type_matched) {
      return true;
    }
  }

  const changeProductSearchCategory = (value) => {
    setCategorySearchValue(value);
  }

  const findItemsByParentId = (data, targetItemName, parentId) => {
    const items = [];
    for (const item of data) {
      if (item.parent_id === parentId) {
        items.push(item.name);
        items.push(...findItemsByParentId(data, targetItemName, item.category_id));
      }
    }
    return items;
  }

  const getFilteredTags =()=> {
    let filteredTags = <>
      {filtersApplied && !reorderItems && <Col span={24} style={{ marginLeft: 10, marginTop: 20, textAlign: "left" }}>
        <h4 className='manrope f-14 black-55 italic'>Showing results for</h4>
        <div style={{ marginBottom: 20 }}>
          {
            productCategoryValue.length > 0 && productCategoryValue.map((category) => {
              return <Tag onClose={() => closeTags(category, 'product_category')}
                closable visible={true}
                className='manrope f-12 black-55 tag-filter'>Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
            })}

          {productStyleCategory.length > 0 && productStyleCategory.map((category) => {
            return <Tag onClose={() => closeTags(category, 'product_style_category')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Style Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
          })}

          {colorValue.length > 0 && colorValue.map((category) => {
            return <Tag onClose={() => closeTags(category, 'product_color')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Color: <span className='manrope f-12 grey-77'>{category}</span></Tag>
          })}

          {
            productMaterials.length > 0 && productMaterials.map((category) => {
              return <Tag onClose={() => closeTags(category, 'product_material')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Material: <span className='manrope f-12 grey-77'>{category}</span></Tag>
            })}

          {
            productModelType.length > 0 && productModelType.map((category) => {
              return <Tag onClose={() => closeTags(category, 'product_model_type')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Product Type: <span className='manrope f-12 grey-77'>{FileConstants.PRODUCT_MODEL_TYPE[category]}</span></Tag>
            })}

        </div>
      </Col>}
    </>

    return filteredTags;
  }

  const resetFilters = () => {
    productCategoryValue.length > 0 && productCategoryValue.map((category) => {
      closeTags(category, 'product_category');
    });
    productStyleCategory.length > 0 && productStyleCategory.map((category) => {
      closeTags(category, 'product_style_category');
    });
    colorValue.length > 0 && colorValue.map((category) => {
      closeTags(category, 'product_color');
    })
    productMaterials.length > 0 && productMaterials.map((category) => {
      closeTags(category, 'product_material')
    })
    productModelType.length > 0 && productModelType.map((category) => {
      closeTags(category, 'product_model_type');
    })
  };
 
  const ReorderYourModelsData = (props) => {
    let message = `Do you want to reorder ${props.productTab == "owned" ? "your" : MANAGED_CUSTOMER_USERNAME}'s data by company or by user?`
    return (
    <div style={{ padding: "16px 16px 0 16px" }}>
      <h2 className="manrope f-18 black-00 w-600">{message}</h2>
      {!props.folderState && <p className="blue-note-bg">

          <span className="scene-note flag">
              Note: <span style={{ color: "#333333" }}>Items in {props.productTab == "owned" ? "'Your Models'" : `'${MANAGED_CUSTOMER_USERNAME} Models'`} tab will be reordered since that tab is selected.</span>
          </span>
      </p>}
      <Radio.Group defaultValue={props.reorderByCompany} onChange={props.changeOrderBySelection} className="modal-radio-text">
          <Radio className="manrope f-12 black-55" value={true} style={{ marginBottom: "8px" }}>By Company</Radio>
          <br />
          <Radio className="manrope f-12 black-55" value={false} style={{ marginBottom: "8px" }}>By User</Radio>
      </Radio.Group>
    </div>
    );
  }

  const ReorderOtherModelsData = (props) => {
    let tabName = ""
    if (props.productTab == "owned") { tabName = "'Your Models'" }
    else if (props.productTab == "shared") { tabName = "'Other Models'" }
    else if (props.productTab == "customer_owned") { tabName =`'${MANAGED_CUSTOMER_USERNAME} Models'` }
    else { tabName =`'${MANAGED_CUSTOMER_USERNAME} Other Models'` }
    return (
      <div style={{ padding: "16px 16px 0 16px" }}>
          <h2 className="manrope f-18 black-00 w-600">
              Do you want to reorder your data?
          </h2>
          {!props.folderState && <p className="blue-note-bg">
              <span className="scene-note flag">
                  Note: <span style={{ color: "#333333" }}>Items in {tabName} tab will be reordered since that tab is selected.</span>
              </span>
          </p>}
      </div>)
  }

  const getProductTabCount = (type) => {
    return tabCount[type] !== -1 ? tabCount[type] : getProductsLength(type);
  };

  const updateFiltersFlag = () => {
    setFiltersAppliedFlag((prevFlag) => !prevFlag);
  };

  const selectAssetAndSetBasicInfo = (id, entity, asset) => {
    setCurrentProductId(id);
    let payload = { product_id: id, username: entity.customer_username }
    if (entity.company_id) {
        payload.company_id = entity.company_id
    }
    props.getProduct(payload);
    setProductBasicInfo(entity);
    setSelectedAssetModal(asset);
  }

  const checkFilteredResults = (products) => {
    let productsData = [];
    if (searchValue != '' && !filtersApplied) {
      const allProductsData = Utilities.getDataWithoutFolders(products);
      productsData = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, allProductsData);
    } else if (searchValue == '' && filtersApplied) {
      const allEntities = Utilities.getDataWithoutFolders(products);
      productsData = allEntities.filter(returnSearchValue);
    } else if (searchValue != '' && filtersApplied) {
      const allEntities = Utilities.getDataWithoutFolders(products);
      let filter_data = allEntities.filter(returnSearchValue);
      productsData = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, filter_data);
    }
    return productsData;
  }

  const switchTabIfEmpty = () => {
    // if current selected tab is empty only then switch to other tab
    if (tabChangeThroughClick == false) {
      if (checkFilteredResults(getProducts(productTab))?.length == 0) {
          if ((IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) && checkFilteredResults(getProducts("customer_owned"))?.length > 0) {
            setProductTab("customer_owned");
            Utilities.updateTabUrl("customer_owned");
          } else if ((IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) && checkFilteredResults(getProducts("customer_shared"))?.length > 0) {
            setProductTab('customer_shared');
            Utilities.updateTabUrl('customer_shared');
          } else if (checkFilteredResults(getProducts("owned"))?.length > 0) {
            setProductTab('owned');
            Utilities.updateTabUrl('owned');
          } else if (checkFilteredResults(getProducts("shared"))?.length > 0) {
            setProductTab('shared');
            Utilities.updateTabUrl('shared');
          }
        }
    }
  }


  return (
    <>
      <ProductAssetModals props={props}
      currentPage={currentPage}
      accessed_from={'library'}
      folderParentID={folderParentID}
      setFolderParentID={setFolderParentID}
      currentProductId={currentProductId}
      setCurrentProductId={setCurrentProductId}
      selectedAssetModal={selectedAssetModal}
      productBasicInfo={productBasicInfo} />
      <div className="my-products">
              <CustomerMainLayout selected="3">
                {
                    sceneCreated != -1 ? 
                    <div className="scene-added-banner">
                    <div className="banner-content">
                        <img src='/img/CheckCircle.svg'/>
                        <span className="banner-content-span">
                            {sceneCreated == 1 ? "Your product has been added to your scene" 
                            : "Your product has been added to your scene(s)."}
                        </span>
                        <a href={sceneCreated == 1 ? '/scene_builder/' + sceneAddedID + '?origin=/list-products' : "/my_scenes"}>
                            <div style={{display:"flex", gap: 4, alignItems: 'center'}}>
                                    <span className="banner-content-span" style={{fontWeight: 700}}> 
                                    {sceneCreated == 1 ? "Open Scene Creator" : "Go to Scene Library"} </span>
                                    <img src='/img/ArrowRightGreen.svg' style={{marginTop: 2}}></img>
                            </div>
                        </a>
                    </div>
                    <div>
                        <CloseOutlined style={{color: '#237804'}} onClick={() => setSceneCreated(-1)}/>
                    </div>
                    </div> : ""}
                  <Row className="justify-space-between" style={{ marginTop: "20px", marginBottom: 20 }}>
                      {(initialLoadShared || initialLoadOwned) ? "" :
                          <>
                              <Col lg={10} xl={10}>
                                  <div className='manrope f-24 w-700' style={{ display: 'flex', alignItems: folderState ? "center" : "flex-end" }}>
                                      {folderState ? <img src="/img/back_arrow.png" style={{ marginRight: 20, cursor: "pointer" }} onClick={leaveFolderState} /> : ''}
                                      {folderState ?
                                          <Tooltip title={<span className="manrope f-12 white">
                                              {selectedParentProduct ?
                                                  (getFolderId(selectedParentProduct) != -1 ? selectedParentProduct.folder_name : "") : ""}</span>}>
                                              <span className='manrope f-24 w-700 clamp-text w-60 clamp-w-width'>{selectedParentProduct ?
                                                  (getFolderId(selectedParentProduct) != -1 ?
                                                      selectedParentProduct.folder_name : "") : ""}</span>
                                          </Tooltip> : "Product Library"}
                                      {!folderState && !sharedProductLoader && !ownedProductLoader ? <span style={{ marginLeft: 4, paddingBottom: 2 }} className="manrope f-20">({(searchValue != '' || filtersApplied)&&<span className="manrope grey-6b">{getTabTotalCount()} of&nbsp;</span>}{getProductCount()} items)</span>
                                          : folderState && folderProducts && !loader ? <span style={{ marginLeft: 4 }} className="manrope f-20">({getFolderProductCount()} items)</span> : ''}
                                  </div>

                              </Col>
                              <Col lg={14} xl={14} className="justify-in-end">
                                  {!reorderItems && (
                                      <div className="search-bar-width mr-8">
                                          <Form>
                                              <Input.Group compact size="large" className="d-inline">
                                                  <Input placeholder="Search All Products" value={searchValue} onChange={e => { changeSearchValue(e.target.value); } } className="manrope f-14 mat-search-bar"></Input>
                                              </Input.Group>
                                          </Form>
                                      </div>
                                  )}

                                  {!reorderItems && IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME !== null && !folderState ?
                                      <div>
                                          <Tooltip title={<span className="manrope f-12 white">{currentView == 'folder_view' ? 'Switch to All Products View' : 'Switch to Folder View'}</span>}>
                                            <span className={['customer_owned', 'customer_shared'].includes(productTab) ? "" : "not-allowed-setting"}>
                                                <div className={['customer_owned', 'customer_shared'].includes(productTab) ? "icon-button-container mr-8" : "pointer-none-setting icon-button-container mr-8"} onClick={switchView}>
                                                  {
                                                    currentView === 'flat_view' ? <FolderOpenOutlined/> : <AppstoreOutlined/>
                                                  }
                                                </div>
                                              </span>
                                          </Tooltip>
                                      </div> : ''}

                                      {!reorderItems ?
                                      <div>
                                          <Tooltip title={<span className="manrope f-12 white">Apply Filters</span>}>
                                            <span>
                                                <div className={"icon-button-container mr-8"} onClick={openFilters}>
                                                    <FilterOutlined />
                                                </div>
                                              </span>
                                          </Tooltip>
                                      </div> : ''}

                                      {!reorderItems && !sharedProductLoader && !ownedProductLoader && !folderState && 
                                    <Tooltip title={<span className="manrope f-12 white">Sort by</span>}>
                                        <Dropdown
                                            overlay={sorting_options()}
                                            trigger={["hover"]}>
                                            <div className="icon-button-container mr-8">
                                                <SwapOutlined className='reorder-icon' style={{ transform: 'rotate(90deg)' }} />
                                            </div>
                                        </Dropdown>
                                    </Tooltip>}

                                      {!reorderItems && (SEQUENCE_ID_OTHER != -1 || SEQUENCE_ID_OWNED != -1)  &&
                                        <div>
                                            <Tooltip title={<span className="manrope f-12 white">{(sortBy != 5) ? 'Select Custom Order to enable reorder' : 'Reorder Items'}</span>}>
                                              <span className={!sharedProductLoader && !ownedProductLoader  ? (sortBy != 5) ? "not-allowed-setting" : "" : "not-allowed-setting" } >
                                                <div className={!sharedProductLoader && !ownedProductLoader  ? (sortBy != 5) ? "pointer-none-setting icon-button-container mr-8" :"icon-button-container mr-8": (sortBy != 5) ? "pointer-none-setting icon-button-container mr-8" : " icon-button-container mr-8"} 
                                                onClick={enterReorderingState}>
                                                    <ReorderIcon className='reorder-icon' />
                                                </div>
                                                </span>
                                            </Tooltip>
                                      </div>}

                                  {!reorderItems &&
                                      <div>
                                          <Tooltip title={<span className="manrope f-12 white">Share Items</span>}>
                                            <span className={!sharedProductLoader && !ownedProductLoader  ? "" : "not-allowed-setting"}>
                                                <div className={!sharedProductLoader && !ownedProductLoader  ?  "icon-button-container mr-8": "pointer-none-setting icon-button-container mr-8"}onClick={onClickShareIcon}>
                                                    <ShareAltOutlined />
                                                </div>
                                              </span>
                                          </Tooltip>
                                      </div>}
                                {
                                    !reorderItems &&
                                    <div>
                                          <Tooltip title={<span className="manrope f-12 white">New Product</span>}>
                                          <Dropdown  
                                          overlay={create_new_product_options()}
                                          trigger={["click"]}>
                                              <Button className='new-product-btn black f-14'>
                                                  <PlusCircleFilled />&nbsp;New Product
                                              </Button>
                                          </Dropdown>
                                          </Tooltip>
                                      </div>
                                }
                              </Col>

                          </>}
                  </Row>


                  <div>
                      <div className="text-center"> {initialLoadOwned || initialLoadShared || filteredViewInitialLoader ? <DottedLoader /> : (
                          <span>
                              {reorderItems ?
                              <Row type="flex" style={{ justifyContent: "flex-start", height: "40px", marginBottom: "20px" }}>
                                  <p className="manrope f-20 ">Reorder Items</p>
                              </Row> :
                               <>{getFilteredTags()}</>}
                              {folderState == false ?
                                  <Col span={24}>
                                      <Tabs onChange={(key) => changeProductTab(key, 'click')} className="product-tab library"
                                          tabBarGutter={16}
                                          activeKey={tabActiveKey}
                                          size="large">
                                          {(IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) ?
                                              <>
                                              <TabPane forceRender={true} tab={MANAGED_CUSTOMER_USERNAME + `'s Models ${ initialLoadMsp || mspDataLoad ? '' : `(${getProductTabCount("customer_owned")}${searchValue != '' || filtersApplied ? ` of ${getProductsLength('customer_owned')} items` : ``})`}`} key={"customer_owned"} style={{ padding: "10px" }}>
                                              {selectable && sharingView &&
                                              <Row className='justify-in-end'>
                                                  <Col span={12} className='justify-in-end'>
                                                    { !allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={selectAllProducts}>Select All</p> :
                                                       allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={deselectAllProducts}>Deselect All</p> : ''}
                                                  </Col>
                                                </Row>}
                                                  <>
                                                      { initialLoadMsp ? <DottedLoader /> :
                                                        <ProductItemLinkList
                                                          setTabChangeThroughClick={setTabChangeThroughClick}
                                                          tabChangeThroughClick={tabChangeThroughClick}
                                                          switchTabIfEmpty={switchTabIfEmpty}
                                                          checkFilteredResults={checkFilteredResults}
                                                          tabActiveKey={tabActiveKey}
                                                          setProductTab={setProductTab}
                                                          productModelType={productModelType}
                                                          selectAssetAndSetBasicInfo={selectAssetAndSetBasicInfo}
                                                          SEQUENCE_ID_OTHER={customerSequences["other"]}
                                                          SEQUENCE_ID_OWNED={customerSequences["owned"]}
                                                          setOrderUpdated={setOrderUpdated}
                                                          product_type={'managed_service'}
                                                          folderState={folderState}
                                                          enterFolderState={enterFolderState}
                                                          products={getProducts("customer_owned")}
                                                          status={5}
                                                          managed_customer={true}
                                                          onAddCollectionClick={onAddCollectionClick}
                                                          sellOnStore={sellOnStore}
                                                          link={`/products/`}
                                                          searchValue={searchValue}
                                                          returnSearchValue={returnSearchValue}
                                                          filtersApplied={filtersApplied}
                                                          selectable={selectable}
                                                          selectedProductIds={selectedProductIds}
                                                          setSelectedProductIds={setSelectedProductIds}
                                                          setCurrentId={setCurrentId}
                                                          setCompanyID={setCompanyID}
                                                          setCurrentCustomer={setCurrentCustomer}
                                                          setDeleteModal={setDeleteModal}
                                                          removeItemFromStore={removeItemFromStore}
                                                          selectedFolderProducts={selectedFolderProducts}
                                                          reorderItems={reorderItems}
                                                          setSelectedFolderProducts={setSelectedFolderProducts}
                                                          saveOrderedItems={saveOrderedItems}
                                                          reorderByCompany={mspCustomerReorderByCompany}
                                                          setSaveOrderedItems={setSaveOrderedItems}
                                                          setOrderSaved={setOrderSaved}
                                                          entityArrangement={entityArrangement}
                                                          folderArrangement={folderArrangement}
                                                          setHashUpdated={setHashUpdated}
                                                          hashUpdated={hashUpdated}
                                                          renameFolderOnclick={renameFolderOnclick}
                                                          unGroupFolderItemsOnclick={unGroupFolderItemsOnclick}
                                                          productTab={productTab}
                                                          companyArrangement={customerCompanyArrangement}
                                                          organizeData={organizeData}
                                                          reorderArrangementData={reorderArrangementData}
                                                          appliedFiltersList={appliedFiltersList}
                                                          filtersAppliedFlag={filtersAppliedFlag}
                                                          updateTabCount={updateTabCount}
                                                          tabKey={"customer_owned"}
                                                          projects={projects}
                                                          />}
                                                          { (mspDataLoad && !initialLoadMsp) &&
                                                            <div style={{position:"fixed", width:"100%", textAlign:"center", padding:"20px", bottom:0}}>
                                                              <span className="manrope f-18 black-55" style={{marginRight: "200px"}}>
                                                                Loading complete data...  <LoadingOutlined/>
                                                              </span>
                                                            </div>
                                                          }
                                                  </>
                                              </TabPane>
                                              <TabPane forceRender={true} tab={MANAGED_CUSTOMER_USERNAME + `'s Other Models ${ initialLoadMsp || mspDataLoad ? '' : `(${getProductTabCount("customer_shared", 'all')}${searchValue != '' || filtersApplied || mspCustomerSharedTabType != 'all' ? ` of ${getProductsLength('customer_shared', 'all')} items` : ``})`}`} key={"customer_shared"} style={{ padding: "10px" }}>
                                              {!reorderItems && <Row className="justify-space-between">
                                                  <Col className="shared-models-tab manrope f-14 black-55 j-center-a-baseline">
                                                      <span className="shared-models-tab-span">
                                                          Show Items:
                                                      </span>
                                                      <Radio.Group onChange={onMSPChangeSharedTabType} value={mspCustomerSharedTabType}>
                                                          <Radio value='all'>
                                                              All
                                                          </Radio>
                                                          <Radio value='store'>
                                                              Added from Store
                                                          </Radio>
                                                          <Radio value='shared'>
                                                              Shared
                                                          </Radio>
                                                          {
                                                            MANAGED_COMPANY_ID == WALMART_COMPANY_ID ?
                                                            <Radio value='props'>
                                                                Your Props
                                                            </Radio>
                                                            : ''
                                                          }
                                                      </Radio.Group>
                                                  </Col>
                                                  <Col span={4} className='justify-in-end'>
                                                    {selectable && sharingView && !allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={selectAllProducts}>Select All</p> : 
                                                    selectable && sharingView && allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={deselectAllProducts}>Deselect All</p> : ''}
                                                  </Col>
                                              </Row>}
                                              <ProductItemLinkList
                                                  setTabChangeThroughClick={setTabChangeThroughClick}
                                                  tabChangeThroughClick={tabChangeThroughClick}
                                                  switchTabIfEmpty={switchTabIfEmpty}
                                                  checkFilteredResults={checkFilteredResults}
                                                  tabActiveKey={tabActiveKey}
                                                  setProductTab={setProductTab}
                                                  productModelType={productModelType}
                                                  selectAssetAndSetBasicInfo={selectAssetAndSetBasicInfo}
                                                  SEQUENCE_ID_OTHER={customerSequences["other"]}
                                                  SEQUENCE_ID_OWNED={customerSequences["owned"]}
                                                  setOrderUpdated={setOrderUpdated}
                                                  product_type={'shared_models'}
                                                  sharedProductLoader={sharedProductLoader}
                                                  folderState={folderState}
                                                  enterFolderState={enterFolderState}
                                                  products={getProducts("customer_shared")}
                                                  status={5}
                                                  onAddCollectionClick={onAddCollectionClick}
                                                  sellOnStore={sellOnStore}
                                                  link={`/products/`}
                                                  searchValue={searchValue}
                                                  returnSearchValue={returnSearchValue}
                                                  filtersApplied={filtersApplied}
                                                  managed_customer={true}
                                                  selectable={selectable}
                                                  selectedProductIds={selectedProductIds}
                                                  setSelectedProductIds={setSelectedProductIds}
                                                  setCurrentId={setCurrentId}
                                                  setCompanyID={setCompanyID}
                                                  setCurrentCustomer={setCurrentCustomer}
                                                  setDeleteModal={setDeleteModal}
                                                  removeItemFromStore={removeItemFromStore}
                                                  selectedFolderProducts={selectedFolderProducts}
                                                  reorderItems={reorderItems}
                                                  setSelectedFolderProducts={setSelectedFolderProducts}
                                                  saveOrderedItems={saveOrderedItems}
                                                  reorderByCompany={mspCustomerReorderByCompany}
                                                  setSaveOrderedItems={setSaveOrderedItems}
                                                  setOrderSaved={setOrderSaved}
                                                  entityArrangement={entityArrangement}
                                                  folderArrangement={folderArrangement}
                                                  setHashUpdated={setHashUpdated}
                                                  hashUpdated={hashUpdated}
                                                  renameFolderOnclick={renameFolderOnclick}
                                                  unGroupFolderItemsOnclick={unGroupFolderItemsOnclick}
                                                  productTab={productTab}
                                                  companyArrangement={customerCompanyArrangement}
                                                  organizeData={organizeData}
                                                  reorderArrangementData={reorderArrangementData} 
                                                  appliedFiltersList={appliedFiltersList}
                                                  filtersAppliedFlag={filtersAppliedFlag}
                                                  updateTabCount={updateTabCount}
                                                  tabKey={"customer_shared"}
                                                  projects={projects}
                                                  />
                                              {sharedProductLoader && searchValue == "" && <div className="justify-in-center" style={{ width: '100%' }}>
                                                  <p className="empty-section-text manrope f-16 black-55">
                                                      Please wait while we load all your products.
                                                      <LoadingOutlined style={{ fontSize: 24, marginLeft: 14 }} />
                                                  </p>
                                              </div>}

                                              {mspCustomerOtherOrgProducts?.length == 0 ?
                                                  <div className="no-models-div">
                                                      <Empty description={"No Models To Show Yet"} className="manrope f-14 no-models-span" />
                                                  </div>
                                                  :
                                                  ''}
                                          </TabPane> </> : ''}
                                          <TabPane forceRender={true} tab={"Your Models " + (!ownedProductLoader ? `(${getProductTabCount("owned")}${searchValue != '' || filtersApplied ? ` of ${getProductsLength('owned')} items` : ``})` : `(${tempOwnedCount})`)} key={"owned"} style={{ padding: "10px" }}>
                                          {selectable && sharingView && 
                                          <Row className='justify-in-end'>
                                            <Col span={12} className='justify-in-end'>
                                            {!allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={selectAllProducts}>Select All</p> : 
                                              allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={deselectAllProducts}>Deselect All</p> : ''}
                                            </Col>
                                          </Row>}
                                              {getProductsLength("owned") != 0 ? (
                                                  <>
                                                      <ProductItemLinkList
                                                          setTabChangeThroughClick={setTabChangeThroughClick}
                                                          tabChangeThroughClick={tabChangeThroughClick}
                                                          switchTabIfEmpty={switchTabIfEmpty}
                                                          checkFilteredResults={checkFilteredResults}
                                                          tabActiveKey={tabActiveKey}
                                                          setProductTab={setProductTab}
                                                          productModelType={productModelType}
                                                          selectAssetAndSetBasicInfo={selectAssetAndSetBasicInfo}
                                                          SEQUENCE_ID_OTHER={SEQUENCE_ID_OTHER}
                                                          SEQUENCE_ID_OWNED={SEQUENCE_ID_OWNED}
                                                          setOrderUpdated={setOrderUpdated}
                                                          product_type={'your_models'}
                                                          folderState={folderState}
                                                          ownedProductLoader={ownedProductLoader}
                                                          enterFolderState={enterFolderState}
                                                          products={getProducts("owned")}
                                                          status={5}
                                                          onAddCollectionClick={onAddCollectionClick}
                                                          sellOnStore={sellOnStore}
                                                          link={`/products/`}
                                                          managed_customer={false}
                                                          searchValue={searchValue}
                                                          returnSearchValue={returnSearchValue}
                                                          filtersApplied={filtersApplied}
                                                          selectable={selectable}
                                                          selectedProductIds={selectedProductIds}
                                                          setSelectedProductIds={setSelectedProductIds}
                                                          setCurrentId={setCurrentId}
                                                          setCompanyID={setCompanyID}
                                                          setCurrentCustomer={setCurrentCustomer}
                                                          setDeleteModal={setDeleteModal}
                                                          removeItemFromStore={removeItemFromStore}
                                                          reorderItems={reorderItems}
                                                          selectedFolderProducts={selectedFolderProducts}
                                                          setSelectedFolderProducts={setSelectedFolderProducts}
                                                          saveOrderedItems={saveOrderedItems}
                                                          reorderByCompany={reorderByCompany}
                                                          setSaveOrderedItems={setSaveOrderedItems}
                                                          setOrderSaved={setOrderSaved}
                                                          entityArrangement={entityArrangement}
                                                          folderArrangement={folderArrangement}
                                                          setHashUpdated={setHashUpdated}
                                                          hashUpdated={hashUpdated}
                                                          renameFolderOnclick={renameFolderOnclick}
                                                          unGroupFolderItemsOnclick={unGroupFolderItemsOnclick}
                                                          productTab={productTab}
                                                          companyArrangement={companyArrangement}
                                                          organizeData={organizeData}
                                                          reorderArrangementData={reorderArrangementData} 
                                                          appliedFiltersList={appliedFiltersList}
                                                          filtersAppliedFlag={filtersAppliedFlag}
                                                          updateTabCount={updateTabCount}
                                                          tabKey={"owned"}
                                                          projects={projects}
                                                          />
                                                      {ownedProductLoader && searchValue == "" && <div className="justify-in-center" style={{ width: '100%', position: 'absolute' }}>
                                                          <p className="empty-section-text manrope f-16 black-55">
                                                              Please wait while we load all your products.
                                                              <LoadingOutlined style={{ fontSize: 24, marginLeft: 14 }} />
                                                          </p>
                                                      </div>}
                                                  </>)
                                                  :
                                                  <div className="empty-section" style={{ marginTop: "10%", position: "relative", textAlign: '-webkit-center' }}>
                                                      <img id="empty-section-image" style={{ width: "10%" }} src="../../../../img/Products.svg" />
                                                      <p className="empty-section-text manrope f-14 black-55">
                                                          Nothing to show
                                                      </p>
                                                      <p style={{ marginTop: "-1%" }} className="empty-section-text manrope f-12 grey-6b">
                                                          There are no products of your own. To get started create a new product.
                                                      </p>
                                                      <Button onClick={() => props.history.push('/product-model')} className="outline-red-btn square font-14  pd-10-18">Create New <PlusOutlined /></Button>
                                                  </div>}
                                          </TabPane>
                                          <TabPane forceRender={true} tab={"Other Models " + (!sharedProductLoader ? `(${getProductTabCount("shared")}${searchValue != '' || filtersApplied || sharedTabType != 'all' ? ` of ${getProductsLength('shared', 'all')} items` : ``})` : '')} key={"shared"} style={{ padding: "10px" }}>
                                              {!reorderItems && <Row className="justify-space-between">
                                                  <Col className="shared-models-tab manrope f-14 black-55 j-center-a-baseline">
                                                      <span className="shared-models-tab-span">
                                                          Show Items:
                                                      </span>
                                                      <Radio.Group onChange={onChangeSharedTabType} value={sharedTabType}>
                                                          <Radio value='all'>
                                                              All
                                                          </Radio>
                                                          <Radio value='store'>
                                                              Added from Store
                                                          </Radio>
                                                          <Radio value='shared'>
                                                              Shared
                                                          </Radio>
                                                          {
                                                            COMPANY_ID == WALMART_COMPANY_ID ?
                                                            <Radio value='props'>
                                                                Your Props
                                                            </Radio>
                                                            : ''
                                                          }
                                                      </Radio.Group>
                                                  </Col>
                                                  <Col span={4} className='justify-in-end'>
                                                  {selectable && sharingView && !allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={selectAllProducts}>Select All</p> : 
                                                  selectable && sharingView && allSelected ? <p className='manrope f-14 blue cursor-pointer' onClick={deselectAllProducts}>Deselect All</p> : ''}
                                                </Col>
                                              </Row>}
                                              <ProductItemLinkList
                                                  setTabChangeThroughClick={setTabChangeThroughClick}
                                                  tabChangeThroughClick={tabChangeThroughClick}
                                                  switchTabIfEmpty={switchTabIfEmpty}
                                                  checkFilteredResults={checkFilteredResults}
                                                  tabActiveKey={tabActiveKey}
                                                  setProductTab={setProductTab}
                                                  productModelType={productModelType}
                                                  selectAssetAndSetBasicInfo={selectAssetAndSetBasicInfo}
                                                  SEQUENCE_ID_OTHER={SEQUENCE_ID_OTHER}
                                                  SEQUENCE_ID_OWNED={SEQUENCE_ID_OWNED}
                                                  setOrderUpdated={setOrderUpdated}
                                                  product_type={'shared_models'}
                                                  sharedProductLoader={sharedProductLoader}
                                                  folderState={folderState}
                                                  enterFolderState={enterFolderState}
                                                  products={getProducts("shared")}
                                                  status={5}
                                                  onAddCollectionClick={onAddCollectionClick}
                                                  sellOnStore={sellOnStore}
                                                  link={`/products/`}
                                                  searchValue={searchValue}
                                                  returnSearchValue={returnSearchValue}
                                                  filtersApplied={filtersApplied}
                                                  managed_customer={false}
                                                  selectable={selectable}
                                                  selectedProductIds={selectedProductIds}
                                                  setSelectedProductIds={setSelectedProductIds}
                                                  setCurrentId={setCurrentId}
                                                  setCompanyID={setCompanyID}
                                                  setCurrentCustomer={setCurrentCustomer}
                                                  setDeleteModal={setDeleteModal}
                                                  removeItemFromStore={removeItemFromStore}
                                                  selectedFolderProducts={selectedFolderProducts}
                                                  reorderItems={reorderItems}
                                                  setSelectedFolderProducts={setSelectedFolderProducts}
                                                  saveOrderedItems={saveOrderedItems}
                                                  reorderByCompany={reorderByCompany}
                                                  setSaveOrderedItems={setSaveOrderedItems}
                                                  setOrderSaved={setOrderSaved}
                                                  entityArrangement={entityArrangement}
                                                  folderArrangement={folderArrangement}
                                                  setHashUpdated={setHashUpdated}
                                                  hashUpdated={hashUpdated}
                                                  renameFolderOnclick={renameFolderOnclick}
                                                  unGroupFolderItemsOnclick={unGroupFolderItemsOnclick}
                                                  productTab={productTab}
                                                  companyArrangement={companyArrangement}
                                                  organizeData={organizeData}
                                                  reorderArrangementData={reorderArrangementData}                                                    
                                                  appliedFiltersList={appliedFiltersList}
                                                  filtersAppliedFlag={filtersAppliedFlag}
                                                  updateTabCount={updateTabCount}
                                                  tabKey={"shared"}
                                                  projects={projects}
                                                  />
                                              {sharedProductLoader && searchValue == "" && <div className="justify-in-center" style={{ width: '100%' }}>
                                                  <p className="empty-section-text manrope f-16 black-55">
                                                      Please wait while we load all your products.
                                                      <LoadingOutlined style={{ fontSize: 24, marginLeft: 14 }} />
                                                  </p>
                                              </div>}

                                              {sharedOriginalData?.length == 0 ?
                                                  <div className="no-models-div">
                                                      <Empty description={"No Models To Show Yet"} className="manrope f-14 no-models-span" />
                                                  </div>
                                                  :
                                                  ''}
                                          </TabPane>
                                      </Tabs>
                                  </Col> :
                                  <Col span={24}>
                                      <div className="product-tab library">
                                          <Row className="product-tab-content-scrollable" type="flex" style={{ marginTop: "10px", justifyContent: "start" }}>
                                              <ProductItemLinkList
                                                  setTabChangeThroughClick={setTabChangeThroughClick}
                                                  tabChangeThroughClick={tabChangeThroughClick}
                                                  switchTabIfEmpty={switchTabIfEmpty}
                                                  checkFilteredResults={checkFilteredResults}
                                                  tabActiveKey={tabActiveKey}
                                                  setProductTab={setProductTab}
                                                  productModelType={productModelType}
                                                  selectAssetAndSetBasicInfo={selectAssetAndSetBasicInfo}
                                                  SEQUENCE_ID_OTHER={SEQUENCE_ID_OTHER}
                                                  SEQUENCE_ID_OWNED={SEQUENCE_ID_OWNED}
                                                  setOrderUpdated={setOrderUpdated}
                                                  product_type={'folder'}
                                                  folderState={folderState}
                                                  enterFolderState={enterFolderState}
                                                  products={folderProducts}
                                                  status={5}
                                                  onAddCollectionClick={onAddCollectionClick}
                                                  sellOnStore={sellOnStore}
                                                  link={`/products/`}
                                                  managed_customer={false}
                                                  searchValue={searchValue}
                                                  returnSearchValue={returnSearchValue}
                                                  filtersApplied={filtersApplied}
                                                  selectable={selectable}
                                                  selectedProductIds={selectedProductIds}
                                                  setSelectedProductIds={setSelectedProductIds}
                                                  setCurrentId={setCurrentId}
                                                  setCompanyID={setCompanyID}
                                                  setCurrentCustomer={setCurrentCustomer}
                                                  setDeleteModal={setDeleteModal}
                                                  removeItemFromStore={removeItemFromStore}
                                                  reorderItems={reorderItems}
                                                  selectedFolderProducts={selectedFolderProducts}
                                                  setSelectedFolderProducts={setSelectedFolderProducts}
                                                  saveOrderedItems={saveOrderedItems}
                                                  reorderByCompany={reorderByCompany}
                                                  setSaveOrderedItems={setSaveOrderedItems}
                                                  setOrderSaved={setOrderSaved}
                                                  entityArrangement={entityArrangement}
                                                  folderArrangement={folderArrangement}
                                                  setHashUpdated={setHashUpdated}
                                                  hashUpdated={hashUpdated}
                                                  renameFolderOnclick={renameFolderOnclick}
                                                  unGroupFolderItemsOnclick={unGroupFolderItemsOnclick}
                                                  productTab={productTab}
                                                  companyArrangement={companyArrangement}
                                                  organizeData={organizeData}
                                                  reorderArrangementData={reorderArrangementData}                                                    
                                                  appliedFiltersList={appliedFiltersList}
                                                  filtersAppliedFlag={filtersAppliedFlag}
                                                  projects={projects}
                                                  />
                                          </Row>
                                      </div>
                                  </Col>}
                              {showBottomNavBar && <div className='fixed-row-2'>
                                <Row className='row-margins-2'>

                                  <Col span={18} className="justify-in-end">
                                    <Button onClick={moveOutOfReorderState} className="modal-okay-gray square font-14 mb-20">
                                      Cancel
                                    </Button>
                                    {reorderItems ?
                                      <>
                                        {folderState ? '' :
                                          <Button disabled={selectedFolderProducts.length == 0} style={{ marginLeft: '5px', marginBottom: 20 }} onClick={createNewFolder} className="modal-okay-invert square-disabled font-14">
                                            Create New Folder
                                          </Button>}

                                        <Button disabled={selectedFolderProducts.length == 0 || folderArrangement.length == 0
                                          || folderState && foldersListExceptCurrentFolder.length == 0} style={{ marginLeft: '5px', marginBottom: 20 }} onClick={moveToFolderOnClick} className="modal-okay-invert square-disabled font-14">
                                          Move To Folder
                                        </Button>
                                        {searchValue ? "" :
                                          <Button disabled={!orderUpdated} style={{ marginLeft: '5px', marginBottom: 20 }} onClick={() => setReorderByModal(true)} className="modal-okay square font-14">
                                            Reorder
                                          </Button>}
                                      </> :
                                      <Tooltip title={selectable && selectedProductIds.length == 0 ? <span className="manrope f-12 white">Share selected products</span> : ""}>
                                        <span>
                                          <Button style={{ marginLeft: '5px', marginBottom: 20 }}
                                          disabled={selectedProductIds.length == 0}
                                          onClick={() => setSharingModal(true)} className="modal-okay square font-14">
                                            Share {(selectable) ? `(${selectedProductIds.length}) Product Models` : folderState ? `Folder Models` : ``}
                                          </Button>
                                        </span>
                                      </Tooltip>}
                                  </Col>
                                </Row>
                              </div>}
                          </span>)}
                      </div>
                  </div>

                  <AddCollection
                      productId={selectedProductIdForCollection}
                      visible={collectionModalVisible}
                      addedToLibrary={true}
                      setCollectionModalVisible={setCollectionModalVisible}>
                  </AddCollection>
              </CustomerMainLayout>

              <Modal
                  className="modal-share-success"
                  width={600}
                  centered={true}
                  visible={viewUnGroupFolderModal}
                  closable={false}
                  footer={[
                      <span className="justify-in-center">
                          <Button style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={() => setViewUnGroupFolderModal(false)}>
                              Cancel
                          </Button>
                          <Button disabled={actionLoader} style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => unGroupFolderItemsConfirm()}>
                              Confirm {actionLoader && <LoadingOutlined />}
                          </Button>
                      </span>
                  ]}>
                  <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                      <h2 className="manrope f-16 black-00 w-600">Are you sure you want to ungroup items in this folder?</h2>
                      <p className="manrope f-14 black-55">This will put all items that are in your folder in the library and the folder will be deleted.</p>
                  </div>
              </Modal>

              <Modal
                  visible={viewRenameFolderModal}
                  onCancel={() => setViewRenameFolderModal(false)}
                  className="modal-share"
                  footer={[
                      <div className="justify-in-end">
                          <Button className="modal-okay-gray square font-12" onClick={() => setViewRenameFolderModal(false)}>
                              Cancel
                          </Button>
                          <Button disabled={actionLoader} className="modal-okay square font-12" style={{ marginRight: 8 }} onClick={renameFolderConfirm}>
                              Confirm {actionLoader && <LoadingOutlined />}
                          </Button>
                      </div>
                  ]}>
                  <div style={{ padding: "16px 16px 0 16px" }}>
                      <h2 className="manrope f-18 black-00 w-600">Rename Folder</h2>
                      <p className="manrope f-14 grey-6b" style={{ marginBottom: 5, marginTop: 25 }}>Enter New Folder Name </p>
                      <Input value={folderNewName} onChange={e => { onChangeNewFolderName(e.target.value); } } className="manrope f-12 grey-6b folder-name-input" type="text" placeholder="Type here" />
                      {renameError ?
                          <div className="manrope w-500 f-12" style={{ marginTop: 20 }}>
                              <p style={{ color: "#E54547", marginBottom: 0 }}>{renameError}</p>
                          </div> : ""}
                  </div>
              </Modal>

              <Modal
                  visible={newFolder}
                  onCancel={() => setNewFolder(false)}
                  className="modal-share"
                  footer={[
                      <div className="justify-in-end">
                          <Button className="modal-okay-gray square font-12" onClick={() => setNewFolder(false)}>
                              Cancel
                          </Button>
                          <Button disabled={actionLoader} className="modal-okay square font-12" style={{ marginRight: 8 }} onClick={createNewFolderConfirm}>
                              Confirm {actionLoader && <LoadingOutlined />}
                          </Button>
                      </div>
                  ]}>
                  <div style={{ padding: "16px 16px 0 16px" }}>
                      <h2 className="manrope f-18 black-00 w-600">Create New Folder</h2>
                      <p className="manrope f-14 grey-6b" style={{ marginBottom: 5, marginTop: 25 }}>Folder name </p>
                      <Input onChange={e => { onChangeFolderName(e.target.value); } } className="manrope f-12 grey-6b folder-name-input" type="text" placeholder="Type here" />
                      {errorMessage ?
                          <div className="manrope w-500 f-12" style={{ marginTop: 20 }}>
                              <p style={{ color: "#E54547", marginBottom: 0 }}>{errorMessage}</p>
                          </div> : ""}
                  </div>
              </Modal>

              <Modal
                  visible={existingFolder}
                  onCancel={() => setExistingFolder(false)}
                  className="modal-share"
                  footer={[
                      <div className="justify-in-end">
                          <Button className="modal-okay-gray square font-12" onClick={() => setExistingFolder(false)}>
                              Cancel
                          </Button>
                          <Button disabled={actionLoader} className="modal-okay square font-12" style={{ marginRight: 8 }} onClick={moveToFolderConfirm}>
                              Confirm {actionLoader && <LoadingOutlined />}
                          </Button>
                      </div>
                  ]}>
                  <div style={{ padding: "16px 16px 0 16px" }}>
                      <h2 className="manrope f-18 black-00 w-600">Move Products(s) To Folder</h2>
                      <Form className="input-form-2" onSubmit={() => { } }>
                          <Form.Item name="collection_id" colon={false}>

                              <Select
                                  showSearch
                                  onChange={(value) => {
                                      setSelectedFolder(value);
                                  } }
                                  placeholder="Select Folder">
                                  {folderState ? foldersListExceptCurrentFolder.map((folderInfo, index) => (
                                      <Option key={index} value={folderInfo.folder_id}>
                                          {folderInfo.folder_name}
                                      </Option>)) : folderInfo.map((folderInfo, index) => (
                                          <Option key={index} value={folderInfo.folder_id}>
                                              {folderInfo.folder_name}
                                          </Option>
                                      ))}

                              </Select>

                          </Form.Item>

                      </Form>
                  </div>
              </Modal>

              
              <SuccessModal
                  visible={successModal}
                  heading={"Product(s) shared successfully!"}
                  onCancel={() => setSuccessModal(false)}
                  footer={[
                      <div className="justify-in-center">
                          <Button style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => setSuccessModal(false)}>
                              Okay
                          </Button>
                      </div>
                  ]} />
              <Modal
                  className="modal-share-success"
                  width={600}
                  centered={true}
                  visible={deleteModal}
                  closable={false}
                  footer={[
                      <span className="justify-in-center">
                          <Button style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={() => setDeleteModal(false)}>
                              Cancel
                          </Button>
                          <Button style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => onDelete()}>
                              {deleteLoader ? <span> Deleting <LoadingOutlined spin /></span> : <span>Yes, Delete Product </span>}
                          </Button>
                      </span>
                  ]}>
                  <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                      <h2 className="manrope f-16 black-00 w-600">You are about to delete this product</h2>
                      {companyID == COMPANY_ID ?
                          // another user from the company is deleting a product so display specific warning message 
                          <p className="manrope f-14 black-55">This product and its assets will be deleted for all the users in your company. Are you sure you want to proceed?</p>
                          :
                          <p className="manrope f-14 black-55">Deleting this product would delete all assets generated with it. Are you sure you want to delete this product? </p>}
                  </div>
              </Modal>

              <Modal
                  className="modal-share-success"
                  width={600}
                  centered={true}
                  onCancel={() => setRemoveFromStore(false)}
                  visible={removeFromStore}
                  closable={false}
                  footer={[
                      <span className="justify-in-center">
                          <Button style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={() => setRemoveFromStore(false)}>
                              Cancel
                          </Button>
                          <Button style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={setStoreRequests}>
                              {removeLoader ? <span> Removing <LoadingOutlined spin /></span> : <span> Okay </span>}
                          </Button>
                      </span>
                  ]}>
                  <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                      <h2 className="manrope f-16 black-00 w-600">You're about to remove this product from store. Are you sure you want to remove?</h2>
                  </div>
              </Modal>

              <BatchSharingModal
                entityType='product'
                modalVisible={sharingModal}
                setSharingModal={setSharingModal}
                getEntityIds={getSelectedProductIds}
              />

              <AddItemToStore
                  type={"customer_details"}
                  entity_type={"product"}
                  addToStore={setStoreRequests}
                  visible={sellVisible}
                  add_store_item_loader={sellLoader}
                  onCancel={cancelSellModal}
                  selected_item={showProductDetails}
                  onChangePrice={onChangePrice}
                  price={price} />

              <Modal
                  visible={reorderByModal}
                  onCancel={closeReorderByModal}
                  className="modal-share"
                  footer={[
                      <div className="justify-in-end">
                          <Button
                              className="modal-okay-gray square font-12"
                              htmlType="submit"
                              onClick={closeReorderByModal}>
                              Cancel
                          </Button>
                          <Button disabled={saveOrderedItems || rearrangementLoader} className="modal-okay square font-12" style={{ marginRight: 8 }} onClick={saveArrangements}>
                              Reorder {rearrangementLoader && <LoadingOutlined />}
                          </Button>
                      </div>
                  ]}
              >
                  {saveByCompany && productTab == "owned" ? (
                      <ReorderYourModelsData
                      folderState={folderState}
                      productTab={productTab}
                      reorderByCompany={reorderByCompany}
                      changeOrderBySelection={changeOrderBySelection}/>
                      ) : 
                    mspCustomerSaveByCompany && productTab == "customer_owned" ? (
                      <ReorderYourModelsData
                      folderState={folderState}
                      productTab={productTab}
                      reorderByCompany={mspCustomerReorderByCompany}
                      changeOrderBySelection={changeMSPCustomerOrderBySelection}/>
                    ) :
                    <ReorderOtherModelsData 
                      folderState={folderState}
                      productTab={productTab}/>
                    }
              </Modal>
              <Drawer
                  width={360}
                  visible={filtersDrawer}
                  onClose={closeFiltersDrawer}
                  placement="right">
                  <div style={{ padding: 20 }}>
                      <h2 className="manrope f-18 black-14 w-700">Filters</h2>
                      <div>
                          <h5 className='manrope f-16 black-14' style={{ marginTop: 20 }}>
                              Product Category
                          </h5>
                          <TreeSelect
                              value={productCategoryValue}
                              showSearch={true}
                              className="tree-select-material f-14 filter bg-light"
                              style={{ width: '100%' }}
                              multiple
                              dropdownStyle={categoryStyle}
                              placeholder="Search and select category"
                              treeData={productCategoriesData}
                              onChange={changeProductCategory}
                              onSearch={changeProductSearchCategory} />

                          <h5 className='manrope f-16 black-14' style={{ marginTop: 20 }}>Style Category</h5>
                          <StyleCategory
                              className="tree-select-material f-14 filter bg-light"
                              onChange={changeProductStyleCategory}
                              value={productStyleCategory}
                              placeholder="Search and select style category"
                              mode="multiple" />

                          <h5 className='manrope f-16 black-14' style={{ marginTop: 20 }}>Product Color</h5>
                          <ColorSelect
                              className="tree-select-material f-14 filter bg-light"
                              value={colorValue}
                              onChange={changeColorValue}
                              placeholder="Search and select color"
                              mode="multiple" />

                          <h5 className='manrope f-16 black-14' style={{ marginTop: 20 }}>Product Material</h5>
                          <TreeSelect
                              value={productMaterials}
                              showSearch={true}
                              className="tree-select-material f-14 filter bg-light"
                              style={{ width: '100%' }}
                              multiple
                              dropdownStyle={categoryStyle}
                              placeholder="Search and select material"
                              treeData={productMaterialsData}
                              onChange={changeProductMaterial}
                              onSearch={changeProductSearchCategory}
                          >
                          </TreeSelect>
                          <h5 className='manrope f-16 black-14' style={{ marginTop: 20 }}>Product Type</h5>
                          <Select
                              className="tree-select-material f-14 filter bg-light"
                              value={productModelType}
                              onChange={changeProductModelType}
                              showSearch
                              placeholder="Search and select product type"
                              mode="multiple"
                          >
                              {Object.keys(FileConstants.PRODUCT_MODEL_TYPE).map((key) => (
                                  <Select.Option
                                      key={key}
                                      className="manrope f-10"
                                      value={key}
                                  >
                                      {FileConstants.PRODUCT_MODEL_TYPE[key]}
                                  </Select.Option>
                              ))}
                          </Select>
                      </div>
                  </div>
              </Drawer>
          </div>
          </>
  );
}


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  listProducts: () => {
    dispatch(listProducts());
  },
  getScannedProducts: () => {
    dispatch(getScannedProducts())
  },
  getArrangements: (payload) => {
    dispatch(getArrangements(payload))
  },
  reorderData: (payload) => {
    dispatch(reorderData(payload))
  },
  createFolder: (payload) => {
    dispatch(createFolder(payload))
  },
  moveToFolder: (payload) => {
    dispatch(moveToFolder(payload))
  },
  renameFolder: (payload) => {
    dispatch(renameFolder(payload))
  },
  getProduct: (payload) => {
    dispatch(getProductRender(payload));
  }

});


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerProducts)
)
