import React, { useContext, useEffect, useState } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Row, Col, Button, Card, Dropdown, Menu, Carousel, Modal, message, Tooltip } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, DownOutlined, LoadingOutlined, HeartFilled, HeartOutlined, EditOutlined } from '@ant-design/icons';
import FileConstants from '../../../../FileConstants';
import ImageMagnify from '../ImageMagnify/ImageMagnify';
import * as Utilities from '../../Utilities.jsx';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import DeleteImageModal from './DeleteImageModal';
import './ProductComponents.scss';
import ZoomImage from './imageZoomable.jsx';

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem("username");

const customSortOrder = {
    "silo": 0,
    "dim": 1,
    "low_res_preview": 2,
    "lifestyle": 3
};


const ImageListType = (props) => {

    const openDeleteLifestyleModal = (image_data) => {
        props.setDeleteModal(true);
        props.setDeleteImageType(image_data.type)
        props.setSelectedDeleteLifestyle(image_data.filename)
        props.setSelectedLifestyleSceneId(image_data.scene_id)
    }

    const openDeleteSiloModal = (image_data) => {
        props.setDeleteImageType(image_data.type)
        props.setDeleteModal(true);
        props.setSelectedDeleteSilo(image_data.camera_name)
        props.setSelectedDeleteSiloURL(image_data.url)
    }

    const handleDeleteModal = (image) => {
        props.selectImage(image.url)
        if (props.type == 'lifestyle') {
            openDeleteLifestyleModal(image)
        }
        else if (props.type == 'silo') {
            openDeleteSiloModal(image)
        }
    }

    return (props.allProductImages.filter(image => image.main_type == props.type).length > 0 ?
        <>
            <div className='manrope f-14 black-26 w-100'>{props.name}</div>
            {props.allProductImages.filter(image => image.main_type == props.type).map((image, index) => (
                <Card className={`product-img-grid-card display-flex ${props.selectedProductImage == image.url ? `selected` : ``}`}
                    key={index} onClick={() => {
                            props.selectImage(image.url); 
                            if(props.type == 'lifestyle'){
                                props.setSelectedLifestyleSceneId(image.scene_id)
                                props.setSelectedFilenameWithExtension(image.filename)
                            }
                            if(props.type == 'silo'){
                                props.setSelectedFilenameWithExtension(image.filename)
                            }
                        }}>
                    <img className='product-img-grid-img' src={image.low_url} />
                    {
                        (props.type == "lifestyle" || (props.type == 'silo' && image.status != 'pending')) ?
                            <img className='product-silo-lifestyle-delete-icon' src="/img/delete2.png" alt="Delte Icon" onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteModal(image);
                            }}
                            />
                            : ''
                    }
                </Card>
            ))}
        </> : "")
}


const ProductImageCarousel = () => {
    let carousel = React.createRef();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [deleteImageType, setDeleteImageType] = useState('')
    const [selectedDeleteLifestyle, setSelectedDeleteLifestyle] = useState('')
    const [selectedDeleteSilo, setSelectedDeleteSilo] = useState('')
    const [selectedDeleteSiloURL, setSelectedDeleteSiloURL] = useState('')
    const [selectedLifestyleSceneId, setSelectedLifestyleSceneId] = useState(0)
    const [collaborateConfirmButtonLoader, setCollaborateConfirmButtonLoader] = useState(false)
    const [collaborationConfirmModal, setCollaborationConfirmModal] = useState(false)
    const [selectedFilenameWithExtension, setSelectedFilenameWithExtension] = useState(null)
    const [filenameBeingEdited, setFilenameBeingEdited] = useState(false)
    const [editRenderNameLoader, setEditRenderNameLoader] = useState(false)
    const [editedRenderUrl, setEditedRenderUrl] = useState(null)
    const [previousRenderName, setPreviousRenderName] = useState(null)
    const [tooltipVisible, setTooltipVisible] = useState(false)

    const { allProductImages, productImageCount, downloadImagesArchive, setSelectedImgOriginalFormat,
        imgAccessedFromCarousel, setImgAccessedFromCarousel, selectedImgOriginalFormat, selectedImgFilename,
        setSelectedImgFilename, selectedProductImage, setSelectedProductImage, downloadSiloImages, isStorePage, product_id,
        companyId, setAllProductImages, setProductImageCount, secondaryShots, setSecondaryShots, productAssetData,
        setProductAssetData, allSystemGeneratedProductImages, selectedImageResolution, setSelectedImageResolution, 
        setSelectedImageFormat, selectedImageFormat, setSelectedImageName, selectedImageName, selectedImageCreationDate, 
        setSelectedImageCreationDate, baseVariantPlatform, selectedImageType, setSelectedImageType, selectedImageCapturedBy, setSelectedImageCapturedBy } = useContext(CustomerProductContext);

    allProductImages.sort((a, b) => customSortOrder[a.main_type] - customSortOrder[b.main_type]);
    useEffect(() => {
        if (allProductImages?.length > 0) {
            setInitialImageDetails(allProductImages[0]);
        }
    }, []);

    const updateProductImages = (images) => {
        setAllProductImages(images)
    }

    const handleFavorite = () => {
        let images = allProductImages.filter(img => img.url === selectedProductImage);
        let image = null;
        if(images.length == 1){
            image = images[0]
        }
        if(image['type'] == 'lifestyle'){
            // pass lifestyleRenders list and setLifestyleRenders function so that the function can update the state
            Utilities.updateLifestyleFavoriteStatus(image.scene_id, image.filename, allProductImages, updateProductImages, CUSTOMER_USERNAME)
          }
          else if(image['type'] == 'silo'){
            image['product_id'] = product_id
            // pass silos list and setSilos function so that the function can update the state
            Utilities.updateSiloFavoriteStatus(image.product_id, image.camera_name + '.' + image.format, allProductImages, updateProductImages, CUSTOMER_USERNAME)
          }
    }

    const setInitialImageDetails = (image) => {
        setSelectedProductImage(image.url);
        setSelectedImgOriginalFormat(image.format);
        setSelectedImgFilename(image.filename);
        setSelectedImageResolution(image.resolution);
        setSelectedImageFormat(image.format);
        setSelectedImageName(image.display_name);
        setSelectedImageCapturedBy(image.captured_by)
        setSelectedImageCreationDate(image.created_at);
        setSelectedImageType(image.main_type)
        setSelectedFilenameWithExtension(image.filename)
    };

    useEffect(() => {
        if (imgAccessedFromCarousel == false) {
            let image_index = allProductImages.findIndex(img => img.url == selectedProductImage);
            carousel.goTo(image_index, true);
        }
    }, [selectedProductImage, imgAccessedFromCarousel]);

    const selectImage = (url) => {
        resetRenderNameField()
        setSelectedProductImage(url);
        let image_index = allProductImages.findIndex(img => img.url == url);
        console.log("Checking img", allProductImages[image_index])  
        setSelectedImgOriginalFormat(allProductImages[image_index].format);
        setSelectedImgFilename(allProductImages[image_index].display_name ? allProductImages[image_index].display_name : allProductImages[image_index].filename);
        setSelectedImageResolution(allProductImages[image_index].resolution);
        setSelectedImageFormat(allProductImages[image_index].format);
        setSelectedImageName(allProductImages[image_index].display_name);
        setSelectedImageCapturedBy(allProductImages[image_index].captured_by);
        setSelectedImageType(allProductImages[image_index].main_type);
        setSelectedImageCreationDate(allProductImages[image_index].created_at);

        carousel.goTo(image_index, true);
        setImgAccessedFromCarousel(true);
    }

    const onChangeCarousel = (before, to) => {
        if (before == (productImageCount - 1) && to == 0) {
            carousel.goTo(0, true);
            setImgAccessedFromCarousel(true);
        } else if (before == 0 && to == (productImageCount - 1)) {
            carousel.goTo(productImageCount - 1, true);
            setImgAccessedFromCarousel(true);
        }
    }

    const next = () => {
        let image_index = allProductImages.findIndex(img => img.url == selectedProductImage);

        if ((image_index + 1) == productImageCount) {
            setSelectedProductImage(allProductImages[0].url)
            setSelectedImageResolution(allProductImages[0].resolution);
            setSelectedImageFormat(allProductImages[0].format);
            setSelectedImageName(allProductImages[0].display_name);
            setSelectedImageCapturedBy(allProductImages[0].captured_by);
            setSelectedImageType(allProductImages[0].main_type);
            setSelectedImageCreationDate(allProductImages[0].created_at);

            carousel.goTo(0, true);
            setImgAccessedFromCarousel(true);
        } else {
            setSelectedProductImage(allProductImages[image_index + 1].url);
            setSelectedImageResolution(allProductImages[image_index + 1].resolution);
            setSelectedImageFormat(allProductImages[image_index + 1].format);
            setSelectedImageName(allProductImages[image_index + 1].display_name);
            setSelectedImageCapturedBy(allProductImages[image_index + 1].captured_by);
            setSelectedImageType(allProductImages[image_index + 1].main_type);
            setSelectedImageCreationDate(allProductImages[image_index + 1].created_at);

            carousel.goTo(image_index + 1, true);
            setImgAccessedFromCarousel(true);
        }
        resetRenderNameField()
    }

    const prev = () => {
        let image_index = allProductImages.findIndex(img => img.url === selectedProductImage);

        //if user moves back from step from carousel then close the carousel
        if ((image_index - 1) < 0) {
            carousel.goTo(productImageCount - 1, true);
            setSelectedProductImage(allProductImages[productImageCount - 1].url);
            setImgAccessedFromCarousel(true);
            setSelectedImageResolution(allProductImages[productImageCount - 1].resolution);
            setSelectedImageFormat(allProductImages[productImageCount - 1].format);
            setSelectedImageName(allProductImages[productImageCount - 1].display_name);
            setSelectedImageCapturedBy(allProductImages[productImageCount -1].captured_by);
            setSelectedImageType(allProductImages[productImageCount - 1].main_type);
            setSelectedImageCreationDate(allProductImages[productImageCount - 1].created_at);

        } else {
            setSelectedProductImage(allProductImages[image_index - 1].url);
            setSelectedImageResolution(allProductImages[image_index - 1].resolution);
            setSelectedImageFormat(allProductImages[image_index - 1].format);
            setSelectedImageName(allProductImages[image_index - 1].display_name);
            setSelectedImageCapturedBy(allProductImages[image_index - 1].captured_by);
            setSelectedImageType(allProductImages[image_index - 1].main_type);
            setSelectedImageCreationDate(allProductImages[image_index - 1].created_at);
            carousel.goTo(image_index - 1, true);
            setImgAccessedFromCarousel(true);
        }
        resetRenderNameField()
    }

    const getNextImageURL = (allProductImages) => {
        let image_index = allProductImages.findIndex(img => img.url == selectedProductImage);
        if ((image_index + 1) == productImageCount) {
            return allProductImages[productImageCount - 1].url;
        } else {
            return allProductImages[image_index + 1].url;
        }
    }

    const getImageNumber = () => {
        let image_index = allProductImages.findIndex(img => img.url === selectedProductImage);
        return image_index + 1;
    }

    const getStatus = () => {
        let image = allProductImages.filter(img => img.url === selectedProductImage);
        let status = "";
        if (image.length > 0) {
            status = image[0].status;
        }

        return status;
    }

    const createCollaborateEntity = () => {
        
        let payload = {
            'camera_name': selectedFilenameWithExtension,
            'display_name': selectedImageName,
            'created_by': localStorage.getItem('username'),
            'last_modified_by': localStorage.getItem('username'),
            'state': {}
        }
        payload['is_msprovider'] = FileConstants.isMSProvider;
        if(selectedImageType == 'silo'){
            let base_url = ENVIRONMENT.getBaseURL(baseVariantPlatform)
            let renderPath = selectedProductImage.split(base_url)[1]
            payload['render_path'] = renderPath
            payload['product_id'] = product_id
        }
        else{
            payload['scene_id'] = selectedLifestyleSceneId
        }

        axios.post(ENVIRONMENT.CREATE_COLLABORATE_ENTITY, payload)
        .then(res => {
            if (res.data && res.data.body) {
                setCollaborationConfirmModal(false)
                setCollaborateConfirmButtonLoader(false)
                let collaboration_id = res.data.body.collaboration_entity_id;
                let url = '/collaborate-tool/'+ collaboration_id;
                let new_window = window.open(url, '_blank');
                if (new_window) {
                    new_window.focus();
                } else {
                    window.location.href = url;
                }
            }
        })
    }

    const openCollaborateTool = () => {
        setCollaborateConfirmButtonLoader(true)

        let payload = {
            'camera_name': selectedFilenameWithExtension
        }
        if(selectedImageType == 'lifestyle'){
            payload.scene_id = selectedLifestyleSceneId
        }
        else if(selectedImageType == 'silo'){
            payload.product_id = product_id
        }

        axios.post(ENVIRONMENT.GET_COLLABORATE_ENTITY, payload)
            .then(res => {
                console.log('res', res)
                if (res.data && res.data.length == 0) {
                    createCollaborateEntity();
                } else {
                    setCollaborationConfirmModal(false);
                    setCollaborateConfirmButtonLoader(false)
                    let collaboration_id = res.data.collaboration_entity_id;
                    let url = '/collaborate-tool/' + collaboration_id;

                    let new_window = window.open(url, '_blank');
                    if (new_window) {
                        new_window.focus();
                    } else {
                        window.location.href = url;
                    }
                }
            });
    }

    const deleteLifestyle = () => {
        setDeleteLoader(true)
        let payload = {
            action: "hide_camera_product_mapping",
            product_ids: [product_id],
            old_camera: selectedDeleteLifestyle,
            scene_id: selectedLifestyleSceneId
        }
        axios.post(ENVIRONMENT.CAMERA_PRODUCT_MAP_CONTROLLER, payload)
        const allImages = [];
        for (const imageObj of productAssetData['lifestyle_data']) {
            if (!(imageObj.camera_name == selectedDeleteLifestyle && imageObj.scene_id == selectedLifestyleSceneId)) {
                allImages.unshift(imageObj);
            }
        }

        productAssetData['lifestyle_data'] = allImages
        productAssetData['silo_data'] = productAssetData['silo_data'].reverse()

        setDeleteLoader(false);
        setDeleteModal(false);
        message.info('Lifestyle Image Deleted.');
        allSystemGeneratedProductImages(productAssetData);

        const allProdImages = [];
        for (const imageObj of allProductImages) {
            if (!(imageObj.type == 'lifestyle' && imageObj.filename == selectedDeleteLifestyle && imageObj.scene_id == selectedLifestyleSceneId)) {
                allProdImages.push(imageObj);
            }
        }
        var next_image = getNextImageURL(allProductImages)
        let image_index = allProductImages.findIndex(img => img.url == next_image);
        setSelectedProductImage(allProdImages[image_index - 1].url);
        setSelectedImageResolution(allProductImages[image_index - 1].resolution);
        setSelectedImageFormat(allProductImages[image_index - 1].format);
        setSelectedImageName(allProductImages[image_index - 1].display_name);
        setSelectedImageCapturedBy(allProductImages[image_index - 1].captured_by);
        setSelectedImageType(allProductImages[image_index - 1].main_type);
        setSelectedImageCreationDate(allProductImages[image_index - 1].created_at);

        carousel.goTo(image_index - 1);
        setImgAccessedFromCarousel(true);
    }

    const renderFavIcon = () => {
        let images = allProductImages.filter(img => img.url === selectedProductImage);
        let image = null;
        if(images.length == 1){
            image = images[0]
        }
        if ((image.type == "lifestyle" && getStatus() != 'pending') || (image.type == "silo" && getStatus() != 'pending')) {
            return image.isFavourite ? (
                <Tooltip title={<span className="manrope f-12 white">Unfavorite</span>}>
                <div
                    className="pointer-event-auto img-fav-icon-modal white"
                    onClick={(e) => handleFavorite()}
                >
                    <HeartFilled style={{ fontSize: "24px" }} />
                </div>
                </Tooltip>
            ) : (
                <Tooltip title={<span className="manrope f-12 white">Favorite</span>}>
                <div
                    className="pointer-event-auto img-fav-icon-modal white"
                    onClick={(e) => handleFavorite()}
                >
                    <HeartOutlined style={{ fontSize: "24px" }} />
                </div>
                </Tooltip>
            );
        }
    }

    const updatedRenderNameInImagesList = (url, updatedDisplayName) => {
        let updatedList = allProductImages.map(item => {
          if(item.url === url){
              return { ...item, display_name: updatedDisplayName };
          }
          else{
            return item;
          }
        })
        setAllProductImages(updatedList)
    }

    const renameRenderName = () => {
        setEditRenderNameLoader(true)
        let renderNameField = document.getElementById("render-name");
        renderNameField.contentEditable = false
        let updatedName = renderNameField.innerText

        let selectedObj = allProductImages.filter(item => item.url === editedRenderUrl)[0]
        if(updatedName.trim().length){
            if(selectedImageType == 'silo' && selectedObj){
                let payload = {
                    product_id: `${selectedObj.product_id}`,
                    action: 'rename_silo',
                    camera_name: selectedObj.camera_name,
                    updated_render_name: updatedName
                }
                if(COMPANY_ID){
                    payload['company_id'] = COMPANY_ID
                }
                else{
                    payload['customer_username'] = CUSTOMER_USERNAME
                }
                axios.post(ENVIRONMENT.POST_PRODUCT_ASSET_UPDATE, payload)
                .then(res => {
                    setSelectedImageName(updatedName)
                    updatedRenderNameInImagesList(editedRenderUrl, updatedName)
                    resetRenderNameField()
                })
            }
            else if(selectedImageType == 'lifestyle' && selectedObj){
                let payload = {
                    scene_id: `${selectedObj.scene_id}`,
                    render_name: {
                    [selectedObj.filename]: updatedName
                    }
                }
                axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
                .then(res => {
                    setSelectedImageName(updatedName)
                    updatedRenderNameInImagesList(editedRenderUrl, updatedName)
                    resetRenderNameField()
                })
            }
        }
        else{
            renderNameField.innerText = previousRenderName
            resetRenderNameField()
        }
    }

    const resetRenderNameField = () => {
        let renderNameField = document.getElementById("render-name");
        renderNameField.contentEditable = false;
        setEditRenderNameLoader(false)
        setEditedRenderUrl(null)
        setFilenameBeingEdited(false)
    }

    const makeRenderNameEditable = () => {
        let renderNameField = document.getElementById("render-name");
        renderNameField.contentEditable = true;
        setFilenameBeingEdited(true)
        setPreviousRenderName(renderNameField.innerText)
    }

    const deleteSilo = () => {
        setDeleteLoader(true)
        let payload = {
            action: "delete",
            product_id: product_id,
            camera_name: selectedDeleteSilo,
            customer_username: CUSTOMER_USERNAME
        }

        if (companyId) {
            payload.company_id = companyId
        }

        axios.post(ENVIRONMENT.POST_PRODUCT_ASSET_UPDATE, payload)
        const allImages = [];
        for (const imageObj of productAssetData['silo_data']) {
            if (imageObj.camera_name !== selectedDeleteSilo) {
                allImages.unshift(imageObj);
            }
        }

        productAssetData['silo_data'] = allImages
        productAssetData['lifestyle_data'] = productAssetData['lifestyle_data'].reverse()

        setDeleteLoader(false);
        setDeleteModal(false);
        message.info('Silo Image Deleted.');
        allSystemGeneratedProductImages(productAssetData);

        const allProdImages = [];
        for (const imageObj of allProductImages) {
            if (!(imageObj.type == 'silo' && imageObj.filename == selectedDeleteSilo)) {
                allProdImages.push(imageObj);
            }
        }
        var next_image = getNextImageURL(allProductImages)
        let image_index = allProductImages.findIndex(img => img.url == next_image);
        setSelectedProductImage(allProdImages[image_index - 1].url);
        carousel.goTo(image_index - 1);
        setImgAccessedFromCarousel(true);
    }

    const handleKeyPressForRename = (keyCode) => {
        if(keyCode == 13){
            // Enter is Pressed
            renameRenderName()
        }
        else if(keyCode == 27){
            // ESC is pressed
            let scene_render_field = document.getElementById('render-name');
            scene_render_field.contentEditable = false;
            scene_render_field.innerText = previousRenderName;
    
            setFilenameBeingEdited(false)
            setEditedRenderUrl(null)
            setEditRenderNameLoader(false)
        }
    }

    const handleMouseEnter = () => {
        if (!filenameBeingEdited) {
            setTooltipVisible(true)
        }
    };
    
    const handleMouseLeave = () => {
        setTooltipVisible(false)
    };

    const menu = (
        <Menu style={{ width: "148px" }}>
            <Menu.Item key={0}>
                <a onClick={() => {
                    Utilities.downloadFile(selectedImageFormat == "tiff" ? selectedProductImage.replace("jpg", "tiff") : selectedProductImage, selectedImageName, selectedImageFormat)
                }}
                    className='manrope f-14 black-55' download>This image only</a>
            </Menu.Item>
            <Menu.Item key={1}>
                <div className='manrope f-14 black-55' onClick={() => downloadSiloImages()}>Silo Images</div>
            </Menu.Item>
            <Menu.Item key={2}>
                <div className='manrope f-14 black-55' onClick={() => downloadImagesArchive()}>All Images</div>
            </Menu.Item>
            {COMPANY_ID == ENVIRONMENT.WALMART_COMPANY_ID && selectedImageType == 'lifestyle' ?
            <Menu.Item key={3}>
                <div className='manrope f-14 black-55' onClick={() => Utilities.generateAndDownloadWatermarkedImage(selectedImageFormat == "tiff" ? selectedProductImage.replace("jpg", "tiff") : selectedProductImage, selectedImageName + '.' + selectedImageFormat )}>Watermarked Image</div>
            </Menu.Item> : ''}
        </Menu>
    );

    return (
        <Row>
            <Col md={7} lg={7} xl={5} className='display-flex j-s a-s wrap sa-s gg-16 custom-scroll product-grid-list'>
                <ImageListType
                    allProductImages={allProductImages}
                    type={"silo"}
                    name={"Silo Images"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                    setSelectedDeleteSilo={setSelectedDeleteSilo}
                    setDeleteImageType={setDeleteImageType}
                    setDeleteModal={setDeleteModal}
                    setSelectedDeleteSiloURL={setSelectedDeleteSiloURL}
                    setSelectedFilenameWithExtension={setSelectedFilenameWithExtension}
                />
                <ImageListType
                    allProductImages={allProductImages}
                    type={"dim"}
                    name={"DIM"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                />
                <ImageListType
                    allProductImages={allProductImages}
                    type={"low_res_preview"}
                    name={"Previews"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                />
                <ImageListType
                    allProductImages={allProductImages}
                    type={"lifestyle"}
                    name={"Lifestyle Images"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                    setDeleteModal={setDeleteModal}
                    setDeleteImageType={setDeleteImageType}
                    setSelectedDeleteLifestyle={setSelectedDeleteLifestyle}
                    setSelectedLifestyleSceneId={setSelectedLifestyleSceneId}
                    setSelectedFilenameWithExtension={setSelectedFilenameWithExtension}
                />
            </Col>
            <Col md={17} lg={17} xl={19} className='w-100'>
                <div className="resolutionText">
                    <div className="display-flex justify-start align-baseline">
                        {selectedImageName &&
                            <Tooltip visible={tooltipVisible} title={<span className="manrope f-12 white">{selectedImageName}</span>}>
                                <h4 id='render-name' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onKeyDown={(e) => {e.stopPropagation(); handleKeyPressForRename(e.keyCode)}}
                                className={`manrope f-20 ml-10 mb-0 scene-render-name${editedRenderUrl == selectedProductImage ? `-editable` : ``}`}>
                                {selectedImageName}</h4>
                            </Tooltip>}
                            {selectedImageType == 'silo' || selectedImageType == 'lifestyle' ?
                                filenameBeingEdited && editedRenderUrl == selectedProductImage ?
                                <Button disabled={editRenderNameLoader} className="save-button font-12" onClick={() => { 
                                    renameRenderName()
                                    }} 
                                style={{ marginLeft: 15,display: 'flex', justifyContent: 'flex-end'  }}>
                                    Save {editRenderNameLoader ? <LoadingOutlined /> : ''}
                                </Button>
                                :
                                <div onClick={() => { makeRenderNameEditable(); setEditedRenderUrl(selectedProductImage)}} className="edit-icon-data-small edit-icon-blue mt-0" >
                                    <EditOutlined />
                                </div> 
                            : ''}
                            {selectedImageCreationDate !== 'Invalid Date' && selectedImageCreationDate !== '' &&
                            selectedImageCapturedBy ? (
                                <h5 className="manrope w-500 f-12 ml-8 grey-8c mb-0">
                                    {`Created on ${selectedImageCreationDate} by `}
                                    <span style={{ fontWeight: 'bold' }}>{selectedImageCapturedBy}</span>
                                </h5>
                            ) : selectedImageCreationDate !== 'Invalid Date' && selectedImageCreationDate !== '' ? (
                                <h5 className="manrope w-500 f-12 ml-8 grey-8c mb-0">
                                    {`Created on ${selectedImageCreationDate}`}
                                </h5>
                            ) : selectedImageCapturedBy ? (
                                <h5 className="manrope w-500 f-12 grey-8c mb-0" style={{ marginLeft: '4px' }}>
                                    by <span style={{ fontWeight: 'bold' }}>{`${selectedImageCapturedBy}`}</span>
                                </h5>
                            ) : null}
                    </div>
                    {selectedImageResolution || selectedImageFormat ?
                        <h4 className="manrope w-500 f-16 mr-20 mb-0">{selectedImageResolution ? selectedImageResolution : ''}  {selectedImageFormat ? selectedImageFormat.toUpperCase() : ''}</h4>
                        : ''}
                </div>

                {getStatus() !== 'pending' && !isStorePage && (selectedImageType == 'silo' || selectedImageType == 'lifestyle') &&
                    <div
                        className='silo-image-collaborate-icon white border mt-30'
                        onClick={() => setCollaborationConfirmModal(true)}
                    >
                        <img
                            className='collaborate-img'
                            src='/img/collaborate_tool/new-new-collab.svg'
                            alt="Collaboration Icon"
                        />
                        <span
                            className={'manrope f-14 w-500'}
                        >
                            Collaborate
                        </span>
                    </div>
                }

                {getStatus() != "pending" && !isStorePage &&
                <div className='overlay-container mt-30'>
                    {renderFavIcon()}
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Button className='product-btn dark-blue f-14 br-4'>
                            Download <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
                }
                {getStatus() == "pending" &&
                    <div className="render-in-progress">
                        Render in Progress
                    </div>}
                <div className="carousel-btns right-btn" onClick={next}>
                    <ArrowRightOutlined className="arrow" />
                </div>
                <Carousel autoplay={false} ref={node => (carousel = node)} className='w-100 product-carousel padding-10'
                    beforeChange={onChangeCarousel}>
                    {allProductImages.map((img, index) => (
                        <div key={index}>
                            {<ZoomImage
                                image={img.url}
                                index={index}
                                asset_viewer_modal={true}
                                className="product-carousel-image"
                            />}
                        </div>
                    ))}
                </Carousel>
                <div className="carousel-btns left-btn" onClick={prev}>
                    <ArrowLeftOutlined className="arrow" />
                </div>
                <div className='count-tag'>
                    <div className='manrope f-14 white lh-28'>{getImageNumber()} of {productImageCount}</div>
                </div>
            </Col>

            <DeleteImageModal
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteImageType={deleteImageType}
                deleteLifestyle={deleteLifestyle}
                deleteSilo={deleteSilo}
                deleteLoader={deleteLoader}
            />
        
        <Modal
        destroyOnClose={true}
        className="modal-space"
        width={700}
        visible={collaborationConfirmModal}
        closable={false}
        bodyStyle={{padding: 0}}
        footer={[
            <span className="justify-in-end">
                <Button disabled={collaborateConfirmButtonLoader} style={{textAlign:"center"}} className="modal-okay-gray square font-12" onClick={() => setCollaborationConfirmModal(false)}>
                    Cancel
                </Button>
                <Button disabled={collaborateConfirmButtonLoader} style={{textAlign:"center"}} className="modal-okay square font-12" onClick={openCollaborateTool}>
                    {collaborateConfirmButtonLoader ? <span>Please wait.. <LoadingOutlined spin /></span>:<span>Go to tool </span>}
                </Button>
            </span>
        ]}
        >
        <div style={{padding: 30, display: 'flex'}}>
            <Col span={24}>
            <h2 className="modal-heading manrope f-14 black-00 w-600">Go to collaborate tool? </h2>
            <p className="manrope f-12 black-33">This tool will allow you to post any feedback you have for this render. </p>
            </Col>
        </div>
        </Modal>
        </Row>
    );
}
export default ProductImageCarousel;