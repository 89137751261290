import React, { useState, useEffect, useContext } from 'react';
import ENVIRONMENT from '../../../../environments';
import { Modal, Carousel, Button } from 'antd';
import ChangeLogConstants from './Constants';
import './CustomerChangeLog.scss'
import * as Utilities from '../../Utilities';
import { min } from 'moment';

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const CHANGE_LOG = ChangeLogConstants.CHANGE_LOG;
const CHANGE_LOG_VIEWED = localStorage.getItem(CHANGE_LOG.active_key);
const FEATURE_DETAIL_LIST = CHANGE_LOG.feature_detail;
const ACTIVE_FEATURE_KEY = CHANGE_LOG.active_key;

const Dots = (props) => {
    return (props.carouselData && props.carouselData.map((dot, index) => (
        index == 0 ? '' :
            <div
                className={`change-log-carousel-dots mr-8 ${index == (props.carouselStart - 1) ? `active` : ``}`}
                onClick={() => props.goToDot(index)}>
            </div>
    )))
}

const CustomerChangeLog = (props) => {
    const [visible, setVisible] = useState(false);
    const [carouselData, setCarouselData] = useState(null);
    const [triggerNext, setTriggerNext] = useState(false);
    const [carouselStart, setCarouselStart] = useState(1);
    const [currentSlideInfo, setCurrentSlideInfo] = useState(null);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [carouselCancelled, setCarouselCancelled] = useState(false);
    let carousel = React.createRef();

    useEffect(() => {
        let active_key = ACTIVE_FEATURE_KEY;
        if (props.active_key) {
            active_key = props.active_key;            
        }
        setSelectedFeature(Utilities.findObjectByKey(active_key, FEATURE_DETAIL_LIST));
        setCarouselData(Utilities.findObjectByKey(active_key, FEATURE_DETAIL_LIST).detail);
        setCurrentSlideInfo(Utilities.findObjectByKey(active_key, FEATURE_DETAIL_LIST).detail[0]);
    }, [props]);

    useEffect(() => {
        // Remove the keys for older deployment - set this key according to that
        localStorage.removeItem('product_collab_qa_change');
        localStorage.setItem('revamped-home-page', true);
        // if user has not viewed
        if ((CHANGE_LOG_VIEWED == null || props.active_key != undefined) && carouselData && selectedFeature && currentSlideInfo && carouselCancelled == false) {
            console.log(CHANGE_LOG_VIEWED, props.active_key)
            setTimeout(() => {
                if (localStorage.getItem(CHANGE_LOG.active_key) == null) {
                    console.log(localStorage.getItem(CHANGE_LOG.active_key))
                    setVisible(true);
                }
                    
            }, 10000)
        } else {
            setVisible(false);
        }
    }, [carouselData, selectedFeature, currentSlideInfo, carouselCancelled]);

    const next = () => {
        if (carousel) {
            carousel.next();
            //this detects that the user moved the carousel through next/prev button
            setTriggerNext(true);
        }
    };

    const prev = () => {
        //if user moves back from step from carousel then close the carousel
        if (carousel) {
            if (carouselStart == 1) {
                carousel.goTo(0, true);
            } else {
                carousel.prev();
                //this detects that the user moved the carousel through next/prev button
                setTriggerNext(true);
            }
        }
    };

    const onChangeCarousel = (before, to) => {
        console.log(carouselData)
        setCurrentSlideInfo(carouselData[to]);
        setCarouselStart(to + 1);
        //if user completes the tour i.e if the next step is equal to the number of images on s3, then show congrats modal
        if (
            before == carouselData.length - 1 &&
            to == 0 &&
            triggerNext
        ) {
            if (carousel) {
                carousel.goTo(0, true);
            }

            setTriggerNext(false);
        } else if (
            before == 0 &&
            to == carouselData.length - 1 &&
            triggerNext
        ) {
            setTriggerNext(false);
        }

    };

    const onCancelCarousel = () => {
        setVisible(false);
        setCarouselCancelled(true);
        if (carousel) {
            carousel.goTo(0, true);
        }
        localStorage.setItem(selectedFeature.key, true);
        
    };

    const goToLink = () => {
        localStorage.setItem(selectedFeature.key, true);
        if (selectedFeature.route == '/home') {
            onCancelCarousel();
        } else {
            window.location.href = selectedFeature.route;
        }
    }

    const goToDot = (index) => {
        setCurrentSlideInfo(carouselData[index]);
        setCarouselStart(index + 1);
        if (carousel) {
            carousel.goTo(index, true);
        }
    }

    return (<Modal
        visible={visible}
        title={null}
        closable={false}
        footer={null}
        onCancel={onCancelCarousel}
        bodyStyle={{ padding: 0 }}
        className='change-log-modal-width'
        centered={true}
    >
        <div className='change-log-modal-pd'>
            <div style={{ width: "100%", textAlign: "center" }}>
                <Carousel
                    dots={false}
                    autoplay={false}
                    ref={(node) => carousel = node}
                    style={{ width: "100%" }}
                    beforeChange={onChangeCarousel}
                >
                    {carouselData && carouselData.map((detail) => (
                        <div>
                            <img
                                style={{ marginTop: 21 }}
                                className="change-log-image-dim"
                                src={detail.illustration_link}
                                alt="carousel help"
                            />
                        </div>
                    ))}
                </Carousel>
            </div>

            {carouselStart == 1 && selectedFeature ?
            <div className='change-log-feature-type-bg mt-30'>
                <div className='manrope f-14 blue-96'>
                    {selectedFeature.feature_type}
                </div>
            </div>: ''}

            {currentSlideInfo &&
                <div className='justify-in-center flex-col'>
                    <div className='text-center manrope f-24 black-00 mt-16'>
                        {currentSlideInfo.heading}
                    </div>
                    <div className='text-center manrope f-16 black-00 mt-8 mb-30'>
                        {currentSlideInfo.text}
                    </div>
                </div>}
            {carouselStart == 1 ?
                <div className='justify-in-center'>
                    <div className='manrope f-14 blue cursor-pointer' onMouseDown={() => onCancelCarousel()}>
                        Got it
                    </div>
                    <Button className='basic-blue-btn f-14 ml-16' onClick={() => next()}>
                        Learn More
                    </Button>
                </div> : (selectedFeature && carouselData) && carouselStart == carouselData.length ?
                    <div className='justify-space-between'>
                        <div className='display-flex'>
                            <Dots goToDot={goToDot} 
                            carouselData={carouselData}
                            carouselStart={carouselStart} />
                        </div>
                        <div className='justify-in-end'>
                            {/* <div className='manrope f-14 blue cursor-pointer' onMouseDown={() => onCancelCarousel()}>
                                Close
                            </div> */}
                            <Button className='basic-blue-btn f-14 ml-16' onClick={goToLink}>
                                {selectedFeature.main_action}
                            </Button>
                        </div>
                    </div> : (selectedFeature && carouselData) && 
                    <div className='justify-space-between'>
                        <div className='display-flex'>
                            <Dots goToDot={goToDot} carouselData={carouselData} carouselStart={carouselStart} />
                        </div>
                        <div className='justify-in-end'>
                            <Button className='basic-blue-btn f-14 ml-16' onClick={() => next()}>
                                Next
                            </Button>
                        </div>
                    </div>}
        </div>
    </Modal>)

}

export default CustomerChangeLog;